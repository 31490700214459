import { createAction, handleActions } from 'redux-actions';
interface UpdateTypes {
  id: string;
  text: any;
  data?: any;
}

// Actions
export const Actions_Property = {
  // 目前只用到这个（2022-01-29）
  GET_ALL_PROPERTY_LIST: 'GET_ALL_PROPERTY_LIST', // tenant list / tenancy list / jobinfo chat 模块中的property列表
  GET_ALL_PROPERTY_LIST_COMPLETE: 'GET_ALL_PROPERTY_LIST_COMPLETE',
  GET_ALL_PROPERTY_LIST_CLEAN: 'GET_ALL_PROPERTY_LIST_CLEAN',
  GET_ACTIVE_PROPERTY_LIST: 'GET_ACTIVE_PROPERTY_LIST', // tenancy list 已有租约的property
  GET_ACTIVE_PROPERTY_LIST_COMPLETE: 'GET_ACTIVE_PROPERTY_LIST_COMPLETE',

  GET_PROPERTY_LIST: 'GET_PROPERTY_LIST',
  GET_PROPERTY_LIST_PARAMS: 'GET_PROPERTY_LIST_PARAMS',
  GET_PROPERTY_LIST_COMPLETE: 'GET_PROPERTY_LIST_COMPLETE',
  CLEAN_LIST_DATA: 'CLEAN_LIST_DATA',
  UPDATE_NEW_PROPERTY: 'UPDATE_NEW_PROPERTY',
  CREATE_NEW_PROPERTY: 'CREATE_NEW_PROPERTY',
  DELETE_PROPERTY: 'DELETE_PROPERTY',
  GET_PROPERTY_DETAILS: 'GET_PROPERTY_DETAILS',
  GET_PROPERTY_DETAILS_COMPLETE: 'GET_PROPERTY_DETAILS_COMPLETE',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  // UPDATE_PROPERTY_COMPLETE: 'UPDATE_PROPERTY_COMPLETE',
  // SAVE_TENANT: "SAVE_TENANT",
  // SAVE_TENANCY: "SAVE_TENANCY",
  // GET_TENANCIES_LIST: "GET_TENANCIES_LIST",
  // GET_TENANCIES_LIST_COMPLETE: "GET_TENANCIES_LIST_COMPLETE",
  // GET_TENANCT_PARAMS: "GET_TENANCT_PARAMS",
  // UPDATE_TENANT: "EDIT_TENANT",
  // UPDATE_TENANCY: "UPDATE_TENANCY",
  // DELETE_TENANCY: "DELETE_TENANCY",
  // GET_TENANT_LIST: "GET_TENANT_LIST",
  // GET_TENANT_LIST_COMPLETE: "GET_TENANT_LIST_COMPLETE",

  GET_PMANAGER_LISTING: 'GET_PMANAGER_LISTING',
  GET_PMANAGER_LISTING_COMPLETE: 'GET_PMANAGER_LISTING_COMPLETE',
  NEW_PMANAGER: 'NEW_PMANAGER',
  UPDATE_PMANAGER: 'UPDATE_PMANAGER',
  UPDATE_PROPERTY_MANAGER: 'UPDATE_PROPERTY_MANAGER',
  PMANAGER_PARAMS: 'PMANAGER_PARAMS',

  // owner
  GET_OWNER_LIST: 'GET_OWNER_LIST',
  GET_OWNER_LIST_COMPLETE: 'GET_OWNER_LIST_COMPLETE',
  NEW_OWNER: 'NEW_OWNER',
  BACK_OWNER_LIST: 'BACK_OWNER_LIST'
};

// Actions Creators
// tenant list / tenancy list / jobinfo chat 模块中的property列表
export const _getAllPropertyList = createAction(
  Actions_Property.GET_ALL_PROPERTY_LIST
);
export const _getAllPropertyListComplete = createAction(
  Actions_Property.GET_ALL_PROPERTY_LIST_COMPLETE
);
export const _getAllPropertyListClean = createAction(
  Actions_Property.GET_ALL_PROPERTY_LIST_CLEAN
);
export const _getActivePropertyList = createAction(
  Actions_Property.GET_ACTIVE_PROPERTY_LIST
);
export const _getActivePropertyListComplete = createAction(
  Actions_Property.GET_ACTIVE_PROPERTY_LIST_COMPLETE
);
// 目前只用到前面几个

export const _getPropertyList = createAction(
  Actions_Property.GET_PROPERTY_LIST
);
export const _getPropertyListParams = createAction(
  Actions_Property.GET_PROPERTY_LIST_PARAMS
);
export const _getPropertyListComplete = createAction(
  Actions_Property.GET_PROPERTY_LIST_COMPLETE
);
export const _cleanListData = createAction(Actions_Property.CLEAN_LIST_DATA);
/**
 * @msg: 更新NewProperty的输入数据
 */
export const _updateNewProperty = createAction(
  Actions_Property.UPDATE_NEW_PROPERTY
);
export const _getPropertyDetails = createAction(
  Actions_Property.GET_PROPERTY_DETAILS
);
export const _getPropertyDetailsComplete = createAction(
  Actions_Property.GET_PROPERTY_DETAILS_COMPLETE
);
export const _update_Property = createAction(Actions_Property.UPDATE_PROPERTY);

export const _createNewProperty = createAction(
  Actions_Property.CREATE_NEW_PROPERTY
);

export const _deleteProperty = createAction(Actions_Property.DELETE_PROPERTY);

export const _getPManagerListing = createAction(
  Actions_Property.GET_PMANAGER_LISTING
);
export const _getPManagerListingComplete = createAction(
  Actions_Property.GET_PMANAGER_LISTING_COMPLETE
);
export const _newPManager = createAction(Actions_Property.NEW_PMANAGER);
export const _updatePManager = createAction(Actions_Property.UPDATE_PMANAGER);
export const _updatePropertyManager = createAction(
  Actions_Property.UPDATE_PROPERTY_MANAGER
);
export const _setPmanagerParams = createAction(
  Actions_Property.PMANAGER_PARAMS
);

export const _getOwnerList = createAction(Actions_Property.GET_OWNER_LIST);
export const _getOwnerListComplete = createAction(
  Actions_Property.GET_OWNER_LIST_COMPLETE
);
export const _newOwner = createAction(Actions_Property.NEW_OWNER);
export const _backOwnerList = createAction(Actions_Property.BACK_OWNER_LIST);

export const propertyReducers = {
  _getAllPropertyList, // 2022-01-29
  _getAllPropertyListComplete, // 2022-01-29
  _getAllPropertyListClean,
  _getActivePropertyList, // 2022-02-24
  _getActivePropertyListComplete, // 2022-02-24

  _getPropertyList,
  _getPropertyListParams,
  _getPropertyListComplete,
  _cleanListData,

  _updateNewProperty,
  _createNewProperty,
  _deleteProperty,
  _getPropertyDetails,
  _getPropertyDetailsComplete,
  _update_Property,
  // _update_Property_Complete,
  // _saveTenant,
  // _saveTenancy,
  // _getTenanciesList,
  // _getTenanciesListComplete,
  // _getTenantParams,
  // _updateTenant,
  // _updateTenancy,
  // _deleteTenancy,
  // _getTenantList,
  // _getTenantListComplete,

  _getPManagerListing,
  _getPManagerListingComplete,
  _newPManager,
  _updatePManager,
  _updatePropertyManager,
  _setPmanagerParams,

  _getOwnerList,
  _getOwnerListComplete,
  _newOwner,
  _backOwnerList
};

const PropertyInfoData = {
  property_id: '',
  property_name: '',
  telCountryCode: '',
  tel: '',
  address1: '',
  address2: ' ',
  city: '',
  region: '',
  country: '',
  address1Local: '',
  address2Local: ' ',
  cityLocal: '',
  regionLocal: '',
  postcode: '',
  phoneCC: '',
  phone: '',
  wifi_network: '',
  wifi_password: '',
  album_id: '',
  photo_id: '',
  timezone: '',
  longitude: '114.110992',
  latitude: '22.372415',
  accuracy: '',
  status: 'O',
  property_manager: '',
  created_by: '',
  time_created: '',
  summary: '',
  detailed: '',
  operation_cost: '0',
  expenses: '0',
  currency: '',
  currency_symbol: '$',
  currency_decimals: '2',
  account_balance: '0',
  qrcode: null,
  removed: '0',
  time_updated: '',
  updated_by: '',
  status_label: '<span class="badge badge-success">Open</span>',
  country_text: 'Hong Kong',
  region_text: '',
  address: 'Hong Kong   causeway bay  17 Hennessy road',
  addressLocal: 'Hong Kong   銅鑼灣  軒尼詩道 17 號',
  phoneCountryCode: '852',
  full_phone: '+852 12345678',
  timezone_location: 'Mainland China',
  currency_text: 'Hong Kong Dollars',
  owner: {
    FirstName: '',
    Gender: '',
    LastName: '',
    MiddleName: '',
    photo_id: '',
    role: '',
    timezone_id: '',
    user_id: ''
  },
  tenancies: null,
  pmanager: null,
  photos: []
};
/**
 * Reducer
 */
const arrayAny: any = [];
const arrayString: Array<string> = [];
const propertyListTableDataType: any = [];
const defaultState = {
  allProperty: [], // tenant list / tenancy list / jobInfo chat
  allLength: 0,
  allPropertySize: 10000,
  activeProperty: [], // tenancy list 已有租约的propertylist
  activeLength: 0,

  PropertyListData: {
    data: propertyListTableDataType,
    page: 1,
    size: 10,
    total: 0
  },
  requstParams: {
    size: 12,
    page: 1,
    fill: 1,
    // entity: "", //property asset none
    // internal_jobs: null, //1,0,null
    // status: "", //
    order_by: '', // time_required, provider_id, entity , status
    direction: '', // asc ,desc
    search: ''
  },
  newProperty: {
    PropertyName: '',
    Country: '',
    country_text: '',
    CountryIndex: [],
    StateProvince: '',
    StateProvinceIndex: [],
    StateProvinceData: [],
    City: '',
    Address: '',
    Apartment: '',
    ZipPostal: '',
    photos: arrayAny,
    required: arrayString,
    region: '',
    regionLocal: '',
    region_text: ''
  },
  PropertyInfoData,
  PropertyInfoOldProperty: {},
  tenancyData: {
    data: [],
    page: 1,
    size: 10
  },
  tenantData: {
    data: [],
    page: 1,
    size: 10,
    contacts: []
  },
  tenantParams: {
    type: 0,
    tenant: {},
    tenancy: {},
    onGoBack: () => {
      return null;
    },
    navigation: {}
  },
  PmanagerParams: {
    type: '',
    Pmanager: {},
    navigation: {}
  },
  PManagerListData: {
    metadata: [],
    AppliedData: [],
    page: 1,
    size: 10,
    total: 0
  },
  ownerList: {
    metadata: [],
    AppliedData: [],
    page: 1,
    size: 10,
    total: 0
  },
  backOwnerList: { go: false }
};
export type propertyState = Readonly<typeof defaultState>;
interface UpdatePropertyTypes {
  id: string;
  text: any;
}
export default handleActions(
  {
    // property list
    [Actions_Property.GET_ALL_PROPERTY_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        allProperty: action.payload.arr,
        allLength: action.payload.length
      };
    },
    [Actions_Property.GET_ALL_PROPERTY_LIST_CLEAN]: (state) => {
      return {
        ...state,
        allProperty: [],
        allLength: 0
      };
    },
    [Actions_Property.GET_ACTIVE_PROPERTY_LIST_COMPLETE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        activeProperty: action.payload.arr,
        activeLength: action.payload.length
      };
    },
    // 2022-04-19

    [Actions_Property.GET_PROPERTY_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        PropertyListData: action.payload
      };
    },
    [Actions_Property.GET_PROPERTY_LIST_PARAMS]: (state, action: any) => {
      const { id, text, data }: UpdateTypes = action.payload;
      switch (id) {
        case 'Sort':
          return {
            ...state,
            requstParams: {
              ...state.requstParams
              // order_by: data.order_by,
              // direction: data.direction,
            }
          };
        case 'size':
          return {
            ...state,
            requstParams: {
              ...state.requstParams,
              page: data.page,
              size: data.size
            }
          };
        case 'search':
          return {
            ...state,
            requstParams: {
              ...state.requstParams,
              search: text,
              ...{
                size: 10,
                page: 1
                // entity: "", //property asset none
                // internal_jobs: null, //1,0,null
                // status: "", //
                // order_by: "", //time_required, provider_id, entity , status
                // direction: "", //asc ,desc
              }
            }
          };
        default:
          return {
            ...state,
            requstParams: { ...state.requstParams }
          };
      }
    },
    [Actions_Property.CLEAN_LIST_DATA]: (state) => {
      return {
        ...state,
        PropertyListData: {
          ...state.PropertyListData,
          data: []
        },
        allProperty: []
      };
    },
    [Actions_Property.GET_PROPERTY_DETAILS_COMPLETE]: (state, action: any) => {
      const { id, text } = action.payload;
      switch (id) {
        case 'add': {
          const {
            property_name,
            city,
            address1Local,
            address2Local,
            postcode,
            country,
            region_text
          } = text;
          return {
            ...state,
            PropertyInfoData: text,
            newProperty: {
              ...state.newProperty,
              ...text,
              PropertyName: property_name,
              City: city,
              Address: address1Local,
              Apartment: address2Local,
              ZipPostal: postcode,
              photos: arrayAny,
              Country: country,
              region_text: region_text
            },
            PropertyInfoOldProperty: {
              ...state.newProperty,
              ...text,
              PropertyName: property_name,
              City: city,
              Address: address1Local,
              Apartment: address2Local,
              ZipPostal: postcode,
              photos: arrayAny,
              Country: country,
              region_text: region_text
            }
          };
        }
        case 'clean':
          return {
            ...state,
            PropertyInfoData: PropertyInfoData,
            PropertyInfoOldProperty: {}
          };
        default:
          return {
            ...state,
            newProperty: action.payload
          };
      }
    },
    [Actions_Property.UPDATE_NEW_PROPERTY]: (state, action: any) => {
      const { id, text }: UpdatePropertyTypes = action.payload;
      switch (id) {
        case 'Property Name':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              PropertyName: text,
              required: text
                ? state.newProperty.required.filter((value) => value !== id)
                : [...state.newProperty.required, id]
            }
          };
        case 'Address':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              Address: text,
              required: text
                ? state.newProperty.required.filter((value) => value !== id)
                : [...state.newProperty.required, id]
            }
          };
        case 'City':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              City: text,
              required: text
                ? state.newProperty.required.filter((value) => value !== id)
                : [...state.newProperty.required, id]
            }
          };
        case 'Apartment,suite,etc':
          return {
            ...state,
            newProperty: { ...state.newProperty, Apartment: text }
          };
        case 'Zip/Postal':
          return {
            ...state,
            newProperty: { ...state.newProperty, ZipPostal: text }
          };
        case 'CountryIndex':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              CountryIndex: text.index,
              Country: text.item.value || '',
              country_text: text.item.name || ''
            }
          };
        case 'StateProvinceIndex':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              StateProvinceIndex: text.index,
              StateProvince: text.item.value || '',
              region: text.item.value || '',
              region_text: text.item.name || ''
            }
          };
        case 'StateProvinceData':
          return {
            ...state,
            newProperty: { ...state.newProperty, StateProvinceData: text }
          };
        case 'photos':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              photos: [...state.newProperty.photos, ...text]
            }
          };
        case 'photosDelete':
          state.newProperty.photos.splice(text, 1);
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              photos: state.newProperty.photos
            }
          };
        case 'photosChange':
          state.newProperty.photos.splice(text.index, 1, text.base64);
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              photos: state.newProperty.photos
            }
          };
        case 'clean':
          return {
            ...state,
            newProperty: {
              PropertyName: '',
              Country: '',
              CountryIndex: [],
              StateProvince: '',
              StateProvinceIndex: [],
              StateProvinceData: [],
              City: '',
              Address: '',
              Apartment: '',
              ZipPostal: '',
              photos: [],
              required: arrayString
            }
          };
        case 'required':
          return {
            ...state,
            newProperty: {
              ...state.newProperty,
              required: [...state.newProperty.required, text]
            }
          };
        default:
          return {
            ...state,
            newProperty: action.payload
          };
      }
    },
    // [Actions_Property.GET_TENANCIES_LIST_COMPLETE]: (state, action: any) => {
    //   return {
    //     ...state,
    //     tenancyData: action.payload,
    //   };
    // },
    // [Actions_Property.GET_TENANT_LIST_COMPLETE]: (state, action: any) => {
    //   return {
    //     ...state,
    //     tenantData: action.payload,
    //   };
    // },
    // [Actions_Property.GET_TENANCT_PARAMS]: (state, action: any) => {
    //   return {
    //     ...state,
    //     tenantParams: { ...state.tenantParams, ...action.payload },
    //   };
    // },
    [Actions_Property.GET_PMANAGER_LISTING_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        PManagerListData: action.payload
      };
    },
    [Actions_Property.PMANAGER_PARAMS]: (state, action: any) => {
      return {
        ...state,
        PmanagerParams: { ...state.PmanagerParams, ...action.payload }
      };
    },
    [Actions_Property.GET_OWNER_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        ownerList: action.payload
      };
    },
    [Actions_Property.BACK_OWNER_LIST]: (state, action: any) => {
      return {
        ...state,
        backOwnerList: action.payload
      };
    }
  },
  defaultState
);


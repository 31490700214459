import { isArray, isString } from 'lodash';
import { isClient } from './roles';
import * as Request from '@/axios/request';

export function SearchToMap(str: string) {
  const obj: any = {};
  let result = str;
  if (result.startsWith('?')) {
    result = result.substring(1);
  }
  const arr = result.split('&');
  arr.forEach((item) => {
    if (typeof item === 'string' && item.length > 0) {
      const index = item.indexOf('=');
      if (index >= 0) {
        obj[item.substring(0, index)] = item.substring(index + 1);
      }
    }
  });
  return obj;
}

export function ObjToMap(str: string, obj: Object) {
  let url = str + '?';
  Object.entries(obj).forEach(([key, val]: any) => {
    if (isArray(val)) {
      val.forEach((v) => {
        url = `${url}${key}[]=${v}&`;
      });
    }
    if (isString(val)) {
      url = `${url}${key}=${val}&`;
    }
  });
  return url.substring(0, url.lastIndexOf('&'));
}

export function ObjToMap2(str: string, obj: Object) {
  let url = str + '?';
  Object.entries(obj).forEach(([key, val]: any) => {
    if (isArray(val)) {
      val.forEach((v, i) => {
        url = `${url}${key}[${i}]=${v}&`;
      });
    }
    if (isString(val)) {
      url = `${url}${key}=${val}&`;
    }
  });
  return url.substring(0, url.lastIndexOf('&'));
}

/**
 * 跳转到jobinfo界面
 * @param v
 * @param open
 */
export const linkToJob = (v: any, open?: boolean) => {
  if (open) {
    window.open(`/portals/v1/jobinfo?job_id=${v}&isClient=${isClient()}`);
  } else {
    window.location.href = `/portals/v1/jobinfo?job_id=${v}&isClient=${isClient()}`;
  }
};

/**
 * 跳转到propertyinfo界面
 * @param v
 * @param open
 */
export const linkToProperty = (v: any, open?: boolean) => {
  if (open) {
    window.open(`/portals/v1/propertyinfo?entityId=${v}`);
  } else {
    const window1: any = window;
    window1.top.location.href = `/portals/v1/propertyinfo?entityId=${v}`;
  }
};

/**
 * 跳转到assetinfo界面
 * @param v
 * @param open
 */
export const linkToAsset = (v: any, open?: boolean) => {
  if (open) {
    window.open(`/portals/v1/assetinfo?entityId=${v}`);
  } else {
    const window1: any = window;
    window1.top.location.href = `/portals/v1/assetinfo?entityId=${v}`;
  }
};

/**
 * 跳转到tenantinfo界面
 */
export const linkToTenant = (value: any, open?: boolean) => {
  if (open) {
    window.open(
      `/portals/v1/tenantinfo?id=${value.id}&entityId=${value.entityId}&entity=${value.entity}`
    );
  } else {
    const window1: any = window;
    window1.top.location.href = `/portals/v1/tenantinfo?id=${value.id}&entityId=${value.entityId}&entity=${value.entity}`;
  }
};

/**
 * 跳转到tenancyinfo界面
 */
export const linkToTenancy = (value: any, open?: boolean) => {
  if (open) {
    window.open(
      `/portals/v1/tenancyinfo?id=${value.id}&entityId=${value.entityId}&entity=${value.entity}`
    );
  } else {
    const window1: any = window;
    window1.top.location.href = `/portals/v1/tenancyinfo?id=${value.id}&entityId=${value.entityId}&entity=${value.entity}`;
  }
};

/**
 * 跳转到 tenant financial list 界面
 */
export const linkToTenantFinancialList = (value: any, open?: boolean) => {
  if (open) {
    window.open(
      `/portals/v1/financialsinfo?id=${value.id}&entityId=${value.entityId}&entity=${value.entity}`
    );
  } else {
    window.location.href = `/portals/v1/financialsinfo?id=${value.id}&entityId=${value.entityId}&entity=${value.entity}`;
  }
};

/**
 * 当token过期时 先调用logout 接口 在跳转到登录界面
 */
export const linkToLogin = async (path: string) => {
  const window1: any = window;
  localStorage.clear();
  await Request.logout({});
  window1.top.location.href = path;
};


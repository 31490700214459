import React, { useEffect, useState } from 'react';
import { t } from '@/i18n';
import FreeField from '../Components/FreeField';
import * as Request from '@/axios/request';
import {
  chackPassword,
  classNames,
  isMobile,
  validationEmail,
  validationStrisNull
} from '@/utils/fn';
import FreeButton from '../Components/FreeButton';
import FreeLoading from '../Components/FreeLoading';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import '../index.css';
import { EyeOfIcon, EyeIcon } from '@/assets/featherIcons';
import getPublicImg from '@/utils/getPublicImg';
import { openAlertDialog } from '@/utils/openDialog';
import LanguageMenu from '@/layouts/NewAdmin/LanguageMenu';
import FreeAlertModal from '../Components/FreeAlertModal';

export default function LoginPage() {
  const history = useHistory();
  const [showPassWord, setShowPassWord] = useState(false);
  const [newState, setNewState] = useState<any>({
    login:
      (window.sessionStorage.rmbPassWord === 'true' &&
        window.sessionStorage?.userEmail) ||
      '',
    password:
      (window.sessionStorage.rmbPassWord === 'true' &&
        window.sessionStorage?.passWord) ||
      ''
  });
  const [emptyError, setEmptyError] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [checked, setChecked] = useState<any>(
    (!window.sessionStorage.passWord &&
      !window.sessionStorage.userEmail &&
      true) ||
      window.sessionStorage.rmbPassWord === 'true'
  );
  const handleSubmit = async () => {
    if (loading) return;
    const arr: any = [];
    if (
      !validationEmail(newState?.login) ||
      !validationStrisNull(newState?.login)
    ) {
      arr.push({
        field: 'login',
        value: t('Please enter a valid email address.')
      });
    }
    if (!chackPassword(newState?.password)) {
      arr.push({
        field: 'password',
        value: t('Password error, please re-enter.')
      });
    }
    setEmptyError(arr);
    if (arr?.length > 0) return;
    setLoading(true);
    const res: any = await Request.authSignIn(newState);
    if (res?.result === 0) {
      localStorage.setItem('user', JSON.stringify(res?.data?.user));
      localStorage.setItem('token', JSON.stringify(res?.data?.token));
      window.sessionStorage.userEmail = newState.login;
      window.sessionStorage.passWord = newState.password;
      window.sessionStorage.rmbPassWord = checked;
      const url = window.location.pathname;
      const code = url?.split('qrcode/')?.[1] || localStorage.getItem('qrcode');
      if (code) {
        getBookInfo(code);
      } else {
        if (isMobile()) {
          localStorage.setItem('path', '/bookings');
          history.push('/v1/bookings?mShowType=today');
        } else {
          localStorage.setItem('path', '/dashboard');
          history.push('/v1/dashboard');
        }
      }
    } else {
      if (res?.data?.message === 'allow_only_pmanager_login') {
        arr.push({
          field: 'login',
          value: t(
            'This account does not allow login, please contact support@assetbozz.com for more help, thanks.'
          )
        });
        setEmptyError(arr);
      }
      if (res?.data?.message === 'the_email_already_exists_in_homebozz') {
        arr.push({
          field: 'login',
          value: t(
            'The email already exists in HomeBozz, please sign up with a new email address'
          )
        });
        setEmptyError(arr);
      }
      if (res?.data?.message === 'the_login_non_existent') {
        arr.push({
          field: 'login',
          value: t(
            'This email does not exist, please re-enter or sign up with this email address'
          )
        });
        setEmptyError(arr);
      }
      if (res?.data?.message === 'login_password_incorrect') {
        arr.push({
          field: 'password',
          value: t('Your password is incorrect')
        });
        setEmptyError(arr);
      }
    }
    setLoading(false);
  };

  const getBookInfo = async (code: string) => {
    const res: any = await Request.getBookingByCode({
      code
    });
    if (res?.result === 0) {
      if (res?.data?.entity === 'booking') {
        const book: any = await Request.getBookingRecordsJavaInfo(
          res?.data?.entityId
        );
        if (!book?.data || book?.data?.accountId !== res?.data?.accountId) {
          history.push('/v1/bookings?mShowType=today');
          openAlertDialog({
            type: 'error',
            title: t(
              'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
            ),
            size: '2xl',
            leftBtn: {
              label: 'ok',
              onClick: (close) => {
                close();
              }
            }
          });
        } else {
          history.push(`/v1/bookingInfo/${res?.data?.entityId}/${code}`);
        }
      } else {
        history.push('/v1/bookings?mShowType=today');
        openAlertDialog({
          type: 'error',
          title: t(
            'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
          ),
          size: '2xl',
          leftBtn: {
            label: 'ok',
            onClick: (close) => {
              close();
            }
          }
        });
      }
    } else {
      history.push('/v1/bookings?mShowType=today');
    }
  };

  useEffect(() => {
    const url = window.location.pathname;
    const code = url?.split('qrcode/')?.[1];
    if (code) {
      // 已登录
      if (localStorage.getItem('user')) {
        getBookInfo(code);
      }
    } else {
      console.log('login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [show, setShow] = useState(false);
  return (
    <div className="flex">
      <div
        className="relative flex items-center justify-center overflow-hidden lmD:hidden"
        style={{ flex: 7, height: window?.innerHeight }}
      >
        <img
          src={getPublicImg('loginImage.png')}
          className="w-full h-full object-cover object-center"
        />
        <div className="absolute top-[20%] flex flex-col items-center">
          <div className="flex items-center">
            <img
              className="w-20 h-20"
              src={getPublicImg('AssetBozzIcon.png')}
            />
            <div className="text-3xl font-semibold text-gray-500">
              AssetBozz
            </div>
          </div>

          <div
            className="text-center text-4xl font-semibold pb-[20px]"
            style={{ textTransform: 'capitalize' }}
          >
            {t('Welcome back!')}
          </div>
        </div>
      </div>
      <div
        className="flex justify-center items-center bg-whitenew overflow-hidden"
        style={{ flex: 5, height: window?.innerHeight }}
      >
        {loading && <FreeLoading />}
        <div className="w-full p-20 lmD:px-8">
          <div className="flex justify-end">
            <LanguageMenu />
          </div>
          <div
            className="text-center text-4xl font-semibold pb-[50px]"
            style={{ textTransform: 'capitalize' }}
          >
            {t('Sign in to your account')}
          </div>
          <div className="py-3">
            <FreeField
              complete="on"
              value={newState.login}
              label={t('Email')}
              inputStyle={{ height: '50px' }}
              onChange={(val: any) => {
                setEmptyError(
                  emptyError?.filter((item: any) => item?.field !== 'login')
                );
                setNewState({
                  ...newState,
                  login: val?.target?.value
                });
              }}
              errorText={
                emptyError.find((item: any) => item?.field === 'login')?.value
              }
              disabled={loading}
            />
          </div>
          <div className="py-3">
            <FreeField
              complete="on"
              value={newState.password}
              label={t('Password')}
              inputStyle={{ height: '50px' }}
              type={(showPassWord && 'text') || 'password'}
              onChange={(val: any) => {
                setEmptyError(
                  emptyError?.filter((item: any) => item?.field !== 'password')
                );
                setNewState({
                  ...newState,
                  password: val?.target?.value
                });
              }}
              errorText={
                emptyError.find((item: any) => item?.field === 'password')
                  ?.value
              }
              disabled={loading}
              rightIcon={
                <div
                  className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer mx-2"
                  style={{
                    border: '0px',
                    borderRadius: '0 0.375rem 0.375rem 0'
                  }}
                  onClick={() => {
                    setShowPassWord(!showPassWord);
                  }}
                >
                  <EyeIcon
                    className={classNames('w-5 h-5', showPassWord && 'hidden')}
                  />
                  <EyeOfIcon
                    className={classNames('w-5 h-5', !showPassWord && 'hidden')}
                  />
                </div>
              }
            />
          </div>
          <div className="py-3 flex justify-between items-center font-medium">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                if (loading) return;
                setChecked(!checked);
              }}
            >
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                checked={checked}
                onClick={(e: any) => setChecked(e?.target?.checked)}
                className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
              />
              <p
                style={{
                  color: '#111827',
                  fontWeight: 400,
                  lineHeight: '20px',
                  fontSize: '14px',
                  paddingLeft: '0.5rem'
                }}
              >
                {t('Remember me')}
              </p>
            </div>
            <div
              className="text-cyan-600 text-sm cursor-pointer"
              onClick={() => {
                if (loading) return;
                history.push('/verification/forgot-password');
              }}
            >
              {t('Forgot your password?')}
            </div>
          </div>
          <div className="py-5">
            <FreeButton
              text={t('Sign in')}
              type="freeStyle1"
              style={{
                fontSize: '18px',
                margin: 0
              }}
              onClick={handleSubmit}
              disabled={loading}
            />
          </div>

          <div className="py-5 flex justify-between font-normal items-center text-sm text-cardgray">
            <div style={{ width: '30%', border: '0.5px solid #D1D5DB' }} />
            <span style={{ textAlign: 'center' }}>
              {t('New to AssetBozz?')}
            </span>
            <div style={{ width: '30%', border: '0.5px solid #D1D5DB' }} />
          </div>
          <div className="py-5">
            <FreeButton
              text={t('Sign up')}
              type="freeStyle2"
              style={{
                fontSize: '18px',
                margin: 0
              }}
              disabled={loading}
              onClick={() => {
                if (loading) return;
                setShow(true);
                // history.push('/signup');
              }}
            />
          </div>
          <div className="py-2 text-center">
            &copy;&nbsp;2019-
            {moment().get('year')}&nbsp;
            {t('AssetBozz Limited. All rights reserved.')}
          </div>
          <FreeAlertModal
            show={show}
            hideCloseBtn={true}
            submitBtnType="normal"
            onSure={() => {
              setShow(false);
            }}
            subContent={
              <p>
                Please contact{' '}
                <a
                  href="mailto:admin@assetbozz.com"
                  className="text-cyan-600 border-b border-cyan-600"
                >
                  admin@assetbozz.com
                </a>{' '}
                if you wish to set up a new account.
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
}


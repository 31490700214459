import { takeLatest, call, put, select } from 'redux-saga/effects';
import { Actions, allReducers, storeState } from '@/store/reducers';
import * as request from '@/axios/request';
import { RESPONSE_TYPES } from '@/axios/axios';
import toast from 'react-hot-toast';
import { filterName } from '@/utils/fn';
import { isClient } from '@/utils/roles';
import { t } from '@/i18n';

export function* watchNotificationSaga() {
  yield takeLatest(Actions.GET_NOTIFICATION_LIST, getNotificationList);
  yield takeLatest(Actions.GET_NOTIFICATION_USER_LIST, getNotificationUserList);
  yield takeLatest(Actions.NEW_NOTIFICATION, newNotification);
}

// 获取notification list
function* getNotificationList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getNotification',
      value: true
    })
  );
  try {
    const type = isClient() ? 'received' : 'all';
    const res: RESPONSE_TYPES = yield call(request.getNotificationListJava, {
      ...props.payload,
      type: type
    });
    if (res.result === 0) {
      const datas = res.data.data ?? [];
      const total = res.data.total ?? 0;
      const pageNum = res.data.pageNum ?? 1;
      yield put({
        type: 'GET_NOTIFICATION_LIST_SUCCESS',
        payload: {
          data: datas,
          total: total,
          pageNum: pageNum
        }
      });
    } else {
      toast('Get annoucements error!', {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getNotification',
      value: false
    })
  );
}

// 新增notification
function* newNotification(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'newnotificationtip',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.saveNotificationJava,
      props.payload
    );
    if (res.result === 0) {
      toast(t('New announcement created!'), {
        id: 'only-one',
        icon: '✔',
        duration: 6000,
        position: 'top-right',
        style: {
          color: 'white',
          background: '#4CAF50'
        }
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'addNewAnnouncements',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'resendAnnouncements',
          value: false
        })
      );
      yield put(allReducers._getNotificationList({ page: 1, size: 10 }));
      yield put(
        allReducers._getNotificationUserListSuccess({ data: [], total: 0 })
      );
    } else {
      toast(res.data, {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'newnotificationtip',
      value: false
    })
  );
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: false
    })
  );
}

// 获取user
function* getNotificationUserList(props: any) {
  const {
    NotificationUserList,
    requestNotificationUserParams
  }: storeState['notificationState'] = yield select(
    (store: storeState) => store.notificationState
  );
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getNotificationUser',
      value: true
    })
  );
  try {
    const page = props.payload.page ?? 1;
    const res: RESPONSE_TYPES = yield call(
      request.getNotificationUsers,
      props.payload
    );
    if (res.result === 0) {
      const arr: [] = (res.data.data ?? [])
        .filter((val: any) => val?.role?.persona === 'pmanager')
        .map((value: any) => ({
          value: value.userId,
          label: filterName(
            value.firstname ?? '',
            value.middlename ?? '',
            value.lastname ?? ''
          )
        }));
      const total = res.data.total ?? 0;
      yield put({
        type: 'GET_NOTIFICATION_USER_LIST_SUCCESS',
        payload: {
          data: NotificationUserList.concat(arr),
          total: total
        }
      });
      if (total > page * requestNotificationUserParams.size) {
        yield put(
          allReducers._getNotificationUserList({
            ...requestNotificationUserParams,
            page: page + 1
          })
        );
      }
    } else {
      toast('Get user error!', {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getNotificationUser',
      value: false
    })
  );
}


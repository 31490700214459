import React, { useCallback, useEffect, useState } from 'react';

import { t } from '@/i18n';
import FreeButton from '../Components/FreeButton';
import { useHistory } from 'react-router-dom';
import FreeField from '../Components/FreeField';
import { chackPassword, classNames, formatString } from '@/utils/fn';
import FreeLoading from '../Components/FreeLoading';
import { CheckIcon, EyeIcon, EyeOfIcon } from '@/assets/featherIcons';
import * as Request from '@/axios/request';
import moment from 'moment';
import getPublicImg from '@/utils/getPublicImg';

const Logo = getPublicImg('AssetBozzIcon.png');

let timeChange: any = null;

export default function ResetPasswordPage() {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [newState, setNewState] = useState<any>({
    password: '',
    confirmPassword: ''
  });
  const [emptyError, setEmptyError] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const uuid = window.location.pathname.split(
    '/verification/reset-password/'
  )[1];
  const [expired, setPired] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);

  const [showPsw, setShowPsw] = useState(false);
  const [showCPsw, setShowCPsw] = useState(false);

  const getExpiring = useCallback(async (uuid: any) => {
    setLoadingGet(true);
    const res: any = await Request.getUserTempAuth(uuid);
    if (res?.result === 0) {
      setEmail(res?.data?.email);
    } else {
      setPired(true);
    }
    setLoadingGet(false);
  }, []);
  useEffect(() => {
    if (uuid) {
      getExpiring(uuid);
    }
  }, [getExpiring, uuid]);
  const handleSubmit = async () => {
    if (loading) return;
    const arr: any = [];
    if (!chackPassword(newState?.password) || newState?.password?.length < 8) {
      arr.push({
        field: 'password',
        value: t(
          'At least 8 alphanumerical characters with 1 or more upper case.'
        )
      });
    }
    if (newState?.password !== newState?.confirmPassword) {
      arr.push({
        field: 'confirmPassword',
        value: t("Those passwords didn't match. Please try again.")
      });
    }
    setEmptyError(arr);
    if (arr?.length > 0) return;
    setLoading(true);
    const res: any = await Request.authResetPassword({
      ...newState,
      uuid: uuid
    });
    if (res?.result === 0) {
      setSuccess(true);
    } else {
      if (res?.data?.errorCode === 'account_verification_error') {
        arr.push({
          field: 'confirmPassword',
          value: formatString(res?.data?.message)
        });
      }
      setEmptyError(arr);
    }
    setLoading(false);
  };
  const [countdown, setCountdown] = useState(5);
  useEffect(() => {
    if (success) {
      timeChange = setInterval(() => setCountdown((t) => --t), 1000);
      if (countdown === 0) {
        clearInterval(timeChange);
        history.push('/login');
        localStorage.clear();
      }
    }
  }, [countdown, history, success]);
  return (
    <div
      className="flex justify-center items-center"
      style={{ height: window?.innerHeight }}
    >
      {loading && <FreeLoading />}

      {(loadingGet && <FreeLoading />) ||
        (expired && (
          <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
            <div className="flex items-center">
              <img className="w-20" src={Logo} alt="" />
            </div>
            <div className="font-semibold text-2xl my-[20px] text-center lmD:text-xl">
              {t('Sorry, the verification link has expired. Please try again.')}
            </div>
          </div>
        )) ||
        (!success && (
          <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
            <div className="flex items-center">
              <img src={Logo} alt="" className="w-20" />
            </div>
            <div
              className="font-semibold text-4xl my-[20px] text-center lmD:text-2xl"
              style={{ textTransform: 'capitalize' }}
            >
              {t('Reset password')}
            </div>
            <div className="font-normal text-2xl my-[20px] text-center lmD:text-xl">
              {`${t('Enter a new password for ')}${email}`}
            </div>
            <div className="py-3 w-full">
              <FreeField
                value={newState.password}
                type={(showPsw && 'text') || 'password'}
                label={t('Password')}
                required
                inputStyle={{ height: '50px' }}
                onChange={(val: any) => {
                  setEmptyError(
                    emptyError?.filter(
                      (item: any) => item?.field !== 'password'
                    )
                  );
                  setNewState({
                    ...newState,
                    password: val?.target?.value
                  });
                }}
                errorText={
                  emptyError.find((item: any) => item?.field === 'password')
                    ?.value
                }
                disabled={loading}
                hintText={
                  !emptyError?.find((i: any) => i.field === 'password') &&
                  t(
                    'At least 8 alphanumerical characters with 1 or more upper case.'
                  )
                }
                rightIcon={
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer mx-2"
                    style={{
                      border: '0px',
                      borderRadius: '0 0.375rem 0.375rem 0'
                    }}
                    onClick={() => {
                      setShowPsw(!showPsw);
                    }}
                  >
                    <EyeIcon
                      className={classNames('w-5 h-5', showPsw && 'hidden')}
                    />
                    <EyeOfIcon
                      className={classNames('w-5 h-5', !showPsw && 'hidden')}
                    />
                  </div>
                }
              />
            </div>
            <div className="py-3 w-full">
              <FreeField
                value={newState.confirmPassword}
                type={(showCPsw && 'text') || 'password'}
                label={t('Re-enter password')}
                required
                inputStyle={{ height: '50px' }}
                onChange={(val: any) => {
                  setEmptyError(
                    emptyError?.filter(
                      (item: any) => item?.field !== 'confirmPassword'
                    )
                  );
                  setNewState({
                    ...newState,
                    confirmPassword: val?.target?.value
                  });
                }}
                errorText={
                  emptyError.find(
                    (item: any) => item?.field === 'confirmPassword'
                  )?.value
                }
                disabled={loading}
                rightIcon={
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer mx-2"
                    style={{
                      border: '0px',
                      borderRadius: '0 0.375rem 0.375rem 0'
                    }}
                    onClick={() => {
                      setShowCPsw(!showCPsw);
                    }}
                  >
                    <EyeIcon
                      className={classNames('w-5 h-5', showCPsw && 'hidden')}
                    />
                    <EyeOfIcon
                      className={classNames('w-5 h-5', !showCPsw && 'hidden')}
                    />
                  </div>
                }
              />
            </div>
            <div className="py-5 w-full">
              <FreeButton
                text={t('Reset password')}
                type="freeStyle1"
                style={{
                  fontSize: '16px',
                  margin: 0
                }}
                onClick={handleSubmit}
              />
            </div>
            <div className="py-2 text-center">
              &copy;&nbsp;2019-
              {moment().get('year')}&nbsp;
              {t('AssetBozz Limited. All rights reserved.')}
            </div>
          </div>
        )) || (
          <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
            <div className="flex justify-center items-center w-[50px] h-[50px] rounded-full bg-cyan-100bg">
              <CheckIcon
                className="h-10 w-10 text-servicecolor"
                aria-hidden="true"
              />
            </div>
            <div className="text-servicecolor font-bold text-4xl text-center my-[20px]">
              {t('Reset password successful!')}
            </div>
            <div className="py-5 w-full">
              <FreeButton
                text={t('Login now')}
                type="freeStyle1"
                style={{
                  fontSize: '16px',
                  margin: 0
                }}
                onClick={() => {
                  history.push('/login');
                  localStorage.clear();
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}

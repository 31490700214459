import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
interface updateTypes {
  id: string;
  text: any;
  data?: any;
}
/**
 * Actions
 */
export const Actions_Asset = {
  NEW_ASSET: 'NEW_ASSET',

  GET_ASSET_LIST: 'GET_ASSET_LIST',
  GET_ASSET_LIST_PARAMS: 'GET_ASSET_LIST_PARAMS',
  GET_ASSET_LIST_COMPLETE: 'GET_ASSET_LIST_COMPLETE',

  GET_ALL_ASSET_LIST: 'GET_ALL_ASSET_LIST',
  CLEAN_ALL_ASSET_LIST: 'CLEAN_ALL_ASSET_LIST',
  GET_ALL_ASSET_LIST_COMPLETE: 'GET_ALL_ASSET_LIST_COMPLETE',

  GET_ASSET_INFO: 'GET_ASSET_INFO',
  GET_ASSET_INFO_COMPLETE: 'GET_ASSET_INFO_COMPLETE',

  GET_ASSET_DETAILS: 'GET_ASSET_DETAILS',
  GET_ASSET_DETAILS_COMPLETE: 'GET_ASSET_DETAILS_COMPLETE',

  GET_REGIONTEXT_BY: 'GET_REGIONTEXT_BY',

  UPDATE_ASSET_INFO: 'UPDATE_ASSET_INFO',
  DELETE_ASSET: 'DELETE_ASSET',

  SEARCH_ASSET: 'SEARCH_ASSET'
};

/**
 * Actions Creators
 */
export const _newAsset = createAction(Actions_Asset.NEW_ASSET);

export const _getAssetList = createAction(Actions_Asset.GET_ASSET_LIST);
export const _getAssetListComplete = createAction(
  Actions_Asset.GET_ASSET_LIST_COMPLETE
);
export const _getAssetListParams = createAction(
  Actions_Asset.GET_ASSET_LIST_PARAMS
);

export const _getAllAssetList = createAction(Actions_Asset.GET_ALL_ASSET_LIST);
export const _cleanAllAssetList = createAction(
  Actions_Asset.CLEAN_ALL_ASSET_LIST
);
export const _getAllAssetListComplete = createAction(
  Actions_Asset.GET_ALL_ASSET_LIST_COMPLETE
);

export const _getAssetInfo = createAction(Actions_Asset.GET_ASSET_INFO);
export const _getAssetInfoComplete = createAction(
  Actions_Asset.GET_ASSET_INFO_COMPLETE
);

export const _getAssetDetails = createAction(Actions_Asset.GET_ASSET_DETAILS);
export const _getAssetDetailsComplete = createAction(
  Actions_Asset.GET_ASSET_DETAILS_COMPLETE
);

export const _getRegionTextBy = createAction(Actions_Asset.GET_REGIONTEXT_BY);

export const _updateAssetInfo = createAction(Actions_Asset.UPDATE_ASSET_INFO);
export const _deleteAsset = createAction(Actions_Asset.DELETE_ASSET);

export const _searchAsset = createAction(Actions_Asset.SEARCH_ASSET);

export const assetReducers = {
  _newAsset,

  _getAssetList,
  _getAssetListComplete,
  _getAssetListParams,

  _getAllAssetList,
  _getAllAssetListComplete,
  _cleanAllAssetList,

  _getAssetInfo,
  _getAssetInfoComplete,

  _getAssetDetails,
  _getAssetDetailsComplete,

  _getRegionTextBy,

  _updateAssetInfo,
  _deleteAsset,

  _searchAsset
};
/**
 * Reducer
 */

const DEFAULT_ASSET_INFO_LIST = [
  {
    key: 'product_name',
    label: 'productName',
    type: 'singletext',
    value: '',
    mandatory: 1,
    display: true,
    isDefault: true
  },
  {
    key: 'brand',
    label: 'brand',
    type: 'singletext',
    value: '',
    mandatory: 0,
    display: true,
    isDefault: true
  },
  {
    key: 'asset_name',
    label: 'description',
    type: 'singletext',
    value: '',
    mandatory: 0,
    display: true,
    isDefault: true
  },
  {
    key: 'model',
    label: 'modelNo',
    type: 'singletext',
    value: '',
    mandatory: 0,
    display: true,
    isDefault: true
  },
  {
    key: 'serial_number',
    label: 'sernum',
    type: 'singletext',
    value: '',
    mandatory: 0,
    display: true,
    isDefault: true
  },
  {
    key: 'property_id',
    label: 'propertyold',
    type: 'button',
    value: JSON.stringify({
      property_id: '',
      address: ''
    }),
    mandatory: 1,
    display: true,
    isDefault: true
  },
  {
    key: 'asset_class_id',
    label: 'assetClass',
    type: 'singleoption',
    value: '',
    mandatory: 1,
    display: true,
    options: [''],
    isDefault: true
  },
  {
    key: 'provider_id',
    label: 'serviceProvider',
    type: 'singleoption',
    value: '',
    mandatory: 0,
    display: true,
    options: [''],
    isDefault: true
  },
  {
    key: 'reference_id',
    label: 'refID',
    type: 'singletext',
    value: '',
    mandatory: 0,
    display: false,
    isDefault: true
  },
  {
    key: 'date_acquired',
    label: 'dateAcquired',
    type: 'date',
    value: '',
    mandatory: 0,
    display: false,
    isDefault: true
  },
  {
    key: 'depreciation_months',
    label: 'depreciation',
    type: 'int',
    value: '',
    mandatory: 0,
    display: false,
    isDefault: true
  },
  {
    key: 'quantity',
    label: 'qty',
    type: 'int',
    value: '1',
    mandatory: 1,
    display: false,
    isDefault: true
  },
  {
    key: 'quantity_unit',
    label: 'uqty',
    type: 'singletext',
    value: 'pc',
    mandatory: 1,
    display: false,
    isDefault: true
  },
  {
    key: 'currency',
    label: 'currency',
    type: 'singleoption',
    value: '',
    mandatory: 1,
    display: false,
    options: [''],
    isDefault: true
  },
  {
    key: 'unit_cost',
    label: 'uconst',
    type: 'int',
    value: '',
    mandatory: 0,
    display: false,
    isDefault: true
  }
];
const propertyListTableDataType: any = [];
const defaultState = {
  assetInfo: {
    car: [...DEFAULT_ASSET_INFO_LIST],
    appliance: [...DEFAULT_ASSET_INFO_LIST],
    general: [...DEFAULT_ASSET_INFO_LIST]
  },
  allAsset: [],
  assetListData: {
    data: propertyListTableDataType,
    page: 1,
    size: 12,
    total: 0
  },
  requstParams: {
    size: 12,
    page: 1,
    fill: 1,
    // entity: "", //property asset none
    // internal_jobs: null, //1,0,null
    // status: "", //
    order_by: '', // time_required, provider_id, entity , status
    direction: '', // asc ,desc
    search: ''
  },
  assetDetailData: {
    photo_id: ''
  }
};
export type assetState = Readonly<typeof defaultState>;
export default handleActions(
  {
    [Actions_Asset.GET_ALL_ASSET_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        allAsset: action.payload
      };
    },
    [Actions_Asset.GET_ASSET_LIST_PARAMS]: (state, action: any) => {
      const { id, text, data }: updateTypes = action.payload;
      switch (id) {
        case 'Sort':
          return {
            ...state,
            requstParams: {
              ...state.requstParams
              // order_by: data.order_by,
              // direction: data.direction,
            }
          };
        case 'size':
          return {
            ...state,
            requstParams: {
              ...state.requstParams,
              page: data.page,
              size: data.size
            }
          };
        case 'search':
          return {
            ...state,
            requstParams: {
              ...state.requstParams,
              search: text,
              ...{
                size: 10,
                page: 1
                // entity: "", //property asset none
                // internal_jobs: null, //1,0,null
                // status: "", //
                // order_by: "", //time_required, provider_id, entity , status
                // direction: "", //asc ,desc
              }
            }
          };
        default:
          return {
            ...state,
            requstParams: { ...state.requstParams }
          };
      }
    },
    [Actions_Asset.GET_ASSET_INFO_COMPLETE]: (state: any, action: any) => {
      const { key, key2, value } = action.payload;
      // 创建物资
      if (key === 'done') {
        return {
          ...state,
          assetInfo: {
            car: [...DEFAULT_ASSET_INFO_LIST, ...(value.car || [])],
            appliance: [...DEFAULT_ASSET_INFO_LIST, ...(value.appliance || [])],
            general: [...DEFAULT_ASSET_INFO_LIST, ...(value.general || [])]
          }
        };
        // 创建成功，清除
      } else if (key === 'clean') {
        return {
          ...state,
          assetInfo: {
            car: DEFAULT_ASSET_INFO_LIST.map((item: any) => {
              if (item.key === 'property_id') {
                item.value = JSON.stringify({
                  property_id: '',
                  address: ''
                });
              } else {
                item.value = '';
              }
              return item;
            }),
            appliance: DEFAULT_ASSET_INFO_LIST.map((item: any) => {
              if (item.key === 'property_id') {
                item.value = JSON.stringify({
                  property_id: '',
                  address: ''
                });
              } else {
                item.value = '';
              }
              return item;
            }),
            general: DEFAULT_ASSET_INFO_LIST.map((item: any) => {
              if (item.key === 'property_id') {
                item.value = JSON.stringify({
                  property_id: '',
                  address: ''
                });
              } else {
                item.value = '';
              }
              return item;
            })
          }
        };
        // 物资详情
      } else if (key === 'detail') {
        let values = value?.values;
        const params = value?.params;
        values = {
          car: [...DEFAULT_ASSET_INFO_LIST, ...(values.car || [])],
          appliance: [...DEFAULT_ASSET_INFO_LIST, ...(values.appliance || [])],
          general: [...DEFAULT_ASSET_INFO_LIST, ...(values.general || [])]
        };
        // 物资类型
        const TYPE_KEY = _.get(params, 'asset_type', '') || 'general';
        // 其他字段
        const OTHERS = ['product_name', 'brand', 'model', 'asset_class_id'];

        Object.entries(values).forEach((items: any) => {
          // eslint-disable-next-line prefer-const
          let [key, item] = items;
          if (key !== TYPE_KEY) {
            return null;
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          item = item.map((item2: any) => {
            // 判断详情页的键
            if (Object.keys(params).includes(item2.key)) {
              // 如果为 property_id 则转换
              if (item2.key === 'property_id') {
                item2.value = JSON.stringify({
                  property_id: params.property_id,
                  address:
                    _.get(params, 'property.addressLocal', '') ||
                    _.get(params, 'property.address', '')
                });
              } else {
                item2.value = params[item2.key];
              }
              // 如果为其他字段,则直接赋值
            } else if (OTHERS.includes(item2.key)) {
              item2.value = _.get(
                params,
                `product.${OTHERS[OTHERS.indexOf(item2.key)]}`
              );
            }
            return item2;
          });
          const obj: any = {};
          obj[key] = items;
          return obj;
        });

        return {
          ...state,
          assetInfo: { ...values }
        };
      } else {
        state.assetInfo[key].map((item: any) => {
          if (item.key === key2) {
            item.value = value;
          }
          return item;
        });
        return {
          ...state
        };
      }
    },
    [Actions_Asset.GET_ASSET_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        assetListData: action.payload
      };
    },
    [Actions_Asset.GET_ASSET_DETAILS_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        assetDetailData: { ...action.payload }
      };
    },
    [Actions_Asset.CLEAN_ALL_ASSET_LIST]: (state) => {
      return {
        ...state,
        allAsset: []
      };
    }
  },
  defaultState
);


import React, { useState } from 'react';

import { t } from '@/i18n';
import FreeButton from '../Components/FreeButton';
import FreeField from '../Components/FreeField';
import { validationEmail, validationStrisNull } from '@/utils/fn';
import FreeLoading from '../Components/FreeLoading';
import * as Request from '@/axios/request';
import moment from 'moment';
import getPublicImg from '@/utils/getPublicImg';

const Logo = getPublicImg('AssetBozzIcon.png');

export default function ForgotPasswordPage() {
  const [send, setSend] = useState(false);
  const [newState, setNewState] = useState<any>({
    email: ''
  });
  const [emptyError, setEmptyError] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    if (loading) return;
    const arr: any = [];
    if (
      !validationEmail(newState?.email) ||
      !validationStrisNull(newState?.email)
    ) {
      arr.push({
        field: 'email',
        value: t('Please enter a valid email address.')
      });
    }
    setEmptyError(arr);
    if (arr?.length > 0) return;
    setLoading(true);
    const res: any = await Request.authSignUp({
      ...newState,
      type: 'forgot-password'
    });
    if (res?.result === 0) {
      setSend(true);
    } else {
      if (res?.data?.errorCode === 'account_verification_error') {
        arr.push({
          field: 'email',
          value: t('This Email does not exist, please enter the correct email.')
        });
      }
    }
    setLoading(false);
  };
  return (
    <div
      className="flex justify-center items-center"
      style={{ height: window?.innerHeight }}
    >
      {loading && <FreeLoading />}
      {(!send && (
        <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
          <div className="flex items-center">
            <img className="w-20" src={Logo} alt="" />
          </div>
          <div
            className="font-semibold text-4xl my-[20px] text-center lmD:text-2xl"
            style={{ textTransform: 'capitalize' }}
          >
            {t('Forgot password?')}
          </div>
          <div className="font-normal text-2xl my-[20px] text-center lmD:text-xl">
            {t(
              'Enter your email or phone number below to receive a password reset code.'
            )}
          </div>
          <div className="py-3 w-full">
            <FreeField
              value={newState.email}
              placeholder="you@example.com"
              inputStyle={{ height: '50px' }}
              onChange={(val: any) => {
                setEmptyError(
                  emptyError?.filter((item: any) => item?.field !== 'email')
                );
                setNewState({
                  ...newState,
                  email: val?.target?.value
                });
              }}
              errorText={
                emptyError.find((item: any) => item?.field === 'email')?.value
              }
              disabled={loading}
            />
          </div>
          <div className="py-5 w-full">
            <FreeButton
              text={t('Send reset link')}
              type="freeStyle1"
              style={{
                fontSize: '16px',
                margin: 0
              }}
              onClick={handleSubmit}
            />
          </div>
          <div className="py-2 text-center">
            &copy;&nbsp;2019-
            {moment().get('year')}&nbsp;
            {t('AssetBozz Limited. All rights reserved.')}
          </div>
        </div>
      )) || (
        <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
          <div className="flex items-center">
            <img className="w-20" src={Logo} alt="" />
          </div>
          <div
            className="font-semibold text-4xl my-[20px] text-center lmD:text-2xl"
            style={{ textTransform: 'capitalize' }}
          >
            {t('Email sent!')}
          </div>
          <div className="font-normal text-2xl my-[20px] text-center lmD:text-xl">
            {t(
              'Check your email and open the link we sent to continue. The reset link will be valid for an hour.'
            )}
          </div>
          <div className="font-normal text-sm text-center cursor-pointer text-cardgray">
            <span>
              {t(
                'If you did not receive the email, or still having trouble login to your account, please contact us at '
              )}
            </span>
            <a className="text-cyan-600" href="mailto:support@assetbozz.com">
              support@assetbozz.com
            </a>
          </div>
          <div className="py-5 w-full">
            <FreeButton
              text={t('Login now')}
              style={{
                fontSize: '16px',
                margin: 0
              }}
              type="freeStyle1"
              disabled={loading}
              onClick={() => {
                window.location.href = '/login';
                localStorage.clear();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export const SERVER_ADDRESS = '';
export const HOMEBOZZ = '/endpoints/homebozz';
export const HOMEBOZZ_V1 = '/api/v1';
export const HOMEBOZZ_COMPOSITE = '/api/v1/composite';
export const HOMEBOZZ_ATTACHMENTS = '/api/v1/attachments';

export const HOMEBOZZ_FINANCIAL = '/api/v1/composite/financial';
export const HOMEBOZZ_INVOICES = '/api/v1/composite/financial/invoices';
export const HOMEBOZZ_FINANCIAL_XERO =
  '/api/v1/composite/financial/xero-info/actions/subscribe';
const SERVER_ADDRESS_HOMEBOZZ = `${HOMEBOZZ}`;
const SERVER_ADDRESS_HOMEBOZZ_V1 = `${HOMEBOZZ_V1}`;
// const SERVER_ADDRESS_OLD = 'https://devel.assetbozz.com/endpoints';
const IMAGE_URL = '/getImageByID/';
// http://devel.assetbozz.com/getImageByID/10349/std/' //tn小图 std是大图
export const APP_NickName = 'business';
export const APP_Version = '2.2.0';
export const SERVER_ENV = 'https://online.assetbozz.com';
export const HOMEBOZZ_COMPOSITE_BOOKINGS_HOLIDAY =
  '/api/v1/composite/bookings/holiday';
export const HOMEBOZZ_COMPOSITE_BOOKINGS_COMPANY_HOLIDYS =
  '/api/v1/composite/bookings/company/holidays';
export const HOMEBOZZ_IMAGE_URL_S3 = `${SERVER_ENV}/api/v1/composite/attachments/attachments`;
export const HOMEBOZZ_STRIPE = '/api/v1/composite/financial/stripe';

const API_ADDRESS = {
  country: {
    getCountryList: `${HOMEBOZZ_COMPOSITE}/custom-fields/country-info/actions/visitor`
  },
  image: {
    tnimage: `${SERVER_ADDRESS}${IMAGE_URL}`,
    url: `${IMAGE_URL}`
  },
  socket: {
    socket: `${SERVER_ADDRESS}/`
  },
  user: {
    login: `${SERVER_ADDRESS_HOMEBOZZ}/user/login.php`,
    refreshToken: `${SERVER_ADDRESS_HOMEBOZZ}/user/refreshToken.php`,
    // 检查手机号是否被使用
    isMobileNumberTaken: `${SERVER_ADDRESS_HOMEBOZZ}/user/isMobileNumberTaken.php`,
    // 发送验证码
    requestOTP: `${SERVER_ADDRESS}/server/requestOTP.php`,
    // 检查验证码
    verifyOTP: `${SERVER_ADDRESS}/server/submitOTP.php`,
    // 修改密码
    changePassword: `${SERVER_ADDRESS}/server/changePassword.php`,

    // 创建免费用户
    createFreemiumUser: `${SERVER_ADDRESS_HOMEBOZZ}/user/createFreemiumUser.php`,

    getFinancialAccountListing: `${SERVER_ADDRESS_HOMEBOZZ}/financialAccount/getFinancialAccountListing.php`,

    // 忘记密码发送短信
    forgotPasswordRequest: `${SERVER_ADDRESS_HOMEBOZZ}/user/forgotPasswordRequest.php`,
    // 忘记密码验证短信
    forgotPasswordVerify: `${SERVER_ADDRESS_HOMEBOZZ}/user/forgotPasswordVerify.php`,
    // 重置密码
    resetPassword: `${SERVER_ADDRESS_HOMEBOZZ}/user/resetPassword.php`,
    // 获取协议
    getTerms: `${SERVER_ADDRESS_HOMEBOZZ}/user/getTerms.php`,
    // 同意协议
    acceptTerms: `${SERVER_ADDRESS_HOMEBOZZ}/user/acceptTerms.php`,
    // 多重身份验证相关
    disableMFA: `${SERVER_ADDRESS_HOMEBOZZ}/user/disableMFA.php`,
    getMFA: `${SERVER_ADDRESS_HOMEBOZZ}/user/getMFA.php`,
    saveMFA: `${SERVER_ADDRESS_HOMEBOZZ}/user/saveMFA.php`,
    // 查看用户信息
    viewUser: `${SERVER_ADDRESS_HOMEBOZZ}/user/viewUser.php`,
    // 修改用户信息  2022-03-02
    updateUser: `${SERVER_ADDRESS_HOMEBOZZ}/user/updateUser.php`,
    // 登出
    logout: `${SERVER_ADDRESS_HOMEBOZZ}/user/logout.php`,
    // 主页信息
    dashboard: `${SERVER_ADDRESS_HOMEBOZZ}/user/dashboard.php`,

    // 删除job item
    getTeamUsers: `${SERVER_ADDRESS_HOMEBOZZ}/user/getTeamUsers.php`, // 查询团队成员
    newUser: `${SERVER_ADDRESS_HOMEBOZZ}/user/newUser.php`, // 新增MyTeams角色
    updateUserRoles: `${SERVER_ADDRESS_HOMEBOZZ}/user/updateUserRoles.php`, // 修改用户角色

    getPropertyAccessListing: `${SERVER_ADDRESS_HOMEBOZZ}/user/getPropertyAccess.php`,
    savePropertyAccess: `${SERVER_ADDRESS_HOMEBOZZ}/user/updatePropertyAccess.php`
  },
  job: {
    getJobListing: `${SERVER_ADDRESS_HOMEBOZZ}/job/getJobListing.php`,
    getJobDetails: `${SERVER_ADDRESS_HOMEBOZZ}/job/getJobDetails.php`,
    newJob: `${SERVER_ADDRESS_HOMEBOZZ}/job/newJob.php`,
    newReportJob: `${SERVER_ADDRESS_HOMEBOZZ}/job/newReportJob.php`,
    getJobAssignments: `${SERVER_ADDRESS_HOMEBOZZ}/job/getJobAssignments.php`,
    saveJobAssignments: `${SERVER_ADDRESS_HOMEBOZZ}/job/saveJobAssignments.php`,
    searchEmployeesForJob: `${SERVER_ADDRESS_HOMEBOZZ}/job/searchEmployeesForJob.php`,
    updateJobInfo: `${SERVER_ADDRESS_HOMEBOZZ}/job/updateJob.php`,
    // job发送 短信 或 邮件 或 whatsapp
    getProviderSmsContent: `${SERVER_ADDRESS_HOMEBOZZ}/job/getProviderSmsContent.php`,
    getProviderEmailContent: `${SERVER_ADDRESS_HOMEBOZZ}/job/getProviderEmailContent.php`,
    getProviderWhatsAppContent: `${SERVER_ADDRESS_HOMEBOZZ}/job/getProviderWhatsAppContent.php`,
    sendProviderSMS: `${SERVER_ADDRESS_HOMEBOZZ}/job/sendProviderSMS.php`,
    sendProviderEmail: `${SERVER_ADDRESS_HOMEBOZZ}/job/sendProviderEmail.php`,
    sendProviderWhatsApp: `${SERVER_ADDRESS_HOMEBOZZ}/job/sendProviderWhatsApp.php`,

    updateJobStatus: `${SERVER_ADDRESS_HOMEBOZZ}/job/updateJobStatus.php`,
    acceptJob: `${SERVER_ADDRESS_HOMEBOZZ}/job/acceptJob.php`,
    checkin: `${SERVER_ADDRESS_HOMEBOZZ}/job/checkin.php`,
    getActiveJobListing: `${SERVER_ADDRESS_HOMEBOZZ}/job/getActiveJobListing.php`, // job 新增了一个API,用来返回在property 详情页面显示的job
    countJobStatus: `${SERVER_ADDRESS_HOMEBOZZ}/job/countJobStatus.php`, // web job list page
    saveJobContact: `${SERVER_ADDRESS_HOMEBOZZ}/job/saveJobContact.php`, // 修改  job contact person

    getjobtypeList: `${SERVER_ADDRESS_HOMEBOZZ_V1}/composite/custom-fields/job-types`
  },
  provider: {
    getProviderListing: `${SERVER_ADDRESS_HOMEBOZZ}/provider/getProviderListing.php`, // 查询provider
    newProvider: `${SERVER_ADDRESS_HOMEBOZZ}/provider/newProvider.php`, // 创建provider
    updateProvider: `${SERVER_ADDRESS_HOMEBOZZ}/provider/updateProvider.php`, // 修改 provider
    getProviderUserListing: `${SERVER_ADDRESS_HOMEBOZZ}/provider/getProviderUserListing.php` // provider contacts list
  },
  property: {
    getPropertyListing: `${SERVER_ADDRESS_HOMEBOZZ}/property/getPropertyListing.php`,
    getPropertyDetails: `${SERVER_ADDRESS_HOMEBOZZ}/property/getPropertyDetails.php`,
    newProperty: `${SERVER_ADDRESS_HOMEBOZZ}/property/newProperty.php`, // 创建 property
    deleteProperty: `${SERVER_ADDRESS_HOMEBOZZ}/property/deleteProperty.php`, // 删除 property
    updateProperty: `${SERVER_ADDRESS_HOMEBOZZ}/property/updateProperty.php`, // 修改 property
    updatePropertyTenant: `${SERVER_ADDRESS_HOMEBOZZ}/property/updatePropertyTenant.php` // pmanager 修改 property tenant
  },
  pmanager: {
    getPManagerListing: `${SERVER_ADDRESS_HOMEBOZZ}/pmanager/getPManagerListing.php`, // 获取pmanager
    newPManager: `${SERVER_ADDRESS_HOMEBOZZ}/pmanager/newPManager.php`, // 创建 pmanager
    updatePManager: `${SERVER_ADDRESS_HOMEBOZZ}/pmanager/updatePManager.php`, // 修改 pmanager
    updatePropertyManager: `${SERVER_ADDRESS_HOMEBOZZ}/property/updatePropertyManager.php`, // 修改 property manager
    getPmanagerUserListing: `${SERVER_ADDRESS_HOMEBOZZ}/pmanager/getPmanagerUserListing.php` // pmanager contacts list
  },
  tenant: {
    // saveTenant: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/inviteTenant.php`,
    saveTenant: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/saveTenant.php`,
    updateTenant: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/updateTenant.php`,
    getTenantListing: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/getTenantListing.php`, // 获取租户列表
    getTenantUserListing: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/getTenantUserListing.php`, // tenant contacts list
    connectTenantByAdmin: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/connectTenantByAdmin.php`, // 将租户关联
    disconnectTenant: `${SERVER_ADDRESS_HOMEBOZZ}/tenant/disconnectTenant.php`, // 将租户解绑
    tenant: `${HOMEBOZZ_COMPOSITE}/tenant/tenants/`, // 将租户解绑
    tenantUsers: `${HOMEBOZZ_COMPOSITE}/tenant/tenant-users/tenants/`,
    tenantUsers_: `${HOMEBOZZ_COMPOSITE}/tenant/tenant-users/`,
    getMemberShips: `${HOMEBOZZ_COMPOSITE}/tenant/memberships/`,
    getMemberList: `${HOMEBOZZ_COMPOSITE}/tenant/tenant-users/member`,
    getTenantMembers: `${HOMEBOZZ_COMPOSITE}/tenant/tenant-members`,
    getNonMemberList: `${HOMEBOZZ_COMPOSITE}/tenant/tenant-users/non-member`,
    tenants: `${HOMEBOZZ_COMPOSITE}/tenant/tenants`,
    tenantUsers_1: `${HOMEBOZZ_COMPOSITE}/tenant/tenant-users`
  },
  tenancy: {
    saveTenancy: `${SERVER_ADDRESS_HOMEBOZZ}/tenancy/saveTenancy.php`,
    getTenanciesListing: `${SERVER_ADDRESS_HOMEBOZZ}/tenancy/getTenanciesListing.php`,
    deleteTenancy: `${SERVER_ADDRESS_HOMEBOZZ}/tenancy/deleteTenancy.php`,
    tenancy: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies`, // 保存租约信息
    tenanciesEntitiesActive: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies-entities/actions/active`, // 查询已绑定的entity
    tenanciesEntitiesSoon: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies-entities/actions/soon`,
    tenanciesEntitiesExists: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies-entities/actions/exists`,
    tenanciesReminders: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies`, // 根据租约id获取通知信息
    tenanciesNotiification: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/notification`, // 根据租约id获取通知信息、修改租约下的通知
    getTenancySequences: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/tenancy-sequences/actions/subscribe`, // 获取 tenancyNumber 规则
    putTenancySequences: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/tenancy-sequences/`, // 修改 tenancyNumber 规则
    postTenancyPreView: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/tenancy-sequences/actions/preview`, // 修改 tenancyNumber 规则
    getInvoiceAutomation: `${HOMEBOZZ_COMPOSITE}/tenant/invoices-generate/tenancies/`, // 查询InvoiceAutomation
    putInvoiceAutomation: `${HOMEBOZZ_COMPOSITE}/tenant/invoices-generate/`, // 修改InvoiceAutomation
    getTenancyPayingParty: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/tenancy-paying_party`,
    setTenancyPayingParty: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/tenancy-paying_party`,
    getTenancyNumber: `${HOMEBOZZ_COMPOSITE}/tenant/tenancies/tenant`
  },
  asset: {
    newAsset: `${SERVER_ADDRESS_HOMEBOZZ}/asset/newAsset.php`,
    getAssetListing: `${SERVER_ADDRESS_HOMEBOZZ}/asset/getAssetListing.php`,
    getAssetDetails: `${SERVER_ADDRESS_HOMEBOZZ}/asset/getAssetDetails.php`,
    getAssetInfo: `${SERVER_ADDRESS_HOMEBOZZ}/asset/getAssetInfo.php`,
    updateAssetInfo: `${SERVER_ADDRESS_HOMEBOZZ}/asset/updateAsset.php`,
    deleteAsset: `${SERVER_ADDRESS_HOMEBOZZ}/asset/deleteAsset.php`
  },
  // 设置
  settings: {
    getSettingsGeo: `${SERVER_ADDRESS_HOMEBOZZ}/settings/geo.php`, // 根据文字获取GPS位置
    getSettingsZoneListing: `${SERVER_ADDRESS_HOMEBOZZ}/settings/getZoneListing.php`, // 获取时区列表
    getSettingsgetStateListing: `${SERVER_ADDRESS_HOMEBOZZ}/settings/getStateListing.php`, // 根据国家code获取地区列表
    getManagerSettingDetails: `${SERVER_ADDRESS_HOMEBOZZ}/settings/getManagerSettingDetails.php`, // 获取团队设置详情
    getMySettings: `${SERVER_ADDRESS_HOMEBOZZ}/settings/getMySettings.php`, // 获取我的设置详情
    updateMySettings: `${SERVER_ADDRESS_HOMEBOZZ}/settings/updateMySettings.php`, // 修改我的设置
    updateManagerSetting: `${SERVER_ADDRESS_HOMEBOZZ}/settings/updateManagerSetting.php`, // 更新团队设置
    // freemiu Setting
    general: `${HOMEBOZZ_COMPOSITE}/accounts/company`,
    spacetype: `${HOMEBOZZ_COMPOSITE}/properties/type`,
    customlabel: `${HOMEBOZZ_COMPOSITE}/accounts/company-configure/code`,
    customother: `${HOMEBOZZ_COMPOSITE}/accounts/company-configure`,
    compayinfo: `${HOMEBOZZ_COMPOSITE}/accounts/company-email-configure`,
    getuserList: `${HOMEBOZZ_COMPOSITE}/accounts/company/members`,
    addUser: `${HOMEBOZZ_COMPOSITE}/accounts/company/members`,
    editmyfile: `${SERVER_ADDRESS}/server/profileSave.php`,
    countryDistricts: `${HOMEBOZZ_COMPOSITE}/accounts/company/country-districts`
  },
  // 照片
  photos: {
    uploadPhoto: `${SERVER_ADDRESS_HOMEBOZZ}/photos/uploadPhoto.php`, // 上传 照片
    getPhotoListing: `${SERVER_ADDRESS_HOMEBOZZ}/photos/getPhotoListing.php`, // 获取 photos 照片列表
    deletePhoto: `${SERVER_ADDRESS_HOMEBOZZ}/photos/deletePhoto.php`, // 删除照片
    savePhotoCaption: `${SERVER_ADDRESS_HOMEBOZZ}/photos/savePhotoCaption.php` // 修改照片描述
  },
  // 二维码相关接口
  qrcode: {
    getQrcodeDetail: `${SERVER_ADDRESS_HOMEBOZZ}/qrcode/getQrcodeDetail.php`, // 根据二维码内容查看是否有绑定
    deleteQrcode: `${SERVER_ADDRESS_HOMEBOZZ}/qrcode/deleteQrcode.php`, // 删除二维码
    newQrcode: `${SERVER_ADDRESS_HOMEBOZZ}/qrcode/newQrcode.php`, // 绑定二维码
    getQrcodeByEntity: `${SERVER_ADDRESS_HOMEBOZZ}/qrcode/getQrcodeByEntity.php`, // 根据 entity 获取二维码
    getQrcodeByEntityAndId: `${HOMEBOZZ_COMPOSITE}/qrcodes`, // 用Java根据entity和id获取二维码
    getQrcodeForSpace: `${HOMEBOZZ_COMPOSITE}/properties/links`,
    getQrcodeForBooking: `${HOMEBOZZ_COMPOSITE}/qrcodes/entity/booking/id`
  },

  // 聊天室chat
  chat: {
    getChatMessage: `${SERVER_ADDRESS_HOMEBOZZ}/qrcode/getQrcodeDetail.php` // 获取chat的信息列表
  },
  // 消息推送Notification
  notification: {
    getNotificationListing: `${SERVER_ADDRESS_HOMEBOZZ}/notification/getNotificationListing.php`,
    removeNotification: `${SERVER_ADDRESS_HOMEBOZZ}/notification/removeNotification.php`,
    saveNotificationToken: `${SERVER_ADDRESS_HOMEBOZZ}/notification/saveNotificationToken.php`,
    readNotification: `${SERVER_ADDRESS_HOMEBOZZ}/notification/readNotification.php`,
    notificationJava: `${HOMEBOZZ_COMPOSITE}/notifications`
  },
  // 事件
  event: {
    getEvents: `${SERVER_ADDRESS_HOMEBOZZ}/event/getEvents.php`, // 获取event的信息列表
    newEvent: `${SERVER_ADDRESS_HOMEBOZZ}/event/newEvent.php`,
    updateEvent: `${SERVER_ADDRESS_HOMEBOZZ}/event/updateEvent.php`,
    removeEvent: `${SERVER_ADDRESS_HOMEBOZZ}/event/removeEvent.php`
  },
  // 附件
  attachments: {
    getAttachmentListing: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/getAttachmentListing.php`,
    getAttachmentSharingListing: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/getAttachmentSharingListing.php`,
    newSubfolder: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/newSubfolder.php`,
    uploadAttachment: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/uploadAttachment.php`,
    deleteAttachment: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/deleteAttachment.php`,
    saveAttachmentSharing: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/saveAttachmentSharing.php`,
    updateAttachment: `${SERVER_ADDRESS_HOMEBOZZ}/attachments/updateAttachment.php`,
    attachmentFiles: `${HOMEBOZZ_ATTACHMENTS}/attachments`,
    saveAttachmentFiles: `${HOMEBOZZ_COMPOSITE}/attachments/attachments`,
    updateAttachmentFiles: `${HOMEBOZZ_COMPOSITE}/attachments/attachments`,
    removeAttachmentFiles: `${HOMEBOZZ_COMPOSITE}/attachments/attachments`,
    actionUsers: `${HOMEBOZZ_COMPOSITE}/attachments`, // 返回client users
    attachmentFileCount: `${HOMEBOZZ_ATTACHMENTS}/attachments/count` // 获取knowledge base下文件数量
  },
  // Custom Fields
  customFields: {
    getCustomFields: `${HOMEBOZZ_COMPOSITE}/custom-fields`,
    getComments: `${HOMEBOZZ_COMPOSITE}/custom-fields/comments`,
    newComments: `${HOMEBOZZ_COMPOSITE}/custom-fields/comments/`,
    deleteComments: `${HOMEBOZZ_COMPOSITE}/custom-fields/comments`,

    getCategories: `${HOMEBOZZ_COMPOSITE}/custom-fields/invoice-categories`,
    saveCategories: `${HOMEBOZZ_COMPOSITE}/custom-fields/invoice-categories `
  },
  // 文件上传
  file: {
    file: `/api/chat/file`
    // file:`http://10.10.10.143:3000/file`
  },
  // expense
  expense: {
    getExpenseListing: `${SERVER_ADDRESS_HOMEBOZZ}/expense/getExpenseListing.php`,
    saveExpense: `${SERVER_ADDRESS_HOMEBOZZ}/expense/saveExpense.php`,
    deleteExpense: `${SERVER_ADDRESS_HOMEBOZZ}/expense/deleteExpense.php`,
    countExpense: `${SERVER_ADDRESS_HOMEBOZZ}/expense/countExpense.php` // 根据时间统计 expense
  },
  // checklist
  checklist: {
    getlist: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/getChecklistListing.php`,
    getjobChecklist: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/getJobChecklists.php`,
    getchecklistDetails: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/getCheckListDetails.php`,
    getJobChecklistDetails: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/getJobChecklistDetails.php`,
    addChecklistToJob: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/addChecklistToJob.php`,
    saveCheckListTemplate: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/saveCheckListTemplate.php`,
    saveCheckListTemplateItem: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/saveCheckListTemplateItem.php`,
    removeChecklistTemplate: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/removeChecklistTemplate.php`,
    removeChecklistTemplateItem: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/removeChecklistTemplateItem.php`,
    saveJobChecklist: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/saveJobChecklist.php`
  },
  // customer
  customer: {
    getlist: `${SERVER_ADDRESS_HOMEBOZZ}/checklist/getChecklistListing.php`
  },
  myfolder: {
    // 附件列表
    getAttachmentListing: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/getAttachmentListing.php`,
    // 图片列表
    getPhotoListing: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/getPhotoListing.php`,
    // 上传附件
    uploadAttchment: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/uploadAttchment.php`,
    // 上传图片
    uploadPhoto: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/uploadPhoto.php`,
    // 修改文件
    updateAttachment: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/updateAttachment.php`,
    // 修改照片
    updatePhoto: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/updatePhoto.php`,
    // 删除文件
    deleteAttachment: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/deleteAttachment.php`,
    // 删除照片
    deletePhoto: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/deletePhoto.php`,
    // 创建文件夹
    newSubfolder: `${SERVER_ADDRESS_HOMEBOZZ}/myfolder/newSubfolder.php`
  },
  // owner
  owner: {
    getOwnerListing: `${SERVER_ADDRESS_HOMEBOZZ}/owner/getOwnerListing.php`, // 查询owner
    getOwnerUserListing: `${SERVER_ADDRESS_HOMEBOZZ}/owner/getOwnerUserListing.php`, // 获取 owner 下面的用户,用来显示在通讯录中
    updateOwner: `${SERVER_ADDRESS_HOMEBOZZ}/owner/updateOwner.php`, // 修改 owner
    newOwner: `${SERVER_ADDRESS_HOMEBOZZ}/owner/newOwner.php`, // 创建 owner
    getOwnerDetails: `${SERVER_ADDRESS_HOMEBOZZ}/owner/getOwnerDetails.php` // 查询 owner
  },
  // contacts
  contacts: {
    checkUserMobile: `${SERVER_ADDRESS_HOMEBOZZ}/contacts/checkUserMobile.php`, // 检测是否有这个号码的用户
    getMyContacts: `${SERVER_ADDRESS_HOMEBOZZ}/contacts/getMyContacts.php`, // 查询 联系人
    getApplicationListing: `${SERVER_ADDRESS_HOMEBOZZ}/contacts/getApplicationListing.php`, // 查看好友申请
    addUserFriend: `${SERVER_ADDRESS_HOMEBOZZ}/contacts/addUserFriend.php`, // 添加好友
    declineUser: `${SERVER_ADDRESS_HOMEBOZZ}/contacts/declineUser.php`, // 拒绝好友申请
    acceptUser: `${SERVER_ADDRESS_HOMEBOZZ}/contacts/acceptUser.php` // 通过好友申请
  },
  // report
  report: {
    reportJob: `${SERVER_ADDRESS_HOMEBOZZ_V1}/exports/jobs/actions/exports` // job report
  },
  // financial
  // financial: {
  //   financial: `${SERVER_ADDRESS_HOMEBOZZ_V1}/financial/`, //
  //   accounts: `${SERVER_ADDRESS_HOMEBOZZ_V1}/financial/accounts/`, //
  //   count: `${SERVER_ADDRESS_HOMEBOZZ_V1}/financial/count`, //
  // },
  financial: {
    financial: `${SERVER_ADDRESS_HOMEBOZZ}/financial/`, //
    accounts: `${SERVER_ADDRESS_HOMEBOZZ}/financial/listType.php`, //
    count: `${SERVER_ADDRESS_HOMEBOZZ}/financial/count.php`, //
    exportFinancials: `${SERVER_ADDRESS_HOMEBOZZ}/financial/exportFinancials.php`,
    getInvoiceList: HOMEBOZZ_INVOICES, // 获取账单列表
    getInvoicesCount: `${HOMEBOZZ_INVOICES}/count/state`, // 获取账单数据统计
    getInvoiceIssueDate: `${HOMEBOZZ_INVOICES}/configure`,
    setInvoice: HOMEBOZZ_INVOICES, // 保持账单信息
    getInvocieInfo: `${HOMEBOZZ_INVOICES}/`, // 查看账单详情
    putMarkAsPaid: `${HOMEBOZZ_INVOICES}/`, // 标记为已付款
    getInvoiceCount: `${HOMEBOZZ_INVOICES}/count`, // 获取账单金额总数
    putInvoiceInfo: `${HOMEBOZZ_INVOICES}/`, // 修改账单信息
    getInvoiceHistories: `${HOMEBOZZ_FINANCIAL}/invoices-histories`, // 账单记录列表
    getXeroInfo: HOMEBOZZ_FINANCIAL_XERO,
    getInvoiceSequences: `${HOMEBOZZ_FINANCIAL}/invoice-sequences/actions/subscribe`, // 获取发票序列号规则
    putInvoiceSequences: `${HOMEBOZZ_FINANCIAL}/invoice-sequences/`, // 修改发票序列号规则
    postInvoicePreView: `${HOMEBOZZ_FINANCIAL}/invoice-sequences/actions/preview`, // 修改发票序列号规则
    postXeroSyncContacts: `${HOMEBOZZ_FINANCIAL}/xero-info/sync-contacts/`,
    exportFinancialData: `${HOMEBOZZ_V1}/financial/export`,
    firstMonthInvoice: `${HOMEBOZZ_FINANCIAL}/invoices`,
    getStripe: `${HOMEBOZZ_FINANCIAL}/stripe`,
    postStripeConnect: `${HOMEBOZZ_FINANCIAL}/stripe/connect`,
    putXeroState: `${HOMEBOZZ_FINANCIAL}/authorization/xero/authorization-state`,
    exportOverDue: `${HOMEBOZZ_INVOICES}/overdue/export`,
    financialByJava: HOMEBOZZ_FINANCIAL
  },
  crmNotification: {
    notificationTemplates: `${HOMEBOZZ_COMPOSITE}/notifications/email-templates`, // 模板数据相关
    notificationVariables: `${HOMEBOZZ_COMPOSITE}/notifications/variables` // 变量相关
  },
  properties: {
    // getPropertiesList: `${SERVER_ADDRESS_HOMEBOZZ}/property/getPropertyListing.php`, // 获取properties list数据
    getPropertiesList: `${HOMEBOZZ_COMPOSITE}/properties/`, // 获取properties list数据
    putProperties: `${HOMEBOZZ_COMPOSITE}/properties/`, // 修改properties list数据
    getPropertiesType: `${HOMEBOZZ_COMPOSITE}/properties/type`, // 获取space type
    getBookingRecords: `${HOMEBOZZ_COMPOSITE}/bookings/records`, // 获取space type
    getPropertyBooking: `${HOMEBOZZ_COMPOSITE}/bookings/`, // 获取Property预定信息
    addPropertyBooking: `${HOMEBOZZ_COMPOSITE}/bookings/`, // 新增Property预定信息
    addNewProperties: `${HOMEBOZZ_COMPOSITE}/properties/`, // 创建 property 单个
    // addNewProperties: `${SERVER_ADDRESS_HOMEBOZZ}/property/newProperty.php`, // 创建 property
    deleteProperties: `${HOMEBOZZ_COMPOSITE}/properties/`,
    addMoreProperties: `${HOMEBOZZ_COMPOSITE}/properties/actions/multi`, // 创建 property 多个
    getPropertyDetail: `${HOMEBOZZ_COMPOSITE}/properties/`,
    getPropertyAccess: `${HOMEBOZZ_COMPOSITE}/properties/access`,
    updateAccess: `${HOMEBOZZ_COMPOSITE}/properties/access`,
    updateTemplateField: `${HOMEBOZZ_COMPOSITE}/properties/template-field`,
    getPropertiesLinkUrl: `${HOMEBOZZ_COMPOSITE}/properties/links`
  },
  bookings: {
    getTemplates: `${HOMEBOZZ_COMPOSITE_BOOKINGS_HOLIDAY}/templates`,
    getRecordsActions: `${HOMEBOZZ_COMPOSITE_BOOKINGS_HOLIDAY}/records/actions/account`,
    getCompanyHolidays: HOMEBOZZ_COMPOSITE_BOOKINGS_COMPANY_HOLIDYS,
    getBookingStatistics: `${HOMEBOZZ_COMPOSITE}/bookings/statistics`,
    bookingJob: `${HOMEBOZZ_COMPOSITE}/bookings/records`,
    generatorCodeByType: `${HOMEBOZZ_COMPOSITE}/bookings/records/verification-code`
  },
  health: {
    getHealthByEntityId: `${HOMEBOZZ_COMPOSITE}/audits/health/jobs`
  },
  operatorLog: {
    getOperatorLogList: `${HOMEBOZZ_COMPOSITE}/audits/operator-logs`
  },
  accounts: {
    accounts: `${HOMEBOZZ_COMPOSITE}/accounts`,
    saveUserRoles: `/server/saveUserRoles.php`
  },
  stripe: {
    getPaymentUrl: `${HOMEBOZZ_STRIPE}/payment`
  },
  dashboard: {
    getDashboardData: `${HOMEBOZZ_V1}/accounts/company/dashboard`
  }
};

export default API_ADDRESS;


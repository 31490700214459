import _ from 'lodash';
import { nanoid } from 'nanoid';
import Axios from './axios';
import Address from './api';
interface UploadToS3Props {
  url: string;
  method: string;
  options: object;
  headers: object;
  key: string;
}

async function callApi(
  endpoint: string,
  method: string,
  options: object,
  type?: string,
  delete_type?: boolean,
  uploadS3?: boolean,
  uploadS3headers?: {}
) {
  return Axios(
    `${endpoint}`,
    method,
    options,
    type,
    delete_type,
    uploadS3,
    uploadS3headers
  );
}

/** ************************************************* 用户 User ***************************************************/

// 获取国家信息数据
export async function getCountryList() {
  return callApi(Address.country.getCountryList, 'GET', {});
}

// 登录接口
export async function loginFun(body: Object) {
  return callApi(Address.user.login, 'POST', body);
}

// 刷新token
export async function refreshToken(body: Object) {
  return callApi(Address.user.refreshToken, 'POST', body);
}
/**
 * 多重身份验证相关
 * @param body
 */
export async function disableMFA(body: Object) {
  return callApi(Address.user.disableMFA, 'POST', body);
}
export async function getMFA(body: Object) {
  return callApi(Address.user.getMFA, 'POST', body);
}
export async function saveMFA(body: Object) {
  return callApi(Address.user.saveMFA, 'POST', body);
}

/**
 * 查询用户信息
 * @param body
 */
export async function viewUser(body: Object) {
  return callApi(Address.user.viewUser, 'POST', body);
}

/**
 * 修改用户信息
 * @param body
 */
export async function updateUser(body: Object) {
  return callApi(Address.user.updateUser, 'POST', body);
}

/**
 * 新增用户信息
 * @param body
 */
export async function newUser(body: Object) {
  return callApi(Address.user.newUser, 'POST', body);
}
/**
 * 在tenant 新增用户信息
 * @param body
 */
export async function newUserToAssetBozz(body: any) {
  return callApi(
    `${Address.tenant.tenantUsers}${body.tenantId}`,
    'POST',
    body.params
  );
}

/**
 * 修改用户角色
 * @param body
 */
export async function updateUserRoles(body: Object) {
  return callApi(Address.user.updateUserRoles, 'POST', body);
}

/**
 * 修改密码
 * @param body
 */
export async function changePassword(body: Object) {
  return callApi(Address.user.changePassword, 'POST', body);
}

/**
 * 主页信息
 * @param body
 */
export async function dashboard(body: Object) {
  return callApi(Address.user.dashboard, 'POST', body);
}

/**
 * 查询团队成员
 * @param body
 */
export async function getTeamUsers(body: Object) {
  return callApi(Address.user.getTeamUsers, 'POST', body);
}

// 检查手机号码是否被使用
export async function isMobileNumberTaken(body: Object) {
  return callApi(Address.user.isMobileNumberTaken, 'POST', body);
}

// 发送验证码
export async function requestOTP(body: Object) {
  return callApi(Address.user.requestOTP, 'POST', body);
}

// 检查验证码
export async function verifyOTP(body: Object) {
  return callApi(Address.user.verifyOTP, 'POST', body);
}

// 创建免费用户
export async function createFreemiumUser(body: Object) {
  return callApi(Address.user.createFreemiumUser, 'POST', body);
}
// 忘记密码发送短信
export async function forgotPasswordRequest(body: Object) {
  return callApi(Address.user.forgotPasswordRequest, 'POST', body);
}

// 忘记密码验证短信
export async function forgotPasswordVerify(body: Object) {
  return callApi(Address.user.forgotPasswordVerify, 'POST', body);
}

// 重置密码
export async function resetPassword(body: Object) {
  return callApi(Address.user.resetPassword, 'POST', body);
}

// 获取协议
export async function getTerms(body: Object) {
  return callApi(Address.user.getTerms, 'POST', body);
}

// 同意协议
export async function acceptTerms(body: Object) {
  return callApi(Address.user.acceptTerms, 'POST', body);
}

// 新增金额调用
export async function getFinancialAccountListing(body: Object) {
  return callApi(Address.user.getFinancialAccountListing, 'POST', body);
}

/** ************************************************* start contacts ***************************************************/

/**
 * 检测是否有这个号码的用户
 * @param body
 */
export async function checkUserMobile(body: Object) {
  return callApi(Address.contacts.checkUserMobile, 'POST', body);
}

// 查询 联系人
export async function getMyContacts(body: Object) {
  return callApi(Address.contacts.getMyContacts, 'POST', body);
}
// 查看好友申请
export async function getApplicationListing(body: Object) {
  return callApi(Address.contacts.getApplicationListing, 'POST', body);
}
// 添加好友
export async function addUserFriend(body: Object) {
  return callApi(Address.contacts.addUserFriend, 'POST', body);
}
// 拒绝好友申请
export async function declineUser(body: Object) {
  return callApi(Address.contacts.declineUser, 'POST', body);
}
// 通过好友申请
export async function acceptUser(body: Object) {
  return callApi(Address.contacts.acceptUser, 'POST', body);
}

/** ************************************************* end contacts ***************************************************/

/** ************************************************* 物业 property ***************************************************/

/**
 * @msg: 物业列表
 * @param {Object} body
 */
export async function getPropertyListing(body: Object) {
  return callApi(Address.property.getPropertyListing, 'POST', body);
}
/**
 * @msg: 创建 property
 * @param {Object} body
 */
export async function newProperty(body: Object) {
  return callApi(Address.property.newProperty, 'POST', body);
}
/**
 * @msg: 创建 property
 * @param {Object} body
 */
export async function getPropertyDetails(body: Object) {
  return callApi(Address.property.getPropertyDetails, 'POST', body);
}
/**
 * @msg: delete Property
 * @param {Object} body
 */
export async function deleteProperty(body: Object) {
  return callApi(Address.property.deleteProperty, 'POST', body);
}
/**
 * @msg: update Property
 * @param {Object} body
 */
export async function updateProperty(body: Object) {
  return callApi(Address.property.updateProperty, 'POST', body);
}
/**
 * @msg: pmanager 修改 property tenant
 * @param {Object} body
 */
export async function updatePropertyTenant(body: Object) {
  return callApi(Address.property.updatePropertyTenant, 'POST', body);
}
/** ************************************************* 租户 tenant ***************************************************/
/**
 * 保存租户
 * @param body
 */
export async function saveTenant(body: Object) {
  return callApi(Address.tenant.saveTenant, 'POST', body);
}
export async function saveTenant_Java(body: Object) {
  return callApi(Address.tenant.tenant, 'POST', body);
}
/**
 * 修改租户信息
 * @param body
 */
export async function updateTenant(body: Object) {
  return callApi(Address.tenant.updateTenant, 'POST', body);
}
export async function newTenantTempEntity_Java(body: any) {
  return callApi(
    `${Address.tenant.tenant}${body.id}/tenant-temp-entity`,
    'POST',
    body.params
  );
}
export async function getTenantTempEntity_Java(body: any) {
  return callApi(
    `${Address.tenant.tenant}${body}/tenant-temp-entity`,
    'GET',
    body
  );
}
export async function deleteTenantTempEntity_Java(body: any) {
  return callApi(
    `${Address.tenant.tenant}${body.id}/tenant-temp-entity/${body.tempId}`,
    'DELETE',
    body
  );
}
export async function updateTenant_Java(body: any) {
  return callApi(`${Address.tenant.tenant}${body.id}`, 'PUT', body.params);
}
export async function getTenantUser_Java(body: any) {
  return callApi(`${Address.tenant.tenant}user`, 'GET', body);
}
// 获取 分享的 client users
export async function getActionsUsers_Java(body: any) {
  return callApi(
    `${Address.attachments.actionUsers}/${body.id}/share/actions/users`,
    'GET',
    body
  );
}

// 根据 entity 和 entityId 获取 custom field 数据
export async function getCustomFields_Java(body: any) {
  return callApi(
    `${Address.customFields.getCustomFields}/entity/${body.entity}/id/${body.id}`,
    'GET',
    body
  );
}

// 根据 entity 和 entityId 保存 custom field 数据
export async function putCustomFields_Java(body: any) {
  return callApi(
    `${Address.customFields.getCustomFields}/entity/${body.entity}/id/${body.id}`,
    'PUT',
    body.params
  );
}

// 获取knowledge base下文件数量
export async function getAttachmentFileCount(body: any) {
  return callApi(`${Address.attachments.attachmentFileCount}`, 'GET', body);
}

export async function getTenantUser_Java_(body: any) {
  return callApi(`${Address.tenant.tenantUsers_}`, 'GET', body);
}
export async function newTenantUser_Java(body: any) {
  return callApi(
    `${Address.tenant.tenantUsers}${body.tenantId}/user/${body.userId}`,
    'POST',
    body.params
  );
}
export async function updateTenantUser_Java(body: any) {
  return callApi(
    `${Address.tenant.tenantUsers}${body.tenantId}`,
    'PUT',
    body.params
  );
}
export async function deleteTenantUser_Java(body: any) {
  return callApi(
    `${Address.tenant.tenantUsers_}${body.Id}`,
    'DELETE',
    body.params
  );
}
export async function updateTenantStatus_Java(body: any) {
  return callApi(
    `${Address.tenant.tenant}${body.id}/status`,
    'PUT',
    body.params
  );
}
/**
 * 获取租户列表
 * @param body
 */
export async function getTenantListing(body: Object) {
  return callApi(Address.tenant.getTenantListing, 'POST', body);
}
export async function getTenantListing_Java(body: Object) {
  return callApi(Address.tenant.tenant, 'GET', body);
}
export async function getTenantInfo_Java(body: Object) {
  return callApi(`${Address.tenant.tenant}${body}`, 'GET', {});
}
export async function deleteTenant_Java(body: Object) {
  return callApi(`${Address.tenant.tenant}${body}`, 'DELETE', body);
}
/**
 * tenant contacts list
 * @param body
 */
export async function getTenantUserListing(body: Object) {
  return callApi(Address.tenant.getTenantUserListing, 'POST', body);
}

/**
 * 将租户关联
 * @param body
 */
export async function connectTenantByAdmin(body: Object) {
  return callApi(Address.tenant.connectTenantByAdmin, 'POST', body);
}
/**
 * 将租户解绑
 * @param body
 */
export async function disconnectTenant(body: Object) {
  return callApi(Address.tenant.disconnectTenant, 'POST', body);
}
/** ************************************************* 租约 tenancy ***************************************************/
/**
 * 保存租约
 * @param body
 */
export async function saveTenancy(body: Object) {
  return callApi(Address.tenancy.saveTenancy, 'POST', body);
}
export async function saveTenancy_JAVA(body: Object) {
  return callApi(Address.tenancy.tenancy, 'POST', body);
}
export async function updateTenancy_JAVA(body: any) {
  return callApi(`${Address.tenancy.tenancy}/${body.id}`, 'PUT', body.params);
}

/**
 * 获取租约列表
 * @param bodu
 */
export async function getTenanciesListing(body: Object) {
  return callApi(Address.tenancy.getTenanciesListing, 'POST', body);
}
export async function getTenanciesListing_JAVA(body: Object) {
  return callApi(`${Address.tenancy.tenancy}`, 'GET', body);
}
export async function getTenanciesEntitiesActive_Java(body: any) {
  return callApi(`${Address.tenancy.tenanciesEntitiesActive}`, 'GET', body);
}
export async function getTenanciesEntitiesExists_Java(body: any) {
  return callApi(`${Address.tenancy.tenanciesEntitiesExists}`, 'GET', body);
}
export async function getTenanciesInfo_Java(body: any) {
  const res: any = await callApi(
    `${Address.tenancy.tenancy}/${body.id}`,
    'GET',
    {}
  );
  return res;
}
export async function getTenancyCount_Java(body: any) {
  return callApi(`${Address.tenancy.tenancy}/tenanciesCount`, 'GET', body);
}
/**
 * 删除租约
 * @param body
 */
export async function deleteTenancy(body: Object) {
  return callApi(Address.tenancy.deleteTenancy, 'POST', body);
}
export async function deleteTenancy_Java(body: any) {
  return callApi(`${Address.tenancy.tenancy}/${body.id}`, 'DELETE', {});
}

/**
根据租约id获取通知信息
 */
export async function getReminderForTenancyId_Java(body: any) {
  return callApi(
    `${Address.tenancy.tenanciesReminders}/${body.tenancyId}/notification`,
    'get',
    body
  );
}

/**
根据通知id获取通知详情
 */
export async function getNotiificationForReminderId_Java(body: any) {
  return callApi(
    `${Address.tenancy.tenanciesNotiification}/${body.notificationId}`,
    'get',
    body?.params
  );
}

/**
修改租约下的通知
 */
export async function puttNotiificationForId_Java(body: any) {
  return callApi(
    `${Address.tenancy.tenanciesNotiification}/${body.notificationId}`,
    'put',
    body.params
  );
}

/** ************************************************* 项目管理员 pmanager ***************************************************/

/**
 * @msg: 获取pmanager
 * @param {Object} body
 */
export async function getPManagerListing(body: Object) {
  return callApi(Address.pmanager.getPManagerListing, 'POST', body);
}
/**
 * @msg: 创建 pmanager
 * @param {Object} body
 */
export async function newPManager(body: Object) {
  return callApi(Address.pmanager.newPManager, 'POST', body);
}
/**
 * @msg: 修改 pmanager
 * @param {Object} body
 */
export async function updatePManager(body: Object) {
  return callApi(Address.pmanager.updatePManager, 'POST', body);
}
/**
 * @msg: 修改 property manager
 * @param {Object} body
 */
export async function updatePropertyManager(body: Object) {
  return callApi(Address.pmanager.updatePropertyManager, 'POST', body);
}
/**
 * @msg: pmanager contacts list
 * @param {Object} body
 */
export async function getPmanagerUserListing(body: Object) {
  return callApi(Address.pmanager.getPmanagerUserListing, 'POST', body);
}

/** ************************************************* 设置  Settings ***************************************************/
/**
 * @msg: 根据文字获取GPS位置
 * @param {Object} body
 */
export async function getSettingsGeo(body: Object) {
  return callApi(Address.settings.getSettingsGeo, 'POST', body);
}
/**
 * @msg: 获取时区列表
 * @param {Object} body
 */
export async function getSettingsZoneListing(body: Object) {
  return callApi(Address.settings.getSettingsZoneListing, 'POST', body);
}
/**
 * @msg: 根据国家code获取地区列表
 * @param {Object} body
 */
export async function getSettingsgetStateListing(body: Object) {
  return callApi(Address.settings.getSettingsgetStateListing, 'POST', body);
}

/**
 * 获取团队设置详情
 * @param body
 */
export async function getManagerSettingDetails(body: Object) {
  return callApi(Address.settings.getManagerSettingDetails, 'POST', body);
}

/**
 * 设置
 * @param body
 */
export async function getMySettings(body: Object) {
  return callApi(Address.settings.getMySettings, 'POST', body);
}

/**
 * 修改我的设置
 * @param body
 */
export async function updateMySettings(body: Object) {
  return callApi(Address.settings.updateMySettings, 'POST', body);
}
/**
 * 更新团队设置
 * @param body
 */
export async function updateManagerSetting(body: Object) {
  return callApi(Address.settings.updateManagerSetting, 'POST', body);
}

/** ************************************************* 物資 Asset ***************************************************/
// 新建物资
export async function newAsset(body: Object) {
  return callApi(Address.asset.newAsset, 'POST', body);
}

// 物资列表
export async function getAssetList(body: Object) {
  return callApi(Address.asset.getAssetListing, 'POST', body);
}

// 物资详情
export async function getAssetDetails(body: Object) {
  return callApi(Address.asset.getAssetDetails, 'POST', body);
}

export const RequestHoc = (Com: any) => {
  try {
    Com();
  } catch (error) {
    console.log(error);
  }
};

export const getAssetDetailsFC = (asset_id: string, setLinkedEntityId: any) =>
  RequestHoc(async () => {
    const res: any = await getAssetDetails({
      asset_id
    });
    if (_.get(res, 'result', '') === 0) {
      setLinkedEntityId({
        value: _.get(res, 'data.data.asset_id') || '',
        label: _.get(res, 'data.data.member_asset_name') || ''
      });
    }
  });

// 物资信息
export async function getAssetInfo(body: Object) {
  return callApi(Address.asset.getAssetInfo, 'POST', body);
}

// 修改物资信息
export async function updateAssetInfo(body: Object) {
  return callApi(Address.asset.updateAssetInfo, 'POST', body);
}

// 删除物资
export async function deleteAsset(body: Object) {
  return callApi(Address.asset.deleteAsset, 'POST', body);
}

/** ************************************************* 工单 Job ***************************************************/

// 获取joblist
export async function getJoblist(body: Object) {
  return callApi(Address.job.getJobListing, 'POST', body);
}

// 获取joblist status
export async function getCountJobStatus(body: Object) {
  return callApi(Address.job.countJobStatus, 'POST', body);
}

// 修改  job contact person
export async function saveJobContact(body: Object) {
  return callApi(Address.job.saveJobContact, 'POST', body);
}

// 获取job单个信息数据
export async function getJobinfo(body: Object) {
  const jobinfo: any = await callApi(Address.job.getJobDetails, 'POST', body);
  const status =
    typeof jobinfo.data.data.status === 'object'
      ? jobinfo.data.data.status.status
      : jobinfo.data.data.status;
  jobinfo.data.data.status = status;
  return jobinfo;
}

// 修改jobinfo
export async function updateJobInfo(body: Object) {
  return callApi(Address.job.updateJobInfo, 'POST', body);
}

// job获取 provider 短信链接
export async function getProviderSmsContent(body: Object) {
  return callApi(Address.job.getProviderSmsContent, 'POST', body);
}

// job获取 发送provider 短信
export async function sendProviderSMS(body: Object) {
  return callApi(Address.job.sendProviderSMS, 'POST', body);
}

// job获取 provider 邮件链接
export async function getProviderEmailContent(body: Object) {
  return callApi(Address.job.getProviderEmailContent, 'POST', body);
}

// job获取 发送provider 邮件
export async function sendProviderEmail(body: Object) {
  return callApi(Address.job.sendProviderEmail, 'POST', body);
}

// job获取 provider whatsapp 链接
export async function getProviderWhatsAppContent(body: Object) {
  return callApi(Address.job.getProviderWhatsAppContent, 'POST', body);
}

// job获取 发送provider whatsapp
export async function sendProviderWhatsApp(body: Object) {
  return callApi(Address.job.sendProviderWhatsApp, 'POST', body);
}

// 更改job的状态信息
export async function updateJobStatus(body: Object) {
  return callApi(Address.job.updateJobStatus, 'POST', body);
}
// new job
export async function newJobinformation(body: Object) {
  return callApi(Address.job.newJob, 'POST', body);
}
// new report job
export async function newReportJobinformation(body: Object) {
  return callApi(Address.job.newReportJob, 'POST', body);
}

// 获取照片的列表信息
export async function getfolderPhotos(body: Object) {
  return getPhotoListing(body);
}

// 上传图片
export async function setfolderPhotos(body: Object) {
  return uploadPhoto(body);
}

export async function getJobAssignments(body: Object) {
  return callApi(Address.job.getJobAssignments, 'POST', body);
}

export async function saveJobAssignments(body: Object) {
  return callApi(Address.job.saveJobAssignments, 'POST', body);
}

export async function searchEmployeesForJob(body: Object) {
  return callApi(Address.job.searchEmployeesForJob, 'POST', body);
}

export async function acceptJob(body: Object) {
  return callApi(Address.job.acceptJob, 'POST', body);
}

export async function checkin(body: Object) {
  return callApi(Address.job.checkin, 'POST', body);
}

/**
 *
 * @param body job 新增了一个API,用来返回在property 详情页面显示的job
 *  entity,entity_id,status
 * @returns
 */
export async function getActiveJobListing(body: Object) {
  return callApi(Address.job.getActiveJobListing, 'POST', body);
}
/** ************************************************* 收入  Expense ***************************************************/
// getExpenseListing 获取工单的加钱
export async function getExpenseListing(body: Object) {
  return callApi(Address.expense.getExpenseListing, 'POST', body);
}

// 新增金额
export async function saveExpense(body: Object) {
  return callApi(Address.expense.saveExpense, 'POST', body);
}

// 删除金额部分
export async function deleteExpense(body: Object) {
  return callApi(Address.expense.deleteExpense, 'POST', body);
}

// 根据时间统计 expense
export async function countExpense(body: Object) {
  return callApi(Address.expense.countExpense, 'POST', body);
}

/** ************************************************* 照片  photos ***************************************************/
// 照片的列表
export async function getPhotoListing(body: Object) {
  return callApi(Address.photos.getPhotoListing, 'POST', body);
}

// 上传图片
export async function uploadPhoto(body: Object) {
  return callApi(Address.photos.uploadPhoto, 'POST', body, 'formdata');
}

// 删除图片
export async function deletePhoto(body: Object) {
  return callApi(Address.photos.deletePhoto, 'POST', body);
}

// 修改照片描述
export async function savePhotosCaption(body: Object) {
  return callApi(Address.photos.savePhotoCaption, 'POST', body);
}

/** ************************************************* 二维码相关接口  qrcode ***************************************************/
// 根据二维码内容查看是否有绑定
export async function getQrcodeDetail(body: Object) {
  return callApi(Address.qrcode.getQrcodeDetail, 'POST', body);
}
// 删除二维码
export async function deleteQrcode(body: Object) {
  return callApi(Address.qrcode.deleteQrcode, 'POST', body);
}
// 绑定二维码
export async function newQrcode(body: Object) {
  return callApi(Address.qrcode.newQrcode, 'POST', body);
}
// 绑定二维码
export async function getQrcodeByEntity(body: Object) {
  return callApi(Address.qrcode.getQrcodeByEntity, 'POST', body);
}

// 获取二维码
export async function getQrcodeImage(id: any) {
  return callApi(Address.qrcode.getQrcodeForSpace, 'GET', {
    propertyIdIn: id,
    infoLinks: true,
    type: 1
  });
}

// 获取booking二维码
export async function getBookingQrcodeImage(id: any) {
  return callApi(`${Address.qrcode.getQrcodeForBooking}/${id}`, 'GET', {});
}

export async function sendEmailForBooking(id: any) {
  return callApi(`${Address.bookings.bookingJob}/${id}/email`, 'POST', {});
}

/** *************************************************  provider ***************************************************/
/**
 * @msg: getProviderListing 获取供应商人员列表
 * @param {Object} body
 */
export async function getProviderListing(body: Object) {
  return callApi(Address.provider.getProviderListing, 'POST', body);
}
/**
 * @msg: newProvider 添加供应商人员
 * @param {Object} body
 */
export async function newProvider(body: Object) {
  return callApi(Address.provider.newProvider, 'POST', body);
}
/**
 * @msg: updateProvider 更新供应商人员列表
 * @param {Object} body
 */
export async function updateProvider(body: Object) {
  return callApi(Address.provider.updateProvider, 'POST', body);
}
/**
 * @msg: Provider contacts list
 * @param {Object} body
 */
export async function getProviderUserListing(body: Object) {
  return callApi(Address.provider.getProviderUserListing, 'POST', body);
}

export async function getPropertyAccessListing(body: Object) {
  return callApi(Address.user.getPropertyAccessListing, 'POST', body);
}

export async function savePropertyAccess(body: Object) {
  return callApi(Address.user.savePropertyAccess, 'POST', body);
}

/** ************************************************* chat聊天室 ***************************************************/
// 获取聊天室的信息列表
export async function getChatMessage(body: Object) {
  return callApi(Address.chat.getChatMessage, 'POST', body);
}

// 获取消息推送
export async function getNotificationListing(body: Object) {
  return callApi(Address.notification.getNotificationListing, 'POST', body);
}

// 保存消息推送的
export async function saveNotificationToken(body: Object) {
  return callApi(Address.notification.saveNotificationToken, 'POST', body);
}

export async function readNotification(body: Object) {
  return callApi(Address.notification.readNotification, 'POST', body);
}

export async function removeNotification(body: Object) {
  return callApi(Address.notification.removeNotification, 'POST', body);
}

// 保存notification
export async function saveNotificationJava(body: Object) {
  return callApi(`${Address.notification.notificationJava}/`, 'POST', body);
}
// 获取notificationlist
export async function getNotificationListJava(body: Object) {
  return callApi(`${Address.notification.notificationJava}/`, 'GET', body);
}
// 获取notificationinfo
export async function getNotificationInfoJava(body: Object) {
  return callApi(
    `${Address.notification.notificationJava}/${body}`,
    'GET',
    body
  );
}
// 获取notification tenant users
export async function getNotificationUsers(body: Object) {
  return callApi(`${Address.notification.notificationJava}/users`, 'GET', body);
}
// 标记notification 已读
export async function changeRead(body: Object) {
  return callApi(
    `${Address.notification.notificationJava}/${body}/read`,
    'PUT',
    body
  );
}

/** ************************************************* Event 事件 ***************************************************/
// 查询事件
export async function getEvents(body: Object) {
  return callApi(Address.event.getEvents, 'POST', body);
}

export async function newEvent(body: Object) {
  return callApi(Address.event.newEvent, 'POST', body);
}

export async function updateEvent(body: Object) {
  return callApi(Address.event.updateEvent, 'POST', body);
}

export async function removeEvent(body: Object) {
  return callApi(Address.event.removeEvent, 'POST', body);
}
/** ************************************************* Attachment 附件 ***************************************************/
// 附件 Java
// 获取 S3 访问的token
export async function getSignature_Java(body: object) {
  return callApi(
    `${Address.attachments.attachmentFiles}/signature`,
    'GET',
    body
  );
}
// 上传文件到s3
export async function uploadToS3(body: UploadToS3Props) {
  return callApi(
    body.url,
    body.method,
    body.options,
    undefined,
    false,
    true,
    body.headers
  );
}
// 上传文件到服务器压缩
export async function uploadCompression(body: object) {
  return callApi(
    `${Address.attachments.attachmentFiles}/image/upload-compression`,
    'POST',
    body,
    'formdata'
  );
}
// 保存附件 (file,url,photo,folder)
export async function saveAttachmentFile_Java(body: object) {
  return callApi(Address.attachments.saveAttachmentFiles, 'POST', body);
}
// 查询附件列表
export async function getAttachmentFileListing_Java(body: Object) {
  return callApi(Address.attachments.attachmentFiles, 'GET', body);
}
// 查询附件详情
export async function getAttachmentFileInfo_Java(body: Object) {
  return callApi(`${Address.attachments.attachmentFiles}/${body}`, 'GET', body);
}
// 获取附件内容
export async function getAttachmentFileContent_Java(body: Object) {
  return callApi(
    `${Address.attachments.attachmentFiles}/${body}/content`,
    'GET',
    body
  );
}
// 获取shareType的文件
export async function getAttachmentShareFileContent_Java(
  shareType: string,
  body: any
) {
  return callApi(
    `${Address.attachments.attachmentFiles}/share-type/${shareType}`,
    'GET',
    body
  );
}
// 修改附件详情
export async function updateAttachmentFileInfo_Java(body: any) {
  return callApi(
    `${Address.attachments.updateAttachmentFiles}/${body.id}`,
    'PUT',
    body.params
  );
}
export async function replaceAttachmentFile_Java(body: any) {
  return callApi(
    `${Address.attachments.updateAttachmentFiles}/replace/default`,
    'PUT',
    body
  );
}
// 批量删除附件
export async function deleteAttachmentFile_Java(body: any) {
  return callApi(
    Address.attachments.removeAttachmentFiles,
    'DELETE',
    body,
    '',
    true
  );
}

// 批量上传文件
export async function uploadAttachmentFileMore_Java(body: any) {
  return callApi(
    `${Address.attachments.saveAttachmentFiles}/multiple`,
    'POST',
    body
  );
}
// 批量复制附件
export async function copyAttachmentFile_Java(body: any) {
  return callApi(`${Address.attachments.attachmentFiles}/move`, 'POST', body);
}
// 查询分享文件列表
export async function getShareAttachmentListing_Java(body: Object) {
  return callApi(`${Address.attachments.attachmentFiles}/share`, 'GET', body);
}
// 保存 分享文件
export async function saveShareAttachmentFile_Java(body: any) {
  return callApi(
    `${Address.attachments.attachmentFiles}/${body.id}/share`,
    'POST',
    body
  );
}
// 批量删除分享文件
export async function deleteShareAttachmentFile_Java(body: any) {
  return callApi(
    `${Address.attachments.attachmentFiles}/share`,
    'DELETE',
    body,
    '',
    true
  );
}

// 查询附件
export async function getAttachmentListing(body: Object) {
  return callApi(Address.attachments.getAttachmentListing, 'POST', body);
}

// 查询分享附件
export async function getAttachmentSharingListing(body: Object) {
  return callApi(Address.attachments.getAttachmentSharingListing, 'POST', body);
}

// 创建子文件夹
export async function setFolder(body: Object) {
  return callApi(Address.attachments.newSubfolder, 'POST', body);
}

// 上传附件
export async function uploadAttachment(body: Object) {
  return callApi(
    Address.attachments.uploadAttachment,
    'POST',
    body,
    'formdata'
  );
}

// 删除附件
export async function deleteAttachment(body: Object) {
  return callApi(Address.attachments.deleteAttachment, 'POST', body);
}

// 保存文件分享状态
export async function saveAttachmentSharing(body: Object) {
  return callApi(Address.attachments.saveAttachmentSharing, 'POST', body);
}

// 修改文件信息
export async function updateAttachment(body: Object) {
  return callApi(Address.attachments.updateAttachment, 'POST', body);
}

// 上传文件
export async function uploadFile(body: Object) {
  console.log('requestde信息', body);
  return callApi(Address.file.file, 'POST', body, 'formdata');
} // 上传文件
/** ************************************************* checklist ***************************************************/
export async function getChecklistList(body: Object) {
  return callApi(Address.checklist.getlist, 'POST', body);
}

export async function getjobChecklistList(body: Object) {
  return callApi(Address.checklist.getjobChecklist, 'POST', body);
}

export async function getchecklistDetails(body: Object) {
  return callApi(Address.checklist.getchecklistDetails, 'POST', body);
}

export async function getJobChecklistDetails(body: Object) {
  return callApi(Address.checklist.getJobChecklistDetails, 'POST', body);
}

export async function addChecklistToJob(body: Object) {
  return callApi(Address.checklist.addChecklistToJob, 'POST', body);
}

export async function saveCheckListTemplate(body: Object) {
  return callApi(Address.checklist.saveCheckListTemplate, 'POST', body);
}

export async function saveCheckListTemplateItem(body: Object) {
  return callApi(Address.checklist.saveCheckListTemplateItem, 'POST', body);
}

export async function removeChecklistTemplate(body: Object) {
  return callApi(Address.checklist.removeChecklistTemplate, 'POST', body);
}

export async function removeChecklistTemplateItem(body: Object) {
  return callApi(Address.checklist.removeChecklistTemplateItem, 'POST', body);
}

export async function saveJobChecklist(body: Object) {
  return callApi(Address.checklist.saveJobChecklist, 'POST', body);
}

/** ************************************************* Customer ***************************************************/

export async function getCustomerList(body: Object) {
  return callApi(Address.customer.getlist, 'POST', body);
}

/** ************************************************* My Folder ***************************************************/

export async function getMyFolderAttachmentListing(body: Object) {
  return callApi(Address.myfolder.getAttachmentListing, 'POST', body);
}

export async function getMyFolderPhotoListing(body: Object) {
  return callApi(Address.myfolder.getPhotoListing, 'POST', body);
}

export async function uploadMyFolderAttchment(body: Object) {
  return callApi(Address.myfolder.uploadAttchment, 'POST', body, 'formdata');
}

export async function uploadMyFolderPhoto(body: Object) {
  return callApi(Address.myfolder.uploadPhoto, 'POST', body, 'formdata');
}

export async function newMyFolderSubfolder(body: Object) {
  return callApi(Address.myfolder.newSubfolder, 'POST', body);
}

export async function deleteMyFolderAttachment(body: Object) {
  return callApi(Address.myfolder.deleteAttachment, 'POST', body);
}

export async function deleteMyFolderPhoto(body: Object) {
  return callApi(Address.myfolder.deletePhoto, 'POST', body);
}

export async function updateMyFolderAttachment(body: Object) {
  return callApi(Address.myfolder.updateAttachment, 'POST', body);
}
/** ************************************************* Customer ***************************************************/
/**
 * 查询owner
 * @param body
 * @returns
 */

export async function getOwnerListing(body: Object) {
  return callApi(Address.owner.getOwnerListing, 'POST', body);
}
/**
 * 获取 owner 下面的用户,用来显示在通讯录中
 * @param body
 * @returns
 */
export async function getOwnerUserListing(body: Object) {
  return callApi(Address.owner.getOwnerUserListing, 'POST', body);
}
/**
 * 修改 owner
 * @param body
 * @returns
 */
export async function updateOwner(body: Object) {
  return callApi(Address.owner.updateOwner, 'POST', body);
}
/**
 * 创建 owner
 * @param body
 * @returns
 */
export async function newOwner(body: Object) {
  return callApi(Address.owner.newOwner, 'POST', body);
}
/**
 * 查询 owner
 * @param body
 * @returnsF
 */
export async function getOwnerDetails(body: Object) {
  return callApi(Address.owner.getOwnerDetails, 'POST', body);
}

/** ************************************************* Report ***************************************************/

// export async function reportDownloads(url:string, body: Object) {
//   try{
//     await callApi(url, 'GET', body)
//     const a =  await document.createElement('a');
//     a.href = url;
//     a.click();
//     return true
//   }catch (e){
//     return false
//   }
// }
export async function reportDownloads(body: any) {
  console.log('body', body);
}

/** ************************************************* financial ***************************************************/
export async function newFinancial(body: any) {
  return callApi(`${Address.financial.financial}save.php`, 'POST', body);
}
export async function exportFinancials(body: any) {
  return callApi(Address.financial.exportFinancials, 'POST', body);
}
export async function deleteFinancial(body: any) {
  return callApi(`${Address.financial.financial}delete.php`, 'GET', body);
}
export async function putFinancial(body: any) {
  return callApi(
    `${Address.financial.financial}update.php?id=${body.id}`,
    'POST',
    body
  );
}

export async function getFinancial(body: any) {
  const res: any = await callApi(
    `${Address.financial.financial}list.php`,
    'GET',
    body
  );
  if (Array.isArray(res?.data?.data)) {
    res.data.data.forEach((item: any) => {
      if (item?.financialAccount?.accountType === 'cre') {
        if (item?.paid === 0) {
          item.paid = 'uncredited';
        } else if (item?.paid === 1) {
          item.paid = 'credited';
        }
      } else {
        if (item?.paid === 0) {
          item.paid = 'unpaid';
        } else if (item?.paid === 1) {
          item.paid = 'paid';
        } else if (item?.paid === 2) {
          item.paid = 'pending';
        }
      }
    });
  }
  return res;
}
export async function getFinancialAccounts(body: any) {
  return callApi(Address.financial.accounts, 'GET', body);
}
export async function getFinancialCount(body: any) {
  return callApi(Address.financial.count, 'GET', body);
}

// 获取账单列表
export async function getInvoiceListJava(body: any) {
  return callApi(Address.financial.getInvoiceList, 'GET', body);
}

// 获取账单数据统计
export async function getInvoicesCount() {
  const res: any = await callApi(Address.financial.getInvoicesCount, 'GET', {});
  if (res?.result === 0) {
    const dataNum: any[] = Object.keys(res?.data)?.map((key) =>
      Number(res?.data[key])
    );
    const all = dataNum.reduce((a, b) => a + b, 0);
    return {
      ALL: all,
      ...res?.data
    };
  }
  return null;
}

// 保持账单列表
export async function setInvoiceJava(body: any) {
  return callApi(Address.financial.setInvoice, 'POST', body);
}

// 获取账单金额总数
export async function getInvoiceCountJava(body: any) {
  return callApi(Address.financial.getInvoiceCount, 'GET', body);
}

// 获取账单详情
export async function getInvoceInfoJava(body: any) {
  const res: any = await callApi(
    `${Address.financial.getInvocieInfo}${body?.invoiceId}`,
    'GET',
    {}
  );
  if (Array.isArray(res?.data?.financials)) {
    res.data.financials.forEach((item: any) => {
      if (Number(item?.paid) === 0) {
        item.paid = 'unpaid';
      } else if (Number(item?.paid) === 1) {
        item.paid = 'paid';
      } else if (Number(item?.paid) === 2) {
        item.paid = 'pending';
      }
    });
  }
  return res;
}

// 修改账单信息putInvoiceInfo
export async function putInvoceInfoJava(body: any) {
  // body.type = '/draft-new' 是将draft状态的invoice切换为new状态
  const url =
    (body?.type && `${Address.financial.getInvocieInfo}state${body?.type}`) ||
    `${Address.financial.getInvocieInfo}${body?.invoiceId}/state`;
  return callApi(url, 'PUT', body.params);
}

// 修改draft状态的invoice信息
export async function putDraftInvoceInfoJava(body: any) {
  return callApi(
    `${Address.financial.getInvocieInfo}${body?.invoiceId}/draft`,
    'PUT',
    body.params
  );
}

// 删除账单信息delInvoiceInfo
export async function delInvoiceInfo(params: { invoiceIdIn: any[] }) {
  return callApi(
    `${Address.financial.getInvocieInfo}?invoiceIdIn=${params.invoiceIdIn}`,
    'DELETE',
    {}
  );
}

// 标记为已付款
export async function putMarkAsPaid(body: any) {
  return callApi(
    `${Address.financial.getInvocieInfo}${body?.invoiceId}/state`,
    'PUT',
    body.params
  );
}

// 获取账单列表
export async function getInvoiceHistoriesJava(params: any) {
  return callApi(Address.financial.getInvoiceHistories, 'GET', params);
}

// 获取xero绑定信息
export async function getXeroStatusJava(params: any) {
  return callApi(Address.financial.getXeroInfo, 'GET', params);
}

// 修改xero绑定信息
export async function putXeroStatusJava(params: any) {
  return callApi(Address.financial.getXeroInfo, 'PUT', params);
}
// 取消xero绑定
export async function delXeroStatusJava() {
  return callApi(Address.financial.getXeroInfo, 'DELETE', {});
}

// 模板列表查询
export async function getNotificationsTemplateListJava(body: any) {
  return callApi(
    Address.crmNotification.notificationTemplates,
    'GET',
    body.params
  );
}

// 模板详情查询
export async function getNotificationsTemplateInfoJava(body: any) {
  return callApi(
    `${Address.crmNotification.notificationTemplates}/${body.templateId}`,
    'GET',
    body.params
  );
}

// 获取变量列表
export async function getNotificationsvVariablesJava(body: any) {
  return callApi(
    Address.crmNotification.notificationVariables,
    'GET',
    body.params
  );
}

// 模板详情预览
export async function getNotificationsTemplatePreviewJava(body: {
  templateId: any;
  entityId: any;
  entity: string;
}) {
  return callApi(
    `${Address.crmNotification.notificationTemplates}/${body.templateId}/actions/preview`,
    'GET',
    {
      entity: body.entity,
      entityId: body.entityId
    }
  );
}
// 模板详情预览
export async function getNotificationsTemplatePreviewByTypeJava(body: {
  type: any;
  entityId: any;
}) {
  return callApi(
    `${Address.crmNotification.notificationTemplates}/template-type/${body.type}/actions/preview`,
    'GET',
    {
      entityId: body.entityId
    }
  );
}
// 模板详情预览
export async function sendEmailByTypeAndEntityId(body: {
  type: any;
  entityId: any;
}) {
  return callApi(
    `${Address.crmNotification.notificationTemplates}/template-type/${body.type}/actions/send-email?entityId=${body.entityId}`,
    'POST',
    {}
  );
}

// 模板详情设置
export async function setNotificationsJava(body: any, method: string) {
  return callApi(
    `${Address.crmNotification.notificationTemplates}/${body.templateId}`,
    method,
    body.params
  );
}

// 获取发票序列号规则
export async function getInvoiceSequencesJava(body: any) {
  return callApi(Address.financial.getInvoiceSequences, 'GET', body);
}

// 修改发票序列号规则
export async function putInvoiceSequencesJava(body: any) {
  return callApi(
    `${Address.financial.putInvoiceSequences}${body.id}`,
    'PUT',
    body.params
  );
}

// 预览序列号
export async function postInvoicePreViewJava(params: any) {
  return callApi(Address.financial.postInvoicePreView, 'POST', params);
}

// 获取 tenancyNumber 规则
export async function getTenancySequencesJava() {
  return callApi(Address.tenancy.getTenancySequences, 'GET', {});
}

// 修改 tenancyNumber 规则
export async function putTenancySequencesJava(body: any) {
  return callApi(
    `${Address.tenancy.putTenancySequences}${body.id}`,
    'PUT',
    body.params
  );
}

// 预览 tenancyNumber
export async function postTenancyPreViewJava(params: any) {
  return callApi(Address.tenancy.postTenancyPreView, 'POST', params);
}

// 根据entity和entityId获取comment数据
export async function getCommentsForEntityJava(params: any) {
  return callApi(
    `${Address.customFields.getComments}/entity/${params.entity}/id/${params.entityId}`,
    'Get',
    {}
  );
}

// 根据entity和entityId新增comment数据
export async function addCommentForEntityJava(params: {
  comment: string;
  entity: string;
  entityId: number;
}) {
  return callApi(Address.customFields.newComments, 'POST', params);
}

// 根据id删除comments数据
export async function deleteCommentForIdJava(params: any) {
  return callApi(
    `${Address.customFields.deleteComments}/${params.id}`,
    'DELETE',
    {}
  );
}

// 查询InvoiceAutomation
export async function getInvoiceAutomationJava(params: { tenancyId: string }) {
  return callApi(
    `${Address.tenancy.getInvoiceAutomation}${params.tenancyId}`,
    'GET',
    {}
  );
}

// 修改InvoiceAutomation
export async function putInvoiceAutomationJava(params: {
  id: string;
  body: {
    status?: 0 | 1;
    invoiceDueDateType?: string;
    staticRentDueDate?: string;
    staticRentDueDateType?: string;
    specificSpeDay?: number;
    generateTimeType?: string;
    generateTimeDistanceDay?: number;
  };
}) {
  return callApi(
    `${Address.tenancy.putInvoiceAutomation}${params.id}`,
    'PUT',
    params.body
  );
}

// 获取properties数据
export async function getPropertiesList(params: any) {
  const res: any = await callApi(
    Address.properties.getPropertiesList,
    'GET',
    params
  );

  if (res?.result === 0) {
    let arr = res.data.data.map((element: any) => ({
      ...element,
      id: nanoid()
    }));
    // 在Search查询的结果下 不显示折叠Icon
    if (params?.propertyName || params?.keyword) {
      arr = arr.map((i: any) => ({
        ...i,
        childCount: 0
      }));
    }
    return {
      ...res,
      data: {
        ...res.data,
        data: arr
      }
    };
  } else {
    return res;
  }
}

// 新增properties数据
export async function addNewProperties(params: any) {
  return callApi(Address.properties.addNewProperties, 'POST', params);
}

// 删除Property信息
export async function deleteNewProperties(params: { propertyId: string }) {
  return callApi(
    `${Address.properties.deleteProperties}${params.propertyId}`,
    'DELETE',
    {}
  );
}

// 获取Property预定信息
export async function getPropertyBookingJava(params: {
  entity: string;
  entityId: string;
}) {
  return callApi(`${Address.properties.getPropertyBooking}`, 'GET', params);
}

// 获取Property type
export async function getPropertyTypeJava() {
  return callApi(`${Address.properties.getPropertiesType}`, 'GET', {});
}

// 新增Property type
export async function addPropertyTypeJava(body: any) {
  console.log('这是个什么', body);
  return callApi(`${Address.properties.getPropertiesType}`, 'POST', body);
}

// 修改Property预定信息
export async function putPropertyBookingJava(params: {
  bookingId: string;
  body: any;
}) {
  return callApi(
    `${Address.properties.getPropertyBooking}${params.bookingId}`,
    'PUT',
    params.body
  );
}
// 批量修改Property预定信息
export async function putPropertyBookingMoreJava(body: any) {
  return callApi(
    `${Address.properties.getPropertyBooking}entity/${body.entity}/active/${body.status}`,
    'PUT',
    body.params
  );
}

// 新增Property预定信息
export async function addPropertyBookingJava(params: any) {
  return callApi(`${Address.properties.addPropertyBooking}`, 'POST', params);
}

// 获取已预约的记录
export async function getBookingRecordsJava(params: any) {
  return callApi(Address.properties.getBookingRecords, 'GET', params);
}

// 获取已预约的记录
export async function getBookingRecordsJavaInfo(id: any) {
  return callApi(Address.properties.getBookingRecords + `/${id}`, 'GET', {});
}

// 修改已预约的记录
export async function putBookingRecordsJavaInfo(params: any) {
  return callApi(
    Address.properties.getBookingRecords + `/${params?.id}`,
    'PUT',
    {
      visitorJsonFields: params?.visitorJsonFields,
      overtimeChargeJsonFields: params?.overtimeChargeJsonFields
    }
  );
}

// 修改client信息
export async function putBookingsClient(params: any) {
  return callApi(
    Address.properties.getBookingRecords + `/${params?.id}`,
    'PUT',
    params?.obj
  );
}

// 用Java根据entity和id获取二维码
export async function getQrcodeByEntityAndId(params: any) {
  return callApi(
    `${Address.qrcode.getQrcodeByEntityAndId}/entity/${params.entity}/id/${params.entityId}`,
    'GET',
    {}
  );
}

// 批量新增
export async function addMorePropertiesJava(body: any) {
  return callApi(Address.properties.addMoreProperties, 'POST', body);
}

// 获取property详情
export async function getPropertiesDetailJava(params: any) {
  return callApi(
    `${Address.properties.getPropertyDetail}${params.entityId}`,
    'GET',
    {}
  );
}

// 获取property  Asset-Items
export async function getAssetItemsJava(params: any) {
  return callApi(
    `${Address.properties.getPropertyDetail}${params.entityId}/asset-items`,
    'GET',
    {}
  );
}

// 获取租约类型
export async function getMemberShipsJava() {
  return callApi(Address.tenant.getMemberShips, 'GET', {});
}

// 同步Xero联系人
export async function postXeroSyncContactsJava() {
  return callApi(Address.financial.postXeroSyncContacts, 'POST', {});
}

// 修改propeies
export async function putPropertiesJava(params: any) {
  return callApi(
    `${Address.properties.putProperties}${params.entity}`,
    'PUT',
    params.body
  );
}
// 批量修改 properties 的状态
export async function putPropertiesMoreJava(body: any) {
  return callApi(
    `${Address.properties.putProperties}active/${body.status}`,
    'PUT',
    body.params
  );
}

// 获取getTenancyPayingParty列表
export async function getTenancyPayingPartyJava(params: {
  page: number;
  size: number;
  tenancyId: number | string;
}) {
  return callApi(Address.tenancy.getTenancyPayingParty, 'GET', params);
}

export async function setTenancyPayingPartyJava(
  type: 'POST' | 'PUT',
  params: any
) {
  const url =
    (type === 'PUT' &&
      `${Address.tenancy.getTenancyPayingParty}/${params.payindPartyId}`) ||
    `${Address.tenancy.getTenancyPayingParty}/`;
  return callApi(url, type, params);
}
export async function getTenancyNumberJava(params: any) {
  return callApi(`${Address.tenancy.getTenancyNumber}/${params}`, 'GET', {});
}

export async function getHealthByEntityId(params: any) {
  return callApi(`${Address.health.getHealthByEntityId}/${params}`, 'GET', {});
}

export async function getOperatorLogList(params: any) {
  return callApi(`${Address.operatorLog.getOperatorLogList}`, 'GET', params);
}

export async function exportFinancial(params: { idIn: string[] }) {
  return callApi(Address.financial.exportFinancialData, 'POST', params);
}
export async function getPropertyAccess_Java(body: any) {
  return callApi(`${Address.properties.getPropertyAccess}/${body}`, 'GET', {});
}

export async function updateAccess_Java(body: any) {
  return callApi(
    `${Address.properties.updateAccess}/${body?.entityId}`,
    'PUT',
    body
  );
}
// 登录成功后如果路径有 xeroTenantId 和 state 调用绑定到 xero
export async function putXeroState(obj: any) {
  return callApi(Address.financial.putXeroState, 'PUT', obj);
}

// 查询配置invoice issue date:
export async function getInvoiceIssueDate() {
  return callApi(Address.financial.getInvoiceIssueDate, 'GET', {
    code: 'invoice_issue_date'
  });
}

// 修改配置invoice issue date:
export async function putInvoiceIssueDate(params: any) {
  return callApi(
    `${Address.financial.getInvoiceIssueDate}?code=invoice_issue_date`,
    'PUT',
    {
      invoiceIssueDateKey: 'invoice_issue_date',
      invoiceIssueDateValue: params?.invoiceIssueDateValue
    }
  );
}

// 获取系统可选的假期
export async function getBookingsTemplates() {
  return callApi(Address.bookings.getTemplates, 'GET', {});
}

// 获取/编辑(切换)公司的假期模板激励
export async function getBookingsRecords({
  method = 'GET',
  params = {}
}: {
  method?: string;
  params?: any;
}) {
  return callApi(Address.bookings.getRecordsActions, method, params);
}

// 获取/新增 公司手动添加假期的记录
export async function getCompanyHolidays({
  method = 'GET',
  params = {}
}: {
  method?: string;
  params?: any;
}) {
  return callApi(Address.bookings.getCompanyHolidays, method, params);
}

// 删除/修改 公司手动添加假期的记录
export async function setCompanyHolidays({
  id,
  method = 'DELETE',
  params = {}
}: {
  id: string | number;
  method?: string;
  params?: any;
}) {
  return callApi(
    `${Address.bookings.getCompanyHolidays}/${id}`,
    method,
    params
  );
}

// 自动创建第一个月的Invoices
export async function createFirstMonthInvoice({ id }: { id: string | number }) {
  return callApi(
    `${Address.financial.firstMonthInvoice}/${id}/first-month`,
    'POST',
    {}
  );
}

export async function getAllHolidays() {
  return callApi(`${Address.bookings.getCompanyHolidays}/all`, 'GET', {});
}

// 查询是否绑定stripe
export async function getStripe_Java() {
  return callApi(Address.financial.getStripe, 'GET', {});
}

// 绑定stripec查询link-url
export async function getStripeConnect_Java(body: any) {
  return callApi(Address.financial.postStripeConnect, 'POST', body);
}

// 解绑
export async function unSetStripe_Java() {
  return callApi(Address.financial.getStripe, 'DELETE', {});
}

// 获取jobtype list
export async function getJobTypeList() {
  return callApi(Address.job.getjobtypeList, 'GET', {});
}

// 获取Category list
export async function getCategoryList() {
  return callApi(Address.customFields.getCategories, 'GET', {});
}

// 保存Category list
export async function saveCategoryList(params: any) {
  return callApi(Address.customFields.saveCategories, 'PUT', params);
}

export async function getAccount() {
  return callApi(Address.accounts.accounts, 'GET', {});
}

// 编辑公司信息
export async function updateAccount(body: any) {
  return callApi(Address.accounts.accounts, 'PUT', body);
}

export async function updateTemplateField(body: any) {
  return callApi(
    `${Address.properties.updateTemplateField}/entity/${body?.entity}/id/${body?.entityId}`,
    'PUT',
    body
  );
}

export async function getPropertiesLinkUrl(body: any) {
  return callApi(
    `${Address.properties.getPropertiesLinkUrl}?propertyIdIn=${body.propertyIdIn}&infoLinks=${body?.infoLinks}`,
    'GET',
    {}
  );
}

/**
 * 根据entity/entityId 获取booking 的记录
 * @param body
 * @returns
 */
export async function getBookingStatistics(body: any) {
  return callApi(
    `${Address.bookings.getBookingStatistics}/entity/${body.entity}/id/${body?.entityId}/actions/visitor`,
    'GET',
    body.params
  );
}

/**
 * 新建 booking job
 * @param body
 * @returnsF
 */
export async function newBookingJob(body: any) {
  return callApi(
    `${Address.bookings.bookingJob}/entity/${body.entity}/id/${body?.entityId}/actions/visitor`,
    'POST',
    body.body
  );
}

/**
 * 根据 邮箱获取验证码
 * @param body
 * @returns
 */
export async function generatorCodeByType(body: any) {
  return callApi(
    `${Address.bookings.generatorCodeByType}/entity/${body.entity}/id/${body?.entityId}/actions/visitor`,
    'POST',
    body.body
  );
}

/**
 * 新增booking的时候 如果选择stripe 获取支付页面的url
 * @param body
 * @returns
 */
export async function getPaymentUrl(body: any) {
  return callApi(
    `${Address.stripe.getPaymentUrl}/entity/${body.entity}/id/${body.entityId}/actions/visitor?paymentType=${body?.paymentType}`,
    'POST',
    {}
  );
}

export async function editBookingJob(body: any) {
  return callApi(
    `${Address.bookings.bookingJob}/${body?.id}`,
    'PUT',
    body?.obj
  );
}
// 获取member/non-member list
export async function getMemberList(body: any) {
  return callApi(`${Address.tenant.getMemberList}`, 'GET', body);
}

// 批量删除clients
export async function deleteClients(body: any) {
  return callApi(
    `${Address.tenant.tenants}?tenantIdIn=${body?.tenantIdIn}`,
    'DELETE',
    {}
  );
}

// 批量删除members
export async function deleteMembers(body: any) {
  return callApi(
    `${Address.tenant.tenantUsers_1}?idIn=${body?.idIn}`,
    'DELETE',
    {}
  );
}
// 获取member info 详情
export async function getMemberInfo(body: any) {
  return callApi(`${Address.tenant.getMemberList}/${body}`, 'GET', {});
}
export async function saveUserRoles(body: any) {
  return callApi(Address.accounts.saveUserRoles, 'POST', body);
}

// 获取 member list
export async function getMemberListByStatus(body: any) {
  return callApi(`${Address.accounts.accounts}/company/members`, 'GET', body);
}

// settings
export async function getGeneral(body: any) {
  console.log(body);
  return callApi(`${Address.settings.general}`, 'GET', {});
}

export async function getSpaceType(body: any) {
  console.log(body);
  return callApi(`${Address.settings.spacetype}`, 'GET', {});
}

export async function getCustomLabel(id: any) {
  return callApi(`${Address.settings.customlabel}/${id}`, 'GET', {});
}

export async function editGeneral(body: any) {
  return callApi(`${Address.settings.general}`, 'PUT', body);
}

export async function modifyDeSpace(body: any) {
  return callApi(
    `${Address.properties.getPropertiesType}/${body?.id}`,
    'PUT',
    body?.value
  );
}

export async function removeDeSpace(typeId: any) {
  return callApi(
    `${Address.properties.getPropertiesType}/${typeId}`,
    'DELETE',
    {}
  );
}
// user 改变 account 的接口
export async function putMemberAccount(body: any) {
  return callApi(
    `${Address.accounts.accounts}/company/members/${body.userId}`,
    'PUT',
    body
  );
}
export async function getUserInfo(body: any) {
  return callApi(
    `${Address.accounts.accounts}/company/members/${body}`,
    'GET',
    {}
  );
}

export async function addCustomother(body: any) {
  return callApi(`${Address.settings.customother}`, 'POST', body);
}

export async function modifyCustomother(obj: any) {
  return callApi(
    `${Address.settings.customother}/${obj?.id}`,
    'PUT',
    obj?.value
  );
}

export async function deleteCustomother(id: any) {
  return callApi(`${Address.settings.customother}/${id}`, 'DELETE', {});
}

export async function getCompayInfo() {
  return callApi(`${Address.settings.compayinfo}`, 'GET', {});
}

export async function putCompayInfo(body: any) {
  return callApi(`${Address.settings.compayinfo}`, 'PUT', body);
}

export async function getAdminList() {
  return callApi(`${Address.settings.getuserList}?status=1&role=PM`, 'GET', {});
}

export async function addUserMember(params: {
  firstName: string;
  lastName: string;
  email: string;
  phoneCC: string;
  phone: string;
  password: string;
}) {
  return callApi(Address.settings.addUser, 'POST', params);
}

export async function setSeleopen(body: any) {
  return callApi(`${Address.settings.general}`, 'PUT', body);
}

export async function getMyprofile(id: any) {
  return callApi(
    `${Address.settings.getuserList}/${id}?status=1&role=PM`,
    'GET',
    {}
  );
}
export async function editMyfile(body: any) {
  return callApi(`${Address.settings.editmyfile}`, 'POST', body);
}
// open account
export async function newOpenAccount(body: any) {
  return callApi(`${Address.settings.general}/non-members`, 'POST', body);
}
// client 模块 financial generatedBy 下拉框数据
export async function getGeneratedBy(body: any) {
  return callApi(`${Address.tenant.tenant}/generated-by/${body}`, 'GET', {});
}
// 删除公司 logo
export async function deleteCompanyLogo(body: any) {
  return callApi(`${Address.settings.general}/logo/${body}`, 'DELETE', {});
}
// 删除用户 logo
export async function deleteUserLogo(body: any) {
  return callApi(
    `${Address.accounts.accounts}/auth/user-profile/photo/${body.photoId}`,
    'DELETE',
    {}
  );
}
// Homebozz 注册(Java)
export async function signUp(body: any) {
  return callApi(`${Address.accounts.accounts}/signup-free`, 'POST', body);
}

// 登录(Java)
export async function authSignIn(body: any) {
  return callApi(`${Address.accounts.accounts}/auth/signin`, 'POST', body);
}

// 通过qrcode获取booking信息
export async function getBookingByCode(body: any) {
  return callApi(
    `${Address.qrcode.getQrcodeByEntityAndId}/${body?.code}`,
    'GET',
    {}
  );
}
// 保存临时注册记录接口 (发送邮件)
export async function authSignUp(body: any) {
  return callApi(
    `${Address.accounts.accounts}/auth/user-temp-auth`,
    'POST',
    body
  );
}
// 邮件链接点进去设置密码的接口
export async function authSetPassword(body: any) {
  return callApi(`${Address.accounts.accounts}/auth/signup-free`, 'POST', body);
}

// 重置密码
export async function authResetPassword(body: any) {
  return callApi(
    `${Address.accounts.accounts}/auth/reset-password`,
    'POST',
    body
  );
}
// 修改密码
export async function authChangePassword(body: any) {
  return callApi(
    `${Address.accounts.accounts}/auth/change-password`,
    'POST',
    body
  );
}

/**
 * 登出
 * @param body
 */
export async function logout(body: any) {
  return callApi(`${Address.accounts.accounts}/auth/logout`, 'POST', body);
}

/**
 * update myProfile
 * @param body
 */
export async function putProfile(body: any) {
  return callApi(`${Address.accounts.accounts}/auth/user-profile`, 'PUT', body);
}

/**
 * 发送邮箱验证码
 * @param body
 * @returns
 */
export async function sendVerificationCode(body: any) {
  return callApi(
    `${Address.accounts.accounts}/auth/verification-code`,
    'POST',
    body
  );
}

export async function getUserTempAuth(body: any) {
  return callApi(
    `${Address.accounts.accounts}/auth/user-temp-auth/${body}`,
    'GET',
    {}
  );
}

export async function putCompany(body: any) {
  return callApi(`${Address.accounts.accounts}/company`, 'PUT', body);
}

export async function getBookingRecordsInfo(body: any) {
  return callApi(`${Address.bookings.bookingJob}/actions/visitor`, 'GET', body);
}
export async function changeBookingCheck(body: any) {
  return callApi(
    `${Address.bookings.bookingJob}/${body?.bookingId}/checkin`,
    'POST',
    body
  );
}
export async function cancelBooking(body: any) {
  return callApi(`${Address.bookings.bookingJob}/${body}`, 'DELETE', {});
}

export async function getTenantMembersList(body: any) {
  return callApi(Address.tenant.getTenantMembers, 'GET', body);
}
export async function getTenantMembersInfo(body: any) {
  return callApi(`${Address.tenant.getTenantMembers}/${body}`, 'GET', {});
}
export async function putTenantMembers(body: any) {
  return callApi(
    `${Address.tenant.getTenantMembers}/${body.id}`,
    'PUT',
    body.data
  );
}
export async function deleteTenantMembers(body: any) {
  return callApi(
    `${Address.tenant.getTenantMembers}?idIn=${body?.idIn}`,
    'DELETE',
    {}
  );
}
export async function newTenantMembers(body: any) {
  return callApi(Address.tenant.getTenantMembers, 'POST', body);
}

export async function getFinancialType_Java(body: any) {
  return callApi(`${Address.financial.financialByJava}/accounts`, 'GET', body);
}
export async function getFinancial_Java(body: any) {
  return callApi(`${Address.financial.financialByJava}/`, 'GET', body);
}

export async function getDashboardData({ type }: { type: string }) {
  return callApi(`${Address.dashboard.getDashboardData}`, 'GET', {
    timeUnit: type
  });
}

export async function getCountryDistricts(body: any) {
  return callApi(`${Address.settings.countryDistricts}`, 'GET', body);
}


import { takeLatest, call, put } from 'redux-saga/effects';
import _ from 'lodash';

import * as request from '@/axios/request';
import { RESPONSE_TYPES } from '@/axios/axios';
import { Actions, allReducers } from '@/store/reducers';
import { RequestFullData } from '@/utils/fn';
export function* watchHomejobSaga() {
  yield takeLatest(Actions.GET_PROVIDER, getProviderList);
  yield takeLatest(Actions.GET_ALL_PROVIDER, getAllProviderList);
}

// 获取job的列表
function* getProviderList(props: any) {
  const res: RESPONSE_TYPES = yield call(
    request.getProviderListing,
    props.payload
  );
  if (res.result === 0) {
    const resList = _.get(res, 'data.data', []);

    const arr: any = resList.map((value: any) => {
      return {
        value: _.get(value, 'provider_id', ''),
        label: _.get(value, 'legal_name', '')
      };
    });
    yield put(allReducers._getProviderSuccess(arr));
  }
}

// 获取job的列表
function* getAllProviderList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allProviderList',
      value: true
    })
  );
  try {
    const { result } = yield call(
      RequestFullData,
      request.getProviderListing,
      1,
      props.payload,
      []
    );
    const arr: any = result.map((value: any) => {
      return {
        value: _.get(value, 'provider_id', ''),
        label: `${_.get(value, 'legal_name', '')}(${_.get(
          value,
          'contact.FirstName',
          ''
        )}${_.get(value, 'contact.LastName', '')})`
      };
    });
    if (arr.length) {
      yield put(allReducers._getAllProviderSuccess(arr));
    }
  } catch (error) {
    console.log('error', error);
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allProviderList',
      value: false
    })
  );
}

import { takeLatest, call, put } from 'redux-saga/effects';
import * as request from '@/axios/request';
import { RESPONSE_TYPES } from '@/axios/axios';
import { Actions, allReducers } from '@/store/reducers';
import toast from 'react-hot-toast';
import { formatString } from '@/utils/fn';

export function* watchSpaceSaga() {
  yield takeLatest(Actions.GETSPACE_INFO, getSpaceInfo);
  yield takeLatest(Actions.GETBOOKING_INFO, getBookingInfo);
  yield takeLatest(Actions.UPDATESPACE_INFO, updateSpaceInfo);
  yield takeLatest(Actions.UPDATEBOOKING_INFO, updateBookingInfo);
}

// 获取spaceinfo的列表
function* getSpaceInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(
    request.getPropertiesDetailJava,
    props.payload
  );
  console.log('寄来了', res);
  if (res?.result === 0 && res?.data) {
    yield put({
      type: 'GETSPACE_INFOSUCCESS',
      payload: res?.data
    });
  } else {
    yield put({
      type: 'GETSPACE_INFOSUCCESS',
      payload: null
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

// 获取booking的列表
function* getBookingInfo(props: any) {
  const res: RESPONSE_TYPES = yield call(
    request.getPropertyBookingJava,
    props.payload
  );
  console.log('获取booking', res);
  if (res?.result === 0 && res?.data) {
    yield put({
      type: 'GETBOOKING_INFOSUCCESS',
      payload: {
        ...res?.data,
        fromTime: res?.data?.shiftTimeIn[0].fromTime,
        toTime: res?.data?.shiftTimeIn[0].toTime,
        duration: res?.data?.shiftTimeIn[0].duration
      }
    });
  } else {
    yield put({
      type: 'GETBOOKING_INFOSUCCESS',
      payload: null
    });
  }
}

// 更新spaceinfo
function* updateSpaceInfo(props: any) {
  const { data, entityId, setLoading, getData, setEmptyError } = props.payload;
  const body: any = {
    phoneCC:
      (data?.phonecc?.includes('+') && data?.phonecc.split('+')[1]) ||
      data?.phonecc,
    ...data
  };

  const res: RESPONSE_TYPES = yield call(request.putPropertiesJava, {
    entity: entityId,
    body
  });

  if (res?.result === 0) {
    yield put(allReducers._openSapceModal(false));
    setLoading?.(false);
    if (getData) {
      getData?.();
    } else {
      yield put(
        allReducers._getSpaceInfo({
          entityId: entityId
        })
      );
      yield put(
        allReducers._getBookingInfo({
          entity: 'property',
          entityId: entityId
        })
      );
    }
    toast.success('Space information updated', {
      duration: 3000,
      position: 'bottom-left'
    });
  } else {
    setLoading?.(false);
    const arr: any = [];
    if (res?.data?.message === 'reference_id_already_exists') {
      arr.push({
        field: 'referenceId',
        value: formatString(res?.data?.message)
      });
    }
    if (res?.data?.message === 'property_name_already_exists') {
      arr.push({
        field: 'propertyName',
        value: 'Space name already exists'
      });
    }
    setEmptyError(arr);
  }
  console.log('更新space', res, body);
}

// 更新booking
function* updateBookingInfo(props: any) {
  const { data, entityId, setLoading, getData } = props.payload;

  let res: RESPONSE_TYPES;
  const body: any = {
    ...data,
    shiftTimeIn: [
      {
        fromTime: data?.fromTime,
        toTime: data?.toTime,
        duration: data?.duration
      }
    ]
  };

  if (body?.id) {
    const params = {
      bookingId: data?.id,
      body
    };
    console.log('这个是做修改，', params);
    res = yield call(request.putPropertyBookingJava, params);
  } else {
    if (!body?.actived) {
      setLoading(false);
      yield put(allReducers._openBookingModal(false));
      return;
    }
    console.log('这个是做兴增', body);
    res = yield call(request.addPropertyBookingJava, body);
  }
  if (res?.result === 0) {
    yield put(allReducers._openBookingModal(false));
    setLoading(false);
    if (getData) {
      getData?.();
    } else {
      yield put(
        allReducers._getBookingInfo({
          entity: 'property',
          entityId: entityId
        })
      );
    }
    toast.success('Space booking engine updated', {
      duration: 3000,
      position: 'bottom-left'
    });
  }
  console.log('更新booking', res, body);
}


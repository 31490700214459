import { JsonParse } from '@/utils/fn';
import { linkToLogin } from '@/utils/url';
import _ from 'lodash';
import { refreshToken } from '../axios/request';

export function saveToken(obj: any) {
  localStorage.setItem('token', JSON.stringify(obj));
}

export async function getToken() {
  const token = localStorage.getItem('token');
  const tokenobj: any = JsonParse(
    (token?.includes('access_token') && token) || '{}'
  );
  const now = Date.now();
  if (tokenobj?.expires_in && now <= Number(tokenobj?.expires_in)) {
    return tokenobj;
  }

  if (
    tokenobj?.refresh_expires_in &&
    now <= Number(tokenobj?.refresh_expires_in)
  ) {
    return refreshTokenFn();
  }
  linkToLogin('/login');
  return null;
}

export async function refreshTokenFn() {
  const token = localStorage.getItem('token');
  const tokenobj: any = JsonParse(
    (token?.includes('access_token') && token) || '{}'
  );
  if (tokenobj?.refresh_token) {
    const { data, result }: any = await refreshToken({
      token: tokenobj.refresh_token
    });
    if (result === 0 && _.get(data, 'data.access_token', '')) {
      const obj = _.get(data, 'data');
      saveToken(obj);
      return obj;
    } else {
      return linkToLogin('/login');
    }
  }
  linkToLogin('/login');
  return null;
}

/** console 测试Token过期
function change(date) {
    return date.getFullYear() +'-'+ date.getMonth() +'-'+ date.getDate() + ' '+date.getHours() +':'+ date.getMinutes() +':'+ date.getSeconds()
}
const token = localStorage.getItem('token');
const tokenobj = JSON.parse(
    (token?.includes('access_token') && token) || '{}'
  );
const now = Date.now();
const status1 = now <= tokenobj?.expires_in;
const status2 = now <= tokenobj?.refresh_expires_in;
console.log('------ tokten ------','\n',
'当前时间:   ',change(new Date(now)),'\n',
'过期时间:   ',change(new Date(tokenobj?.expires_in)),'\n',
'刷新时间:   ',change(new Date(tokenobj?.refresh_expires_in)),'\n',
'token状态: ',!status1 && "已过期" || "未过期",'\n')
 */


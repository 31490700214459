/*
 * @Date: 2020-06-16 14:25:36
 * @LastEditTime: 2021-06-21 16:17:21
 */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '@/store/reducers';
import rootSaga from '@/store/sagas';
const sagaMiddleware = createSagaMiddleware();

// 配置store信息
export default function configureStore(initialState = {}) {
  const middlewares = [sagaMiddleware];

  const enhancers: any = [applyMiddleware(...middlewares)];

  const fun: any = compose;

  // 将reducer组合起来
  // const reducer = combineReducers(reducers);
  const store = createStore(rootReducer, initialState, fun(...enhancers));
  sagaMiddleware.run(rootSaga);
  // 创建store
  // store.runSaga = sagaMiddleware.run;

  return store;
}


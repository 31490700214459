/* eslint-disable no-control-regex */
/* eslint-disable radix */
/* eslint-disable no-var */
import Intl from 'react-intl-universal';
import _ from 'lodash';
import moment from 'moment';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { t } from '@/i18n';
import * as XLSX from 'xlsx';

export function formatCurrency(value: number | string, symbol?: any): string {
  let numericValue: number;

  // 如果输入是字符串，则尝试将其转换为数字
  if (typeof value === 'string') {
    numericValue = parseFloat(value);
  } else {
    numericValue = value;
  }

  // 确保数字有效
  if (isNaN(numericValue)) {
    throw new Error(
      'Invalid input. Please provide a valid number or string representation of a number.'
    );
  }

  // 使用 toLocaleString() 方法将数字格式化为货币字符串，并添加千位分隔符
  const currencyString = numericValue.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return `${(symbol && symbol + ' ') || ''}${currencyString}`;
}

// 判断当前分享页
export const getSource = () => {
  const path = document.referrer;
  if (['assetbozz', 'localhost'].find((i) => path.includes(i))) {
    return 'website';
  }
  if (['google'].find((i) => path.includes(i))) {
    return 'google';
  }
  if (['facebook', 'fb'].find((i) => path.includes(i))) {
    return 'facebook';
  }
  if (['linkedin'].find((i) => path.includes(i))) {
    return 'linkedin';
  }
  if (['market-place'].find((i) => path.includes(i))) {
    return 'market-place';
  }
  if (['flow'].find((i) => path.includes(i))) {
    return 'flow';
  }
  return 'others';
};

// 判断是否为小尺寸屏幕(移动端)
export const isMobile = () => {
  return (
    window.matchMedia('only screen and (max-width: 750px)').matches ||
    /Mobi|Android|iPhone/i.test(navigator.userAgent)
  );
};

// 获取字符串长度
export const getBLen = (str: string) => {
  if (str === null) return 0;
  if (typeof str !== 'string') {
    str = String(str);
  }
  return str.replace(/[^\x00-\xff]/g, '0000').length;
};

export interface StateOption {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

// 计算时间差
export const getDateDiff = (start: any, end = new Date()) => {
  const d1 = new Date(start);
  const d2 = new Date(end);
  const d3 = d2.getTime() - d1.getTime();
  if (d3 < 0) {
    return { days: '', hours: '', minutes: '', seconds: '' };
  }

  // 计算出相差天数
  const days = Math.floor(d3 / (24 * 3600 * 1000));
  // 计算出小时数
  const leave1 = d3 % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000));
  // 计算相差分钟数
  var leave2 = leave1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000));
  // 计算相差秒数
  var leave3 = leave2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000);

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

// 切换 全年/年/月/日 展示相关
export const getPriceMethod = (type: string) => {
  switch (type) {
    case 'year':
      return 'Ann';
    case 'month':
      return 'Mo';
    case 'date':
      return 'D';
    default:
      return 'L.s.';
  }
};

export const countryArr = () => {
  return [
    {
      id: 1,
      name: 'Afghanistan ',
      countryCode: 'AF',
      internationalDialing: '+93',
      currencyName: 'Afghanistan Afghani',
      currencyCode: 'AFN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 2,
      name: 'Albania ',
      countryCode: 'AL',
      internationalDialing: '+355',
      currencyName: 'Albanian Lek',
      currencyCode: 'ALL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 3,
      name: 'Algeria ',
      countryCode: 'DZ',
      internationalDialing: '+213',
      currencyName: 'Algerian Diner',
      currencyCode: 'DZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 4,
      name: 'American Samoa',
      countryCode: 'AS',
      internationalDialing: '+1-684',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 5,
      name: 'Andorra, Principality of ',
      countryCode: 'AD',
      internationalDialing: '+376',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 6,
      name: 'Angola',
      countryCode: 'AO',
      internationalDialing: '+244',
      currencyName: 'Angolan Kwanza',
      currencyCode: 'AOA',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 7,
      name: 'Anguilla ',
      countryCode: 'AI',
      internationalDialing: '+1-264',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 8,
      name: 'Antarctica',
      countryCode: 'AQ',
      internationalDialing: '+672',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 9,
      name: 'Antigua and Barbuda',
      countryCode: 'AG',
      internationalDialing: '+1-268',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 10,
      name: 'Argentina ',
      countryCode: 'AR',
      internationalDialing: '+54',
      currencyName: 'Argentine Peso ',
      currencyCode: 'ARS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 11,
      name: 'Armenia',
      countryCode: 'AM',
      internationalDialing: '+374',
      currencyName: 'Arrmenian Dram',
      currencyCode: 'AMD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 12,
      name: 'Aruba',
      countryCode: 'AW',
      internationalDialing: '+297',
      currencyName: 'Aruban Guilder',
      currencyCode: 'AWG',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 13,
      name: 'Australia',
      countryCode: 'AU',
      internationalDialing: '+61',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 14,
      name: 'Austria',
      countryCode: 'AT',
      internationalDialing: '+43',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 15,
      name: 'Azerbaijan or Azerbaidjan',
      countryCode: 'AZ',
      internationalDialing: '+994',
      currencyName: 'Azerbaijan New Manat',
      currencyCode: 'AZN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 16,
      name: 'Bahamas, Commonwealth of The',
      countryCode: 'BS',
      internationalDialing: '+1-242',
      currencyName: 'Bahamian',
      currencyCode: 'BSD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 17,
      name: 'Bahrain, Kingdom of',
      countryCode: 'BH',
      internationalDialing: '+973',
      currencyName: 'Bahraini Dinar',
      currencyCode: 'DHD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 18,
      name: 'Bangladesh',
      countryCode: 'BD',
      internationalDialing: '+880',
      currencyName: 'Bangladeshi Taka',
      currencyCode: 'BDT',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 19,
      name: 'Barbados ',
      countryCode: 'BB',
      internationalDialing: '+1-246',
      currencyName: 'Barbados Dollar',
      currencyCode: 'BBD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 20,
      name: 'Belarus',
      countryCode: 'BY',
      internationalDialing: '+375',
      currencyName: 'Belarussian Ruble ',
      currencyCode: 'BYR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 21,
      name: 'Belgium ',
      countryCode: 'BE',
      internationalDialing: '+32',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 22,
      name: 'Belize',
      countryCode: 'BZ',
      internationalDialing: '+501',
      currencyName: 'Belize Dollar',
      currencyCode: 'BZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 23,
      name: 'Benin',
      countryCode: 'BJ',
      internationalDialing: '+229',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 24,
      name: 'Bermuda ',
      countryCode: 'BM',
      internationalDialing: '+1-441',
      currencyName: 'Bermudian Dollar',
      currencyCode: 'BMD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 25,
      name: 'Bhutan, Kingdom of',
      countryCode: 'BT',
      internationalDialing: '+975',
      currencyName: 'Bhutan Ngultrum',
      currencyCode: 'BTN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 26,
      name: 'Bolivia ',
      countryCode: 'BO',
      internationalDialing: '+591',
      currencyName: 'Boliviano',
      currencyCode: 'BOB',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 27,
      name: 'Bosnia and Herzegovina ',
      countryCode: 'BA',
      internationalDialing: '+387',
      currencyName: 'Marka',
      currencyCode: 'BAM',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 28,
      name: 'Botswana',
      countryCode: 'BW',
      internationalDialing: '+267',
      currencyName: 'Botswana Pula',
      currencyCode: 'BWP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 29,
      name: 'Bouvet Island',
      countryCode: 'BV',
      internationalDialing: '+55',
      currencyName: 'Norwegian Krone',
      currencyCode: 'NOK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 30,
      name: 'Brazil ',
      countryCode: 'BR',
      internationalDialing: '+55',
      currencyName: 'Brazilian Real',
      currencyCode: 'BRL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 31,
      name: 'British Indian Ocean Territory',
      countryCode: 'IO',
      internationalDialing: '+246',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 32,
      name: 'Brunei ',
      countryCode: 'BN',
      internationalDialing: '+673',
      currencyName: 'Brunei Dollar',
      currencyCode: 'BND',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 33,
      name: 'Bulgaria ',
      countryCode: 'BG',
      internationalDialing: '+359',
      currencyName: 'Bulgarian Lev',
      currencyCode: 'BGN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 34,
      name: 'Burkina Faso',
      countryCode: 'BF',
      internationalDialing: '+226',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 35,
      name: 'Burundi',
      countryCode: 'BI',
      internationalDialing: '+257',
      currencyName: 'Burundi Franc',
      currencyCode: 'BIF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 36,
      name: 'Cambodia, Kingdom of',
      countryCode: 'KH',
      internationalDialing: '+855',
      currencyName: 'Kampuchean Riel',
      currencyCode: 'KHR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 37,
      name: 'Cameroon',
      countryCode: 'CM',
      internationalDialing: '+237',
      currencyName: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 38,
      name: 'Canada ',
      countryCode: 'CA',
      internationalDialing: '+1',
      currencyName: 'Canadian Dollar',
      currencyCode: 'CAD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 39,
      name: 'Cape Verde ',
      countryCode: 'CV',
      internationalDialing: '+238',
      currencyName: 'Cape Verde Escudo',
      currencyCode: 'CVE',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 40,
      name: 'Cayman Islands ',
      countryCode: 'KY',
      internationalDialing: '+1-345',
      currencyName: 'Cayman Islands Dollar',
      currencyCode: 'KYD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 41,
      name: 'Central African Republic ',
      countryCode: 'CF',
      internationalDialing: '+236',
      currencyName: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 42,
      name: 'Chad ',
      countryCode: 'TD',
      internationalDialing: '+235',
      currencyName: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 43,
      name: 'Chile ',
      countryCode: 'CL',
      internationalDialing: '+56',
      currencyName: 'Chilean Peso ',
      currencyCode: 'CLP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 44,
      name: 'China ',
      countryCode: 'CN',
      internationalDialing: '+86',
      currencyName: 'Yuan Renminbi',
      currencyCode: 'CNY',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 45,
      name: 'Christmas Island ',
      countryCode: 'CX',
      internationalDialing: '+53',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 46,
      name: 'Cocos Islands ',
      countryCode: 'CC',
      internationalDialing: '+61',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 47,
      name: 'Colombia ',
      countryCode: 'CO',
      internationalDialing: '+57',
      currencyName: 'Colombian Peso ',
      currencyCode: 'COP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 48,
      name: 'Comoros, Union of the ',
      countryCode: 'KM',
      internationalDialing: '+269',
      currencyName: 'Comoros Franc',
      currencyCode: 'KMF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 49,
      name: 'Congo, Democratic Republic of the',
      countryCode: 'CD',
      internationalDialing: '+243',
      currencyName: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 50,
      name: 'Congo, Republic of the',
      countryCode: 'CG',
      internationalDialing: '+242',
      currencyName: 'Francs',
      currencyCode: 'CDF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 51,
      name: 'Cook Islands',
      countryCode: 'CK',
      internationalDialing: '+682',
      currencyName: 'New Zealand Dollar',
      currencyCode: 'NZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 52,
      name: 'Costa Rica ',
      countryCode: 'CR',
      internationalDialing: '+506',
      currencyName: 'Costa Rican Colon',
      currencyCode: 'CRC',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 53,
      name: "Cote D'Ivoire",
      countryCode: 'CI',
      internationalDialing: '+225',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 54,
      name: 'Croatia',
      countryCode: 'HR',
      internationalDialing: '+385',
      currencyName: 'Croatian Kuna',
      currencyCode: 'HRK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 55,
      name: 'Cuba ',
      countryCode: 'CU',
      internationalDialing: '+53',
      currencyName: 'Cuban Peso',
      currencyCode: 'CUP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 56,
      name: 'Cyprus ',
      countryCode: 'CY',
      internationalDialing: '+357',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 57,
      name: 'Czech Republic',
      countryCode: 'CZ',
      internationalDialing: '+420',
      currencyName: 'Czech Koruna',
      currencyCode: 'CZK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 58,
      name: 'Denmark ',
      countryCode: 'DK',
      internationalDialing: '+45',
      currencyName: 'Danish Krone',
      currencyCode: 'DKK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 59,
      name: 'Djibouti',
      countryCode: 'DJ',
      internationalDialing: '+253',
      currencyName: 'Djibouti Franc',
      currencyCode: 'DJF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 60,
      name: 'Dominica ',
      countryCode: 'DM',
      internationalDialing: '+1-767',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 61,
      name: 'Dominican Republic ',
      countryCode: 'DO',
      internationalDialing: '+1-809',
      currencyName: 'Dominican Peso',
      currencyCode: 'DOP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 62,
      name: 'Ecuador ',
      countryCode: 'EC',
      internationalDialing: '+593 ',
      currencyName: 'Ecuador Sucre',
      currencyCode: 'ECS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 63,
      name: 'Egypt',
      countryCode: 'EG',
      internationalDialing: '+20',
      currencyName: 'Egyptian Pound',
      currencyCode: 'EGP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 64,
      name: 'El Salvador ',
      countryCode: 'SV',
      internationalDialing: '+503',
      currencyName: 'El Salvador Colon',
      currencyCode: 'SVC',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 65,
      name: 'Equatorial Guinea',
      countryCode: 'GQ',
      internationalDialing: '+240',
      currencyName: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 66,
      name: 'Eritrea',
      countryCode: 'ER',
      internationalDialing: '+291',
      currencyName: 'Eritrean Nakfa',
      currencyCode: 'ERN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 67,
      name: 'Estonia',
      countryCode: 'EE',
      internationalDialing: '+372',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 68,
      name: 'Ethiopia',
      countryCode: 'ET',
      internationalDialing: '+251',
      currencyName: 'Ethiopian Birr',
      currencyCode: 'ETB',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 69,
      name: 'Falkland Islands ',
      countryCode: 'FK',
      internationalDialing: '+500',
      currencyName: 'Falkland Islands Pound',
      currencyCode: 'FKP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 70,
      name: 'Faroe Islands ',
      countryCode: 'FO',
      internationalDialing: '+298',
      currencyName: 'Danish Krone',
      currencyCode: 'DKK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 71,
      name: 'Fiji ',
      countryCode: 'FJ',
      internationalDialing: '+679',
      currencyName: 'Fiji Dollar',
      currencyCode: 'FJD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 72,
      name: 'Finland ',
      countryCode: 'FI',
      internationalDialing: '+358',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 73,
      name: 'France ',
      countryCode: 'FR',
      internationalDialing: '+33',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 74,
      name: 'French Guiana or French Guyana ',
      countryCode: 'GF',
      internationalDialing: '+594',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 75,
      name: 'French Polynesia',
      countryCode: 'PF',
      internationalDialing: '+689',
      currencyName: 'CFP Franc',
      currencyCode: 'XPF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 76,
      name: 'French Southern Territories and Antarctic Lands ',
      countryCode: 'TF',
      internationalDialing: '+262',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 77,
      name: 'Gabon',
      countryCode: 'GA',
      internationalDialing: '+241',
      currencyName: 'CFA Franc BEAC',
      currencyCode: 'XAF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 78,
      name: 'Gambia, The ',
      countryCode: 'GM',
      internationalDialing: '+220',
      currencyName: 'Gambian Dalasi',
      currencyCode: 'GMD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 79,
      name: 'Georgia',
      countryCode: 'GE',
      internationalDialing: '+995',
      currencyName: 'Georgian Lari',
      currencyCode: 'GEL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 80,
      name: 'Germany ',
      countryCode: 'DE',
      internationalDialing: '+49',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 81,
      name: 'Ghana',
      countryCode: 'GH',
      internationalDialing: '+233',
      currencyName: 'Ghanaian Cedi',
      currencyCode: 'GHS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 82,
      name: 'Gibraltar ',
      countryCode: 'GI',
      internationalDialing: '+350',
      currencyName: 'Gibraltar Pound',
      currencyCode: 'GIP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 83,
      name: 'Greece ',
      countryCode: 'GR',
      internationalDialing: '+30',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 84,
      name: 'Greenland ',
      countryCode: 'GL',
      internationalDialing: '+299',
      currencyName: 'Danish Krone',
      currencyCode: 'DKK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 85,
      name: 'Grenada ',
      countryCode: 'GD',
      internationalDialing: '+1-473',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 86,
      name: 'Guadeloupe',
      countryCode: 'GP',
      internationalDialing: '+590',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 87,
      name: 'Guam',
      countryCode: 'GU',
      internationalDialing: '+1-671',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 88,
      name: 'Guatemala ',
      countryCode: 'GT',
      internationalDialing: '+502',
      currencyName: 'Guatemalan Quetzal',
      currencyCode: 'GTO',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 89,
      name: 'Guinea',
      countryCode: 'GN',
      internationalDialing: '+224',
      currencyName: 'Guinea Franc',
      currencyCode: 'GNF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 90,
      name: 'Guinea-Bissau',
      countryCode: 'GW',
      internationalDialing: '+245',
      currencyName: 'Guinea-Bissau Peso',
      currencyCode: 'GWP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 91,
      name: 'Guyana',
      countryCode: 'GY',
      internationalDialing: '+592',
      currencyName: 'Guyana Dollar',
      currencyCode: 'GYD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 92,
      name: 'Haiti ',
      countryCode: 'HT',
      internationalDialing: '+509',
      currencyName: 'Haitian Gourde',
      currencyCode: 'HTG',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 93,
      name: 'Heard Island and McDonald Islands',
      countryCode: 'HM',
      internationalDialing: '+61',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 94,
      name: 'Holy See',
      countryCode: 'VA',
      internationalDialing: '418',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 95,
      name: 'Honduras ',
      countryCode: 'HN',
      internationalDialing: '+504',
      currencyName: 'Honduran Lempira',
      currencyCode: 'HNL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 96,
      name: 'Hong Kong ',
      countryCode: 'HK',
      internationalDialing: '+852',
      currencyName: 'Hong Kong Dollar',
      currencyCode: 'HKD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 97,
      name: 'Hungary ',
      countryCode: 'HU',
      internationalDialing: '+36',
      currencyName: 'Hungarian Forint ',
      currencyCode: 'HUF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 98,
      name: 'Iceland ',
      countryCode: 'IS',
      internationalDialing: '+354',
      currencyName: 'Iceland Krona',
      currencyCode: 'ISK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 99,
      name: 'India ',
      countryCode: 'IN',
      internationalDialing: '+91',
      currencyName: 'Indian Rupee',
      currencyCode: 'INR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 100,
      name: 'Indonesia',
      countryCode: 'ID',
      internationalDialing: '+62',
      currencyName: 'Indonesian Rupiah',
      currencyCode: 'IDR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 101,
      name: 'Iran, Islamic Republic of',
      countryCode: 'IR',
      internationalDialing: '+98',
      currencyName: 'Iranian Rial',
      currencyCode: 'IRR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 102,
      name: 'Iraq ',
      countryCode: 'IQ',
      internationalDialing: '+964',
      currencyName: 'Iraqi Dinar',
      currencyCode: 'IQD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 103,
      name: 'Ireland ',
      countryCode: 'IE',
      internationalDialing: '+353',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 104,
      name: 'Israel ',
      countryCode: 'IL',
      internationalDialing: '+972',
      currencyName: 'Israeli New Shekel',
      currencyCode: 'ILS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 105,
      name: 'Italy ',
      countryCode: 'IT',
      internationalDialing: '+39',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 106,
      name: 'Jamaica ',
      countryCode: 'JM',
      internationalDialing: '+1-876',
      currencyName: 'Jamaican Dollar',
      currencyCode: 'JMD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 107,
      name: 'Japan ',
      countryCode: 'JP',
      internationalDialing: '+81',
      currencyName: 'Japanese Yen',
      currencyCode: 'JPY',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 108,
      name: 'Jordan',
      countryCode: 'JO',
      internationalDialing: '+962',
      currencyName: 'Jordanian Dinar',
      currencyCode: 'JOD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 109,
      name: 'Kazakstan or Kazakhstan',
      countryCode: 'KZ',
      internationalDialing: '+7',
      currencyName: 'Kazakhstan Tenge',
      currencyCode: 'KZT',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 110,
      name: 'Kenya',
      countryCode: 'KE',
      internationalDialing: '+254',
      currencyName: 'Kenyan Shilling',
      currencyCode: 'KES',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 111,
      name: 'Kiribati',
      countryCode: 'KI',
      internationalDialing: '+686',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 112,
      name: "Korea, Democratic People's Republic of",
      countryCode: 'KP',
      internationalDialing: '+850',
      currencyName: 'North Korean Won',
      currencyCode: 'KRW',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 113,
      name: 'Korea, Republic of',
      countryCode: 'KR',
      internationalDialing: '+82',
      currencyName: 'Korean Won',
      currencyCode: 'KRW',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 114,
      name: 'Kuwait ',
      countryCode: 'KW',
      internationalDialing: '+965',
      currencyName: 'Kuwaiti Dinar',
      currencyCode: 'KWD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 115,
      name: 'Kyrgyzstan',
      countryCode: 'KG',
      internationalDialing: '+996',
      currencyName: 'Som',
      currencyCode: 'KGS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 116,
      name: "Lao People's Democratic Republic",
      countryCode: 'LA',
      internationalDialing: '+856',
      currencyName: 'Lao Kip',
      currencyCode: 'LAK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 117,
      name: 'Latvia',
      countryCode: 'LV',
      internationalDialing: '+371',
      currencyName: 'Latvian Lats',
      currencyCode: 'LVL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 118,
      name: 'Lebanon ',
      countryCode: 'LB',
      internationalDialing: '+961',
      currencyName: 'Lebanese Pound',
      currencyCode: 'LBP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 119,
      name: 'Lesotho',
      countryCode: 'LS',
      internationalDialing: '+266',
      currencyName: 'Lesotho Loti',
      currencyCode: 'LSL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 120,
      name: 'Liberia ',
      countryCode: 'LR',
      internationalDialing: '+231',
      currencyName: 'Liberian Dollar',
      currencyCode: 'LRD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 121,
      name: 'Libya',
      countryCode: 'LY',
      internationalDialing: '+218',
      currencyName: 'Libyan Dinar',
      currencyCode: 'LYD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 122,
      name: 'Liechtenstein ',
      countryCode: 'LI',
      internationalDialing: '+423',
      currencyName: 'Swiss Franc',
      currencyCode: 'CHF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 123,
      name: 'Lithuania',
      countryCode: 'LT',
      internationalDialing: '+370',
      currencyName: 'Lithuanian Litas',
      currencyCode: 'LTL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 124,
      name: 'Luxembourg ',
      countryCode: 'LU',
      internationalDialing: '+352',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 125,
      name: 'Macau ',
      countryCode: 'MO',
      internationalDialing: '+853',
      currencyName: 'Macau Pataca',
      currencyCode: 'MOP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 126,
      name: 'Madagascar',
      countryCode: 'MG',
      internationalDialing: '+261',
      currencyName: 'Malagasy Franc ',
      currencyCode: 'MGF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 127,
      name: 'Malawi',
      countryCode: 'MW',
      internationalDialing: '+265',
      currencyName: 'Malawi Kwacha',
      currencyCode: 'MWK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 128,
      name: 'Malaysia ',
      countryCode: 'MY',
      internationalDialing: '+60',
      currencyName: 'Malaysian Ringgit ',
      currencyCode: 'MYR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 129,
      name: 'Maldives ',
      countryCode: 'MV',
      internationalDialing: '+960',
      currencyName: 'Maldive Rufiyaa',
      currencyCode: 'MVR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 130,
      name: 'Mali',
      countryCode: 'ML',
      internationalDialing: '+223',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 131,
      name: 'Malta ',
      countryCode: 'MT',
      internationalDialing: '+356',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 132,
      name: 'Marshall Islands',
      countryCode: 'MH',
      internationalDialing: '+692',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 133,
      name: 'Martinique',
      countryCode: 'MQ',
      internationalDialing: '+596',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 134,
      name: 'Mauritania ',
      countryCode: 'MR',
      internationalDialing: '+222',
      currencyName: 'Mauritanian Ouguiya',
      currencyCode: 'MRO',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 135,
      name: 'Mauritius ',
      countryCode: 'MU',
      internationalDialing: '+230',
      currencyName: 'Mauritius Rupee',
      currencyCode: 'MUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 136,
      name: 'Mayotte',
      countryCode: 'YT',
      internationalDialing: '+269',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 137,
      name: 'Mexico ',
      countryCode: 'MX',
      internationalDialing: '+52',
      currencyName: 'Mexican Nuevo Peso',
      currencyCode: 'MXN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 138,
      name: 'Micronesia, Federated States of',
      countryCode: 'FM',
      internationalDialing: '+691',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 139,
      name: 'Moldova, Republic of',
      countryCode: 'MD',
      internationalDialing: '+373',
      currencyName: 'Moldovan Leu',
      currencyCode: 'MDL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 140,
      name: 'Monaco, Principality of',
      countryCode: 'MC',
      internationalDialing: '+377',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 141,
      name: 'Mongolia',
      countryCode: 'MN',
      internationalDialing: '+976',
      currencyName: 'Mongolian Tugrik ',
      currencyCode: 'MNT',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 142,
      name: 'Montserrat ',
      countryCode: 'MS',
      internationalDialing: '+1-664',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 143,
      name: 'Morocco ',
      countryCode: 'MA',
      internationalDialing: '+212',
      currencyName: 'Moroccan Dirham',
      currencyCode: 'MAD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 144,
      name: 'Mozambique',
      countryCode: 'MZ',
      internationalDialing: '+258',
      currencyName: 'Mozambique Metical',
      currencyCode: 'MZN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 145,
      name: 'Myanmar, Union of',
      countryCode: 'MM',
      internationalDialing: '+95',
      currencyName: 'Myanmar Kyat',
      currencyCode: 'MMK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 146,
      name: 'Namibia',
      countryCode: 'NA',
      internationalDialing: '+264',
      currencyName: 'Namibian Dollar',
      currencyCode: 'NAD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 147,
      name: 'Nauru',
      countryCode: 'NR',
      internationalDialing: '+674',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 148,
      name: 'Nepal ',
      countryCode: 'NP',
      internationalDialing: '+977',
      currencyName: 'Nepalese Rupee',
      currencyCode: 'NPR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 149,
      name: 'Netherlands ',
      countryCode: 'NL',
      internationalDialing: '+31',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 150,
      name: 'New Caledonia ',
      countryCode: 'NC',
      internationalDialing: '+687',
      currencyName: 'CFP Franc',
      currencyCode: 'XPF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 151,
      name: 'New Zealand',
      countryCode: 'NZ',
      internationalDialing: '+64',
      currencyName: 'New Zealand Dollar',
      currencyCode: 'NZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 152,
      name: 'Nicaragua ',
      countryCode: 'NI',
      internationalDialing: '+505',
      currencyName: 'Nicaraguan Cordoba Oro',
      currencyCode: 'NIO',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 153,
      name: 'Niger ',
      countryCode: 'NE',
      internationalDialing: '+227',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 154,
      name: 'Nigeria ',
      countryCode: 'NG',
      internationalDialing: '+234',
      currencyName: 'Nigerian Naira',
      currencyCode: 'NGN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 155,
      name: 'Niue',
      countryCode: 'NU',
      internationalDialing: '+683',
      currencyName: 'New Zealand Dollar',
      currencyCode: 'NZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 156,
      name: 'Norfolk Island ',
      countryCode: 'NF',
      internationalDialing: '+672',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 157,
      name: 'Northern Mariana Islands',
      countryCode: 'MP',
      internationalDialing: '+1-670',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 158,
      name: 'Norway ',
      countryCode: 'NO',
      internationalDialing: '+47',
      currencyName: 'Norwegian Krone',
      currencyCode: 'NOK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 159,
      name: 'Oman, Sultanate of',
      countryCode: 'OM',
      internationalDialing: '+968',
      currencyName: 'Omani Rial ',
      currencyCode: 'OMR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 160,
      name: 'Pakistan',
      countryCode: 'PK',
      internationalDialing: '+92',
      currencyName: 'Pakistan Rupee',
      currencyCode: 'PKR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 161,
      name: 'Palau',
      countryCode: 'PW',
      internationalDialing: '+680',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 162,
      name: 'Panama ',
      countryCode: 'PA',
      internationalDialing: '+507',
      currencyName: 'Panamanian Balboa',
      currencyCode: 'PAB',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 163,
      name: 'Papua New Guinea',
      countryCode: 'PG',
      internationalDialing: '+675',
      currencyName: 'Papua New Guinea Kina',
      currencyCode: 'PGK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 164,
      name: 'Paraguay ',
      countryCode: 'PY',
      internationalDialing: '+595',
      currencyName: 'Paraguay Guarani ',
      currencyCode: 'PYG',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 165,
      name: 'Peru ',
      countryCode: 'PE',
      internationalDialing: '+51',
      currencyName: 'Peruvian Nuevo Sol',
      currencyCode: 'PEN',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 166,
      name: 'Philippines ',
      countryCode: 'PH',
      internationalDialing: '+63',
      currencyName: 'Philippine Peso ',
      currencyCode: 'PHP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 167,
      name: 'Pitcairn Island',
      countryCode: 'PN',
      currencyName: 'New Zealand Dollar',
      currencyCode: 'NZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 168,
      name: 'Poland ',
      countryCode: 'PL',
      internationalDialing: '+48',
      currencyName: 'Polish Zloty ',
      currencyCode: 'PLN ',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 169,
      name: 'Portugal ',
      countryCode: 'PT',
      internationalDialing: '+351',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 170,
      name: 'Puerto Rico ',
      countryCode: 'PR',
      internationalDialing: '+1-787',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 171,
      name: 'Qatar, State of ',
      countryCode: 'QA',
      internationalDialing: '+974 ',
      currencyName: 'Qatari Rial',
      currencyCode: 'QAR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 172,
      name: 'Reunion',
      countryCode: 'RE',
      internationalDialing: '+262',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 173,
      name: 'Romania ',
      countryCode: 'RO',
      internationalDialing: '+40',
      currencyName: 'Romanian New Leu',
      currencyCode: 'Ron ',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 175,
      name: 'Russian Federation ',
      countryCode: 'RU',
      internationalDialing: '+7',
      currencyName: 'Russian Ruble',
      currencyCode: 'RUB',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 176,
      name: 'Rwanda',
      countryCode: 'RW',
      internationalDialing: '+250',
      currencyName: 'Rwanda Franc',
      currencyCode: 'RWF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 177,
      name: 'Saint Helena ',
      countryCode: 'SH',
      internationalDialing: '+290',
      currencyName: 'St. Helena Pound ',
      currencyCode: 'SHP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 178,
      name: 'Saint Kitts and Nevis',
      countryCode: 'KN',
      internationalDialing: '+1-869',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 179,
      name: 'Saint Lucia ',
      countryCode: 'LC',
      internationalDialing: '+1-758',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 180,
      name: 'Saint Pierre and Miquelon ',
      countryCode: 'PM',
      internationalDialing: '+508',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 181,
      name: 'Saint Vincent and the Grenadines ',
      countryCode: 'VC',
      internationalDialing: '+1-784',
      currencyName: 'East Caribbean Dollar',
      currencyCode: 'XCD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 182,
      name: 'Samoa',
      countryCode: 'WS',
      internationalDialing: '+685',
      currencyName: 'Samoan Tala',
      currencyCode: 'WST',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 183,
      name: 'San Marino ',
      countryCode: 'SM',
      internationalDialing: '+378',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 184,
      name: 'Sao Tome and Principe ',
      countryCode: 'ST',
      internationalDialing: '+239',
      currencyName: 'Dobra',
      currencyCode: 'STD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 185,
      name: 'Saudi Arabia ',
      countryCode: 'SA',
      internationalDialing: '+966',
      currencyName: 'Saudi Riyal ',
      currencyCode: 'SAR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 186,
      name: 'Serbia, Republic of',
      countryCode: 'RS',
      internationalDialing: '+381',
      currencyName: 'Dinar',
      currencyCode: 'RSD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 187,
      name: 'Senegal ',
      countryCode: 'SN',
      internationalDialing: '+221',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 188,
      name: 'Seychelles ',
      countryCode: 'SC',
      internationalDialing: '+248',
      currencyName: 'Seychelles Rupee',
      currencyCode: 'SCR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 189,
      name: 'Sierra Leone ',
      countryCode: 'SL',
      internationalDialing: '+232',
      currencyName: 'Sierra Leone Leone ',
      currencyCode: 'SLL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 190,
      name: 'Singapore ',
      countryCode: 'SG',
      internationalDialing: '+65',
      currencyName: 'Singapore Dollar',
      currencyCode: 'SGD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 191,
      name: 'Slovakia',
      countryCode: 'SK',
      internationalDialing: '+421',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 192,
      name: 'Slovenia ',
      countryCode: 'SI',
      internationalDialing: '+386',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 193,
      name: 'Solomon Islands',
      countryCode: 'SB',
      internationalDialing: '+677',
      currencyName: 'Solomon Islands Dollar',
      currencyCode: 'SBD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 194,
      name: 'Somalia ',
      countryCode: 'SO',
      internationalDialing: '+252',
      currencyName: 'Somali Shilling',
      currencyCode: 'SOS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 195,
      name: 'South Africa',
      countryCode: 'ZA',
      internationalDialing: '+27',
      currencyName: 'South African Rand',
      currencyCode: 'ZAR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 196,
      name: 'South Georgia and the South Sandwich Islands',
      countryCode: 'GS',
      internationalDialing: '+500',
      currencyName: 'Pound Sterling ',
      currencyCode: 'GBP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 197,
      name: 'Spain ',
      countryCode: 'ES',
      internationalDialing: '+34',
      currencyName: 'Euro',
      currencyCode: 'EUR',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 198,
      name: 'Sri Lanka ',
      countryCode: 'LK',
      internationalDialing: '+94',
      currencyName: 'Sri Lanka Rupee',
      currencyCode: 'LKR ',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 199,
      name: 'Sudan ',
      countryCode: 'SD',
      internationalDialing: '+249',
      currencyName: 'Sudanese Pound ',
      currencyCode: 'SDG',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 200,
      name: 'Suriname',
      countryCode: 'SR',
      internationalDialing: '+597',
      currencyName: 'Surinam Dollar',
      currencyCode: 'SRD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 201,
      name: 'Svalbard and Jan Mayen Islands ',
      countryCode: 'SJ',
      internationalDialing: '+47',
      currencyName: 'Norwegian Krone',
      currencyCode: 'NOK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 202,
      name: 'Swaziland, Kingdom of ',
      countryCode: 'SZ',
      internationalDialing: '+268',
      currencyName: 'Lilangeni',
      currencyCode: 'SZL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 203,
      name: 'Sweden ',
      countryCode: 'SE',
      internationalDialing: '+46',
      currencyName: 'Swedish Krona',
      currencyCode: 'SEK',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 204,
      name: 'Switzerland ',
      countryCode: 'CH',
      internationalDialing: '+41',
      currencyName: 'Swiss Franc',
      currencyCode: 'CHF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 205,
      name: 'Syria',
      countryCode: 'SY',
      internationalDialing: '+963',
      currencyName: 'Syrian Pound ',
      currencyCode: 'SYP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 206,
      name: 'Taiwan',
      countryCode: 'TW',
      internationalDialing: '+886',
      currencyName: 'New Taiwan Dollar',
      currencyCode: 'TWD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 207,
      name: 'Tajikistan',
      countryCode: 'TJ',
      internationalDialing: '+992',
      currencyName: 'Somoni',
      currencyCode: 'TJS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 208,
      name: 'Tanzania, United Republic of',
      countryCode: 'TZ',
      internationalDialing: '+255',
      currencyName: 'Tanzanian Shilling',
      currencyCode: 'TZS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 209,
      name: 'Thailand',
      countryCode: 'TH',
      internationalDialing: '+66',
      currencyName: 'Baht',
      currencyCode: 'THB',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 210,
      name: 'Togo',
      countryCode: 'TG',
      internationalDialing: '+228',
      currencyName: 'CFA Franc BCEAO',
      currencyCode: 'XOF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 211,
      name: 'Tokelau ',
      countryCode: 'TK',
      internationalDialing: '+690',
      currencyName: 'New Zealand Dollar',
      currencyCode: 'NZD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 212,
      name: 'Tonga, Kingdom of',
      countryCode: 'TO',
      internationalDialing: '+676',
      currencyName: "Pa'anga",
      currencyCode: 'TOP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 213,
      name: 'Trinidad and Tobago ',
      countryCode: 'TT',
      internationalDialing: '+1-868',
      currencyName: 'Trinidad and Tobago Dollar',
      currencyCode: 'TTD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 214,
      name: 'Tunisia ',
      countryCode: 'TN',
      internationalDialing: '+216',
      currencyName: 'Tunisian Dinar',
      currencyCode: 'TND',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 215,
      name: 'Turkey ',
      countryCode: 'TR',
      internationalDialing: '+90',
      currencyName: 'Turkish Lira',
      currencyCode: 'TRY',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 216,
      name: 'Turkmenistan',
      countryCode: 'TM',
      internationalDialing: '+993',
      currencyName: 'Turkmenistan New Manat',
      currencyCode: 'TMT',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 217,
      name: 'Turks and Caicos Islands ',
      countryCode: 'TC',
      internationalDialing: '+1-649',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 218,
      name: 'Tuvalu',
      countryCode: 'TV',
      internationalDialing: '+688',
      currencyName: 'Australian Dollar',
      currencyCode: 'AUD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 219,
      name: 'Uganda, Republic of',
      countryCode: 'UG',
      internationalDialing: '+256',
      currencyName: 'Uganda Shilling',
      currencyCode: 'UGX',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 220,
      name: 'Ukraine',
      countryCode: 'UA',
      internationalDialing: '+380',
      currencyName: 'Hryvnia',
      currencyCode: 'UAH',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 221,
      name: 'United Arab Emirates',
      countryCode: 'AE',
      internationalDialing: '+971',
      currencyName: 'UAE Dirham',
      currencyCode: 'AED',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 222,
      name: 'United Kingdom',
      countryCode: 'GB',
      internationalDialing: '+44',
      currencyName: 'Pound Sterling ',
      currencyCode: 'GBP',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 223,
      name: 'United States ',
      countryCode: 'US',
      internationalDialing: '+1',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 224,
      name: 'United States Minor Outlying Islands ',
      countryCode: 'UM',
      internationalDialing: '+1',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 225,
      name: 'Uruguay, Oriental Republic of',
      countryCode: 'UY',
      internationalDialing: '+598',
      currencyName: 'Peso Uruguayo',
      currencyCode: 'UYU',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 226,
      name: 'Uzbekistan',
      countryCode: 'UZ',
      internationalDialing: '+998',
      currencyName: 'Uzbekistan Sum',
      currencyCode: 'UZS',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 227,
      name: 'Vanuatu',
      countryCode: 'VU',
      internationalDialing: '+678',
      currencyName: 'Vatu',
      currencyCode: 'VUV',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 228,
      name: 'Venezuela ',
      countryCode: 'VE',
      internationalDialing: '+58',
      currencyName: 'Bolivar',
      currencyCode: 'VED',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 229,
      name: 'Vietnam ',
      countryCode: 'VN',
      internationalDialing: '+84',
      currencyName: 'Dong ',
      currencyCode: 'VND',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 230,
      name: 'Virgin Islands, British ',
      countryCode: 'VI',
      internationalDialing: '+1-284',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 231,
      name: 'Virgin Islands, United States',
      countryCode: 'VQ',
      internationalDialing: '+1-340',
      currencyName: 'US Dollar',
      currencyCode: 'USD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 232,
      name: 'Wallis and Futuna Islands ',
      countryCode: 'WF',
      internationalDialing: '+681',
      currencyName: 'CFP Franc',
      currencyCode: 'XPF',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 233,
      name: 'Western Sahara',
      countryCode: 'EH',
      internationalDialing: '+212',
      currencyName: 'Moroccan Dirham',
      currencyCode: 'MAD',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 234,
      name: 'Yemen ',
      countryCode: 'YE',
      internationalDialing: '+967',
      currencyName: 'Yemeni Rial ',
      currencyCode: 'YER',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 235,
      name: 'Zambia, Republic of',
      countryCode: 'ZM',
      internationalDialing: '+260',
      currencyName: 'Zambian Kwacha ',
      currencyCode: 'ZMW',
      phoneRule: '/^\\+?\\d{6,20}$/'
    },
    {
      id: 236,
      name: 'Zimbabwe, Republic of',
      countryCode: 'ZW',
      internationalDialing: '+263',
      currencyName: 'Zimbabwe Dollar',
      currencyCode: 'ZWL',
      phoneRule: '/^\\+?\\d{6,20}$/'
    }
  ].filter((itm: any) => itm?.internationalDialing);
};

export const stateOptions: readonly StateOption[] =
  (countryArr()?.length &&
    countryArr().map((itm: any) => ({
      value: itm?.internationalDialing || '',
      label: `${itm?.internationalDialing} ${itm?.name}`,
      groupLabel: itm?.countryCode?.substr(0, 1)
    }))) ||
  [];

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly StateOption[];
}

export const groupedOptions: readonly GroupedOption[] = [
  {
    label: 'Asia',
    options: stateOptions
  }
];

/**
 * 获取分页api 所有分页加起来的总数据
 * @param fn
 * @param page
 * @param param
 * @param arr
 * @returns
 */
export const RequestFullData: any = async (
  fn: Function,
  page: number,
  param: any,
  arr: any[]
) => {
  const sizeMax = _.get(param, 'size', 100);
  const resList: any = await fn({ ...param, page, size: sizeMax });
  if (
    _.get(resList, 'result') === 0 &&
    page * sizeMax < _.get(resList, 'data.total', 0)
  ) {
    return RequestFullData(fn, page + 1, param, [
      ...arr,
      ..._.get(resList, 'data.data', [])
    ]);
  }
  return { result: [...arr, ..._.get(resList, 'data.data', [])] };
};

/**
 * 获取国际化html
 * @param str
 * @returns
 */
export const tHtml = (str: string) => {
  return Intl.getHTML(str);
};

/**
 * 日期格式 显示日期时间
 */
export const showDateTime = (str: any) => {
  return moment(str).format('yyyy-MM-DD HH:mm');
};

/**
 * 日期格式 显示日期
 * @param str
 * @returns
 */
export const showDate = (str?: any) => {
  return (
    (str && moment(str).format('YYYY-MM-DD')) || moment().format('YYYY-MM-DD')
  );
};

export const showLastDate = (str?: any) => {
  return (
    (str && moment(str).subtract(1, 'days').format('YYYY-MM-DD')) ||
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );
};

/**
 * 日期格式 显示时间
 * @param str
 * @returns
 */
export const showTime = (str: any) => {
  return moment(str).format('HH:mm');
};

/**
 * 获取时间戳
 * @param str
 * @returns
 */
export const getTimeValue = (str: any) => {
  return moment(str).valueOf();
};

export const getMinDate = (str: any) => {
  return moment(str).format('YYYY-MM-DD 00:00:00');
};

export const getMaxDate = (str: any) => {
  return moment(str).format('YYYY-MM-DD 23:59:59');
};

/**
 * 判断一个时间是否在当前时间之前 endTime
 * @param str
 * @returns
 */
export const getTimeisBefore = (str: any) => {
  const currentTime = moment().startOf('day').valueOf();
  const strTime = moment(str).startOf('day').valueOf();
  return currentTime > strTime;
};

/**
 * 判断一个时间是否在当前时间之后 startTime
 * @param str
 * @returns
 */
export const getTimeisAfter = (str: string) => {
  const currentTime = moment().startOf('day').valueOf();
  const strTime = moment(str).startOf('day').valueOf();
  return currentTime < strTime;
};

/**
 * dialog 与 react-select的兼容
 */
export const DIALOG_CONTAINER_CLASS_NAME = 'dialogContainerClassName';

/**
 * 页面显示的 用户名
 * @param firstName
 * @param middleName
 * @param lastName
 * @returns
 */
export const filterName = (
  firstName: string,
  middleName: string,
  lastName: string
) => {
  let name = firstName;
  if (middleName) {
    name += ' ' + middleName;
  }
  if (lastName) {
    name += ' ' + lastName;
  }
  return name;
};

/**
 * 页面显示的 货币格式
 * @param CurrencySymbol
 * @param Currency
 * @param rent
 * @param type
 * @returns
 */
export const filterRent = (
  CurrencySymbol: string,
  Currency: string,
  rent: any,
  type?: any,
  symbol?: any,
  bracket?: boolean
) => {
  if (Currency && rent) {
    let str = `${Currency} ${Number(rent).toFixed(2).toLocaleString()}`;
    if (symbol) {
      str = `${symbol} ${Currency} ${Number(rent).toFixed(2).toLocaleString()}`;
    }
    if (bracket) {
      str = `(${str})`;
    }
    return str;
  } else {
    if (type === 'job' || type === 'billinghistory' || type === 'invoice') {
      return '0';
    }
    return '';
  }
};

/**
 * 手机格式
 * @param Phone
 * @param Phonecc
 * @returns
 */
export const filterPhone = (Phone: string, Phonecc: string) => {
  if (Phone && Phonecc) {
    if (Phonecc.includes('+')) {
      return `${Phonecc} ${Phone}`;
    } else {
      return `+${Phonecc} ${Phone}`;
    }
  }
  if (Phone && !Phonecc) {
    return `${Phone}`;
  } else {
    return '';
  }
};

/**
 * 手机区号
 * @param zone
 * @returns
 */
export const filterZone = (zone: string) => {
  let value = { value: '', label: '' };
  stateOptions.forEach((v) => {
    if (v.value === zone || v.value === '+' + zone) {
      value = v;
    }
  });
  return value;
};

/**
 * 验证手机格式
 * @param phone
 * @returns
 */
export const validationPhone = (phonecc: any, phone: any) => {
  if (!phonecc || !phone) {
    return false;
  }
  if (!String(phonecc)?.includes('+')) {
    phonecc = '+' + phonecc;
  }
  phone = `${phonecc}${phone}`;
  const PhoneCN = new RegExp(/^(\+?86)?(1[3-9][0-9])\d{8}$/);
  const PhoneHK = new RegExp(/^(\+?852)?([1-9])\d{7}$/);
  const PhoneJP = new RegExp(/^(\+?81)?\d{10}$/);
  const PhoneSG = new RegExp(/^(\+?65)?\d{8}$/);
  const PhoneID = new RegExp(/^(\+?62)?\d{8,13}$/);
  const PhoneVI = new RegExp(/^(\+?84)?\d{9,12}$/);
  const PhoneAU = new RegExp(/^(\+?61)?\d{9}$/);
  const PhoneUK = new RegExp(/^(\+?44)?\d{10}$/);
  const PhoneNZ = new RegExp(/^(\+?64)?\d{8,9}$/);
  const PhoneIN = new RegExp(/^(\+?91)?\d{10}$/);
  const PhoneIT = new RegExp(/^(\+?39)?\d{8,11}$/);
  const PhoneDefault = new RegExp(/^\+?\d{6,20}$/);
  if (new RegExp(/^(\+?852)[0-9]*$/).test(phone)) {
    return PhoneHK.test(phone);
  } else if (new RegExp(/^(\+?86)[0-9]*$/).test(phone)) {
    return PhoneCN.test(phone);
  } else if (new RegExp(/^(\+?81)[0-9]*$/).test(phone)) {
    return PhoneJP.test(phone);
  } else if (new RegExp(/^(\+?65)[0-9]*$/).test(phone)) {
    return PhoneSG.test(phone);
  } else if (new RegExp(/^(\+?62)[0-9]*$/).test(phone)) {
    return PhoneID.test(phone);
  } else if (new RegExp(/^(\+?84)[0-9]*$/).test(phone)) {
    return PhoneVI.test(phone);
  } else if (new RegExp(/^(\+?61)[0-9]*$/).test(phone)) {
    return PhoneAU.test(phone);
  } else if (new RegExp(/^(\+?44)[0-9]*$/).test(phone)) {
    return PhoneUK.test(phone);
  } else if (new RegExp(/^(\+?64)[0-9]*$/).test(phone)) {
    return PhoneNZ.test(phone);
  } else if (new RegExp(/^(\+?91)[0-9]*$/).test(phone)) {
    return PhoneIN.test(phone);
  } else if (new RegExp(/^(\+?39)[0-9]*$/).test(phone)) {
    return PhoneIT.test(phone);
  } else {
    return PhoneDefault.test(phone);
  }
};

/**
 * 验证邮箱格式
 * @param email
 * @returns
 */
export const validationEmail = (email: any) => {
  if (email === 'undefined') {
    return false;
  }
  const Email = new RegExp(
    /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  );
  return Email.test(email);
};

/**
 * 验证仅输入中英文
 */
export const validationInvoiceNum = (str: any) => {
  const Str = new RegExp(/^[A-Za-z0-9]+$/g);
  return Str.test(str);
};

/**
 * 修改密码输入框
 * @param index
 * @param value
 */
export const chackPassword = (text: any) => {
  if (
    /^(.+){8,16}$/.test(text) &&
    /[0-9]/.test(text) &&
    /[a-z]/.test(text) &&
    /[A-Z]/.test(text)
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 验证金额
 * @param text
 * @returns
 */
export const validationNumber = (text: any) => {
  if (Number(text) < 0) {
    return false;
  }
  const strNumber = new RegExp(/^\d{0,9}$/);
  const strNumber1 = new RegExp(/^\d{0,9}\.\d{1,2}$/);
  return strNumber.test(text) || strNumber1.test(text);
};

// 统一JSON.parse的方法
export const JsonParse = (val?: string) => {
  return (val && JSON.parse(val)) || val;
};

/**
 * 验证输入的值 的长度
 * @param text
 * @param num
 * @returns
 */
export const validationInput = (text: any, num: number) => {
  if (text?.length === 0) {
    return false;
  }
  return text?.length <= num;
};

/**
 * 将数组数据存储到本地
 */
export function setLocalStorage(storage: string, data: any) {
  localStorage.setItem(storage, JSON.stringify(data));
}

/**
 * 获取本地存储里的数组数据
 */
export function getLocalStorage(storage: string) {
  const data = JSON.parse(localStorage.getItem(storage) || '{}');
  if (!Object.keys(data)?.length) {
    return null;
  }
  return data;
}

/**
 * 删除本地存储里的某个数据
 */
export function clearLocalStorage(storage: string) {
  localStorage.removeItem(storage);
}

/**
 * 计算两个时间的天数几个
 * @param strDateStart
 * @param strDateEnd
 * @returns
 */
export function getDays(strDateStart: string, strDateEnd: string) {
  const startDate = moment(strDateStart, 'YYYY-MM-DD HH:mm:ss');
  const endDate = moment(strDateEnd, 'YYYY-MM-DD HH:mm:ss');
  return endDate.diff(startDate, 'day');
}

/**
 * 格式化处理字符串
 */
export function formatString(str: string) {
  let val: any = str.replace(/does_not/g, 'don’t').replace(/_|-/g, ' ');
  const valArr: Array<string> = [...val];
  valArr[0] = valArr[0].toUpperCase();
  val = valArr.join('');
  return val;
}

/**
 * 导出PDF文件
 */
export function exportPdf(id: string, name: string) {
  const target: any = document.querySelector(`#${id}`);
  target.style.background = '#ffffff';
  html2canvas(target, { allowTaint: true }).then((canvas: any) => {
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const pageHeight = (contentWidth / 592.28) * 841.89;
    let leftHeight = contentHeight;
    let position = 10;
    const imgWidth = 595.28;
    const imgHeight = (592.28 / contentWidth) * contentHeight;
    const pageData = canvas.toDataURL('image/jpeg', 1.0);
    console.log('canvas', pageData);

    const pdf = new JsPDF(undefined, 'pt', 'a4');
    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, 'png', 0, 0, imgWidth, imgHeight);
    } else {
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'png', 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }
    pdf.save(`${name}.pdf`);
  });
}

/**
 * 导出图片
 * @params id - 作为导出的节点id
 * @params type - 导出图片的类型
 * @params name - 导出图片的名称
 */
export async function exportImage(
  id: string,
  type = 'jpeg',
  name: string,
  callBack?: () => void
) {
  const target: any = document.querySelector(`#${id}`);
  const canvas = await html2canvas(target, {});
  const pageUrl = canvas.toDataURL(`image/${type}`, 1.0);
  const aTag = window.document.createElement('a');
  aTag.href = pageUrl;
  aTag.download = name;
  window.document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();
  callBack?.();
}

/**
 * 设置字符串首字母大写
 */
export function stringCase(str: string) {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
}
const that = this;

export function debounce(func: any, wait: any, immediate: any) {
  let timeout: any = null;
  return function () {
    const context = that;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// 通过number返回星期简写
export const getWeekDayName = (
  num: 1 | 2 | 3 | 4 | 5 | 6 | 7 | number,
  all?: boolean
) => {
  const data = [
    {
      value: 1,
      name: 'Monday',
      shortMame: 'Mon'
    },
    {
      value: 2,
      name: 'Tuesday',
      shortMame: 'Tue'
    },
    {
      value: 3,
      name: 'Wednesday',
      shortMame: 'Wed'
    },
    {
      value: 4,
      name: 'Thursday',
      shortMame: 'Thu'
    },
    {
      value: 5,
      name: 'Friday',
      shortMame: 'Fri'
    },
    {
      value: 6,
      name: 'Saturday',
      shortMame: 'Sat'
    },
    {
      value: 7,
      name: 'Sunday',
      shortMame: 'Sun'
    }
  ];
  const obj = data.find((i) => i.value === num);
  return obj?.[(all && 'name') || 'shortMame'];
};

// 创建24小时的时间字符串
export function dayTimes(): {
  value: string;
  label: string;
  num: number;
}[] {
  const numArr = [];
  for (let i = 0; i < 24; i++) {
    const obj: any = {};
    // if (i < 13) {
    if (i < 10) {
      obj.value = `0${i}:00:00`;
      obj.label = `0${i}:00`;
    } else if (i === 12) {
      obj.value = `${i}:00:00`;
      obj.label = `${i}:00`;
    } else {
      obj.value = `${i}:00:00`;
      obj.label = `${i}:00`;
    }
    // } else {
    //   if (i === 24) {
    //     obj.value = '00:00:00';
    //     obj.label = `${i - 12}:00`;
    //   } else {
    //     obj.value = `${i}:00:00`;
    //     obj.label = (i - 12 > 9 && `${i - 12}:00`) || `0${i - 12}:00`;
    //   }
    // }
    obj.num = i;
    numArr.push(obj);
  }
  return numArr;
}

// 部分国家地区
export const countryRegionOpts: {
  value: string;
  label: string;
  phoneCC: string;
  name: string;
  currencyName: string;
}[] = (countryArr()?.length &&
  countryArr()?.map((itm: any) => ({
    ...itm,
    value: itm?.countryCode?.toLowerCase(),
    label: itm?.name || '',
    phoneCC: itm?.internationalDialing?.slice(1) || '',
    groupLabel: itm?.countryCode?.substr(0, 1) || ''
  }))) || [
  {
    value: 'hk',
    label: t('Hong Kong, China'),
    phoneCC: '852',
    groupLabel: 'H'
  },
  {
    value: 'cn',
    label: t('Mainland China'),
    phoneCC: '86',
    groupLabel: 'M'
  },
  {
    value: 'jp',
    label: t('Japan'),
    phoneCC: '81',
    groupLabel: 'J'
  },
  {
    value: 'sg',
    label: t('Singapore'),
    phoneCC: '65',
    groupLabel: 'S'
  },
  {
    value: 'us',
    label: t('USA'),
    phoneCC: '1',
    groupLabel: 'U'
  },
  {
    value: 'ca',
    label: t('Canada'),
    phoneCC: '1',
    groupLabel: 'C'
  },
  {
    value: 'au',
    label: t('Australia'),
    phoneCC: '61',
    groupLabel: 'A'
  },
  {
    value: 'id',
    label: t('Indonesia'),
    phoneCC: '62',
    groupLabel: 'I'
  },
  {
    value: 'vi',
    label: t('Vietnam'),
    phoneCC: '84',
    groupLabel: 'V'
  },
  {
    value: 'uk',
    label: t('United Kingdom'),
    phoneCC: '44',
    groupLabel: 'U'
  },
  {
    value: 'nz',
    label: t('New Zealand'),
    phoneCC: '64',
    groupLabel: 'N'
  },
  {
    value: 'in',
    label: t('India'),
    phoneCC: '91',
    groupLabel: 'I'
  },
  {
    value: 'it',
    label: t('Italy'),
    phoneCC: '39',
    groupLabel: 'I'
  }
];

export const countryOpts = () => {
  return countryRegionOpts.map((i) => ({
    ...i,
    label: i.label + ` (+${i.phoneCC})`
  }));
};

// 地区分组
export const setGroupArray = (data: any[]) => {
  const newArr: any = [];
  data?.forEach((itm: any) => {
    if (!newArr.find((i: any) => i.label === itm.groupLabel)) {
      const obj: any = {
        label: itm.groupLabel,
        options: [
          {
            ...itm
          }
        ]
      };
      newArr.push(obj);
    } else {
      newArr
        .find((i: any) => i.label === itm.groupLabel)
        ?.options.push({ ...itm });
    }
  });
  return newArr.sort((a: any, b: any) => {
    return a.label.localeCompare(b.label);
  });
};

/**
 * 可返回 正数、负数、和小数
 * @param str 传入的文本
 * @param float 预留的小数位 默认: 2
 * @param neg 是否支持负数
 * @returns 符合标准的字符串
 */
export const regExpNumber = (
  str: string | number,
  float = 2,
  neg = true,
  lastValue = ''
) => {
  str = String(str).replace(/,/g, '');
  if (!Number(str)) return str;
  let reg = '^(\\d+|\\-)(\\.?(\\d{0,' + float + '})?)?$';
  if (!neg) {
    reg = '^\\d+(\\.?(\\d{0,' + float + '})?)?$';
  }
  if (str) {
    if (new RegExp(reg, 'g').test(String(str))) {
      return str;
    } else {
      return lastValue || String(str).substring(0, String(str).length - 1);
    }
  } else {
    return '';
  }
};

/**
 * 根据指定key 为对象数组去重
 * @param arr 原始数组
 * @param val 指定的key
 * @returns 去重后的数组
 */
export const unique = (arr: any[], val: string) => {
  const res = new Map();
  return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
};

// 对比两个对象的值是否完全相等 返回值 true/false
export const compare = (origin: any, target: any) => {
  if (typeof target !== 'object') {
    // target不是对象/数组
    return origin === target; // 直接返回全等的比较结果
  }

  if (typeof origin !== 'object') {
    // origin不是对象/数组
    return false; // 直接返回false
  }
  for (const key of Object.keys(target)) {
    // 遍历target的所有自身属性的key
    if (!compare(origin[key], target[key])) {
      // 递归比较key对应的value，
      // value不等，则两对象不等，结束循环，退出函数，返回false
      return false;
    }
  }
  // 遍历结束，所有value都深度比较相等，则两对象相等
  return true;
};

/**
 * 根据开始时间 和 结束时间 获取时间数组
 * @param start
 * @param end
 * @returns
 */
export const getDayIn = (start: string, end: string) => {
  let startTime = moment(start).startOf('day').valueOf();
  const endTime = moment(end).startOf('day').valueOf();
  const arr: any = [];
  while (Number(startTime) <= Number(endTime)) {
    arr.push(startTime);
    startTime = moment(moment(startTime).add(1, 'days'))
      .startOf('day')
      .valueOf();
  }
  return arr;
};

/**
 *
 * @param 映射数据源
 * @returns
 */
export function getNameByCode(
  codeNameList: { value: string | number; label: string }[]
) {
  return (code: any) => {
    const tmp = codeNameList.filter((item: any) => item.value === code);
    if (tmp.length > 0) {
      return tmp[0].label;
    }
    return code;
  };
}

/**
 * 判断输入字符串是否为空或者全部都是空格
 * @param str
 * @returns
 */
export const validationStrisNull = (str: any) => {
  if (str === '' || str === undefined || str === null) return false;
  const regu = '^[ ]+$';
  const re = new RegExp(regu);
  return !re.test(str);
};

export const weekArray = new Array(
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat'
);
// 获取时间间隔
export const getDuration = [
  { value: '15', label: '15' },
  { value: '30', label: '30' },
  { value: '60', label: '60' }
];

// 根据 时分秒 获取 时间
export const getTimeFormat = (str: any) => {
  if (!str) return moment();
  return moment().hour(str.split(':')[0]).minute(str.split(':')[1]).second(0);
};

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

// 计算两个时间差  返回天小时分钟
export const timeDiff = (begin_time: any, end_time: any) => {
  // 年月日时分秒转换为时间戳
  const beginTime = new Date(begin_time).getTime() / 1000;
  const endTime = new Date(end_time).getTime() / 1000;
  let starttime: any;
  let endtime: any;
  if (beginTime < endTime) {
    starttime = beginTime;
    endtime = endTime;
  } else {
    starttime = endTime;
    endtime = beginTime;
  }
  // 计算天数
  const timediff = endtime - starttime;
  const days = parseInt(String(timediff / 86400));
  // 计算小时数
  let remain: any = timediff % 86400;
  const hours = parseInt(String(remain / 3600));
  // 计算分钟数
  remain = remain % 3600;
  const mins = parseInt(String(remain / 60));
  const leave3 = mins % (60 * 1000); // 计算分钟数后剩余的毫秒数
  const seconds = Math.round(leave3 / 1000);
  const res = `${days ? days + t(' day') : ''}${
    hours
      ? hours > 1
        ? ' ' + hours + t(' hours')
        : ' ' + hours + t(' hour')
      : ''
  }${mins ? ' ' + mins + t(' min') : ''}${
    seconds ? ' ' + seconds + t(' s') : ''
  }`;
  return res;
};

export const sort = (a: any, b: any) => {
  return a - b;
};

// 判断是否显示 checkin 按钮
export const showCheckIn = (start: any, end: any, unit = 15) => {
  if (
    !moment().isBefore(moment(start).subtract('minutes', unit)) &&
    !moment().isAfter(moment(end))
  ) {
    return true;
  } else {
    return false;
  }
};
export const isValidUrl = (str: string) => {
  try {
    // eslint-disable-next-line no-new
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
};
export const formatNumber = (str: any) => {
  // let price: any = str;
  // if (Number(str) >= 1000) {
  //   price = Number(str) / 1000 + 'k';
  // }
  // if (Number(str) >= 1000000) {
  //   price = Number(str) / 1000000 + 'm';
  // }
  return str;
};

export const getWinVariable = (label: string) => {
  const win: any = window;
  return win[label];
};

export const setWinVariable = (label: string, value?: any) => {
  const win: any = window;
  win[label] = value;
};

export const clearWinVariable = (label: string) => {
  const win: any = window;
  delete win[label];
};

/**
 * 导出数据未xlsx文件
 * @param data 导出数据源
 * @param fileName 文件名称
 * @param colsW 配置列宽
 */
export const exportToXLSX = ({
  data,
  fileName,
  colsW
}: {
  data: (string | number)[][];
  fileName?: string;
  colsW?: number[];
}) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  if (colsW) {
    worksheet['!cols'] = data[0].map((i, idx) => ({
      width: colsW[idx] || colsW[colsW?.length - 1]
    }));
    console.log('asd', worksheet['!cols']);
  }
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  XLSX.writeFile(
    workbook,
    `${fileName || moment().format('yyyy_MM_DD_HH.mm.ss')}.xlsx`
  );
};

export const getBookDate = (start: any, end: any) => {
  if (start === null || end === null) return '';
  const startDate = moment(start).format('yyyy-MM-DD');
  const endDate = moment(end).format('yyyy-MM-DD');
  if (startDate === endDate) {
    return startDate;
  } else {
    return `${startDate} to ${endDate}`;
  }
};


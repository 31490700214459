import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

export const FreeTooltip = ({
  message,
  children
}: {
  message: React.ReactElement | any;
  children: React.ReactElement;
}) => {
  return (message && <Tippy content={message}>{children}</Tippy>) || children;
};

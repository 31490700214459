import { takeLatest, call, put } from 'redux-saga/effects';
import _ from 'lodash';
import toast from 'react-hot-toast';

import { Actions, allReducers } from '@/store/reducers';
import * as request from '@/axios/request';
import { RESPONSE_TYPES } from '@/axios/axios';
import { RequestFullData } from '@/utils/fn';
import { linkToJob } from '@/utils/url';
import { t } from '@/i18n';

export function* watchHomejobSaga() {
  yield takeLatest(Actions.GETJOB_LIST, getJobList);
  yield takeLatest(Actions.GETJOB_INFO, getJobInfo);
  yield takeLatest(Actions.GET_REPORT, getReport);
  yield takeLatest(Actions.GETEXPENSELIST, getExpenseList);
  yield takeLatest(Actions.NEWJOB_INFORMATION, creatJob);
  yield takeLatest(Actions.GETFINANC_LIST, getFinancialAccountListing);
  yield takeLatest(Actions.SAVE_EXPENSE, saveExpense);
  yield takeLatest(Actions.GETCHECKLISTLIST, getChecklistList);
  yield takeLatest(Actions.GETJOBCHECKLISTLIST, getJobChecklistList);
  yield takeLatest(Actions.UPDATEJOBINFO, updateJobinfo);
  yield takeLatest(Actions.GETMYTEAMUSERLIST, getMyTeamUserList);
  yield takeLatest(Actions.GET_JOB_ASSIGNMENTS, getJobAssignments);
  yield takeLatest(Actions.SAVE_JOB_ASSIGNMENTS, saveJobAssignments);
  yield takeLatest(Actions.ADDCHECKLISTTOJOB, addChecklistToJob);
  yield takeLatest(Actions.SAVE_JOB_CONTACT, saveJobContact);
  yield takeLatest(Actions.CHECK_IN, checkin);

  yield takeLatest(Actions.GETPROVIDE_SMS, getProviderSmsContent); // 获取短信链接
  yield takeLatest(Actions.SENDPROVIDE_SMS, sendProviderSMS); // 发送短信
  yield takeLatest(Actions.GETPROVIDE_EMAIL, getProviderEmailContent); // 获取邮件链接
  yield takeLatest(Actions.SENDPROVIDE_EMAIL, sendProviderEmail); // 发送邮件
  yield takeLatest(Actions.GETPROVIDE_WHATSAPP, getProviderWhatsAppContent); // 获取whatsapp链接
  yield takeLatest(Actions.SENDPROVIDE_WHATSAPP, sendProviderWhatsApp); // 发送whatsapp

  yield takeLatest(Actions.ACCEPT_JOB, acceptJob);
  yield takeLatest(Actions.UPDATEJOBSTATUS, updateJobstatus);

  yield takeLatest(Actions.GETJOB_TYPE, getJobtypeList);
}

// 获取job的列表
function* getJobList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getJobList',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(request.getJoblist, props.payload);
  const CountJobStatus: RESPONSE_TYPES = yield call(
    request.getCountJobStatus,
    {}
  );

  if (res.result === 0) {
    const page: number = _.get(res, 'data.page_num', 0);
    const size = 10;
    const total = Number(_.get(res, 'data.total', 0));

    yield put({
      type: Actions.GETJOB_LISTSUCCESS,
      payload: {
        page,
        size,
        total
      }
    });
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'getJobList',
        value: false
      })
    );
    if (CountJobStatus.result === 0) {
      yield put({
        type: Actions.GETJOB_LIST_STATUS,
        payload: CountJobStatus.data.data
      });
    }
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getJobList',
      value: false
    })
  );
}

// 获取单个job信息
function* getJobInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getJobInfo',
      value: true
    })
  );
  try {
    yield put({
      type: 'GETJOB_INFOSUCCESS',
      payload: {}
    });
    const res: RESPONSE_TYPES = yield call(request.getJobinfo, props.payload);
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'GETJOB_INFOSUCCESS',
        payload: data.data
      });
      yield put(
        allReducers._GetFinancialList({
          entity: 'job',
          entityId: props.payload.job_id
        })
      );
      yield put(
        allReducers._getchecklist({
          obj: { job_id: props.payload.job_id }
        })
      );
      yield put(
        allReducers._getjobchecklist({
          obj: { job_id: props.payload.job_id }
        })
      );
      yield put(
        allReducers._getmyteamuserlist({
          obj: { job_id: null }
        })
      );
      yield put(
        allReducers._getJobAssignments({
          obj: { entity: 'job', job_id: props.payload.job_id }
        })
      );
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getJobInfo',
      value: false
    })
  );
}

// 获取单个job信息
function* getReport(props: any) {
  try {
    const res: boolean = yield call(request.reportDownloads, props.payload);
    return res;
    // const {result, data} = res;
    // if (result === 0) {
    //   yield put({
    //     type: 'GETJOB_INFOSUCCESS',
    //     payload: data.data,
    //   });
    // } else {
    // }
  } catch (error) {}
  return null;
}

// 获取工单的价格
function* getExpenseList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getExpenseListing',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getExpenseListing,
      props.payload.obj
    );
    const { result, data } = res;

    if (result === 0) {
      let num = 0;
      data.data.forEach((item: any) => {
        num += Number(item.amount);
      });
      data.expenses = num;
      data.currency = _.get(data, 'data[0].currency', '');
      yield put({
        type: 'GETEXPENSELIST_SUCCESS',
        payload: data
      });
    }
  } catch (error) {
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'getExpenseListing',
        value: false
      })
    );
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getExpenseListing',
      value: false
    })
  );
}

// 创建新的job订单
function* creatJob(props: any) {
  try {
    // const qr_job_type = props.payload.obj.data.qr_job_type;
    const res: RESPONSE_TYPES = yield call(
      request.newJobinformation,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'NEWJOB_INFORMATION_SUCCESS'
        // payload: res.job,
      });

      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newjob',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'globalValidationBox',
          value: false
        })
      );

      yield put(allReducers._setParentjobId(''));
      if (_.get(data, 'job_id')) {
        linkToJob(_.get(data, 'job_id'));
      } else {
        yield put(allReducers._getJoblist({ page: 1, size: 10 }));
      }
    }
  } catch (error) {}
}

function* getFinancialAccountListing(props: any) {
  try {
    // yield put(allReducers._FullScreenState(true));
    const res: RESPONSE_TYPES = yield call(
      request.getFinancialAccountListing,
      props.payload
    );
    const { result, data } = res;
    if (result === 0) {
      const arr: any = data.data.map((value: any) => {
        return {
          value: _.get(value, 'id', ''),
          label: _.get(value, 'account_name', '')
        };
      });
      yield put({
        type: 'GETFINANC_LISTSUCCESS',
        payload: { financialist: data.data, selelist: arr }
      });
    } else {
      return;
    }
  } catch (error) {}
}

function* saveExpense(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.saveExpense,
      props.payload.obj
    );
    const { result } = res;
    if (result === 0) {
      // yield put(
      //   allReducers.getexpenlist({
      //     obj: props.payload.entityObj,
      //   })
      // );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newExpense',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newFinancials',
          value: false
        })
      );
    } else {
      toast(`${t('Update failed')}`, {
        id: 'only-one',
        icon: '❗',
        duration: 3000,
        position: 'top-center'
      });
    }
    yield props.payload.setSubmitting(false);
  } catch (error) {
    yield props.payload.setSubmitting(false);
    toast(`${error}`, {
      id: 'only-one',
      icon: '❗',
      duration: 3000,
      position: 'top-center'
    });
  }
}

function* getChecklistList(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getChecklistList,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      const arr: any = data.data.map((value: any) => {
        return {
          value: _.get(value, 'template_id', ''),
          label: _.get(value, 'name', ''),
          count: _.get(value, 'count', '')
        };
      });
      const arr1: any = arr.filter((value: any) => {
        return _.get(value, 'count', '') > 0 ? true : false;
      });
      yield put({
        type: 'GETCHECKLISTLIST_SUCCESS',
        payload: arr1
      });
    }
  } catch (error) {}
}
// 添加CheckList
function* addChecklistToJob(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.addChecklistToJob,
      props.payload.obj
    );
    const { result } = res;
    if (result === 0) {
      yield put(
        allReducers._getjobchecklist({
          obj: { job_id: props.payload.obj.job_id }
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'addCheckLists',
          value: false
        })
      );
    } else {
      toast(`${t('Update failed')}`, {
        id: 'only-one',
        icon: '❗',
        duration: 3000,
        position: 'top-center'
      });
    }
    yield props.payload.setSubmitting(false);
  } catch (error) {
    yield props.payload.setSubmitting(false);
    toast(`${error}`, {
      id: 'only-one',
      icon: '❗',
      duration: 3000,
      position: 'top-center'
    });
  }
}

// 获取jobchecklist
function* getJobChecklistList(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getjobChecklistList,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'GETJOBCHECKLISTLIST_SUCCESS',
        payload: data
      });
    }
  } catch (error) {}
}

function* updateJobinfo(props: any) {
  try {
    // yield put(allReducers._FullScreenState(true));
    const res: RESPONSE_TYPES = yield call(
      request.updateJobInfo,
      props.payload.jobInfo
    );
    const { result } = res;
    if (result === 0) {
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'editjob',
          value: false
        })
      );
      yield put(allReducers.getjobInfo({ job_id: _.get(res, 'data.job_id') }));
      yield props.payload.setSubmitting(false);
    } else {
      return;
    }
  } catch (error) {}
}
// 获取工作人员信息
function* getMyTeamUserList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getMyTeamUserList',
      value: true
    })
  );
  try {
    const { result } = yield call(
      RequestFullData,
      request.getTeamUsers,
      1,
      props.payload,
      []
    );
    const arr: any = result.map((value: any) => {
      let label_name = `${_.get(value, 'FirstName', '')}`;
      if (_.get(value, 'MiddleName', '')) {
        label_name += ` ${_.get(value, 'MiddleName', '')}`;
      }
      if (_.get(value, 'LastName', '')) {
        label_name += ` ${_.get(value, 'LastName', '')}`;
      }
      return {
        value: _.get(value, 'user_id', ''),
        label: label_name
      };
    });
    const arr1: any = result
      .filter((value: any) => value.role === null && value.Status === 'A')
      .map((value: any) => {
        let label_name = `${_.get(value, 'FirstName', '')}`;
        if (_.get(value, 'MiddleName', '')) {
          label_name += ` ${_.get(value, 'MiddleName', '')}`;
        }
        if (_.get(value, 'LastName', '')) {
          label_name += ` ${_.get(value, 'LastName', '')}`;
        }
        return {
          value: _.get(value, 'user_id', ''),
          label: label_name
        };
      });

    yield put({
      type: 'GETMYTEAMUSERLIST_SUCCESS',
      payload: {
        myteamuserList: arr,
        myteamuserclientList: arr1
      }
    });
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getMyTeamUserList',
      value: false
    })
  );
}

// 获取分配列表
function* getJobAssignments(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getJobAssignments,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'GET_JOB_ASSIGNMENTS_SUCCESS',
        payload: data
      });
    }
  } catch (error) {}
}

// 获取短信链接
function* getProviderSmsContent(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getProviderSmsContent,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'GETPROVIDE_SMSSUCCESS',
        payload: data.data
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'viewProvidersms',
          value: true
        })
      );
    } else {
      toast(`${res.message}`, {
        id: 'only-one',
        icon: '❗',
        duration: 3000,
        position: 'top-center'
      });
    }
  } catch (error) {}
}
// 获取邮件链接
function* getProviderEmailContent(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getProviderEmailContent,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'GETPROVIDE_EMAILSUCCESS',
        payload: data.data
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'viewProvideremail',
          value: true
        })
      );
    } else {
      toast(`${res.message}`, {
        id: 'only-one',
        icon: '❗',
        duration: 3000,
        position: 'top-center'
      });
    }
  } catch (error) {}
}
// 获取whatsapp链接
function* getProviderWhatsAppContent(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getProviderWhatsAppContent,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'GETPROVIDE_WHATSAPPSUCCESS',
        payload: data.data
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'viewProviderwhatsApp',
          value: true
        })
      );
    } else {
      toast(`${res.message}`, {
        id: 'only-one',
        icon: '❗',
        duration: 3000,
        position: 'top-center'
      });
    }
  } catch (error) {}
}

// 发送短信
function* sendProviderSMS(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.sendProviderSMS,
      props.payload.obj
    );
    const { result, data } = res;
    console.log('asdasdasd', res);

    if (result === 0) {
      yield put({
        type: 'SENDPROVIDE_SMS_SUCCESS',
        payload: data
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'viewProvidersms',
          value: false
        })
      );
      toast('Send sms successfully!', {
        icon: '🔗'
      });
    } else {
      // const str = message?.split('error ');
      yield props.payload?.setError(
        t('Invalid mobile no. Please check if you have entered it correctly.')
      );
    }
    yield props.payload.setSubmitting(false);
  } catch (error) {
    yield props.payload.setSubmitting(false);
  }
}
// 发送邮件
function* sendProviderEmail(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.sendProviderEmail,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'SENDPROVIDE_EMAIL_SUCCESS',
        payload: data
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'viewProvideremail',
          value: false
        })
      );
      yield props.payload.setSubmitting(false);
      toast(t('Send email successfully!'), {
        icon: '🔗'
      });
    } else {
      // const str = message?.split('error ');
      yield props.payload?.setError(
        t('Invalid mobile no. Please check if you have entered it correctly.')
      );
    }
    yield props.payload.setSubmitting(false);
  } catch (error) {
    yield props.payload.setSubmitting(false);
  }
}
// 发送whatsapp
function* sendProviderWhatsApp(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.sendProviderWhatsApp,
      props.payload.obj
    );
    const { result, data } = res;
    if (result === 0) {
      yield put({
        type: 'SENDPROVIDE_WHATSAPP_SUCCESS',
        payload: data
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'viewProviderwhatsApp',
          value: false
        })
      );
      toast(t('Send whatsApp successfully!'), {
        icon: '🔗'
      });
    }
  } catch (error) {}
}

// 保存 job contact person
function* saveJobContact(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.saveJobContact,
      props.payload.obj
    );
    const { result } = res;
    if (result === 0) {
      yield put(
        allReducers.getjobInfo({
          job_id: _.get(res, 'data.job_id')
        })
      );
    }
  } catch (error) {}
}
function* checkin({ payload }: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'jobCheckin',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(request.checkin, payload.params);
    if (res.result === 0) {
      payload.onCompleted?.();
    }
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'jobCheckin',
        value: false
      })
    );
  } catch (error) {
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'jobCheckin',
        value: false
      })
    );
  }
}

function* acceptJob(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.acceptJob,
      props.payload.obj
    );
    if (res.result === 0) {
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'declinedJob',
          value: false
        })
      );
      yield put(
        allReducers.getjobInfo({
          job_id: _.get(res, 'data.job_id')
        })
      );
    }
  } catch (error) {}
}

/**
 * @msg: 更新job状态
 * @param {any} props
 */
function* updateJobstatus(props: any) {
  const { payload } = props;
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'updateJobStatusLoading',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.updateJobStatus,
      payload.params
    );
    const { result } = res;
    if (result === 0) {
      yield put(allReducers.getjobInfo({ job_id: payload.params.job_id }));
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'updateJobStatusLoading',
      value: false
    })
  );
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'globalValidationBox',
      value: false
    })
  );
}

function* saveJobAssignments(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'updateJobStatusLoading',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.saveJobAssignments,
      props.payload.obj
    );
    const { result } = res;
    if (result === 0) {
      yield put(
        allReducers.getjobInfo({
          job_id: _.get(res, 'data.job_id')
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'globalValidationBox',
          value: false
        })
      );
    } else {
      toast(`${t('Update failed')}`, {
        id: 'only-one',
        icon: '❗',
        duration: 3000,
        position: 'top-center'
      });
    }
    yield props.payload.setSubmitting(false);
  } catch (error) {
    yield props.payload.setSubmitting(false);
    toast(`${error}`, {
      id: 'only-one',
      icon: '❗',
      duration: 3000,
      position: 'top-center'
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'updateJobStatusLoading',
      value: false
    })
  );
}

function* getJobtypeList() {
  try {
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'getJobTypeList',
        value: true
      })
    );
    const res: RESPONSE_TYPES = yield call(request.getJobTypeList);
    if (res.result === 0) {
      console.log('这个jobtype', res);
      const list = res.data;
      const arr: any = list.map((item: any) => {
        return { value: item.id, label: item.value };
      });
      console.log('数据解析的位置', arr);
      yield put({
        type: 'GETJOB_TYPESUCCESS',
        payload: arr
      });
    }
  } catch (error) {
    console.log('这个jobtypeerror', error);
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getJobTypeList',
      value: false
    })
  );
}


/*
 * @Date: 2020-11-20 11:14:58
 * @LastEditTime: 2022-01-12 23:07:32
 * @Author: 爱你一万年！！！
 * @LastEditors: mebin
 */

import { createAction, handleActions } from 'redux-actions';
interface updateTypes {
  id: string;
  text: any;
  data?: any;
}
export interface FinancialListTableDataType {
  dateRequired: string;
  jobId: string;
  jobNo: string;
  date: string;
  description: string;
  invoiceNo: string;
  amount: string;
  id: string;
  type: string;
}
/**
 * Actions
 */
export const Actions_Financial = {
  GET_FINANCIAL_LIST: 'GET_FINANCIAL_LIST',
  GET_FINANCIAL_LIST_PARAMS: 'GET_FINANCIAL_LIST_PARAMS',
  GET_FINANCIAL_LIST_OK: 'GET_FINANCIAL_LIST_OK',
  GET_FINANCIAL: 'GET_FINANCIAL',
  NEW_FINANCIAL: 'NEW_FINANCIAL',
  DELETE_FINANCIAL: 'DELETE_FINANCIAL',
  PUT_FINANCIAL: 'PUT_FINANCIAL',
  GET_FINANCIAL_ACCOUNT_LIST: 'GET_FINANCIAL_ACCOUNT_LIST',
  GET_FINANCIAL_ACCOUNT_LIST_OK: 'GET_FINANCIAL_ACCOUNT_LIST_OK',
  GET_FINANCIAL_ACCOUNT_LIST_CLEAN: 'GET_FINANCIAL_ACCOUNT_LIST_CLEAN'
};

/**
 * Actions Creators
 */

export const _NewFinancial = createAction(Actions_Financial.NEW_FINANCIAL);
export const _GetFinancialListParams = createAction(
  Actions_Financial.GET_FINANCIAL_LIST_PARAMS
);
export const _GetFinancialListOk = createAction(
  Actions_Financial.GET_FINANCIAL_LIST_OK
);
export const _GetFinancialAccounts = createAction(
  Actions_Financial.GET_FINANCIAL_ACCOUNT_LIST
);
export const _GetFinancialAccountsClean = createAction(
  Actions_Financial.GET_FINANCIAL_ACCOUNT_LIST_CLEAN
);
export const _GetFinancialList = createAction(
  Actions_Financial.GET_FINANCIAL_LIST
);

export const financialReducers = {
  _NewFinancial,
  _GetFinancialAccounts,
  _GetFinancialAccountsClean,
  _GetFinancialList,
  _GetFinancialListParams,
  _GetFinancialListOk
};
/**
 * Reducer
 */
const financialListTableDataType: Array<FinancialListTableDataType> = [];
const financialListObj = {
  data: financialListTableDataType,
  page: 1,
  size: 10,
  total: 0
};

const defaultState = {
  financialType: [],
  financialTypeSize: 1000,
  financialTypeTotal: 0,

  FinancialList: [],
  financialListObj,
  FinancialListData: financialListObj,
  requestFinancialListParams: {
    size: 10,
    page: 1,
    id: '',
    gtId: '',
    ltId: '',
    persona: '',
    personaId: '',
    entity: '',
    entityId: '',
    linkedEntity: '',
    linkedEntityId: '',
    financialAccountId: '',
    accountType: '',
    search: '',
    description: '',
    gteTransactionTime: '',
    gtTransactionTime: '',
    lteTransactionTime: '',
    ltTransactionTime: '',
    currency: '',
    gteAmount: '',
    gtAmount: '',
    lteAmount: '',
    ltAmount: '',
    refreshTime: ''
  }
};
export type financialState = Readonly<typeof defaultState>;
export default handleActions(
  {
    [Actions_Financial.GET_FINANCIAL_ACCOUNT_LIST_OK]: (state, action: any) => {
      return {
        ...state,
        financialType: action.payload.data,
        financialTypeTotal: action.payload.total
      };
    },
    [Actions_Financial.GET_FINANCIAL_ACCOUNT_LIST_CLEAN]: (state) => {
      return {
        ...state,
        financialType: [],
        financialTypeTotal: 0
      };
    },
    [Actions_Financial.GET_FINANCIAL_LIST_OK]: (state, action: any) => {
      return {
        ...state,
        FinancialListData: action.payload
      };
    },
    [Actions_Financial.GET_FINANCIAL_LIST_PARAMS]: (state, action: any) => {
      const { id, text, data }: updateTypes = action.payload;
      switch (id) {
        case 'size':
          return {
            ...state,
            requestFinancialListParams: {
              ...state.requestFinancialListParams,
              page: data.page,
              size: data.size
            }
          };
        case 'time':
          return {
            ...state,
            requestFinancialListParams: {
              ...state.requestFinancialListParams,
              gteTransactionTime: data.gteTransactionTime,
              lteTransactionTime: data.lteTransactionTime
            }
          };
        case 'type':
          return {
            ...state,
            requestFinancialListParams: {
              ...state.requestFinancialListParams,
              accountType: text
            }
          };
        case 'search':
          return {
            ...state,
            requestFinancialListParams: {
              ...state.requestFinancialListParams,
              search: text
            }
          };
        case 'refreshTime':
          return {
            ...state,
            requestFinancialListParams: {
              ...state.requestFinancialListParams,
              refreshTime: text
            }
          };
        default:
          return {
            ...state,
            requestFinancialListParams: { ...state.requestFinancialListParams }
          };
      }
    }
  },
  defaultState
);

import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import styles from '@/assets/jss/material-dashboard-react/layouts/adminStyle.js';
import { t } from '@/i18n';
import { useMediaQuery, useTheme } from '@mui/material';

const theme: any = styles;
const useStyles = makeStyles(theme);
export default function FootLink() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(780));
  return (
    <div
      className={classes.footmenua}
      style={{
        justifyContent: (matches && 'center') || undefined,
        marginTop: (matches && '5px') || undefined
      }}
    >
      <span
        onClick={() => {
          window.open('https://www.assetbozz.com');
        }}
      >
        {t('About')}
      </span>
      <span
        onClick={() => {
          window.open('https://www.assetbozz.com/privacy-policy');
        }}
      >
        {t('Privacy')}
      </span>
      <span
        onClick={() => {
          window.open('https://www.assetbozz.com/terms-of-use');
        }}
      >
        {t('Terms')}
      </span>
      {/* <span
        onClick={() => {
          window.open('https://www.assetbozz.com/talktous');
        }}
      >
        {t('Contacts')}
      </span> */}
    </div>
  );
}


import React, { useRef } from 'react';
import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import styles from '@/assets/jss/material-dashboard-react/layouts/adminStyle.js';
// import MenuBtn from '@/components/MenuBtn';
import HBDrawerList from './HBDrawerList';
import { classNames } from '@/utils/fn';
import { ChevronLeftIcon, ChevronRightIcon } from '@/assets/featherIcons';
import getPublicImg from '@/utils/getPublicImg';

const theme: any = styles;
const useStyles = makeStyles(theme);

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  openD: boolean;
  setOpenD: (v: boolean) => void;
  envname: string;
  onLogOut: () => void;
  onClick?: () => void;
}

export default function DrawerModule(props: Props) {
  const { open, setOpen, openD, setOpenD, envname, onLogOut } = props;
  const drawerRef = useRef<any>(null);
  const classes = useStyles();
  const mainListItemsView = (
    <ul
      className={classNames(
        'h-full m-0 p-0 relative py-2 overflow-hidden',
        (open && 'overflow-y-auto') || 'overflow-x-hidden'
      )}
    >
      <HBDrawerList
        open={open}
        setOpen={setOpen}
        envname={envname}
        onLogOut={onLogOut}
      />
    </ul>
  );

  return (
    <div
      id="free-web-drawer"
      ref={drawerRef}
      className={classes.menu}
      // onMouseOverCapture={() => {
      //   setOpen(true);
      // }}
      // onMouseOutCapture={() => {
      //   if (!openD) {
      //     setOpen(false);
      //   }
      // }}
      style={{
        width: (open && '240px') || '70px',
        background: '#0891b2',
        color: '#d8e1fe'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '6px',
          // justifyContent: (open && 'flex-start') || 'center',
          height: '64px',
          transition: 'all 300ms ease 0s'
          // paddingRight: (open && 0) || '15px'
        }}
      >
        <img
          src={getPublicImg('AssetBozzIcon.png') || ''}
          style={{
            // display: 'inline-block'
            height: '58px'
            // marginRight: '20px',
            // paddingLeft: '14px',
            // paddingBottom: '5px'
          }}
          onClick={() => {}}
        />
        <h1 className="pl-4 text-2xl font-medium text-whitenew font-hbz">
          AssetBozz {envname}
        </h1>
        <div
          className="fixed rounded-full top-6 flex items-center justify-center z-50 w-6 h-6
          bg-whitenew shadow-sm border border-l-0 border-gray-200 cursor-pointer"
          style={{
            transition: 'all 300ms ease 0s',
            minWidth: 0,
            left: (open && '230px') || '60px'
          }}
          onClick={() => {
            setOpen(!open);
            setOpenD(!openD);
          }}
        >
          {(open && (
            <ChevronLeftIcon className="text-cyan-600 w-2/3 h-2/3 font-medium stroke-[3]" />
          )) || (
            <ChevronRightIcon className="text-cyan-600 w-2/3 h-2/3 font-medium stroke-[3]" />
          )}
        </div>
        {/* <IconButton
          color="inherit"
          onClick={() => {
            setOpenD(!openD);
          }}
        >
          <MenuBtn />
        </IconButton> */}
      </div>
      <Divider />
      {mainListItemsView}
    </div>
  );
}


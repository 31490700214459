/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { t } from '@/i18n';

import FootLink from './HBFootLink';
import FreeMoreMenu from '@/views/Components/FreeMoreMenu';
import { HelpCircleIcon } from '@/assets/featherIcons';
import { useSize } from 'ahooks';
import { isMobile } from '@/utils/fn';

export default function Foot(props: any) {
  const { version } = props;
  const { width } = useSize(document.getElementById('free-web-drawer'));

  return (
    <div className="w-full">
      {!isMobile() && (
        <div className="z-30 w-full flex justify-center fixed bottom-[50px] bg-gray-bgc md:hidden">
          <FootLink />
        </div>
      )}
      <div
        style={{
          left: width,
          width: (!isMobile() && `calc(100% - ${width || 70}px)`) || '100%'
        }}
        className="fixed flex items-center justify-between text-gray-400 bg-gray-bgc font-light z-40 h-[50px] bottom-0 p-0 pr-5 md:pl-5 md:pr-2"
      >
        <div className="w-full flex justify-center text-[16px] md:justify-start md:pl-0 md:text-[13px]">
          <span>
            &copy;&nbsp;2019-
            {moment().get('year')}&nbsp;
            {t('AssetBozz Limited.')}
          </span>
          &nbsp;
          <span>{version}</span>
        </div>
        {!isMobile() && (
          <div className="flex">
            <div className="hidden md:flex items-center">
              <FootLink />
            </div>
            <FreeMoreMenu
              child={
                <div
                  style={{
                    boxShadow:
                      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                  }}
                  id="dropdownMenuButton1"
                  className="ml-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-cyan-600 cursor-pointer"
                  data-te-dropdown-toggle-ref
                  aria-expanded="false"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <HelpCircleIcon className="text-whitenew" />
                </div>
              }
              data={[
                // {
                //   name: t('What’s new?'),
                //   onClick: () => {
                //     window.open(
                //       'https://www.notion.so/assetbozz/HomeBozz-Public-Wiki-864180b6bfdd49259f808cb5e8a61f6c#c7cbe6a2b90d40bf97f1fb9119a305ec'
                //     );
                //   }
                // },
                // {
                //   name: t('Tutorials'),
                //   onClick: () => {
                //     window.open(
                //       'https://www.notion.so/assetbozz/HomeBozz-Public-Wiki-864180b6bfdd49259f808cb5e8a61f6c#6bf8483557854f309ad7be27145dc9f9'
                //     );
                //   }
                // },
                {
                  name: t('Contact us'),
                  onClick: () => {
                    // window.open('https://g0cwn6nnwqk.typeform.com/to/Nzv458OE');
                    window.open('mailto:support@assetbozz.com');
                  }
                },
                {
                  name: t('Help & Support'),
                  onClick: () => {
                    // window.open('https://g0cwn6nnwqk.typeform.com/to/Nzv458OE');
                    window.open(
                      '[https://assetbozz.notion.site/AssetBozz-Public-Wiki-6766c6e6b9db4b419f7c4cd1107591c7](https://www.notion.so/AssetBozz-Public-Wiki-6766c6e6b9db4b419f7c4cd1107591c7)'
                    );
                  }
                }
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
}


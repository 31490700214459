/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAction, handleActions } from 'redux-actions';
interface updateTypes {
  id: string;
  text: any;
  data?: any;
}
/**
 * Actions
 */
export const Actions_Tenant = {
  // tenant列表相关
  GET_TENANT_LIST: 'GET_TENANT_LIST',
  GET_TENANT_LIST_PARAMS: 'GET_TENANT_LIST_PARAMS',
  GET_TENANT_LIST_STATUS: 'GET_TENANT_LIST_STATUS',
  GET_TENANT_LISTSUCCESS: 'GET_TENANT_LISTSUCCESS',
  GET_TENANT_LISTFAIL: 'GET_TENANT_LISTFAIL',
  GET_ALL_TENANT_LIST: 'GET_ALL_TENANT_LIST',
  GET_ALL_TENANT_LIST_COMPLETE: 'GET_ALL_TENANT_LIST_COMPLETE',
  // 单个tenant操作
  SAVE_TENANT: 'SAVE_TENANT',
  GET_TENANCT_PARAMS: 'GET_TENANCT_PARAMS',
  UPDATE_TENANT: 'UPDATE_TENANT',
  UPDATE_TENANT_STATUS: 'UPDATE_TENANT_STATUS',
  DELETE_TENANT: 'DELETE_TENANT',
  GET_TENANT_INFO: 'GET_TENANT_INFO',
  GET_TENANT_INFO_COMPLETE: 'GET_TENANT_INFO_COMPLETE',
  GET_USER_BIND_TENANT_DATA: 'GET_USER_BIND_TENANT_DATA',
  GET_USER_BIND_TENANT_DATA_COMPLETE: 'GET_USER_BIND_TENANT_DATA_COMPLETE',
  // 单个tenancy操作
  SAVE_TENANCY: 'SAVE_TENANCY',
  UPDATE_TENANCY: 'UPDATE_TENANCY',
  DELETE_TENANCY: 'DELETE_TENANCY',
  GET_TENANCIES_LIST: 'GET_TENANCIES_LIST',
  GET_TENANCIES_LIST_PARAMS: 'GET_TENANCIES_LIST_PARAMS',
  GET_TENANCIES_LIST_PARAMS_SUCCESS: 'GET_TENANCIES_LIST_PARAMS_SUCCESS',
  GET_TENANCIES_LIST_COMPLETE: 'GET_TENANCIES_LIST_COMPLETE',
  GET_TENANCIES_INFO: 'GET_TENANCIES_INFO',
  GET_TENANCIES_INFO_COMPLETE: 'GET_TENANCIES_INFO_COMPLETE',
  GET_TENANCIES_COUNT: 'GET_TENANCIES_COUNT',
  GET_TENANCIES_COUNT_SUCCESS: 'GET_TENANCIES_COUNT_SUCCESS'
};

/**
 * Actions Creators
 */

export const _getTenantlist = createAction(Actions_Tenant.GET_TENANT_LIST);
export const _getTenantlistParams = createAction(
  Actions_Tenant.GET_TENANT_LIST_PARAMS
);
export const _getTenancieslistParams = createAction(
  Actions_Tenant.GET_TENANCIES_LIST_PARAMS
);
export const _saveTenant = createAction(Actions_Tenant.SAVE_TENANT);
export const _saveTenancy = createAction(Actions_Tenant.SAVE_TENANCY);
export const _getTenanciesList = createAction(
  Actions_Tenant.GET_TENANCIES_LIST
);
export const _getTenanciesListComplete = createAction(
  Actions_Tenant.GET_TENANCIES_LIST_COMPLETE
);
export const _getTenantParams = createAction(Actions_Tenant.GET_TENANCT_PARAMS);
export const _updateTenant = createAction(Actions_Tenant.UPDATE_TENANT);
export const _updateTenantStatus = createAction(
  Actions_Tenant.UPDATE_TENANT_STATUS
);
export const _deleteTenant = createAction(Actions_Tenant.DELETE_TENANT);
export const _updateTenancy = createAction(Actions_Tenant.UPDATE_TENANCY);
export const _deleteTenancy = createAction(Actions_Tenant.DELETE_TENANCY);
export const _getTenantInfo = createAction(Actions_Tenant.GET_TENANT_INFO);
export const _getTenanciesInfo = createAction(
  Actions_Tenant.GET_TENANCIES_INFO
);
// export const _getTenantInfoComplete = createAction(Actions_Tenant.GET_TENANT_INFO_COMPLETE);

export const _getAllTenantList = createAction(
  Actions_Tenant.GET_ALL_TENANT_LIST
);
export const _getAllTenantListComplete = createAction(
  Actions_Tenant.GET_ALL_TENANT_LIST_COMPLETE
);
export const _getUserBindTenantData = createAction(
  Actions_Tenant.GET_USER_BIND_TENANT_DATA
);
export const _getTenanciesCount = createAction(
  Actions_Tenant.GET_TENANCIES_COUNT
);

export const tenantReducers = {
  _getTenantlist,
  _getTenantlistParams,
  _getTenancieslistParams,
  _saveTenant,
  _updateTenant,
  _getTenantInfo,
  _getTenanciesInfo,
  _deleteTenant,
  _updateTenantStatus,

  _saveTenancy,
  _getTenanciesList,
  _updateTenancy,
  _getAllTenantListComplete,
  _getAllTenantList,
  _getUserBindTenantData,
  _getTenanciesCount
};
/**
 * Reducer
 */
// const arrayString: Array<string> = [];
// const tenantInfoType: any = {};

const tenantListTableDataType: any = [];
const tenancyListTableDataType: any = [];
const defaultSessionState = {
  loading: false,
  allTenant: [],
  tenantListData: {
    data: tenantListTableDataType,
    page: 1,
    size: 10,
    total: 0
  },
  requestTenantParams: {
    size: 10,
    page: 1,
    entity: '', // property asset none
    // internal_tenants: null, //1,0,null
    status: 'member', //
    entityIdIn: '', //
    field: 'time_created', // time_required, provider_id, entity , status
    direction: 'desc', // asc ,desc
    name: '',
    history: '', // [ active , upcoming , completed , closed ]
    actived: 1 // [ active , actived ]
  },
  requestTenancyParams: {
    size: 10,
    page: 1,
    field: 'time_created', // time_required, provider_id, entity , status
    direction: 'desc', // asc ,desc
    search: '',
    history: '',
    actived: 1, // [ active , actived ]
    activeNumIn: '',
    entity: '',
    entityIdIn: ''
  },
  tenantInfo: { status: '' },
  tenanciesInfo: {},
  tenancies: [],
  tenancyListData: {
    data: tenancyListTableDataType,
    page: 1,
    size: 10,
    total: 0
  },
  tenantBindUser: [],
  tenanciesCount: {}
};
export type tenantState = Readonly<typeof defaultSessionState>;
export default handleActions(
  {
    [Actions_Tenant.GET_TENANCIES_COUNT_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        tenanciesCount: action.payload
      };
    },
    [Actions_Tenant.GET_ALL_TENANT_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        allTenant: action.payload
      };
    },
    [Actions_Tenant.GET_TENANT_LIST]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    /**
     * TENANT LIST 请求不同参数
     * @param state
     * @param action
     * @returns
     */
    [Actions_Tenant.GET_TENANT_LIST_PARAMS]: (state, action: any) => {
      const { id, text, data }: updateTypes = action.payload;
      switch (id) {
        case 'clear':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              ...data
            }
          };
        case 'status':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              status: text,
              page: 1,
              direction: '',
              actived: 1
            }
          };
        case 'history':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              history: text,
              page: 1
            }
          };
        case 'Sort':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              field: data.order_by,
              direction: data.direction
            }
          };
        case 'internal_tenants': {
          let param: any = null;
          if (text === 'Internal') {
            param = 1;
          } else if (text === 'External') {
            param = 0;
          }
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              // internal_tenants: param,
              page: 1
            }
          };
        }
        case 'entity':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              entity: data.entity,
              entityIdIn: data.entityIdIn,
              page: 1
            }
          };
        case 'size':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              page: data.page,
              size: data.size
            }
          };
        case 'active':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              actived: text,
              direction: '',
              status: ''
            }
          };
        case 'search':
          return {
            ...state,
            requestTenantParams: {
              ...state.requestTenantParams,
              name: text,
              ...{
                size: 10,
                page: 1,
                entity: '',
                field: '',
                direction: ''
              }
            }
          };
        default:
          return {
            ...state,
            requestTenantParams: { ...state.requestTenantParams }
          };
      }
    },
    [Actions_Tenant.GET_TENANCIES_LIST_PARAMS]: (state, action: any) => {
      const { id, text, data }: updateTypes = action.payload;
      switch (id) {
        case 'clear':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              ...data
            }
          };
        case 'Sort':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              field: data.order_by,
              direction: data.direction
            }
          };
        case 'size':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              page: data.page,
              size: data.size
            }
          };
        case 'activeNumIn':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              activeNumIn: text,
              direction: '',
              entityIdIn: '',
              field: '',
              search: '',
              entity: ''
            }
          };
        case 'entityIdIn':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              entityIdIn: text,
              entity: 'property',
              direction: ''
            }
          };
        case 'history':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              history: text,
              page: 1
            }
          };
        case 'search':
          return {
            ...state,
            requestTenancyParams: {
              ...state.requestTenancyParams,
              search: text,
              ...{
                size: 10,
                page: 1,
                field: '', // time_required, provider_id, entity , status
                direction: '' // asc ,desc
              }
            }
          };
        default:
          return {
            ...state,
            requestTenancyParams: { ...state.requestTenancyParams }
          };
      }
    },
    [Actions_Tenant.GET_TENANT_LISTSUCCESS]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        tenantListData: action.payload
      };
    },
    [Actions_Tenant.GET_TENANCIES_LIST_PARAMS_SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        loading: false,
        tenancyListData: action.payload.listDatas
      };
    },
    [Actions_Tenant.GET_TENANT_INFO_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        tenantInfo: action.payload
      };
    },
    [Actions_Tenant.GET_TENANCIES_INFO_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        tenanciesInfo: action.payload
      };
    },
    [Actions_Tenant.GET_TENANCIES_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        tenancies: action.payload.datas,
        tenancyListData: action.payload.listDatas
      };
    },
    [Actions_Tenant.GET_USER_BIND_TENANT_DATA_COMPLETE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        tenantBindUser: action.payload
      };
    }
  },
  defaultSessionState
);


/*
 * @Date: 2020-11-20 11:14:58
 * @LastEditTime: 2021-08-02 09:32:40
 * @Author: 爱你一万年！！！
 * @LastEditors: 爱你一万年！！！
 */
import { createAction, handleActions } from 'redux-actions';
/**
 * Actions
 */
export const Actions_Settings = {
  GET_STATE_LISTING: 'GET_STATE_LISTING',
  GET_STATE_LISTING_COMPLETE: 'GET_STATE_LISTING_COMPLETE',

  GET_COUNTRYS_LISTING: 'GET_COUNTRYS_LISTING',
  GET_COUNTRYS_LISTING_COMPLETE: 'GET_COUNTRYS_LISTING_COMPLETE',

  GET_REGION_TEXT: 'GET_REGION_TEXT',
  GET_REGION_TEXT_COMPLETE: 'GET_REGION_TEXT_COMPLETE',

  GET_MANAGER_SETTING_DETAILS: 'GET_MANAGER_SETTING_DETAILS',
  UPDATE_MANAGER_SETTING_DETAILS: 'UPDATE_MANAGER_SETTING_DETAILS',
  GET_MANAGER_SETTING_DETAILS_COMPLETE: 'GET_MANAGER_SETTING_DETAILS_COMPLETE',

  GET_MY_SETTINGS: 'GET_MY_SETTINGS',
  GET_MY_SETTINGS_COMPLETE: 'GET_MY_SETTINGS_COMPLETE',
  UPDATE_MY_SETTINGS: 'UPDATE_MY_SETTINGS',

  GET_MFA: 'GET_MFA',
  GET_MFA_COMPLETE: 'GET_MFA_COMPLETE',
  SAVE_MFA: 'SAVE_MFA',
  DISABLE_MFA: 'DISABLE_MFA',

  GET_ZONE_LIST: 'GET_ZONE_LIST',
  GET_ZONE_LIST_COMPLETE: 'GET_ZONE_LIST_COMPLETE',

  VIEW_USER: 'VIEW_USER',
  UPDATE_USER: 'UPDATE_USER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  DASHBOARD: 'DASHBOARD',
  DASHBOARD_COMPLETE: 'DASHBOARD_COMPLETE',

  SET_STORE_LANGUAGE: 'SET_STORE_LANGUAGE',
  INIT_APP_LANGUAGE: 'INIT_APP_LANGUAGE',

  // freemiu Setting
  GETGENERAL_INFO: 'GETGENERAL_INFO',
  GETGENERAL_INFOSUCCESS: 'GETGENERAL_INFOSUCCESS',

  EDITGENERAL: 'EDITGENERAL',
  EDITGENERAL_SUCCESS: 'EDITGENERAL_SUCCESS',

  GETPREFERENCES_INFO: 'GETPREFERENCES_INFO',
  GETPREFERENCES_INFOSUCCESS: 'GETPREFERENCES_INFOSUCCESS',

  GETTNTEGRATIONS_INFO: 'GETTNTEGRATIONS_INFO',
  GETTNTEGRATIONS_INFOSUCCESS: 'GETTNTEGRATIONS_INFOSUCCESS',

  GETUSER_INFO: 'GETUSER_INFO',
  GETUSER_INFOSUCCESS: 'GETUSER_INFOSUCCESS',

  GETSPACETYPE: 'GETSPACETYPE',
  GETSPACETYPESUCCESS: 'GETSPACETYPESUCCESS',

  GETCUSTOMLABEL: 'GETCUSTOMLABEL',
  GETCUSTOMLABEL_SUCCESS: 'GETCUSTOMLABEL_SUCCESS',

  GETCUSTOMLABELCATE: 'GETCUSTOMLABELCATE',
  GETCUSTOMLABELSOURCE: 'GETCUSTOMLABELSOURCE',

  ADDSPACETYPE: 'ADDSPACETYPE',
  EDITDESPACE: 'EDITDESPACE',

  ADDCUSTOMOTHER: 'ADDCUSTOMOTHER',
  MODIFYCUSTOMOTHER: 'MODIFYCUSTOMOTHER',
  DELETECUSTOMOTHER: 'DELETECUSTOMOTHER',

  OPENSETTING_MODAL: 'OPENSETTING_MODAL',

  GETCOMPAYINFO: 'GETCOMPAYINFO',
  GETCOMPAYINFOSUCCESS: 'GETCOMPAYINFOSUCCESS',

  DELETESAPCE: 'DELETESAPCE',

  GETADMINLIST: 'GETADMINLIST',
  GETADMINLISTSUCCESS: 'GETADMINLISTSUCCESS',

  SETCOMPAYSELETE: 'SETCOMPAYSELETE',

  EDITCOMPAYINFO: 'EDITCOMPAYINFO',

  GETMYPROFILE: 'GETMYPROFILE',
  GETMYPROFILESUCCESS: 'GETMYPROFILESUCCESS',

  GETXEROINFO: 'GETXEROINFO',
  GETXEROINFOSUCCESS: 'GETXEROINFOSUCCESS',

  GETSTRIPTINFO: 'GETSTRIPTINFO',
  GETSTRIPTINFOSUCCESS: 'GETSTRIPTINFOSUCCESS',

  DISCONNECTXERO: 'DISCONNECTXERO',
  DISCONNECTSTRIPE: 'DISCONNECTSTRIPE',

  OPENXERODISMODAL: 'OPENXERODISMODAL',
  OPENSTRIPEMODAL: 'OPENSTRIPEMODAL',

  CONNECTSTRIPE: 'CONNECTSTRIPE',

  STRIPEACTIONS: 'STRIPEACTIONS',

  ROLES_SETTING: 'ROLES_SETTING'
};

/**
 * Actions Creators
 */

export const _getSettingStateList = createAction(
  Actions_Settings.GET_STATE_LISTING
);
export const _getSettingStateListComplete = createAction(
  Actions_Settings.GET_STATE_LISTING_COMPLETE
);
export const _getSettingCountrysList = createAction(
  Actions_Settings.GET_COUNTRYS_LISTING
);
export const _getRegionText = createAction(Actions_Settings.GET_REGION_TEXT);
export const _getRegionTextComplete = createAction(
  Actions_Settings.GET_REGION_TEXT_COMPLETE
);

export const _getManagerSettingDetails = createAction(
  Actions_Settings.GET_MANAGER_SETTING_DETAILS
);
export const _updateManagerSettingDetails = createAction(
  Actions_Settings.UPDATE_MANAGER_SETTING_DETAILS
);
export const _getManagerSettingDetailsComplete = createAction(
  Actions_Settings.GET_MANAGER_SETTING_DETAILS_COMPLETE
);
export const _getMySettings = createAction(Actions_Settings.GET_MY_SETTINGS);
export const _getMySettingsComplete = createAction(
  Actions_Settings.GET_MY_SETTINGS_COMPLETE
);
export const _updateMySettings = createAction(
  Actions_Settings.UPDATE_MY_SETTINGS
);

export const _getMFA = createAction(Actions_Settings.GET_MFA);
export const _getMFAComplete = createAction(Actions_Settings.GET_MFA_COMPLETE);
export const _saveMFA = createAction(Actions_Settings.SAVE_MFA);
export const _disableMFA = createAction(Actions_Settings.DISABLE_MFA);

export const _getZoneList = createAction(Actions_Settings.GET_ZONE_LIST);
export const _getZoneListComplete = createAction(
  Actions_Settings.GET_ZONE_LIST_COMPLETE
);

export const _viewUser = createAction(Actions_Settings.VIEW_USER);
export const _updateUser = createAction(Actions_Settings.UPDATE_USER);
export const _changePassword = createAction(Actions_Settings.CHANGE_PASSWORD);

export const _dashboard = createAction(Actions_Settings.DASHBOARD);
export const _dashboardComplete = createAction(
  Actions_Settings.DASHBOARD_COMPLETE
);
export const _setStoreLanguage = createAction(
  Actions_Settings.SET_STORE_LANGUAGE
);
export const _initAPPLanguage = createAction(
  Actions_Settings.INIT_APP_LANGUAGE
);

// freemiu Setting
export const _getGeneralInfo = createAction(Actions_Settings.GETGENERAL_INFO);

export const _getPreferencesInfo = createAction(
  Actions_Settings.GETPREFERENCES_INFO
);
export const _getIntegrationsInfo = createAction(
  Actions_Settings.GETTNTEGRATIONS_INFO
);
export const _getUserInfo = createAction(Actions_Settings.GETUSER_INFO);
export const _getSpaceType = createAction(Actions_Settings.GETSPACETYPE);

export const _openSettingModal = createAction(
  Actions_Settings.OPENSETTING_MODAL
);
export const _getCustomLabel = createAction(Actions_Settings.GETCUSTOMLABEL);
export const _getCustomLabelcate = createAction(
  Actions_Settings.GETCUSTOMLABELCATE
);
export const _getCustomLabelsource = createAction(
  Actions_Settings.GETCUSTOMLABELSOURCE
);

export const _editGeneral = createAction(Actions_Settings.EDITGENERAL);

export const _addSpacetype = createAction(Actions_Settings.ADDSPACETYPE);
export const _editSpaceType = createAction(Actions_Settings.EDITDESPACE);

export const _addCustomOther = createAction(Actions_Settings.ADDCUSTOMOTHER);
export const _modifyCustomOther = createAction(
  Actions_Settings.MODIFYCUSTOMOTHER
);
export const _deleteCustomOther = createAction(
  Actions_Settings.DELETECUSTOMOTHER
);

export const _getCompayInfo = createAction(Actions_Settings.GETCOMPAYINFO);
export const _deleteSpacetype = createAction(Actions_Settings.DELETESAPCE);

export const _getListAdmin = createAction(Actions_Settings.GETADMINLIST);
export const _setCompaySelete = createAction(Actions_Settings.SETCOMPAYSELETE);

export const _editCompayInfo = createAction(Actions_Settings.EDITCOMPAYINFO);
export const _getMyProfile = createAction(Actions_Settings.GETMYPROFILE);

export const _getXeroInfo = createAction(Actions_Settings.GETXEROINFO);
export const _getStriptInfo = createAction(Actions_Settings.GETSTRIPTINFO);

export const _disConnectXero = createAction(Actions_Settings.DISCONNECTXERO);
export const _disConnectStripe = createAction(
  Actions_Settings.DISCONNECTSTRIPE
);
export const _openXeroDismodal = createAction(
  Actions_Settings.OPENXERODISMODAL
);

export const _openStripeDismodal = createAction(
  Actions_Settings.OPENSTRIPEMODAL
);

export const _connectStripe = createAction(Actions_Settings.CONNECTSTRIPE);

export const _stripeActions = createAction(Actions_Settings.STRIPEACTIONS);

export const settingReducers = {
  _getSettingStateList,
  _getSettingStateListComplete,
  _getRegionText,
  _getRegionTextComplete,
  _getSettingCountrysList,
  _getManagerSettingDetails,
  _getManagerSettingDetailsComplete,
  _getMySettings,
  _getMySettingsComplete,
  _getMFA,
  _getMFAComplete,
  _saveMFA,
  _disableMFA,
  _getZoneList,
  _getZoneListComplete,
  _updateMySettings,
  _viewUser,
  _updateUser,
  _changePassword,
  _dashboard,
  _dashboardComplete,
  _updateManagerSettingDetails,
  _setStoreLanguage,
  _initAPPLanguage,
  _getGeneralInfo,
  _openSettingModal,
  _getPreferencesInfo,
  _getIntegrationsInfo,
  _getUserInfo,
  _getSpaceType,
  _getCustomLabel,
  _getCustomLabelcate,
  _getCustomLabelsource,
  _editGeneral,
  _addSpacetype,
  _editSpaceType,
  _addCustomOther,
  _modifyCustomOther,
  _deleteCustomOther,
  _getCompayInfo,
  _deleteSpacetype,
  _getListAdmin,
  _setCompaySelete,
  _editCompayInfo,
  _getMyProfile,
  _getXeroInfo,
  _getStriptInfo,
  _disConnectXero,
  _disConnectStripe,
  _openXeroDismodal,
  _openStripeDismodal,
  _connectStripe,
  _stripeActions
};
/**
 * Reducer
 */

const defaultState = {
  SettingsgetStateListingData: {
    contacts: [],
    labels: [],
    StateData: []
  },
  RegionText: { latitude: 0, longitude: 0 },
  oldRegionText: { latitude: 0, longitude: 0 },
  managerDetails: {
    asset_classes: {},
    checklists: [],
    country: {},
    currencies: [],
    customer: {},
    job_types: [],
    priority_labels: []
  },
  mySettings: {
    user: {},
    notification_settings: {}
  },
  zoneList: [],
  mfa: {},
  dashboard: {
    application_total: 0,
    property_total: 0,
    job_total: 0,
    providers: 0,
    teams: 0,
    unread_messages: 0,
    asset_total: 0
  },
  storeLanguage: 'en',
  // freemiu Setting
  generalInfo: {
    legalName: '',
    logoId: undefined,
    website: '',
    contactEmail: '',
    contactPhonecc: '',
    bookingInventory: '',
    timeUpdated: undefined,
    contactPhone: '',
    currency: '',
    country: '',
    optikiActived: undefined,
    currencies: {
      currencyCode: '',
      currencySymbol: '',
      name: ''
    },
    proPlan: undefined,
    timeCreated: '',
    address: '',
    province: '',
    city: '',
    region: '',
    description: ''
  },
  roles: {},
  preferencesInfo: {},
  integrationsInfo: {},
  userInfo: {},
  spacetype: [],
  bookingsource: [],
  clientcate: [],
  cliensource: [],
  generalModal: false,
  preferencesModal: false,
  subscriptionModal: false,
  userAddModal: false,
  customAddModal: false,
  myprofileModal: false,
  compayinfo: [
    { all: 0, actived: 0, users: [], emails: [] },
    { all: 0, actived: 0, users: [], emails: [] }
  ],
  adminList: [],
  myprofile: {
    mobilephone: '',
    mobilephonecc: '',
    email: '',
    firstname: '',
    middlename: '',
    lastname: '',
    lastLoginTime: '',
    profileImage: ''
  },
  xeroinfo: {},
  striptinfo: {},
  disxeromodal: false,
  disstripemodal: false,
  connectstripe: false
};
export type settingsState = Readonly<typeof defaultState>;
export default handleActions(
  {
    [Actions_Settings.GET_STATE_LISTING_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        SettingsgetStateListingData: action.payload
      };
    },
    [Actions_Settings.GET_REGION_TEXT_COMPLETE]: (state, action: any) => {
      const { id, text } = action.payload;
      switch (id) {
        case 'NewRegion':
          return {
            ...state,
            RegionText: text
          };
        case 'oldRegionText':
          return {
            ...state,
            RegionText: text,
            oldRegionText: text
          };
        case 'clean':
          return {
            ...state,
            SettingsgetStateListingData: {
              contacts: [],
              labels: [],
              StateData: []
            },
            RegionText: { latitude: 0, longitude: 0 }
          };
        default:
          return {
            ...state
          };
      }
    },
    [Actions_Settings.GET_MANAGER_SETTING_DETAILS_COMPLETE]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        managerDetails: action.payload
      };
    },
    [Actions_Settings.UPDATE_MANAGER_SETTING_DETAILS]: (state, action: any) => {
      return {
        ...state,
        managerDetails: {
          ...state.managerDetails,
          [`${action.payload.key}`]: action.payload.value
        }
      };
    },
    [Actions_Settings.GET_MY_SETTINGS_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        mySettings: action.payload
      };
    },
    [Actions_Settings.GET_ZONE_LIST_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        zoneList: action.payload
      };
    },
    [Actions_Settings.DASHBOARD_COMPLETE]: (state, action: any) => {
      return {
        ...state,
        dashboard: { ...action.payload }
      };
    },
    [Actions_Settings.SET_STORE_LANGUAGE]: (state, action: any) => {
      return {
        ...state,
        storeLanguage: action.payload
      };
    },
    [Actions_Settings.GETGENERAL_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        generalInfo: action.payload
      };
    },
    [Actions_Settings.ROLES_SETTING]: (state, action: any) => {
      return {
        ...state,
        roles: action.payload
      };
    },
    [Actions_Settings.GETPREFERENCES_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        preferencesInfo: action.payload
      };
    },
    [Actions_Settings.GETTNTEGRATIONS_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        integrationsInfo: action.payload
      };
    },
    [Actions_Settings.GETUSER_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        userInfo: action.payload
      };
    },
    [Actions_Settings.GETSPACETYPESUCCESS]: (state, action: any) => {
      return {
        ...state,
        spacetype: action.payload
      };
    },

    [Actions_Settings.OPENSETTING_MODAL]: (state, action: any) => {
      const { name, value } = action.payload;
      if (name === 'General') {
        return {
          ...state,
          generalModal: value
        };
      } else if (name === 'Preferences') {
        return {
          ...state,
          preferencesModal: value
        };
      } else if (name === 'Subscription') {
        return {
          ...state,
          subscriptionModal: value
        };
      } else if (name === 'Users') {
        return {
          ...state,
          userAddModal: value
        };
      } else if (name === 'Custom') {
        return {
          ...state,
          customAddModal: value
        };
      } else if (name === 'Myfile') {
        return {
          ...state,
          myprofileModal: value
        };
      }
      return {
        ...state
      };
    },
    [Actions_Settings.GETCUSTOMLABEL_SUCCESS]: (state, action: any) => {
      console.log('来了几次的', action.payload);
      const { name, value } = action.payload;
      if (name === 'booking_source') {
        return {
          ...state,
          bookingsource: value
        };
      } else if (name === 'client_category') {
        return {
          ...state,
          clientcate: value
        };
      } else {
        return {
          ...state,
          cliensource: value
        };
      }
    },
    [Actions_Settings.EDITGENERAL]: (state, action: any) => {
      console.log(action);
      return {
        ...state
      };
    },
    [Actions_Settings.EDITGENERAL_SUCCESS]: (state, action: any) => {
      console.log(action);
      return {
        ...state
      };
    },
    [Actions_Settings.GETCOMPAYINFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        compayinfo: action.payload
      };
    },
    [Actions_Settings.GETADMINLISTSUCCESS]: (state, action: any) => {
      return {
        ...state,
        adminList: action.payload
      };
    },
    [Actions_Settings.GETMYPROFILESUCCESS]: (state, action: any) => {
      return {
        ...state,
        myprofile: action.payload
      };
    },
    [Actions_Settings.GETXEROINFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        xeroinfo: action.payload
      };
    },
    [Actions_Settings.GETSTRIPTINFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        striptinfo: action.payload
      };
    },
    [Actions_Settings.OPENXERODISMODAL]: (state, action: any) => {
      return {
        ...state,
        disxeromodal: action.payload
      };
    },
    [Actions_Settings.OPENSTRIPEMODAL]: (state, action: any) => {
      return {
        ...state,
        disstripemodal: action.payload
      };
    },
    [Actions_Settings.CONNECTSTRIPE]: (state, action: any) => {
      return {
        ...state,
        connectstripe: action.payload
      };
    }
    // [Actions_Settings.STRIPEACTIONS]: (state, action: any) => {
    //   return {
    //     ...state,
    //     connectstripe: action.payload
    //   };
    // },
  },
  defaultState
);


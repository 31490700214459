import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { t } from '@/i18n';
import { primaryColor } from '@/assets/jss/material-dashboard-react.js';
import { classNames, setGroupArray } from '@/utils/fn';

const defaultOption = [
  { value: 'HK', label: 'HK，China' },
  { value: 'China', label: 'China' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'USA', label: 'USA' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Australia', label: 'Canada' }
];

const FreeSelect = (props: any) => {
  const { options = defaultOption } = props;
  const [optionsOf, setOptionsOf] = useState(options);

  const styles = {
    menuList: (css: any) => ({
      ...css,
      padding: 0,
      zIndex: 99999,
      fontSize: '12px',
      fontWeight: 400,
      maxHeight: props?.height || '200px'
    }),
    option: (css: any, { isDisabled, isFocused, isSelected }: any) => ({
      ...css,
      padding: '1rem',
      outline: '1px dotted #ddd',
      textAlign: 'start',
      fontSize: '12px',
      zIndex: 99999,
      fontWeight: 400,
      color: (isSelected && '#ffffff') || '#000000',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#0891b2'
        : isFocused
        ? 'rgba(13, 115, 143, 0.2)'
        : undefined,
      ':active': {
        ...css[':active'],
        color: '#ffffff',
        backgroundColor: !isDisabled
          ? isSelected
            ? '#ecfeff'
            : 'rgba(13, 115, 143, 0.2)'
          : undefined
      }
    }),
    control: (css: any) => ({
      ...css,
      padding: '0.5px 0px',
      fontSize: '14px',
      fontWeight: 400,
      ':hover': {
        ...css[':hover']
        // borderColor: '#0891b2',
        // boxShadow: '0 0 0 1px #0891b2'
      }
    }),
    container: (css: any) => ({
      ...css,
      '& .select__single-value p': {
        fontWeight: '400 !important',
        color: '#646c9a',
        '& span': {
          display: 'none'
        }
      },
      '& .select__value-container--has-value': {
        maxHeight: '60px',
        overflow: 'auto',
        overflowX: 'hidden'
      },
      '& .select__option--is-disabled': {
        color: '#000',
        textDecoration: 'line-through'
      }
    }),
    multiValueRemove: (css: any) => ({
      ...css
      // paddingTop: '5px'
    }),
    multiValueLabel: (css: any) => ({
      ...css
      // padding: '5px'
    })
  };

  // const commonStyle = {
  //   fontStyle: 'normal',
  //   fontWeight: 500,
  //   fontSize: '11.375px',
  //   lineHeight: '18px',
  //   color: '#646C9A',
  //   marginLeft: (props?.ml && `${props?.ml}px`) || undefined,
  //   marginBottom: '10px'
  // };

  const DropdownIndicator = (props1: any) => {
    return (
      <components.DropdownIndicator {...props1}>
        {props.text}
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    setOptionsOf(options);
  }, [options]);

  const handleChange = (value: any) => {
    if (value) {
      if (props?.onChange) {
        props?.onChange(`${props?.id}`, value);
      }
    } else {
      if (props?.onChange) {
        props?.onChange(`${props?.id}`, { value: '', label: '' });
      }
    }
  };
  return (
    <div className="w-full">
      {props?.label && (
        <label
          // style={commonStyle}
          htmlFor="company-website"
          className="block text-sm font-medium text-gray-700 mb-1"
          style={{ textTransform: 'capitalize' }}
        >
          {props?.label}
          {props?.required ? (
            <span style={{ marginLeft: '.3rem', color: '#b91c1c' }}>*</span>
          ) : null}
        </label>
      )}
      <Select
        value={props?.value}
        options={
          (props?.group &&
            setGroupArray(optionsOf).sort((a: any, b: any) => {
              return a.label.localeCompare(b.label);
            })) ||
          optionsOf
        }
        // options={optionsOf}
        isMulti={props?.isMulti || false}
        placeholder={props.placeholder ?? `${t('Select')}...`}
        closeMenuOnSelect={!props?.isMulti}
        className={classNames('basic-single', props?.className)}
        classNamePrefix="select"
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: primaryColor[2],
            primary: primaryColor[0]
          }
        })}
        styles={{
          ...styles,
          ...props?.styles
        }}
        components={{ DropdownIndicator }}
        onChange={handleChange}
        name={props?.id}
        id={props?.id}
        isDisabled={props?.disabled}
        isClearable={props?.isClearable || false}
        menuPlacement="auto"
      />
      {props?.errorText && (
        <div style={{ color: '#b91c1c', marginTop: '.5rem' }}>
          {props?.errorText}
        </div>
      )}
    </div>
  );
};
export default FreeSelect;

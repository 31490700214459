/* eslint-disable max-len */
import React from 'react';
import { FreeTooltip } from './FreeTooltip';
import { classNames } from '@/utils/fn';
const FreeMoreMenu = ({ child, data }: any) => {
  return (
    <div className="flex justify-center">
      <div className="relative" data-te-dropdown-ref>
        {child}
        <ul
          className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
          aria-labelledby="dropdownMenuButton1"
          data-te-dropdown-menu-ref
        >
          {data.map((item: any, index: any) => {
            return (
              <li
                key={index}
                onClick={(e) => {
                  if (item?.disabled) return;
                  item?.onClick(e);
                }}
                data-te-dropdown-item-ref
                className={classNames(
                  'cursor-pointer',
                  item?.hidden && 'hidden'
                )}
              >
                {(item?.allowRemoved === 0 && (
                  <FreeTooltip message={item?.message}>
                    <div
                      className={classNames(
                        'dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-white text-gray-700 hover:bg-gray-100',
                        item?.disabled && 'opacity-50 hover:cursor-not-allowed'
                      )}
                      style={{
                        color: (item?.delete && '#b91c1c') || '',
                        textTransform: 'capitalize'
                      }}
                    >
                      {item.name}
                    </div>
                  </FreeTooltip>
                )) || (
                  <div
                    className={classNames(
                      'dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-gray-700 hover:bg-gray-100',
                      item?.disabled && 'opacity-50 hover:cursor-not-allowed'
                    )}
                    style={{
                      color: (item?.delete && '#b91c1c') || '',
                      textTransform: 'capitalize'
                    }}
                  >
                    {item.name}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default FreeMoreMenu;


/*
 * @Date: 2020-06-30 19:45:27
 * @LastEditTime: 2021-07-27 17:18:20
 */

import { BaseAction } from '../';
export const action_login = {
  GET_LOGIN_DATA: Symbol('get_login_data'), // 请求登录信息
  GET_LOGIN_DATA_COMPLETED: Symbol('get_login_data_completed'), // 成功请求保存登录信息
  AUTH_LOGINOUT: Symbol('auth_loginout'), // 登出
  REFRESH_TOKEN: Symbol('refresh_token'), // 刷新token
  GET_HUMANVERIFICATIONSTATUS: Symbol('get_humanVerificationStatus'), // 获取 humanVerificationStatus
  SAVE_HUMANVERIFICATIONSTATUS: Symbol('save_humanVerificationStatus'), // 保存 humanVerificationStatus
  SEND_PASSWORD_RESET_EMAIL_REQUEST: Symbol(
    'Send_password_reset_email_request'
  ), // 发送密码重置Email请求
  SEND_PASSWORD_RESET_EMAIL_REQUEST_COMPLETED: Symbol(
    'Send_password_reset_email_request_completed'
  ), // 发送密码重置Email请求完成
  SEND_PASSWORD_RESET_REQUEST: Symbol('Send_password_reset_request'), // 发送密码重置请求
  SEND_PASSWORD_RESET_REQUEST_COMPLETED: Symbol(
    'Send_password_reset_request_completed'
  ), // 发送密码重置请求完成
  SEND_PASSWORD_CHECKOUT_REQUEST: Symbol('Send_password_checkout_request'), // 发送验证密码
  SEND_PASSWORD_CHECKOUT_REQUEST_COMPLETED: Symbol(
    'Send_password_checkout_request_completed'
  ), // 发送验证密码完成
  SEND_PASSWORD_CHANGE_REQUEST: Symbol('Send_password_change_request'), // 发送密码更新请求
  SEND_PASSWORD_CHANGE_REQUEST_COMPLETED: Symbol(
    'Send_password_change_request_completed'
  ) // 发送密码更新请求完成
};

export const _getLoginData: (payload: any) => BaseAction = (payload) => ({
  type: action_login.GET_LOGIN_DATA,
  payload
});

export const _getLoginDataCompleted: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.GET_LOGIN_DATA_COMPLETED,
  payload
});

export const _AuthLoginout: () => BaseAction = () => ({
  type: action_login.AUTH_LOGINOUT,
  payload: ''
});

export const _RefreshToken: (payload: any) => BaseAction = (payload) => ({
  type: action_login.REFRESH_TOKEN,
  payload
});

export const _getHumanVerificationStatus: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.GET_HUMANVERIFICATIONSTATUS,
  payload
});

export const _saveHumanVerificationStatus: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.SAVE_HUMANVERIFICATIONSTATUS,
  payload
});

/** 发送密码重置Email请求 */
export const _sendPasswordResetEmailRequest: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.SEND_PASSWORD_RESET_EMAIL_REQUEST,
  payload
});

/** 发送密码重置Email请求完成 */
export const _sendPasswordResetEmailRequestCompleted: (
  payload: any
) => BaseAction = (payload) => ({
  type: action_login.SEND_PASSWORD_RESET_EMAIL_REQUEST_COMPLETED,
  payload
});

/** 发送密码重置请求 */
export const _sendPasswordResetRequest: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.SEND_PASSWORD_RESET_REQUEST,
  payload
});

/** 发送密码重置请求完成 */
export const _sendPasswordResetRequestCompleted: (
  payload: any
) => BaseAction = (payload) => ({
  type: action_login.SEND_PASSWORD_RESET_REQUEST_COMPLETED,
  payload
});

/** 发送验证密码 */
export const _sendPasswordCheckoutRequest: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.SEND_PASSWORD_CHECKOUT_REQUEST,
  payload
});

/** 发送验证密码完成 */
export const _sendPasswordCheckoutRequestCompleted: (
  payload: any
) => BaseAction = (payload) => ({
  type: action_login.SEND_PASSWORD_CHECKOUT_REQUEST_COMPLETED,
  payload
});

/** 发送密码更新请求 */
export const _sendPasswordChangeRequest: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_login.SEND_PASSWORD_CHANGE_REQUEST,
  payload
});

/** 发送密码更新请求完成 */
export const _sendPasswordChangeRequestCompleted: (
  payload: any
) => BaseAction = (payload) => ({
  type: action_login.SEND_PASSWORD_CHANGE_REQUEST_COMPLETED,
  payload
});

export const loginreducers = {
  _getLoginData,
  _getLoginDataCompleted,
  _AuthLoginout,
  _RefreshToken,
  _getHumanVerificationStatus,
  _saveHumanVerificationStatus,
  _sendPasswordResetEmailRequest,
  _sendPasswordResetEmailRequestCompleted,
  _sendPasswordResetRequest,
  _sendPasswordResetRequestCompleted,
  _sendPasswordCheckoutRequest,
  _sendPasswordCheckoutRequestCompleted,
  _sendPasswordChangeRequest,
  _sendPasswordChangeRequestCompleted
};

const initialState = {
  loginData: [],
  auth: false,
  isAdmin: '',
  userInfo: {},
  humanVerificationStatus: {
    Status: 2, // 0失败 1成功 2默认状态
    errorInfo: ''
  },
  passwordEmailResetCompleted: false,
  passwordResetCompleted: false,
  passwordChangeCompleted: false,
  PasswordCheckout: 2
};

export type loginState = Readonly<typeof initialState>;

export default (state: loginState = initialState, action: BaseAction) => {
  switch (action.type) {
    case action_login.GET_LOGIN_DATA_COMPLETED:
      return {
        ...state,
        // loginData: action.payload.data,
        // userInfo: action.payload.userInfo,
        auth: true,
        isAdmin: action.payload.role_type || ''
      };
    case action_login.AUTH_LOGINOUT:
      return {
        ...state,
        loginData: [],
        userInfo: {},
        auth: false
      };
    case action_login.SAVE_HUMANVERIFICATIONSTATUS:
      return {
        ...state,
        humanVerificationStatus: action.payload
      };

    case action_login.SEND_PASSWORD_RESET_EMAIL_REQUEST_COMPLETED:
      return {
        ...state,
        passwordEmailResetCompleted: action.payload
      };

    case action_login.SEND_PASSWORD_RESET_REQUEST_COMPLETED:
      return {
        ...state,
        passwordResetCompleted: action.payload
      };

    case action_login.SEND_PASSWORD_CHECKOUT_REQUEST_COMPLETED:
      return {
        ...state,
        PasswordCheckout: action.payload
      };

    case action_login.SEND_PASSWORD_CHANGE_REQUEST_COMPLETED:
      return {
        ...state,
        passwordChangeCompleted: action.payload
      };

    default:
      return state;
  }
};

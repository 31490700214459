/* eslint-disable max-len */
import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  AlertTriangleIcon,
  CheckIcon,
  InfoIcon,
  XIcon
} from '@/assets/featherIcons';
import FreeButton from '@/views/Components/FreeButton';
import { t } from '@/i18n';
import FreeCalendar from '@/views/Components/FreeCalendar';

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

interface ModalProps {
  size?: 'lg' | 'xl' | '2xl' | '4xl' | '6xl';
  anchor?: 'top' | 'center' | 'bottom';
  iconChild?: any;
  noTran?: boolean;
  title?: string;
  closeOutSide?: (closeModal: any) => any;
  onSure?: (close: any, load: any, obj?: any, setError?: any) => void;
  children?: (
    close?: any,
    load?: any,
    obj?: any,
    setObj?: any,
    error?: any
  ) => React.ReactNode;
  canceltext?: string;
  suretext?: string;
  disabled?: boolean;
  hideXIcon?: boolean;
  hideHeaderAndFoot?: boolean;
  hideCancel?: boolean;
  hideSubmit?: boolean;
  closeExternal?: boolean;
  defValue?: any;
  hideFoot?: boolean;
}

const Modal = ({
  props: {
    size = '6xl',
    anchor = 'center',
    iconChild,
    title,
    closeOutSide,
    onSure,
    children,
    canceltext = t('Cancel'),
    suretext = t('Save'),
    disabled = false,
    hideXIcon = false,
    hideHeaderAndFoot = false,
    noTran = false,
    hideCancel = false,
    hideSubmit = false,
    closeExternal = true,
    defValue = {},
    hideFoot = false
  }
}: {
  props: ModalProps;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(defValue);
  const [error, setError] = useState({});

  const closeModal = () => setIsOpen(false);

  function onSubmit() {
    onSure?.(closeModal, setLoading, values, setError);
  }

  if (closeOutSide) {
    closeOutSide(closeModal);
  }

  const getSize = () => {
    switch (size) {
      case 'lg':
        return 'sm:max-w-lg';
      case 'xl':
        return 'sm:max-w-xl';
      case '2xl':
        return 'sm:max-w-2xl';
      case '4xl':
        return 'sm:max-w-4xl';
      default:
        return 'sm:max-w-6xl';
    }
  };

  const getAnchor = () => {
    switch (anchor) {
      case 'top':
        return 'items-start';
      case 'bottom':
        return 'items-end';
      default:
        return 'items-center';
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1111] text-"
        onClose={() => {
          if (!closeExternal || loading) return;
          closeModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto bg-cardblack bg-opacity-20">
          <div
            className={classNames(
              'flex min-h-full justify-center p-4 text-center sm:p-0',
              getAnchor()
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative transform rounded-lg px-4 pt-5 pb-4 text-left transition-all sm:my-8 w-full sm:p-6',
                  getSize()
                )}
              >
                {!hideHeaderAndFoot && (
                  <div className="flex rounded-tr-md rounded-tl-md w-full bg-modaltop justify-between px-4 py-5 sm:p-6 items-center">
                    {(iconChild && <div>{iconChild}</div>) || ''}
                    <span
                      className="text-2xl font-normal text-gray-700"
                      style={{
                        fontWeight: 500,
                        textTransform: (!noTran && 'capitalize') || 'none'
                      }}
                    >
                      {title}
                    </span>
                    {!hideXIcon && (
                      <XIcon
                        className="h-7 w-7 text-gray-400 cursor-pointer"
                        aria-hidden="true"
                        onClick={() => {
                          if (loading) return;
                          closeModal();
                        }}
                      />
                    )}
                  </div>
                )}
                <div
                  className={classNames(
                    'bg-whitenew px-4 sm:px-6',
                    (hideHeaderAndFoot && 'rounded-lg p-6') || 'py-4',
                    hideFoot && 'rounded-b-lg'
                  )}
                >
                  {children?.(closeModal, setLoading, values, setValues, error)}
                </div>
                {!hideHeaderAndFoot && (
                  <div
                    className={classNames(
                      'flex items-center justify-end px-4 py-5 sm:p-6 bg-whitenew rounded-br-md rounded-bl-md',
                      hideFoot && 'hidden'
                    )}
                  >
                    {(!hideCancel && (
                      <FreeButton
                        disable={disabled || loading}
                        type="white"
                        text={canceltext}
                        onClick={() => closeModal()}
                      />
                    )) ||
                      ''}
                    {(!hideSubmit && (
                      <FreeButton
                        className="mr-0"
                        disable={disabled || loading}
                        text={suretext}
                        onClick={() => onSubmit()}
                      />
                    )) ||
                      ''}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

/**
 * @param size 弹框大小
 * @param anchor 弹框位置
 * @param iconChild 加入图标
 * @param noTran 标题字母非大写
 * @param title 弹框标题
 * @param closeOutSide 弹框在外部关闭
 * @param onSure 弹框确认事件回调
 * @param children 弹框内容
 * @param canceltext 取消按钮自定义文本
 * @param suretext 确认按钮自定义文本
 * @param disabled 不可操作状态
 * @param hideXIcon 隐藏右上角关闭弹框Icon
 * @param hideHeaderAndFoot 隐藏弹框头部和底部内容
 * @param hideCancel 隐藏取消按钮
 * @param hideSubmit 隐藏确认按钮
 * @param closeExternal 点击弹框外关闭
 * @returns
 */

export function openDialog(props: ModalProps) {
  const el = document.createElement('div');
  const root = document.getElementById('root');
  ReactDOM.render(<Modal props={props} />, el);
  return root?.appendChild(el);
}

interface OpenAlertDialogProps extends ModalProps {
  title?: string;
  contant?: string;
  type?: 'success' | 'error' | 'warning';
  leftBtn?: {
    label?: string;
    onClick?: (close: any) => void;
  };
  subBtn?: {
    label?: string;
    onClick?: (close: any) => void;
  };
}

export function openAlertDialog(props: OpenAlertDialogProps) {
  const getIcon = () => {
    switch (props?.type) {
      case 'success':
        return (
          <div className="flex items-center justify-center w-12 h-12 bg-green-100 rounded-full">
            <CheckIcon className="text-green-600 w-5 h-5" />
          </div>
        );
      case 'error':
        return (
          <div className="flex items-center justify-center w-12 h-12 bg-red-100 rounded-full">
            <AlertTriangleIcon className="text-red-600 w-5 h-5" />
          </div>
        );
      case 'warning':
        return (
          <div className="flex items-center justify-center w-12 h-12 bg-orange-100 rounded-full">
            <InfoIcon className="text-orange-600 w-5 h-5" />
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-center w-12 h-12 bg-cyan-100 rounded-full">
            <InfoIcon className="text-cyan-600 w-5 h-5" />
          </div>
        );
    }
  };
  return openDialog({
    ...props,
    size: props?.size || 'lg',
    hideHeaderAndFoot: true,
    closeExternal: true,
    children: (close) => {
      return (
        <div className="flex flex-col justify-center items-center">
          {getIcon()}
          <p className="mt-5 text-lg font-medium">{props?.title}</p>
          <p className="mt-2 text-sm font-normal font-inter text-gray-500 text-center">
            {props?.contant}
          </p>
          <div className="flex w-full">
            {props?.leftBtn && (
              <FreeButton
                type="white"
                className="mt-6 w-full justify-center"
                text={props?.leftBtn?.label}
                onClick={() => props?.leftBtn?.onClick?.(close)}
              />
            )}
            {props?.subBtn && (
              <FreeButton
                className="mt-6 w-full justify-center"
                text={props?.subBtn?.label}
                onClick={() => props?.subBtn?.onClick?.(close)}
              />
            )}
          </div>
        </div>
      );
    }
  });
}

interface OpenCalendarDialogProps extends ModalProps {
  value?: any;
  onChange?: (date: any, close: any) => void;
}

// 打开日期选择弹框
export function openCalendarDialog(props: OpenCalendarDialogProps) {
  return openDialog({
    ...props,
    hideHeaderAndFoot: true,
    closeExternal: true,
    children: (close) => {
      return (
        <FreeCalendar
          className="shadow-none"
          defSelDate={props?.value}
          onChange={(date) => props?.onChange?.(date, close)}
        />
      );
    }
  });
}

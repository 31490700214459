import React, { useState } from 'react';
import { t } from '@/i18n';
import FreeField from '../Components/FreeField';
import * as Request from '@/axios/request';
import {
  stateOptions,
  validationEmail,
  validationPhone,
  validationStrisNull
} from '@/utils/fn';
import FreeButton from '../Components/FreeButton';
import FreeLoading from '../Components/FreeLoading';
import { useHistory } from 'react-router-dom';
import FreeSelect from '../Components/FreeSelect';
import { CheckIcon } from '@/assets/featherIcons';
import moment from 'moment';
import getPublicImg from '@/utils/getPublicImg';

const Logo = getPublicImg('AssetBozzIcon.png');

export default function SignupPage() {
  const history = useHistory();
  const [newState, setNewState] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    phoneCC: '',
    phone: '',
    legalName: ''
  });
  const [emptyError, setEmptyError] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [checked, setChecked] = useState<any>(false);
  const [success, setSuccess] = useState<any>(false);
  const handleSubmit = async () => {
    if (loading) return;
    const arr: any = [];
    if (!validationStrisNull(newState?.legalName)) {
      arr.push({
        field: 'legalName',
        value: t('An entry is required. Please try again.')
      });
    }
    if (!validationStrisNull(newState?.firstName)) {
      arr.push({
        field: 'firstName',
        value: t('An entry is required. Please try again.')
      });
    }
    if (!validationStrisNull(newState?.lastName)) {
      arr.push({
        field: 'lastName',
        value: t('An entry is required. Please try again.')
      });
    }
    if (
      !validationEmail(newState?.email) ||
      !validationStrisNull(newState?.email)
    ) {
      arr.push({
        field: 'email',
        value: t('Please enter a valid email address.')
      });
    }
    if (
      (newState?.phoneCC || newState?.phone) &&
      !validationPhone(newState?.phoneCC, newState?.phone)
    ) {
      arr.push({
        field: 'phone',
        value: t('The mobile phone number format is incorrect')
      });
    }
    setEmptyError(arr);
    if (arr?.length > 0) return;
    setLoading(true);
    const obj: any = {
      firstName: newState?.firstName,
      lastName: newState?.lastName,
      email: newState?.email,
      legalName: newState?.legalName,
      type: 'signup-free'
    };
    if (newState?.phoneCC) {
      obj['phoneCC'] = newState?.phoneCC.split('+')[1];
    }
    if (newState?.phone) {
      obj['phone'] = newState?.phone;
    }
    const res: any = await Request.authSignUp(obj);
    console.log(res);

    if (res?.result === 0) {
      setSuccess(true);
    } else {
      if (res?.data?.message === 'the_email_has_exists') {
        arr.push({
          field: 'email',
          value: t('This email already exists in our system. Please sign in.')
        });
        setEmptyError(arr);
      }
      if (res?.data?.message === 'the_phone_has_exists') {
        arr.push({
          field: 'phone',
          value: t('This phone already exists in our system. Please sign in.')
        });
        setEmptyError(arr);
      }
    }
    setLoading(false);
  };
  // const [countdown, setCountdown] = useState(3);
  // useEffect(() => {
  //   if (success) {
  //     timeChange = setInterval(() => setCountdown((t) => --t), 1000);
  //     if (countdown === 0) {
  //       clearInterval(timeChange);
  //       history.push('/login');
  //     }
  //   }
  // }, [countdown, history, success]);
  const [width, setWidth] = useState(window.innerWidth);
  window.onresize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="flex">
      <div
        className="relative flex items-center justify-center overflow-hidden lmD:hidden lmD_1100:hidden"
        style={{ flex: 7, height: window.innerHeight }}
      >
        <img
          src={getPublicImg('loginImage.png')}
          className="w-full h-full object-cover object-center"
        />
        <div className="absolute top-[20%]">
          <div className="flex flex-col justify-center items-center">
            <div className="flex items-center">
              <img className="w-20 h-20" src={Logo} />
              <div className="text-3xl font-semibold text-gray-500">
                AssetBozz
              </div>
            </div>
            <div
              className="text-center text-4xl font-semibold pb-[20px]"
              style={{ textTransform: 'capitalize' }}
            >
              {t('Digitise your space today!')}
            </div>
            <div className="py-4 text-lg">{t('Want to know more?')}</div>
            <a
              href="mailto:support@assetbozz.com"
              className="bg-whitenew py-3 px-6 rounded text-sm font-medium shadow-sm"
            >
              {t('Contact us now')}
            </a>
          </div>
        </div>
      </div>
      <div
        className="flex justify-center items-center bg-whitenew overflow-hidden sm_400D:overflow-auto"
        style={{
          flex: 5,
          height: (width > 400 && window.innerHeight) || 'auto'
        }}
      >
        {loading && <FreeLoading />}
        {(success && (
          <div className="w-full p-20 flex justify-center items-center flex-col lmD:px-8">
            <div className="flex justify-center items-center w-[50px] h-[50px] bg-cyan-100bg rounded-full">
              <CheckIcon
                className="h-10 w-10 text-servicecolor"
                aria-hidden="true"
              />
            </div>
            <div className="text-servicecolor font-bold text-4xl text-center my-[20px]">
              {t('Thank you for choosing AssetBozz!')}
            </div>
            <div className="text-2xl text-center font-medium">
              {t(
                'We’ve sent you a verification email. Please verify your account before starting your free trial. '
              )}
            </div>
            <div className="py-5 w-full">
              <FreeButton
                text={t('Sign in')}
                style={{
                  fontSize: '16px',
                  margin: 0
                }}
                type="freeStyle2"
                disabled={loading}
                onClick={() => {
                  history.push('/login');
                  localStorage.clear();
                }}
              />
            </div>
          </div>
        )) || (
          <div className="w-full p-20 lmD:px-10">
            <div
              className="text-center text-4xl font-semibold pb-[20px]"
              style={{ textTransform: 'capitalize' }}
            >
              {t('Try AssetBozz For Free')}
            </div>
            <div className="text-center text-xl font-normal pb-[40px] text-cyan-600">
              {t('Free trial for 30 days. No credit card. Cancel anytime.')}
            </div>
            <div className="py-3 flex justify-between">
              <div style={{ flex: 0.48 }}>
                <FreeField
                  value={newState.firstName}
                  label={t('First Name')}
                  inputStyle={{ height: '50px' }}
                  required
                  onChange={(val: any) => {
                    setEmptyError(
                      emptyError?.filter(
                        (item: any) => item?.field !== 'firstName'
                      )
                    );
                    setNewState({
                      ...newState,
                      firstName: val?.target?.value
                    });
                  }}
                  errorText={
                    emptyError.find((item: any) => item?.field === 'firstName')
                      ?.value
                  }
                  disabled={loading}
                />
              </div>
              <div style={{ flex: 0.48 }}>
                <FreeField
                  value={newState.lastName}
                  label={t('Surname')}
                  inputStyle={{ height: '50px' }}
                  required
                  onChange={(val: any) => {
                    setEmptyError(
                      emptyError?.filter(
                        (item: any) => item?.field !== 'lastName'
                      )
                    );
                    setNewState({
                      ...newState,
                      lastName: val?.target?.value
                    });
                  }}
                  errorText={
                    emptyError.find((item: any) => item?.field === 'lastName')
                      ?.value
                  }
                  disabled={loading}
                />
              </div>
            </div>
            <div className="py-3">
              <FreeField
                value={newState.legalName}
                label={t('Company Name')}
                inputStyle={{ height: '50px' }}
                required
                onChange={(val: any) => {
                  setEmptyError(
                    emptyError?.filter(
                      (item: any) => item?.field !== 'legalName'
                    )
                  );
                  setNewState({
                    ...newState,
                    legalName: val?.target?.value
                  });
                }}
                errorText={
                  emptyError.find((item: any) => item?.field === 'legalName')
                    ?.value
                }
                disabled={loading}
              />
            </div>
            <div className="py-3">
              <FreeField
                value={newState.email}
                label={t('Email')}
                inputStyle={{ height: '50px' }}
                required
                onChange={(val: any) => {
                  setEmptyError(
                    emptyError?.filter((item: any) => item?.field !== 'email')
                  );
                  setNewState({
                    ...newState,
                    email: val?.target?.value
                  });
                }}
                errorText={
                  emptyError.find((item: any) => item?.field === 'email')?.value
                }
                disabled={loading}
              />
            </div>
            <div className="py-3">
              <FreeField
                inputStyle={{ height: '50px' }}
                leftIcon={
                  <div className="w-[300px] h-[50px]">
                    <FreeSelect
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          height: '50px'
                        })
                      }}
                      group
                      value={stateOptions?.find(
                        (item: any) => item.value === newState?.phoneCC
                      )}
                      options={stateOptions}
                      onChange={(id: any, val: any) => {
                        setEmptyError(
                          emptyError?.filter(
                            (item: any) => item?.field !== 'phone'
                          )
                        );
                        setNewState({
                          ...newState,
                          phoneCC: val.value
                        });
                      }}
                      disabled={loading}
                    />
                  </div>
                }
                value={newState?.phone}
                label={t('Phone number')}
                id="phone"
                name="phone"
                onChange={(e: any) => {
                  setEmptyError(
                    emptyError?.filter((item: any) => item?.field !== 'phone')
                  );
                  setNewState({
                    ...newState,
                    phone: e.target.value
                  });
                }}
                errorText={
                  emptyError.find((item: any) => item?.field === 'phone')?.value
                }
                disabled={loading || !newState?.phoneCC}
              />
            </div>
            <div className="py-3 flex justify-between items-center font-medium">
              <div className="flex items-center cursor-pointer">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  defaultChecked={checked}
                  onClick={(e: any) => {
                    if (loading) return;
                    setChecked(e?.target?.checked);
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-cyan-600 focus:ring-cyan-500"
                />
                <p
                  style={{
                    color: '#111827',
                    fontWeight: 400,
                    lineHeight: '20px',
                    fontSize: '14px',
                    paddingLeft: '0.5rem'
                  }}
                >
                  {t('I’ve read and agreed to the ')}
                  <span
                    className="text-cyan-600 cursor-pointer"
                    onClick={() =>
                      window.open('https://www.assetbozz.com/terms-of-use')
                    }
                  >
                    {t('terms of use')}
                  </span>
                  , and{' '}
                  <span
                    className="text-cyan-600 cursor-pointer"
                    onClick={() =>
                      window.open('https://www.assetbozz.com/privacy-policy')
                    }
                  >
                    {t('privacy policies')}
                  </span>
                </p>
              </div>
            </div>
            <div className="py-5">
              <FreeButton
                text={t('Sign up now')}
                type="freeStyle1"
                onClick={handleSubmit}
                disabled={loading || !checked}
                style={{ fontSize: '16px', margin: 0 }}
                alert={
                  (!checked &&
                    t('Please read and accept before proceeding.')) ||
                  ''
                }
              />
            </div>

            <div className="py-5 flex justify-between font-normal items-center text-sm text-cardgray">
              <div style={{ width: '30%', border: '0.5px solid #D1D5DB' }} />
              <span style={{ textAlign: 'center' }}>
                {t('Already have account?')}
              </span>
              <div style={{ width: '30%', border: '0.5px solid #D1D5DB' }} />
            </div>
            <div className="py-5">
              <FreeButton
                text={t('Sign in')}
                style={{
                  fontSize: '16px',
                  margin: 0
                }}
                type="freeStyle2"
                disabled={loading}
                onClick={() => {
                  history.push('/login');
                  localStorage.clear();
                }}
              />
            </div>
            <div className="py-2 text-center">
              &copy;&nbsp;2019-
              {moment().get('year')}&nbsp;
              {t('AssetBozz Limited. All rights reserved.')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

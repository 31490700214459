/* eslint-disable max-len */
import React, { Fragment } from 'react';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { t } from '@/i18n';
import {
  CalendarIcon,
  LogOutIcon,
  MaximizeIcon,
  ChevronLeftIcon
} from '@/assets/featherIcons';
import { Menu, Transition } from '@headlessui/react';
import { SearchToMap } from '@/utils/url';
import { useHistory } from 'react-router-dom';
import FreeQrCode from '@/views/Components/FreeQrCode';

export default function HBMobileAppBar(props: any) {
  const { onLogOut } = props;
  const history = useHistory();
  const pathname = window.location.pathname;
  const { mShowType } = SearchToMap(window?.location.search);

  return (
    <div className="w-full flex fixed justify-between bg-whitenew overflow-hidden z-50 pl-0 border-b left-0">
      {(((pathname === '/v1/bookings' && mShowType === 'today') ||
        pathname === '/v1/mobileNewBooking') && <div />) || (
        <div
          style={{
            fontFamily: 'Poppins, Helvetica, sans-serif'
          }}
          className="flex pl-5 items-center text-[1.5rem] font-medium text-gray-600"
          onClick={() => {
            history.push('/v1/bookings?mShowType=today');
          }}
        >
          <ChevronLeftIcon className="h-8 w-8 rounded-full" />
        </div>
      )}
      <div className="p-4 pb-3 flex items-center">
        <Menu as="div" className="relative inline-block text-left ml-4">
          <Menu.Button className="flex rounded-full bg-white text-sm">
            <DehazeIcon className="bg-white rounded-full h-8 w-8" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="fixed right-2 z-50 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-cyan-600 text-whitenew">
              <FreeQrCode
                child={
                  <div
                    className="flex items-center px-5 py-4 cursor-pointer  hover:bg-cyan-600 hover:bg-opacity-75 hover:text-whitenew"
                    style={{ width: '100%', height: 'auto' }}
                  >
                    <MaximizeIcon className="mr-4 w-5 h-5" />
                    <span>{t('Scan QR Code')}</span>
                  </div>
                }
                type="booking"
              />

              <div
                className="flex items-center px-5 py-4 cursor-pointer  hover:bg-cyan-600 hover:bg-opacity-75 hover:text-whitenew"
                style={{ width: '100%', height: 'auto' }}
                onClick={() => {
                  window.location.href = '/v1/bookings?mShowType=all';
                }}
              >
                <CalendarIcon className="mr-4 w-5 h-5" />
                <span>{t('Bookings')}</span>
              </div>
              <div
                className="flex items-center px-5 py-4 cursor-pointer  hover:bg-cyan-600 hover:bg-opacity-75 hover:text-whitenew"
                style={{ width: '100%', height: 'auto' }}
                onClick={onLogOut}
              >
                <LogOutIcon className="mr-4 w-5 h-5" />
                <span>{t('Log Out')}</span>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

/* eslint-disable max-len */
import React, { Fragment, useState } from 'react';
import { t } from '@/i18n';
import {
  useMediaQuery,
  useTheme,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
  List,
  Box
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import {
  SpacesIcon,
  CalendarIcon,
  UsersIcon,
  LogOutIcon,
  SettingIcon,
  DashboardIcon
} from '@/assets/featherIcons';
import { classNames, clearWinVariable, setWinVariable } from '@/utils/fn';
import { useLocation } from 'react-router-dom';

export default function HBDrawerList(props: any) {
  // 通过判断当前路由来切换左侧导航栏的视图
  const { pathname } = useLocation();
  const history = useHistory();

  const theme = useTheme();
  const { open, setOpen, envname, onLogOut } = props;

  const matches = useMediaQuery(theme.breakpoints.down(780));

  const drawListItemArrayFree = [
    {
      path: '/dashboard',
      name: t('Dashboard'),
      icon: <DashboardIcon className="w-6 h-" />,
      isSelect: pathname.indexOf('/dashboard') > -1
    },
    {
      path: '/spaces',
      name: t('Spaces'),
      icon: <SpacesIcon className="w-6 h-6" />,
      isSelect: pathname.indexOf('/space') > -1
    },
    {
      path: '/bookings',
      name: t('Bookings'),
      icon: <CalendarIcon className="w-6 h-6" />,
      isSelect: pathname.indexOf('/booking') > -1
    },
    {
      path: '/clients',
      name: t('Clients'),
      icon: <UsersIcon className="w-6 h-6" />,
      isSelect: pathname.indexOf('/client') > -1
    },
    {
      path: '/settings',
      name: t('Settings'),
      icon: <SettingIcon className="w-6 h-6" />,
      isSelect: ['/setting', '/user'].find((i) => pathname.indexOf(i) > -1)
    },
    {
      path: '/logout',
      name: t('Log Out'),
      icon: <LogOutIcon className="w-6 h-6" />
    }
  ];

  return (
    <Fragment>
      {drawListItemArrayFree.map((item: any) => {
        return (
          (item?.children?.length && (
            <CollListItem
              key={item?.path}
              {...item}
              open={open}
              matches={matches}
              setOpen={setOpen}
              onLogOut={onLogOut}
              envname={envname}
              history={history}
            />
          )) || (
            <ListItem
              key={item.path}
              {...item}
              matches={matches}
              setOpen={setOpen}
              onLogOut={onLogOut}
              envname={envname}
              history={history}
            />
          )
        );
      })}
    </Fragment>
  );
}

const CollListItem = (props: any) => {
  const [view, setView] = useState(false);

  const handleClick = () => {
    setView(!view);
  };
  return (
    <Fragment>
      <ListItemButton
        sx={{
          margin: '0 8px',
          borderRadius: '40px',
          display: 'flex',
          justifyContent: 'start',
          height: '48px'
        }}
        onClick={handleClick}
      >
        <div
          style={{
            minWidth: '56px'
          }}
        >
          <i
            className={props.icon}
            style={{
              marginRight: '15px',
              paddingLeft: '3px',
              fontSize: '1.3rem',
              color: '#86a3ff'
            }}
          />
        </div>
        <ListItemText sx={{ whiteSpace: 'nowrap', width: '150px' }}>
          {props.name}
          <Box
            component="span"
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              visibility: (props?.open && 'visible') || 'hidden'
            }}
          >
            {view ? <ExpandLess /> : <ExpandMore />}
          </Box>
        </ListItemText>
      </ListItemButton>
      <Collapse
        sx={{ paddingLeft: '15px' }}
        in={view && props?.open}
        timeout="auto"
        unmountOnExit
      >
        <List
          style={{
            height: '100%',
            overflow: 'hidden',
            overflowY: (view && 'auto') || 'hidden'
          }}
        >
          {props?.children.map((child: any) => (
            <ListItem
              key={child.path}
              {...child}
              matches={props?.matches}
              setOpen={props?.setOpen}
              onLogOut={props?.onLogOut}
              envname={props?.envname}
              history={props?.history}
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};

const ListItem = (props: any) => {
  return (
    <div
      className={classNames(
        'rounded-[40px] mx-[8px]',
        (props.isSelect && 'bg-cyan-700 text-whitenew') || '',
        (props.path === '/logout' && 'bottom-0') || '',
        (props.path === '/logout' && !props?.matches && 'absolute') ||
          'relative'
      )}
      style={{
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: 'Poppins, Helvetica, sans-serif'
      }}
      onClick={() => {
        setWinVariable('memberType', null);
        // 清除window变量
        if (props?.path !== '/spaces') {
          clearWinVariable('ASSECT_SPACE_ACTIVED');
        }
        if (props?.matches) {
          props?.setOpen(false);
        }
        if (props.path === '/logout') {
          props?.onLogOut();
        } else {
          localStorage.setItem('path', props.path);
          props?.history?.push('/v1' + props.path);
        }
      }}
    >
      <div
        data-te-ripple-init
        data-te-ripple-color="light"
        className={classNames(
          'flex justify-start h-[54px] m-0 rounded-[40px] cursor-pointer border-0 items-center py-[8px] px-[16px] text-sm font-normal hover:text-whitenew leading-tight text-neutral-700 transition duration-150 ease-in-out focus:bg-neutral-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-neutral-400 active:shadow-lg'
        )}
      >
        {(props?.type === 1 && (
          <ListItemIcon style={{ color: '#06b6d4', minWidth: '54px' }}>
            {props.icon}
          </ListItemIcon>
        )) || (
          <div
            className="pl-[1px]"
            style={{
              minWidth: '54px',
              display: 'flex'
            }}
          >
            {props.icon}
          </div>
        )}
        <div className="w-[150px] whitespace-nowrap my-[4px] min-w-0 flex-auto font-hbz">
          {props.name}
        </div>
      </div>
    </div>
  );
};


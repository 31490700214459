import { transition } from '@/assets/jss/material-dashboard-react.js';

const appStyle = (theme) => ({
  wrapper: {
    height: 'calc(100% - 60px)',
    color: '#646C9A',
    flex: '1 0 auto'
    // paddingBottom: '25px'
  },
  mainPanel: {
    ...transition,
    position: 'relative',
    display: 'flex',
    // height: '100vh',
    // padding: '30px',
    [theme.breakpoints.down(780)]: {
      padding: 0
    }
  },
  menu: {
    // marginTop: '65px',
    zIndex: 1101,
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    transition: 'all 0.3s ease 0s',
    borderRadius: '4px',
    position: 'fixed',
    overflow: 'hidden',
    top: '0px',
    bottom: '0px',
    // left: '30px',
    maxWidth: '100%',
    backgroundColor: '#0891b2',
    boxShadow: '0px 0px 28px 0px rgb(82 63 105 / 13%)',
    boxSizing: 'border-box',
    '& div > div:first-of-type': {
      // paddingLeft: '3px',
      minWidth: '37px'
    },
    '& .MuiListItemButton-root:hover': {
      color: '#fff !important',
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root,& i': {
        color: '#fff !important',
        backgroundColor: 'transparent'
      }
    },
    '& > nav > div > div > .css-10hburv-MuiTypography-root': {
      fontWeight: 500
    },
    '& .MuiTypography-root': {
      fontFamily: 'Poppins, Helvetica, sans-serif'
    },
    [theme.breakpoints.down(780)]: {
      display: 'none'
    }
  },
  content: {
    flex: '1 auto',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '95px',
    transition: 'all 0.3s ease',
    minWidth: 0,
    maxWidth: '100%',
    boxSizing: 'border-box'
    // paddingBottom: '10px'
  },
  contentappbar: {
    boxShadow: 'none',
    // zIndex: 99,
    // position: 'fixed',
    // top: '0px',
    // paddingTop: '30px',
    // width: '100%',
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    // padding: '30px 10px 0 0',
    // // position: 'fixed',
    // // top: 0,
    // // zIndex: 98,
    background: '#efeff5',
    '& h1': {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontWeight: 500,
      fontSize: '1.5rem',
      color: '#595d6e',
      marginLeft: 0,
      [theme.breakpoints.down(780)]: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '0px'
      }
    },
    '& > div': {
      paddingRight: '10px',
      [theme.breakpoints.down(780)]: {
        padding: 0,
        minHeight: '50px'
      }
    },
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    [theme.breakpoints.down(780)]: {
      padding: 0,
      background: '#ffffff',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    }
    // '& > div': {
    //   marginRight: '10px'
    // }
    // '&:hover': {
    //   boxShadow:
    //     '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    // }
    // cursor: 'pointer'
  },
  container: {
    borderRadius: '5px',
    // marginTop: '70px',
    marginTop: '55px'
  },
  containerIframe: {
    borderRadius: '5px'
  },
  contentfoot: {
    color: '#595d6e',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '13px',
    background: '#efeff5',
    paddingBottom: '30px',
    [theme.breakpoints.down(780)]: {
      textAlign: 'center'
    },
    '& > div:first-of-type': {
      marginLeft: '10px'
    },
    '& > div:last-child': {
      marginRight: '10px'
    }
    // '& > div:last-child > div:last-child:hover': {
    //   boxShadow:
    //     '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    // }
  },
  map: {
    marginTop: '70px'
  },
  footmenua: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      color: '#595d6e',
      textDecoration: 'none',
      marginLeft: '16.25px',
      // color: '#595d6e',
      cursor: 'pointer'
    },
    '& > span:hover': {
      color: '#0891b2'
    },
    '& > span:first-of-type': {
      marginLeft: '0'
    }
  },
  headPic: {
    position: 'absolute',
    // zIndex: 1000,
    width: '260px',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  noproviderimg: {
    width: '260px',
    height: '65px',
    borderRadius: '6px 6px 0 0'
  },
  providerimg: {
    width: '260px',
    height: '65px',
    borderRadius: '6px 6px 0 0',
    background: '#6c6e7e'
  },
  fullname: {
    color: '#fff',
    fontSize: '15.6px',
    position: 'absolute',
    zIndex: 3,
    left: '80px',
    top: '40px'
  },
  headPicContent: {
    position: 'relative',
    width: '260px',
    background: '#fff',
    marginTop: '-5px',
    padding: '30px 0px 0.5rem 0',
    borderRadius: '0 0 10px 10px',
    '&>img': {
      position: 'absolute',
      top: '-25px',
      left: '20px'
    }
  },
  headPicMenu: {
    borderBottom: '1px solid #ebedf2'
  },
  headPicMenuItems: {
    width: '220px',
    height: '30px',
    padding: '7px 20px',
    display: 'flex',
    alignItems: 'center',

    '& p': {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#595d6e'
    },
    '&:hover': {
      background: '#595d6e',
      color: '#5578eb'
    }
  },
  headPicFoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.25rem 1.75rem',
    alignItems: 'center'
  },
  headPicFootButton: {
    background: '#5578eb',
    color: '#5578eb',
    padding: '5px 12px',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontFamily: 'Poppins, Helvetica, sans-serif',
    '&:hover': {
      background: '#5578eb',
      color: '#fff'
    }
  },
  avatarImg: {
    cursor: 'pointer',
    background: '#94A8F8',
    width: '40px',
    height: '40px',
    borderRadius: '100%'
  },
  openDraw: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default appStyle;


/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import qs from 'qs';
import * as CacheKey from './cacheKey';
import _ from 'lodash';
import { getToken } from './refreshtoken';
import { formatString, JsonParse } from '@/utils/fn';
import toast from 'react-hot-toast';
import progress from './Progress';

// 请求超时(json)
const JsonRequestTimeout: number = 60 * 1000;
// 请求超时(formdata)
const FormdataRequestTimeout: number = 60 * 1000;
// 请求方法
export enum METHODS_ENUM {
  GET,
  POST,
  PUT,
  DELETE
}
// 请求类型
export enum TYPES_ENUM {
  json,
  formdata
}

export interface RESPONSE_TYPES {
  result: number;
  data: any;
  message: string;
}

const instance = axios.create({});

instance.defaults.withCredentials = true;

const str = window.location.host;
const envname = str.substring(0, str.indexOf('.')).toUpperCase(); // 环境

let reqNum = 0;
// 请求拦截
instance.interceptors.request.use(
  async (config: any) => {
    console.log('请求拦截', config);
    reqNum++;
    if (reqNum === 1) progress.start();
    // 登录接口和刷新token接口绕过
    if (
      [
        '/refreshToken',
        '/login',
        '/signin',
        '/isMobileNumberTaken',
        '/forgotPasswordRequest',
        '/forgotPasswordVerify',
        '/resetPassword',
        '/requestOTP',
        '/reset-password',
        '/signup-free',
        '/user-temp-auth',
        '/logout'
      ].find((i) => config.url.indexOf(i) >= 0)
    ) {
      return config;
    }
    const tokenObj: any = await getToken();
    if (tokenObj?.access_token) {
      config.headers = {
        ...config.headers,
        Authorization: config.headers.Authorization
          ? config.headers.Authorization
          : 'Bearer ' + tokenObj.access_token
      };
    }
    return config;
  },
  (error) => {
    console.log('请求拦截2', error);

    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use((response: any) => {
  console.log('响应拦截', response);
  reqNum--;
  if (reqNum <= 0) progress.done();
  const return_code = _.get(response, 'data.return_code', -1);
  if ([401, 403].includes(response?.status)) {
    const wind: any = window;
    wind.top.location.href = (envname && '/') || '/login';
    localStorage.clear();
  }
  if (return_code !== 0) {
    if (_.get(response, 'config.url').indexOf('/saveNotificationToken') < 0) {
      // Toast.toast(_.get(response, 'data.error_message', ''));
    }
  }
  if (!response?.config?.url?.includes('/auth')) {
    // 在响应拦截判断是否有user相关信息 没有的话在返回到登录页
    if (!localStorage.getItem('user')) {
      // 定时器的作用是保证在刚刚登陆时 异步保存localStorage获取localStorage冲突的问题
      const timer = setTimeout(() => {
        if (!localStorage.getItem('user')) {
          const wind: any = window;
          wind.top.location.href = '/login';
          localStorage.clear();
        } else {
          clearTimeout(timer);
        }
      }, 500);
    }
  }
  return response;
});

/**
 * get参数格式化
 * @param url
 * @param params
 */
const paramsFormat = (url: string, params: any) => {
  let str = '?';
  if (typeof params === 'string') {
    return `${encodeURI(url)}?${params}`;
  }
  if (url.includes('?')) {
    str = '&';
  }
  let queryParams = `${str}subfix=${Date.now()}`;
  Object.keys(params).forEach((item) => {
    if (item && String(params[item])) {
      queryParams += `&${item}=${encodeURIComponent(params[item])}`;
    }
  });
  return encodeURI(url) + queryParams;
};

/**
 * 数据请求
 * @param url
 * @param method
 * @param params
 * @param type
 * @param delete_type
 * @param uploadS3
 * @param uploadS3headers
 * @param _onUploadProgress
 * @returns
 */
export default async function (
  url = '',
  method: string = METHODS_ENUM[1],
  params: any,
  type?: string,
  delete_type?: boolean,
  uploadS3?: boolean,
  uploadS3headers?: {},
  _onUploadProgress?: (n: any) => void
) {
  if (!type) {
    type = TYPES_ENUM[0];
  }
  // //获取当前网络状态
  // let netInfo: any = await NetInfo.fetch();
  // //无网络
  // if (!netInfo.isConnected) {
  //   return Promise.resolve({result: -1, message: 'network_error'});
  // }
  if (!url) {
    throw new TypeError('URL is null');
  }
  let userData: any = {};
  if (localStorage.getItem('user')) {
    userData = JsonParse(localStorage.getItem('user') || '');
  } else {
    userData.language = 'en';
  }
  const AmpleURL = `${url}`; // 添加APP信息到Url
  const config: any = {
    method,
    timeout:
      type === TYPES_ENUM[0] ? JsonRequestTimeout : FormdataRequestTimeout
  };
  if (AmpleURL.includes('endpoints') || AmpleURL.includes('server')) {
    config.url =
      method === METHODS_ENUM[0] ? paramsFormat(AmpleURL, params) : AmpleURL;
    config.data = type === TYPES_ENUM[0] ? qs.stringify(params) : params;
    config.headers = {
      // 表单方式提交
      'Content-Type':
        type === TYPES_ENUM[0]
          ? 'application/x-www-form-urlencoded; charset=UTF-8'
          : 'multipart/form-data'
    };
    if (method === METHODS_ENUM[0]) {
      config.url = paramsFormat(AmpleURL, params);
    }
  } else {
    const USERID: any = await localStorage.getItem(CacheKey.USERID);
    if (
      method === METHODS_ENUM[0] ||
      (delete_type && method === METHODS_ENUM[3])
    ) {
      config.url = paramsFormat(AmpleURL, params);
    } else {
      config.url = AmpleURL;
      config.data = params;
    }
    config.headers = {
      // json方式提交
      'Content-Type':
        type === TYPES_ENUM[0] ? 'application/json' : 'multipart/form-data',
      'user-id': USERID || ''
    };
    if (uploadS3) {
      config.headers = {};
      config.headers = {
        ...uploadS3headers
      };
    }
  }

  return instance(config)
    .then((response: any) => {
      const { data } = response;
      const result: number = _.get(data, 'return_code', 0);
      switch (result) {
        case 0:
          return Promise.resolve({
            result,
            data,
            message: ''
          });
        case 1:
          return Promise.resolve({
            result,
            message: _.get(data, 'error_message', '') || ''
          });
        case 9:
          return Promise.resolve({
            result,
            data: _.get(data, 'data', {}) || {},
            message: _.get(data, 'error_message', '') || ''
          });
        default:
          return Promise.resolve({
            result,
            message: _.get(data, 'error_message', '') || ''
          });
      }
    })
    .catch((error: any) => {
      // 处理已知请求的可预见报错 / 白屏问题
      const pathArr = ['country-info'];
      const exclude = pathArr.find((i) =>
        error?.response?.data?.path?.includes(instance)
      );
      if (exclude) {
        return Promise.reject(error);
      }
      if ([401, 403].includes(error?.response?.status)) {
        window.location.href = (envname && '/') || '/login';
        localStorage.clear();
      } else {
        console.error('---Request Error---', error.response);
        const msg =
          (error?.response?.data?.message &&
            formatString(error?.response?.data?.message)) ||
          error?.response?.statusText ||
          'Request Error';
        reqNum--;
        if (reqNum <= 0) progress.done();

        if (
          msg !== 'Description length too long' &&
          msg !== 'Reference id already exists' &&
          msg !== 'Property name already exists' &&
          msg !== 'Property has active tenancies' &&
          msg !== 'Property has future bookings' &&
          msg !== 'Has exist email' &&
          msg !== 'Has exist phone' &&
          msg !== 'The email has exists' &&
          msg !== 'Space not enough'
        ) {
          toast.error(msg, {
            duration: 3000,
            position: 'bottom-left'
          });
        }
      }
      return Promise.resolve({
        result: -1,
        message: 'Timeout',
        data: error?.response?.data || error
      });
    });
}


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ChevronLeftIcon, ChevronRightIcon } from '@/assets/featherIcons';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

function compStartWeekDayIndex(curYear: any, curMonth: any) {
  /* 周六为6，周日为0 */
  return new Date(
    `${curYear}-${curMonth < 10 ? '0' + curMonth : curMonth}-01`
  ).getDay();
}

interface FreeCalendarDateProps {
  className?: string;
  defSelDate?: any;
  format?: string;
  onChange?: (date: any) => void;
}

/**
 * @param className // 设置自定义样式
 * @param defSelDate // 设置默认所选日期 默认为今天
 * @param format // 日期展示格式
 * @param onChange // 选中日期回调
 * @returns
 */

const FreeCalendar = ({
  className,
  defSelDate = moment().format('YYYY-MM-DD'),
  format = 'yyyy-MM-DD',
  onChange
}: FreeCalendarDateProps) => {
  const defaultCurYear = Number(moment().format('YYYY')); // 获取默认日期 - 年
  const defaultCurMonth = Number(moment().format('MM')); // 获取默认日期 - 月
  const defaultCurDay = Number(moment().format('DD')); // 获取默认日期 - 日

  const [selDate, setSelDate] = useState(defSelDate); // 所选日期
  const curDate = moment().format('YYYY-MM-DD'); // 今天

  const [curYear, setCurYear] = useState(
    Number(moment(new Date()).format('YYYY'))
  );
  const [curMonth, setCurMonth] = useState(Number(moment().format('MM')));
  const [curDay, setCurDay] = useState<any>(Number(moment().format('DD')));

  /* 获取当月天数 */
  const [daysInMon, setDaysInMonth] = useState<any>(moment().daysInMonth());
  /* 获取当月第一天是星期几 */
  const [startWeekDay, setStartWeekDay] = useState(
    compStartWeekDayIndex(curYear, curMonth)
  ); /* moment().startOf('month').weekday() */

  /* 获取上个月月份 */
  // const [lastMonth, setLastMonth] = useState(
  //   moment().subtract(1, 'month').format('MM')
  // );
  /* 获取上个月最后一天几号 */
  const [lastMonthDay, setLastMonthDay] = useState(
    moment().subtract(1, 'month').endOf('month').format('DD')
  );

  // 展示区域日期
  const [calendarList, setCalenderList] = useState<
    Array<{
      value: number; // 日期 day
      day?: string; // 日期
      isGray?: boolean; // 是否为本月日期 day
      isToday?: boolean; // 是否是今天
    }>
  >([]);

  const firstUpdate = useRef(true);

  // 统一回调函数格式
  const callBackDate = (date: string) => {
    onChange?.(moment(date).format(format));
  };

  useEffect(() => {
    if (daysInMon) {
      setCalenderList(() => {
        const copy = new Array(daysInMon).fill('');

        for (let i = 0; i < copy.length; i++) {
          copy[i] = {
            value: i + 1,
            day: `${curYear}${curMonth}${i + 1}`,
            isToday:
              moment().format('YYYYMD') === `${curYear}${curMonth}${i + 1}`
          };
        }
        copy.splice(0, 0, ...new Array(startWeekDay).fill(''));
        /* 一共42个格子 */
        const last = 42 - copy.length;
        if (copy.length !== 42 || copy[0] === '') {
          if (startWeekDay !== 0) {
            /* 塞入上个月的号 */

            for (let i = startWeekDay - 1; i >= 0; i--) {
              const dayTime = `${curYear}${curMonth - 1 || 12}${
                Number(lastMonthDay) - (startWeekDay - i - 1)
              }`;
              copy[i] = {
                value: Number(lastMonthDay) - (startWeekDay - i - 1),
                day: dayTime,
                isGray: true,
                isToday: moment().format('YYYYMD') === dayTime
              };
            }
          }
          /* 塞入下个月的号 */
          for (let i = 0; i < last; i++) {
            const dayTime =
              curMonth === 12
                ? `${curYear + 1}${1}${i + 1}`
                : `${curYear}${curMonth + 1}${i + 1}`;
            copy.push({
              value: i + 1,
              day: dayTime,
              isGray: true,
              isToday: moment().format('YYYYMD') === dayTime
            });
          }
        }
        return copy;
      });
    } else {
      setDaysInMonth((e: any) => {
        let copy = e;
        const m = curMonth < 10 ? `0${curMonth}` : curMonth;
        copy = moment(new Date(`${curYear}-${m}`)).daysInMonth();
        return copy;
      });
    }
  }, [daysInMon, curMonth, startWeekDay]);

  useEffect(() => {
    const month = (curMonth < 10 && `0${curMonth}`) || curMonth;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else {
      /* 每次改变计算当前选择年月的日，先设置null防止值一样不触发 */
      setCurDay(null);
      setStartWeekDay(compStartWeekDayIndex(curYear, curMonth));
      if (curMonth === 1) {
        setLastMonthDay(
          moment(new Date(`${curYear - 1}-${month}`))
            .subtract(1, 'days')
            .endOf('month')
            .format('DD')
        );
      } else if (
        `${defaultCurYear}-${defaultCurMonth}` === `${curYear}-${month}`
      ) {
        setLastMonthDay(
          moment(new Date(`${curYear}-${month}`))
            .subtract(1, 'days')
            .endOf('month')
            .format('DD')
        );
      } else {
        setLastMonthDay(
          moment(new Date(`${curYear}-${month}`))
            .subtract(1, 'days')
            .endOf('month')
            .format('DD')
        );
      }
    }
  }, [curMonth]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else {
      /* 判断防止副作用依赖值一样不触发 */
      if (!curDay) {
        /* 计算是否是当年当月当日赋予当天的样式 */
        if (
          `${defaultCurYear}-${defaultCurMonth}` === `${curYear}-${curMonth}`
        ) {
          setCurDay(defaultCurDay);
        } else {
          setCurDay(
            moment(new Date(`${curYear}-${curMonth}`), 'YYYY-MM').daysInMonth()
          );
        }
        setDaysInMonth(null);
      }
    }
  }, [curDay]);

  function isCurDay(i: any) {
    if (
      curDate ===
        moment(new Date(`${curYear}-${curMonth}-${curDay}`)).format(
          'YYYY-MM-DD'
        ) &&
      defaultCurDay === calendarList[i].value &&
      !calendarList[i].isGray
    ) {
      return true;
    } else {
      return false;
    }
  }

  // 上月视图 - day: 在视图点击非本月日期时切换视图
  function prev(day?: any) {
    if (curMonth === 1) {
      setCurYear(() => {
        let copy = curYear;
        copy -= 1;
        if (day) {
          setSelDate(() => {
            callBackDate(`${copy}-12-${day}`);
            return `${copy}-12-${day}`;
          });
        }
        return copy;
      });
      setCurMonth(12);
    } else {
      setCurMonth(() => {
        let copy = curMonth;
        copy -= 1;
        if (day) {
          setSelDate(() => {
            const m = copy < 10 ? `0${copy}` : copy;
            const d = day < 10 ? `0${day}` : day;
            callBackDate(`${curYear}-${m}-${d}`);
            return `${curYear}-${m}-${d}`;
          });
        }
        return copy;
      });
    }
  }
  // 下月视图 - day: 在视图点击非本月日期时切换视图
  function next(day?: any) {
    if (curMonth === 12) {
      setCurYear(() => {
        let copy = curYear;
        copy += 1;
        if (day) {
          setSelDate(() => {
            callBackDate(`${copy}-01-${day}`);
            return `${copy}-01-${day}`;
          });
        }
        return copy;
      });
      setCurMonth(1);
    } else {
      setCurMonth(() => {
        let copy = curMonth;
        copy += 1;
        if (day) {
          setSelDate(() => {
            const m = copy < 10 ? `0${copy}` : copy;
            const d = day < 10 ? `0${day}` : day;
            callBackDate(`${curYear}-${m}-${d}`);
            return `${curYear}-${m}-${d}`;
          });
        }
        return copy;
      });
    }
  }

  return (
    <div className={classNames('bg-whitenew rounded-md shadow', className)}>
      <div className="flex items-center px-4 py-4">
        <h2 className="flex-auto text-lg font-medium text-gray-700">
          {moment(selDate).format(format)}
        </h2>
        <button
          type="button"
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => prev()}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-7 w-7" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => next()}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-7 w-7" aria-hidden="true" />
        </button>
      </div>

      <div className="grid grid-cols-7 text-center text-sm leading-6 text-gray-500 font-medium">
        <div className="text-center h-10 leading-10">Mon</div>
        <div className="text-center h-10 leading-10">Tue</div>
        <div className="text-center h-10 leading-10">Wed</div>
        <div className="text-center h-10 leading-10">Thu</div>
        <div className="text-center h-10 leading-10">Fri</div>
        <div className="text-center h-10 leading-10">Sat</div>
        <div className="text-center h-10 leading-10">Sun</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm">
        {calendarList.map((item: any, index: any) => {
          // 获取当前项是否为 本月 的 所选日期
          const isSelect = moment(selDate).format('YYYYMD') === item?.day;
          return (
            <div
              key={index}
              className={classNames(
                'flex text-center h-14 leading-10 py-4 items-center',
                // isCurDay(index) ? '' : '',
                index > 6 && 'border-t border-gray-200'
              )}
            >
              <button
                type="button"
                onClick={() => {
                  const m = (curMonth < 10 && `0${curMonth}`) || curMonth;
                  const d = (item.value < 10 && `0${item.value}`) || item.value;
                  const date = curYear + '-' + m + '-' + d; // 得到所选项对应的 年 - 月 - 日
                  // 判断是否为本月的日期 day
                  if (item.isGray) {
                    // 简单用索引判断 因为常规视图不会空多超过15天
                    if (index < 15) {
                      // 非本月日期 day
                      // 切换日历视图到上个月 再设置所选日期值
                      prev(item.value);
                    } else {
                      // 非本月日期 day
                      // 切换日历视图到下个月 再设置所选日期值
                      next(item.value);
                    }
                  } else {
                    // 是本月如期 day 直接设置选定日期
                    setSelDate(() => {
                      callBackDate(date);
                      return date;
                    });
                  }
                }}
                className={classNames(
                  'mx-auto flex h-10 w-10 items-center justify-center rounded-full font-medium cursor-pointer hover:bg-gray-300',
                  // 非本月日期样式处理
                  item.isGray
                    ? (item?.isToday && 'text-cyan-600') ||
                        'text-gray-300 hover:text-gray-500'
                    : '',
                  isSelect ? 'bg-cyan-600 text-whitenew' : '',
                  isCurDay(index) ? 'text-cyan-600' : ''
                )}
                style={{
                  backgroundColor: (isSelect && '#0891b2') || ''
                }}
              >
                {item.value}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FreeCalendar;

import { takeLatest, call, put, select } from 'redux-saga/effects';
import { Actions, allReducers, storeState } from '@/store/reducers';
import * as request from '@/axios/request';
// import _ from 'lodash';
import { RESPONSE_TYPES } from '@/axios/axios';
import { filterName, setLocalStorage, JsonParse } from '@/utils/fn';
import toast from 'react-hot-toast';
import { t } from '@/i18n';

export function* watchSettingsSaga() {
  yield takeLatest(
    Actions.GET_MANAGER_SETTING_DETAILS,
    getManagerSettingDetails
  );
  yield takeLatest(Actions.GETGENERAL_INFO, getGeneralInfo);
  yield takeLatest(Actions.GETSPACETYPE, getSpaceType);
  yield takeLatest(Actions.GETCUSTOMLABEL, getCustomLabel);
  yield takeLatest(Actions.GETCUSTOMLABELCATE, getCustomLabelcate);
  yield takeLatest(Actions.GETCUSTOMLABELSOURCE, getCustomLabelsource);
  yield takeLatest(Actions.EDITGENERAL, editGeneral);
  yield takeLatest(Actions.ADDSPACETYPE, addSpacetype);
  yield takeLatest(Actions.EDITDESPACE, editdeSpacetype);
  yield takeLatest(Actions.DELETESAPCE, removeDeSpace);
  yield takeLatest(Actions.ADDCUSTOMOTHER, addCustomother);
  yield takeLatest(Actions.MODIFYCUSTOMOTHER, modifyCustomother);
  yield takeLatest(Actions.DELETECUSTOMOTHER, deleteCustomother);
  yield takeLatest(Actions.GETCOMPAYINFO, getCompayInfo);
  yield takeLatest(Actions.GETADMINLIST, getAdminList);
  yield takeLatest(Actions.SETCOMPAYSELETE, setCompaySelete);
  yield takeLatest(Actions.EDITCOMPAYINFO, putCompayInfo);
  yield takeLatest(Actions.GETMYPROFILE, getMyprofile);
  yield takeLatest(Actions.GETXEROINFO, getXeroinfo);
  yield takeLatest(Actions.GETSTRIPTINFO, getStriptinfo);
  yield takeLatest(Actions.DISCONNECTXERO, disconnectXero);
  yield takeLatest(Actions.DISCONNECTSTRIPE, disconnectStripe);
  yield takeLatest(Actions.STRIPEACTIONS, stripeActions);
}

/**
 * 获取团队设置详情
 * @param props
 */
function* getManagerSettingDetails(props: any) {
  try {
    const { result, data } = yield call(
      request.getManagerSettingDetails,
      props.payload
    );
    if (result === 0) {
      const reslist = data.data;
      let reslist2: any = [];
      if (reslist.job_types) {
        const obj: any = [];
        yield Object.entries(reslist.job_types).forEach((v) => {
          obj.push({
            value: v[0],
            label: v[1]
          });
        });
        reslist2[`job_types`] = obj;
      }
      if (reslist.priority_labels) {
        const obj: any = [];
        yield Object.entries(reslist.priority_labels).forEach((v) => {
          obj.push({
            value: v[0],
            label: v[1]
          });
        });
        reslist2[`priority_labels`] = obj;
      }

      yield (reslist2 = { ...reslist, ...reslist2 });

      yield put({
        type: Actions.GET_MANAGER_SETTING_DETAILS_COMPLETE,
        payload: reslist2
      });
    }
  } catch (error) {}
}

function* getGeneralInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getGeneral, props.payload);
  console.log('获取generalinfo的接口详情', result, data);

  if (result === 0) {
    yield put({
      type: Actions.GETGENERAL_INFOSUCCESS,
      payload: {
        contactPhoneYup: data?.contactPhonecc + data?.contactPhone,
        ...data
      }
    });
    const obj: any = {};
    data?.planRoles?.forEach((i: any) => {
      obj[i?.roleName] = i?.limitation;
    });
    yield put({
      type: Actions.ROLES_SETTING,
      payload: {
        ...obj
      }
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getSpaceType(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getSpaceType, props.payload);
  console.log('获取spacetype接口', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETSPACETYPESUCCESS,
      payload: data?.data
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getCustomLabel(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getCustomLabel, props.payload);
  if (result === 0) {
    yield put({
      type: Actions.GETCUSTOMLABEL_SUCCESS,
      payload: { name: props.payload, value: data }
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getCustomLabelcate(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getCustomLabel, props.payload);
  if (result === 0) {
    yield put({
      type: Actions.GETCUSTOMLABEL_SUCCESS,
      payload: { name: props.payload, value: data }
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getCustomLabelsource(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getCustomLabel, props.payload);
  if (result === 0) {
    yield put({
      type: Actions.GETCUSTOMLABEL_SUCCESS,
      payload: { name: props.payload, value: data }
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* editGeneral(props: any) {
  const { files, coverFiles } = props.payload;
  if (files?.length > 0 || coverFiles?.length > 0) {
    const obj: any = {
      ...props.payload
    };
    if (files?.length > 0) {
      // const { result } = yield call(
      //   request.uploadToS3,
      //   _.get(props, 'payload.files[0].uploadS3Data')
      // );
      // if (result === 0) {
      obj.logo = {
        attachmentType: files[0]?.attachmentType,
        fileName: files[0]?.fileName,
        fileSize: files[0]?.fileSize,
        mineType: files[0]?.mineType,
        storageId: files[0]?.storageId,
        storageType: files[0]?.storageType,
        compression: 1
      };
      // }
    }

    if (coverFiles?.length > 0) {
      // const { result } = yield call(
      //   request.uploadToS3,
      //   _.get(props, 'payload.coverFiles[0].uploadS3Data')
      // );
      // if (result === 0) {
      obj.cover = {
        attachmentType: coverFiles[0]?.attachmentType,
        fileName: coverFiles[0]?.fileName,
        fileSize: coverFiles[0]?.fileSize,
        mineType: coverFiles[0]?.mineType,
        storageId: coverFiles[0]?.storageId,
        storageType: coverFiles[0]?.storageType,
        compression: 1
      };
      // }
    }

    const res: RESPONSE_TYPES = yield call(request.editGeneral, obj);
    console.log('先看看是不是上传s3111', res);
    if (res?.result === 0) {
      toast.success(t('General information updated successfully'), {
        duration: 3000,
        position: 'bottom-left'
      });
      yield put({
        type: Actions.GETGENERAL_INFO,
        payload: {}
      });
    }
  } else {
    const { result } = yield call(request.editGeneral, props.payload);
    if (result === 0) {
      const { generalModal, preferencesModal }: storeState['settingsState'] =
        yield select((store: storeState) => store.settingsState);
      if (generalModal) {
        toast.success(t('General information updated successfully'), {
          duration: 3000,
          position: 'bottom-left'
        });
      }
      if (preferencesModal) {
        toast.success(t('Preferences updated successfully'), {
          duration: 3000,
          position: 'bottom-left'
        });
      }
      yield put({
        type: Actions.GETGENERAL_INFO,
        payload: {}
      });
    }
  }
  const setEditLoading = props?.payload?.setEditLoading;
  setEditLoading?.(false);
  yield put({
    type: Actions.OPENSETTING_MODAL,
    payload: { name: 'Preferences', value: false }
  });
  yield put({
    type: Actions.OPENSETTING_MODAL,
    payload: { name: 'General', value: false }
  });
}

function* addSpacetype(props: any) {
  const { result } = yield call(request.addPropertyTypeJava, props.payload);
  if (result === 0) {
    yield put(allReducers._openSettingModal({ name: 'Custom', value: false }));
    toast.success(t('Label added successfully.'), {
      duration: 3000,
      position: 'bottom-left'
    });
    yield put({
      type: Actions.GETSPACETYPE
    });
    const setLoading = props?.payload?.setLoading;
    setLoading?.(false);
  }
}

function* editdeSpacetype(props: any) {
  const { result } = yield call(request.modifyDeSpace, props.payload);
  if (result === 0) {
    yield put(allReducers._openSettingModal({ name: 'Custom', value: false }));
    toast.success(t('Label updated successfully.'), {
      duration: 3000,
      position: 'bottom-left'
    });
    yield put({
      type: Actions.GETSPACETYPE
    });
    const setLoading = props?.payload?.setLoading;
    setLoading?.(false);
  }
}

function* removeDeSpace(props: any) {
  const { result } = yield call(request.removeDeSpace, props.payload);
  if (result === 0) {
    toast.success(t('Label removed successfully.'), {
      duration: 3000,
      position: 'bottom-left'
    });
    yield put({
      type: Actions.GETSPACETYPE
    });
    yield put({
      type: Actions.VISITORSDELETEMODAL,
      payload: false
    });
  }
}

function* addCustomother(props: any) {
  const { result } = yield call(request.addCustomother, props.payload);
  if (result === 0) {
    toast.success(t('Label added successfully.'), {
      duration: 3000,
      position: 'bottom-left'
    });
    yield put({
      type: Actions.GETCUSTOMLABEL,
      payload: 'booking_source'
    });
    yield put({
      type: Actions.GETCUSTOMLABELCATE,
      payload: 'client_category'
    });
    yield put({
      type: Actions.GETCUSTOMLABELSOURCE,
      payload: 'client_source'
    });
    yield put({
      type: Actions.OPENSETTING_MODAL,
      payload: { name: 'Custom', value: false }
    });
    const setLoading = props?.payload?.setLoading;
    setLoading?.(false);
  }
}

function* modifyCustomother(props: any) {
  const { result } = yield call(request.modifyCustomother, props.payload);
  if (result === 0) {
    toast.success(t('Label updated successfully.'), {
      duration: 3000,
      position: 'bottom-left'
    });
    yield put({
      type: Actions.GETCUSTOMLABEL,
      payload: 'booking_source'
    });
    yield put({
      type: Actions.GETCUSTOMLABELCATE,
      payload: 'client_category'
    });
    yield put({
      type: Actions.GETCUSTOMLABELSOURCE,
      payload: 'client_source'
    });
    yield put({
      type: Actions.OPENSETTING_MODAL,
      payload: { name: 'Custom', value: false }
    });
    const setLoading = props?.payload?.setLoading;
    setLoading?.(false);
  }
}

function* deleteCustomother(props: any) {
  const { result } = yield call(request.deleteCustomother, props.payload);
  if (result === 0) {
    toast.success(t('Label removed successfully.'), {
      duration: 3000,
      position: 'bottom-left'
    });
    yield put({
      type: Actions.GETCUSTOMLABEL,
      payload: 'booking_source'
    });
    yield put({
      type: Actions.GETCUSTOMLABELCATE,
      payload: 'client_category'
    });
    yield put({
      type: Actions.GETCUSTOMLABELSOURCE,
      payload: 'client_source'
    });
    yield put({
      type: Actions.OPENSETTING_MODAL,
      payload: { name: 'Custom', value: false }
    });
    yield put({
      type: Actions.VISITORSDELETEMODAL,
      payload: false
    });
  }
}

function* getCompayInfo(props: any) {
  console.log(props);
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getCompayInfo);
  console.log('获取公司信息', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETCOMPAYINFOSUCCESS,
      payload: data
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getAdminList(props: any) {
  console.log(props);
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getAdminList);
  console.log('获取user的列表', result, data);
  const arr: any = [];
  if (result === 0) {
    data?.data?.forEach((i: any) => {
      arr.push({
        value: i?.userId,
        label: filterName(i?.firstname, i?.middlename, i?.lastname)
      });
    });
    yield put({
      type: Actions.GETADMINLISTSUCCESS,
      payload: arr
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* setCompaySelete(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.setSeleopen, props.payload);
  console.log('开关公司的东西', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETCOMPAYINFO
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* putCompayInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.putCompayInfo, props.payload);
  console.log('修改公司的信息了', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETCOMPAYINFO
    });
  }
  const setEdit: any = props?.payload?.setEdit;
  setEdit?.(false);
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getMyprofile(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getMyprofile, props.payload);
  console.log('查询个人的信息', result, data);
  if (result === 0) {
    const obj = {
      ...JsonParse(localStorage.getItem('user') || ''),
      ...data
    };
    setLocalStorage('user', obj);
    yield put({
      type: Actions.GETMYPROFILESUCCESS,
      payload: data
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getXeroinfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getXeroStatusJava, props.payload);
  console.log('查询xerinfo的信息', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETXEROINFOSUCCESS,
      payload: data
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* getStriptinfo(props: any) {
  console.log(props);
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const { result, data } = yield call(request.getStripe_Java);
  console.log('查询stript的信息', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETSTRIPTINFOSUCCESS,
      payload: data?.connected
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* disconnectXero(props: any) {
  console.log(props);
  const { result, data } = yield call(request.delXeroStatusJava);
  console.log('断开连接的xero', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETXEROINFO,
      payload: {}
    });
    yield put({
      type: Actions.OPENXERODISMODAL,
      payload: false
    });
  }
}

function* disconnectStripe(props: any) {
  console.log(props);
  const { result, data } = yield call(request.unSetStripe_Java);
  console.log('断开联机的stripe', result, data);
  if (result === 0) {
    yield put({
      type: Actions.GETSTRIPTINFO
    });
    yield put({
      type: Actions.OPENSTRIPEMODAL,
      payload: false
    });
  }
}

function* stripeActions(props: any) {
  const { result, data } = yield call(
    request.getStripeConnect_Java,
    props.payload
  );
  console.log('这个是操作的', result, data);
  if (result === 0) {
    const windowInfo: any = window;
    yield put({
      type: Actions.GETSTRIPTINFO
    });
    windowInfo.top.location.href = data?.accountLinkURL;
  }
}


/*
 * @Date: 2020-06-16 14:27:00
 * @LastEditTime: 2022-03-07 21:29:20
 */

import { all, fork } from 'redux-saga/effects';
import { watchHomejobSaga } from './sagas/job';
import * as propertys from './sagas/property';
import * as roles from './sagas/roles';
import { watchHomeAssetListSaga } from './sagas/asset';
import { watchSettingsSaga } from './sagas/settings';
import { watchFinancialsSaga } from './sagas/financial';
import { watchHomeTenantSaga } from './sagas/tenant';
import { watchKnowledgeBaseSaga } from './sagas/knowledgebase';
import { watchNotificationSaga } from './sagas/notification';
import { watchSpaceSaga } from './sagas/Freemium/Space';
import { watchBookingSaga } from './sagas/Freemium/Booking';

export default function* root() {
  yield fork(watchHomejobSaga);
  yield fork(watchHomeAssetListSaga);
  yield fork(watchFinancialsSaga);
  yield fork(watchSettingsSaga);
  yield fork(watchHomeTenantSaga);
  yield fork(watchKnowledgeBaseSaga);
  yield fork(watchNotificationSaga);
  yield fork(watchSpaceSaga);
  yield fork(watchBookingSaga);

  yield all(
    Object.values({
      ...propertys,
      ...roles
    }).map((v) => fork(v))
  );
}


/* eslint-disable max-len */
import React from 'react';

import { FreeTooltip } from './FreeTooltip';

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};
/**
 * type         按钮类型  white:白底 | primary:常规 | secondary: 浅色背景 | scale:灰度 | bgErr:背景红色 | textErr: 文本红色
 * className    按钮样式
 * style        按钮样式
 * onClick      点击事件
 * text         文本
 * leftIcon     按钮前面的图标
 * rightIcon    按钮后面的图标
 *
 * @param props
 * @returns
 */
const FreeButton = (props: any) => {
  // focus-within:ring-2
  let className = `cursor-pointer inline-flex items-center rounded-md border px-4 py-3 mx-2 text-sm font-medium shadow-sm focus-within:outline-none focus:ring-cyan-500 focus:ring-offset-2 whitespace-nowrap ${props?.className} `;
  switch (props?.type) {
    case 'white':
      className +=
        'border-gray-300 bg-whitenew text-gray-700 shadow-sm hover:bg-gray-50';
      break;
    case 'secondary':
      className +=
        'border-transparent bg-cyan-100 text-cyan-700 hover:bg-cyan-200';
      break;
    case 'scale':
      className +=
        'border-transparent bg-slate-600 text-whitenew hover:bg-slate-700';
      break;
    case 'bgErr':
      className +=
        'border-transparent bg-red-600 text-whitenew hover:bg-red-700';
      break;
    case 'textErr':
      className +=
        'border-red-300 bg-whitenew text-red-700 shadow-sm hover:bg-gray-50';
      break;
    case 'freeStyle1':
      className +=
        'w-full flex justify-center h-[50px] border-transparent bg-cyan-600 text-whitenew shadow-sm hover:bg-cyan-700';
      break;
    case 'freeStyle2':
      className +=
        'w-full flex justify-center h-[50px] border-gray-300 bg-whitenew text-gray-700 shadow-sm hover:bg-gray-50';
      break;
    default:
      className +=
        'border-transparent bg-cyan-600 text-whitenew shadow-sm hover:bg-cyan-700';
  }
  return (
    <FreeTooltip message={props?.alert}>
      <button
        type="button"
        className={classNames(
          className,
          props?.disabled && 'opacity-50 hover:cursor-not-allowed'
        )}
        style={{
          ...props?.style,
          textTransform: 'capitalize'
        }}
        onClick={(e) => {
          if (props?.disabled) return;
          props?.onClick?.(e);
        }}
        // disabled={props?.disabled}
      >
        {props?.leftIcon || ''}
        {(props?.icon && (
          <div className="h-6 w-6 flex items-center justify-center">
            {props?.icon}
          </div>
        )) ||
          ''}
        {props?.text}
        {(props?.rightIcon && (
          <div className="ml-2 h-4 w-4 flex items-center">
            {props?.rightIcon}
          </div>
        )) ||
          ''}
      </button>
    </FreeTooltip>
  );
};
export default FreeButton;

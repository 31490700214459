import { takeLatest, call, put, select } from 'redux-saga/effects';
import { Actions, allReducers, storeState } from '@/store/reducers';
import * as request from '@/axios/request';
import _ from 'lodash';
import { RESPONSE_TYPES } from '@/axios/axios';
export function* watchHomePropertySaga() {
  yield takeLatest(Actions.GET_ALL_PROPERTY_LIST, getAllPropertyList);
  yield takeLatest(Actions.GET_ACTIVE_PROPERTY_LIST, getActivePropertyList);
}

/**
 * 获取 tenant list / tenancy list / jobinfo chat 中的 查询条件为property 列表
 */
function* getAllPropertyList(props: any) {
  const { allProperty, allPropertySize }: storeState['propertyState'] =
    yield select((store: storeState) => store.propertyState);
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allPropertyList',
      value: true
    })
  );
  try {
    const page = props.payload.page ?? 1;
    const tenancy_fill = props.payload.tenancy_fill ?? 0;
    const res: RESPONSE_TYPES = yield call(request.getPropertyListing, {
      ...props.payload,
      size: allPropertySize
    });
    if (res.result === 0) {
      const datas = _.get(res, 'data.data', []);
      const arr: any = datas.map((value: any) => {
        return {
          value: _.get(value, 'property_id', ''),
          label: _.get(value, 'property_name', ''),
          data: value
        };
      });
      const total = _.get(res, 'data.total', 0);
      yield put({
        type: 'GET_ALL_PROPERTY_LIST_COMPLETE',
        payload: {
          arr: allProperty.concat(arr),
          length: total
        }
      });
      if (total > page * allPropertySize) {
        yield put(
          allReducers._getAllPropertyList({
            page: page + 1,
            size: allPropertySize,
            fill: 0,
            tenancy_fill: tenancy_fill
          })
        );
      }
    }
  } catch (error) {
    console.log('error', error);
  }

  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allPropertyList',
      value: false
    })
  );
}
/** 获取tenancieslist 已有租约的propertylist */
function* getActivePropertyList(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getTenanciesEntitiesActive_Java,
      props.payload
    );
    if (res.result === 0) {
      const datas = _.get(res, 'data.property', []);
      yield put({
        type: 'GET_ACTIVE_PROPERTY_LIST_COMPLETE',
        payload: {
          arr: datas,
          length: datas.length
        }
      });
    }
  } catch (error) {
    console.log('error', error);
  }
}


/*
 * @Date: 2020-06-22 15:21:46
 * @LastEditTime: 2022-03-09 23:13:02
 */

import { nanoid } from 'nanoid';
import { BaseAction } from '../';
export const action_common = {
  BACKDROP_STATUS: Symbol('backdrop_status'), //
  FULLSCREENBACKDROP_STATUS: Symbol('fullScreenBackdrop_status'), //
  FULLSCREENBACKDROP_DATA: Symbol('fullScreenBackdrop_data'), //
  ENQUEUE_SNACKBAR: Symbol('ENQUEUE_SNACKBAR'),
  CLOSE_SNACKBAR: Symbol('CLOSE_SNACKBAR'),
  REMOVE_SNACKBAR: Symbol('REMOVE_SNACKBAR'),
  HISTORY_STORE: Symbol('HISTORY_STORE'), // 路由
  SET_CURRENTLOCALE: Symbol('SET_CURRENTLOCALE'), // 存储语言
  SET_BAR_BUTTON: Symbol('set_bar_button'), // 设置工具栏按钮
  GLOBAL_PROMPT: Symbol('GLOBAL_PROMPT'), // 设置工具栏按钮

  GET_REPORT: Symbol('GET_REPORT'),
  SET_VALIDATION_BOX: Symbol('SET_VALIDATION_BOX'), // set_validation_Box
  LANGUAGE_STATUS: Symbol('LANGUAGE_STATUS')
};

export const _ChangeLanguageStatus: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_common.LANGUAGE_STATUS,
  payload
});

export const _BackdropStatus: (payload: any) => BaseAction = (payload) => ({
  type: action_common.BACKDROP_STATUS,
  payload
});
/**
 * 集中处理一些请求状态或者是弹框状态
 * 传参是自定义的字符串
 * @param payload
 * @returns
 */
export const _FullScreenBackdropStatus: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_common.FULLSCREENBACKDROP_STATUS,
  payload
});
export const _FullScreenBackdropData: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_common.FULLSCREENBACKDROP_DATA,
  payload
});

export const _enqueueSnackbar = (notification: any) => {
  const key = notification.options?.key;

  return {
    type: action_common.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || nanoid()
    }
  };
};

export const _closeSnackbar = (key: any) => ({
  type: action_common.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const _removeSnackbar = (key: any) => ({
  type: action_common.REMOVE_SNACKBAR,
  key
});

export const _HistoryStore: (payload: any) => BaseAction = (payload) => ({
  type: action_common.HISTORY_STORE,
  payload
});
export const _SetCurrentLocale: (payload: any) => BaseAction = (payload) => ({
  type: action_common.SET_CURRENTLOCALE,
  payload
});
export const _SetBarButton: (payload: any) => BaseAction = (payload) => ({
  type: action_common.SET_BAR_BUTTON,
  payload
});
export const _GlobalPromptStatus: (payload: any) => BaseAction = (payload) => ({
  type: action_common.GLOBAL_PROMPT,
  payload
});
export const _Get_Report: (payload: any) => BaseAction = (payload) => ({
  type: action_common.GET_REPORT,
  payload
});
export const _SetValidationBox: (payload: any) => BaseAction = (payload) => ({
  type: action_common.SET_VALIDATION_BOX,
  payload
});
export const commonReducers = {
  _BackdropStatus,
  _removeSnackbar,
  _closeSnackbar,
  _enqueueSnackbar,
  _HistoryStore,
  _SetCurrentLocale,
  _SetBarButton,
  _FullScreenBackdropStatus,
  _FullScreenBackdropData,
  _GlobalPromptStatus,
  _Get_Report,
  _SetValidationBox,
  _ChangeLanguageStatus
};

const initialState = {
  open: false,
  fullScreenOpen: false,
  fullScreenPhotos: [],
  notifications: [],
  fullScreenStatus: {
    getJobList: false,
    getJobInfo: false,
    getJobTypeList: false,
    getMyTeamUserList: false,
    newjob: false,
    editjob: false,
    viewPhoto: false,
    allProviderList: false,
    allPropertyList: false,
    allAssetList: false,
    getExpenseListing: false,
    newExpense: false,
    newFinancials: false,
    editExpense: false,
    editFinancial: false,
    newAssignments: false,
    addCheckLists: false,
    viewProvidersms: false,
    viewProvideremail: false,
    viewProviderwhatsApp: false,
    jobCheckin: false,
    declinedJob: false,
    globalValidationBox: false,
    FinancialAccounts: false,
    newTenant: false,
    newTenantView: false,
    editTenantView: false,
    editTenant: false,
    editTenantUser: false,
    getTenantList: false,
    getTenancyList: false,
    newUserToAssetBozz: false,
    newTenancy: false,
    editTenancy: false,

    getAttachment: false,
    getShareAttachment: false,
    filetip: false,
    newFileOrLink: false,
    newFolder: false,
    movetoFolder: false,
    shareFiles: false,
    editFileOrLink: false,
    editFolder: false,
    removedFiles: false,
    overMaxLength: false,
    showSuccessInfo: false,
    editTenancyFinancial: false,

    getNotification: false, // 获取announcement数据加载
    addNewAnnouncements: false, // 新增announcement弹框
    resendAnnouncements: false, // 重发announcement弹框
    newnotificationtip: false, // 新增announcement加载
    getNotificationUser: false, // 获取发送user加载

    addFileOfTest: false,
    noFile: false,
    getTenantInfo: false, // 获取tenantinfo信息
    newTenancyModal: false,
    renewTenancyModal: false,
    editTenancyModal: false,

    getFinancialType: false, // 获取financialtype数据（rev,exp）

    updateJobStatusLoading: false, // 更新job status的加载
    getSpaceInfoLoading: false // 获取spaceInfo的加载
  },
  validationBox: {
    title: '',
    body: ''
  },
  historyStore: {},
  currentLocale: '', // 当前的语言环境
  barButtonStatus: {
    home: false,
    refresh: false
  },
  Prompt: { Text: '', Status: false },
  language: ''
};
interface Iprops {
  open: boolean;
  fullScreenOpen: boolean;
  fullScreenPhotos: any;
  fullScreenStatus: Readonly<typeof initialState.fullScreenStatus>;
  validationBox: {
    title: string;
    body: string;
    confirmButton?: Element;
    cancelButton?: Element;
  };
  notifications: any;
  historyStore: any;
  currentLocale: string;
  barButtonStatus: {
    home: boolean;
    refresh: boolean;
  };
  Prompt: {
    Text: string;
    Status: boolean;
  };
  language: string;
}

export type commonState = Readonly<typeof initialState>;

export default (state: Iprops = initialState, action: any) => {
  switch (action.type) {
    case action_common.BACKDROP_STATUS:
      return {
        ...state,
        open: action.payload
      };
    case action_common.FULLSCREENBACKDROP_STATUS: {
      const fullScreenStatusObj: any = state.fullScreenStatus;
      if (!action.payload.all) {
        fullScreenStatusObj[action.payload.key] = action.payload.value;
        // if (action.payload.value && action.payload.isShow) {
        //   document.documentElement.scrollTop = document.body.scrollTop = 0;
        // }
        if (action.payload.value && action.payload.isShow) {
          window.parent.scrollTo(0, 0);
        }
      } else {
        Object.keys(fullScreenStatusObj).forEach((v: string) => {
          if (v === action.payload.key) {
            fullScreenStatusObj[v] = action.payload.value;
          } else {
            fullScreenStatusObj[v] = false;
          }
        });
      }
      return {
        ...state,
        fullScreenStatus: fullScreenStatusObj
      };
    }
    case action_common.FULLSCREENBACKDROP_DATA:
      return {
        ...state,
        fullScreenPhotos: action.payload
      };
    case action_common.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };
    case action_common.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notification: any) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };
    case action_common.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification: any) => notification.key !== action.key
        )
      };
    case action_common.HISTORY_STORE:
      return {
        ...state,
        historyStore: action.payload
      };
    case action_common.SET_CURRENTLOCALE:
      return {
        ...state,
        currentLocale: action.payload
      };
    case action_common.SET_BAR_BUTTON:
      return {
        ...state,
        barButtonStatus: action.payload
      };
    case action_common.GLOBAL_PROMPT:
      return {
        ...state,
        Prompt: {
          Text: action.payload.Text,
          Status: action.payload.Status
        }
      };
    case action_common.SET_VALIDATION_BOX:
      return {
        ...state,
        validationBox: {
          ...action.payload
        }
      };
    case action_common.LANGUAGE_STATUS:
      return {
        ...state,
        language: action.payload
      };
    default:
      return state;
  }
};


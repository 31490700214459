import { Component } from 'react';

// 错误边界
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidMount() {
    console.log('...loading');
  }
  componentDidCatch(error, errorInfo) {
    console.log('lazy_error', error, errorInfo);
    if (errorInfo || error) {
      location.reload();
    }
  }

  render() {
    return this.props.children;
  }
}

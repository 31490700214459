import React from 'react';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import makeStyles from '@mui/styles/makeStyles';
import styles from '@/assets/jss/material-dashboard-react/layouts/adminStyle.js';
import { linkToLogin } from '@/utils/url';
import Drawer from './drawer';
import Foot from './foot';
import HBAppBar from './HBAppBar';
import { useMediaQuery, useTheme } from '@mui/material';
import Container from './container';
import { isMobile } from '@/utils/fn';
import HBMobileAppBar from './HBMobileAppBar';

const theme: any = styles;
const useStyles = makeStyles(theme);

export default function NewAdmin() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(780));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false); // 侧边栏菜单
  const [openD, setOpenD] = React.useState(false); // 侧边栏菜单 顶部点击按钮
  const str = window.location.host;
  const envname = str.substring(0, str.indexOf('.')).toUpperCase(); // 环境
  const version = "v3.5.0";

  const onLogOut = async () => {
    try {
      linkToLogin('/login');
    } catch (error) {}
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel}>
        <Drawer
          open={open}
          setOpen={setOpen}
          envname={envname}
          openD={openD}
          setOpenD={setOpenD}
          onLogOut={onLogOut}
        />
        <div
          className={classes.content}
          style={{
            paddingLeft: (matches && '0px') || (openD && '240px') || '70px',
            paddingBottom: (matches && '0px') || '30px'
          }}
        >
          {(!isMobile() && (
            <HBAppBar envname={envname} onLogOut={onLogOut} openD={openD} />
          )) || <HBMobileAppBar onLogOut={onLogOut} />}
          <Container />
          <Foot version={version} open={open} openD={openD} />
        </div>
      </div>
    </div>
  );
}


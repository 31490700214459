/* eslint-disable no-duplicate-case */
import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const Actions_Booking = {
  // space info列表相关
  GETBOOKING_LIST: 'GETBOOKING_LIST',
  GETBOOKING_LISTSUCCESS: 'GETBOOKING_LISTSUCCESS',

  GETBOOKINGS_INFO: 'GETBOOKINGS_INFO',
  VOID_BOOKING_INFO: 'VOID_BOOKING_INFO',
  GETBOOKINGS_INFOSUCCESS: 'GETBOOKINGS_INFOSUCCESS',

  CHECKMODAL: 'CHECKMODAL',
  EDITBOOKINGMODAL: 'EDITBOOKINGMODAL',
  VISITORSMODAL: 'VISITORSMODAL',
  VISITORSDELETEMODAL: 'VISITORSDELETEMODAL',
  CLIENTMODAL: 'CLIENTMODAL',
  OVERTIMEMODAL: 'OVERTIMEMODAL',
  CANCELMODAL: 'CANCELMODAL',
  SHOWBOOKINGINFO: 'SHOWBOOKINGINFO',

  GETBOOKINGJOB: 'GETBOOKINGJOB',
  GETBOOKINGJOBSUCCESS: 'GETBOOKINGJOBSUCCESS',

  PUTVISITORS: 'PUTVISITORS',
  PUTCLIENT: 'PUTCLIENT',

  CHECKSTATUS: 'CHECKSTATUS',

  EDITBOOKINGINFO: 'EDITBOOKINGINFO',
  EDITBOOKINGINFOSUCCESS: 'EDITBOOKINGINFOSUCCESS',

  GETFULLRIGHTLIST: 'GETFULLRIGHTLIST',
  GETFULLRIGHTLISTSUCCESS: 'GETFULLRIGHTLISTSUCCESS'
};

/**
 * Actions Creators
 */

export const _getBookingList = createAction(Actions_Booking.GETBOOKING_LIST);

export const _checkModal = createAction(Actions_Booking.CHECKMODAL);
export const _openEditBookModal = createAction(
  Actions_Booking.EDITBOOKINGMODAL
);

export const _getBookingsInfo = createAction(Actions_Booking.GETBOOKINGS_INFO);
export const _voidBookingInfo = createAction(Actions_Booking.VOID_BOOKING_INFO);

export const _getBookingJob = createAction(Actions_Booking.GETBOOKINGJOB);
export const _putVisitors = createAction(Actions_Booking.PUTVISITORS);
export const _openVisitorsModal = createAction(Actions_Booking.VISITORSMODAL);
export const _openVisitorsDeleteModal = createAction(
  Actions_Booking.VISITORSDELETEMODAL
);
export const _openCancelModal = createAction(Actions_Booking.CANCELMODAL);
export const _openOvertimeModal = createAction(Actions_Booking.OVERTIMEMODAL);

export const _putClient = createAction(Actions_Booking.PUTCLIENT);
export const _openClientModal = createAction(Actions_Booking.CLIENTMODAL);

export const _checkBookingStatus = createAction(Actions_Booking.CHECKSTATUS);

export const _editBookingInfo = createAction(Actions_Booking.EDITBOOKINGINFO);

export const _getFullRightList = createAction(Actions_Booking.GETFULLRIGHTLIST);

export const _showBookingInfo = createAction(Actions_Booking.SHOWBOOKINGINFO);

export const bookingReducers = {
  _getBookingList,
  _checkModal,
  _openEditBookModal,
  _getBookingsInfo,
  _voidBookingInfo,
  _getBookingJob,
  _putVisitors,
  _openVisitorsModal,
  _openVisitorsDeleteModal,
  _checkBookingStatus,
  _openClientModal,
  _openCancelModal,
  _putClient,
  _openOvertimeModal,
  _editBookingInfo,
  _getFullRightList,
  _showBookingInfo
};
/**
 * Reducer
 */

const defaultSessionState = {
  bookingList: [],
  bookingInfo: {
    amount: '',
    status: 'new',
    clientName: '',
    payment: {},
    visitorJsonFields: [],
    overtimeChargeJsonFields: [],
    fromDatetime: '',
    toDatetime: new Date().getTime(),
    member: 0,
    memberId: '',
    timeUpdated: '',
    id: '',
    memberName: '',
    memberRemoved: 0,
    tenantId: '',
    tenantName: '',
    tenantRemoved: 0,
    currency: ''
  },
  checkModal: false,
  ediitBookingModal: false,
  visitorsModal: false,
  visitorsDeleteModal: false,
  cancelModal: false,
  clientModal: false,
  overtimeModal: false,
  loading: false,
  joblist: [],
  datarowRight: [],
  showBookingInfo: true
};
export type bookingState = Readonly<typeof defaultSessionState>;
export default handleActions(
  {
    [Actions_Booking.GETBOOKING_LIST]: (state) => {
      return {
        ...state
      };
    },
    [Actions_Booking.GETBOOKING_LISTSUCCESS]: (state, action: any) => {
      return {
        ...state,
        bookingList: action.payload
      };
    },
    [Actions_Booking.CHECKMODAL]: (state, action: any) => {
      return {
        ...state,
        checkModal: action.payload
      };
    },
    [Actions_Booking.EDITBOOKINGMODAL]: (state, action: any) => {
      return {
        ...state,
        ediitBookingModal: action.payload
      };
    },
    [Actions_Booking.GETBOOKINGS_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        bookingInfo: action.payload
      };
    },
    [Actions_Booking.GETBOOKINGS_INFO]: (state) => {
      return {
        ...state
      };
    },
    [Actions_Booking.GETBOOKINGJOBSUCCESS]: (state, action: any) => {
      return {
        ...state,
        joblist: action.payload.joblist,
        showBookingInfo: action.payload.showBookingInfo
      };
    },
    [Actions_Booking.VISITORSMODAL]: (state, action: any) => {
      return {
        ...state,
        visitorsModal: action.payload
      };
    },
    [Actions_Booking.VISITORSDELETEMODAL]: (state, action: any) => {
      return {
        ...state,
        visitorsDeleteModal: action.payload
      };
    },
    [Actions_Booking.CLIENTMODAL]: (state, action: any) => {
      return {
        ...state,
        clientModal: action.payload
      };
    },
    [Actions_Booking.OVERTIMEMODAL]: (state, action: any) => {
      return {
        ...state,
        overtimeModal: action.payload
      };
    },

    [Actions_Booking.CHECKSTATUS]: (state) => {
      return {
        ...state
      };
    },
    [Actions_Booking.CANCELMODAL]: (state, action: any) => {
      return {
        ...state,
        cancelModal: action.payload
      };
    },
    [Actions_Booking.GETFULLRIGHTLISTSUCCESS]: (state, action: any) => {
      return {
        ...state,
        datarowRight: action.payload
      };
    },
    [Actions_Booking.SHOWBOOKINGINFO]: (state, action: any) => {
      return {
        ...state,
        showBookingInfo: action.payload
      };
    }
  },
  defaultSessionState
);


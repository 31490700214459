/* eslint-disable max-len */
import React, { Fragment, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, List, Divider } from '@mui/material';
import DehazeIcon from '@mui/icons-material/Dehaze';
// import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import styles from '@/assets/jss/material-dashboard-react/layouts/adminStyle.js';
import { t } from '@/i18n';
import { useSelector } from 'react-redux';
import { storeState } from '@/store/reducers';
import { renderImageurl_s3 } from '@/utils/imageUrl';
import { filterName, JsonParse, setLocalStorage } from '@/utils/fn';

import LanguageMenu from './LanguageMenu';
import HBDrawerList from './HBDrawerList';
import { UserOwnerIcon } from '@/assets/featherIcons';
import { Menu, Transition } from '@headlessui/react';
import FreeButton from '@/views/Components/FreeButton';
import getPublicImg from '@/utils/getPublicImg';
import FreeQrCode from '@/views/Components/FreeQrCode';
import { useSize } from 'ahooks';

const Logo = getPublicImg('AssetBozzIcon.png');

const theme: any = styles;
const useStyles = makeStyles(theme);

export default function HBAppBar(props: any) {
  const classes = useStyles();
  const { width } = useSize(document.getElementById('free-web-drawer'));
  const [openDraw, setOpenDraw] = useState(false);
  const { envname, onLogOut } = props;

  const userData: any = JsonParse(localStorage.getItem('user') || '');
  const { myprofile }: storeState['settingsState'] = useSelector(
    (store: storeState) => store.settingsState
  );

  return (
    <div
      style={{
        left: width,
        width: `calc(100% - ${width}px)`
      }}
      className="w-full flex fixed justify-between bg-whitenew overflow-hidden z-50 pl-0 border-b left-0"
    >
      <div
        style={{
          fontFamily: 'Poppins, Helvetica, sans-serif'
        }}
        className="flex pl-5 items-center text-[1.5rem] font-medium text-gray-600"
      >
        <div
          className="block md:hidden pr-5 cursor-pointer"
          onClick={() => {
            setOpenDraw(true);
          }}
        >
          <DehazeIcon />
        </div>
        <Drawer
          className="md:hidden"
          anchor="left"
          open={openDraw}
          onClose={() => {
            setOpenDraw(false);
          }}
          sx={{
            '& > .MuiDrawer-paperAnchorLeft': {
              background: '#0891b2',
              color: '#ffffff'
            }
          }}
        >
          <List
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: '0px'
            }}
            className={classes.openDraw}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                paddingLeft: '24px',
                paddingBottom: '10px'
              }}
            >
              <img
                src={Logo}
                style={{
                  height: '45px'
                }}
              />
            </div>
            <Divider className="w-full mb-3" />
            <HBDrawerList
              open={openDraw}
              setOpen={(val: boolean) => {
                setOpenDraw(val);
              }}
              envname={envname}
              onLogOut={onLogOut}
            />
          </List>
        </Drawer>
        <img
          className="block md:hidden"
          src={Logo}
          style={{
            height: '45px',
            paddingLeft: '0px'
          }}
        />
        {/* <h1 className="hidden md:block">AssetBozz {envname}</h1> */}
      </div>
      <div className="p-4 pb-3 flex items-center">
        {/* TODO: 检测到本地存储内的language 打开切换语言的空间 */}
        {/* {localStorage.getItem('language') && <LanguageMenu />} */}
        <LanguageMenu />
        {/* <div
          className="text-center cursor-pointer font-medium text-black mr-5"
          onClick={() => {
            window.location.href = '/v1/settings';
            setLocalStorage('settingbar', 2);
          }}
        >
          {t('Pricing')}
        </div> */}
        <FreeQrCode />
        <Menu as="div" className="relative inline-block text-left ml-4">
          <Menu.Button className="flex rounded-full bg-white text-sm">
            <span className="sr-only">Open user menu</span>
            <img
              className="h-12 w-12 rounded-full"
              src={
                myprofile.profileImage
                  ? renderImageurl_s3(myprofile.profileImage || '')
                  : userData.profileImage
                  ? renderImageurl_s3(userData.profileImage || '')
                  : getPublicImg('user.png')
              }
              alt=""
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="fixed right-2 z-50 mt-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div
                className={classes.noproviderimg}
                style={{ background: '#0891b2' }}
              />
              <p className={classes.fullname}>
                {filterName(userData?.firstname, '', userData?.lastname) || ''}
              </p>
              <div className={classes.headPicContent}>
                <img
                  src={
                    userData.profileImage
                      ? renderImageurl_s3(userData.profileImage || '')
                      : getPublicImg('user.png')
                  }
                  className="bg-white rounded-full h-12 w-12"
                />
                <div className={classes.headPicMenu}>
                  <div
                    className="flex items-center px-5 py-4 cursor-pointer  hover:bg-cyan-600 hover:bg-opacity-75 hover:text-whitenew"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() => {
                      window.location.href = '/v1/settings';
                      setLocalStorage('settingbar', 7);
                    }}
                  >
                    <UserOwnerIcon className="mr-4 w-5 h-5" />
                    <span>{t('My Profile')}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center p-5">
                  <FreeButton
                    className="ml-0"
                    onClick={() => {
                      onLogOut();
                    }}
                    text={t('LOG OUT')}
                  />
                  {/* <ReportGmailerrorredOutlinedIcon sx={{ width: '1.35rem' }} /> */}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}


import { takeLatest, call, put } from 'redux-saga/effects';
import * as request from '@/axios/request';
import { RESPONSE_TYPES } from '@/axios/axios';
import { Actions, allReducers } from '@/store/reducers';
import { isMobile } from '@/utils/fn';

export function* watchBookingSaga() {
  yield takeLatest(Actions.GETBOOKING_LIST, getBookingList);
  yield takeLatest(Actions.GETBOOKINGS_INFO, getBookingInfo);
  yield takeLatest(Actions.VOID_BOOKING_INFO, voidBookingInfo);
  yield takeLatest(Actions.GETBOOKINGJOB, getBookingJob);
  yield takeLatest(Actions.PUTVISITORS, putVisitors);
  yield takeLatest(Actions.CHECKSTATUS, checkBookingStatus);
  yield takeLatest(Actions.PUTCLIENT, putClient);
  yield takeLatest(Actions.EDITBOOKINGINFO, putBookingInfo);
  yield takeLatest(Actions.GETFULLRIGHTLIST, getBookingRightList);
}

// 获取bookingList的列表
function* getBookingList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(
    request.getBookingRecordsJava,
    props.payload
  );
  if (res?.data && res.result === 0) {
    yield put({
      type: 'GETBOOKING_LISTSUCCESS',
      payload: res?.data
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* voidBookingInfo() {
  yield put({
    type: 'GETBOOKINGS_INFOSUCCESS',
    payload: {
      amount: '',
      status: 'new',
      clientName: '',
      payment: {},
      visitorJsonFields: [],
      overtimeChargeJsonFields: [],
      fromDatetime: '',
      toDatetime: new Date().getTime(),
      member: 0,
      memberId: '',
      timeUpdated: '',
      id: '',
      memberName: '',
      memberRemoved: 0,
      tenantId: '',
      tenantName: '',
      tenantRemoved: 0
    }
  });
}

// 获取bookinginfo的列表
function* getBookingInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(
    request.getBookingRecordsJavaInfo,
    props.payload
  );
  console.log('获取info接口', res);
  if (res?.data && res.result === 0) {
    const status = res?.data?.status;
    if (status === 'new') {
      res.data.statusstr = 'New';
    } else if (status === 'in-progress') {
      res.data.statusstr = 'In progress';
    } else if (status === 'completed') {
      res.data.statusstr = 'Done';
    } else {
      res.data.statusstr = 'Cancelled';
    }
    res.data.visitorJsonFields = res?.data?.visitorJsonFields
      ? res?.data?.visitorJsonFields
      : [];
    yield put({
      type: 'GETBOOKINGS_INFOSUCCESS',
      payload: res?.data
    });
    res?.data?.checkins?.forEach((item: any) => {
      if (item?.actions?.length !== undefined) {
        item.actions = null;
      }
    });
    console.log('-1111111', res?.data);
    if (isMobile() && res?.data?.checkins?.length !== 0) {
      yield put({
        type: 'GETBOOKINGJOBSUCCESS',
        payload: {
          joblist: res?.data?.checkins,
          showBookingInfo: false
        }
      });
    } else {
      yield put({
        type: 'GETBOOKINGJOBSUCCESS',
        payload: {
          joblist: res?.data?.checkins,
          showBookingInfo: true
        }
      });
    }
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

// 获取bookinginfo的列表
function* getBookingJob(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(request.getJobinfo, props.payload);
  console.log('获取了job状态的', res);
  if (res?.data && res.result === 0) {
    res?.data?.data?.checkins.forEach((item: any) => {
      if (item?.actions.length !== undefined) {
        item.actions = null;
      }
    });
    yield put({
      type: 'GETBOOKINGJOBSUCCESS',
      payload: {
        joblist: res?.data?.data?.checkins,
        showBookingInfo:
          (isMobile() && res?.data?.data?.checkins?.length === 0 && false) ||
          true
      }
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

// 修改visitors的信息
function* putVisitors(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(
    request.putBookingRecordsJavaInfo,
    props.payload
  );
  console.log('修改这些参数的东西', res);
  if (res.result === 0) {
    yield put(allReducers._getBookingsInfo(props.payload?.id));
    yield put(allReducers._openVisitorsModal(false));
    yield put(allReducers._openVisitorsDeleteModal(false));
    yield put(allReducers._openOvertimeModal(false));
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

// 修改visitors的信息
function* checkBookingStatus(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const jobdata = {
    job_id: props?.payload?.obj?.job_id,
    action: props.payload.action
  };
  const res: RESPONSE_TYPES = yield call(request.checkin, props.payload.obj);
  const getListData = props?.payload?.getListData;
  console.log('去做checkin和checkout', res, getListData);
  if (res?.data && res.result === 0) {
    if (props?.payload?.obj?.action !== 'checkin') {
      const resjob: RESPONSE_TYPES = yield call(
        request.updateJobStatus,
        jobdata
      );
      if (resjob?.data && resjob.result === 0) {
        if (getListData) {
          getListData?.();
          yield put(allReducers._checkModal(false));
          yield put(allReducers._openCancelModal(false));
        } else {
          yield put(allReducers._getBookingsInfo(props.payload?.entityId));
          yield put(allReducers._checkModal(false));
          yield put(allReducers._openCancelModal(false));
        }
      }
      console.log('看看这个job的状态是不是改变了', resjob);
    } else {
      if (getListData) {
        getListData?.();
        yield put(allReducers._checkModal(false));
        yield put(allReducers._openCancelModal(false));
      } else {
        yield put(allReducers._getBookingsInfo(props.payload?.entityId));
        yield put(allReducers._checkModal(false));
        yield put(allReducers._openCancelModal(false));
      }
    }
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

// 修改client的信息
function* putClient(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(
    request.putBookingsClient,
    props.payload
  );
  console.log('这个是修改client', res);
  if (res?.data && res.result === 0) {
    yield put(allReducers._getBookingsInfo(props.payload?.id));
    yield put(allReducers._openClientModal(false));
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

function* putBookingInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const payload = props.payload;
  const data = {
    obj: {
      seats: payload?.seats,
      source: payload?.source,
      statisticIds: payload?.statisticIds,
      bookType: payload?.bookType,
      remarks: payload?.remarks
    },
    id: payload?.id
  };
  const getListData: any = payload?.getListData;

  const res: RESPONSE_TYPES = yield call(request.editBookingJob, data);
  console.log('这个是修改bookinginfo', res);
  if (res?.data && res.result === 0) {
    if (getListData) {
      getListData?.();
    } else {
      yield put(allReducers._getBookingsInfo(props.payload?.id));
    }
    yield put(allReducers._openEditBookModal(false));
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}

// 获取bookingList的列表
function* getBookingRightList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: true
    })
  );
  const res: RESPONSE_TYPES = yield call(
    request.getBookingRecordsJava,
    props.payload
  );
  if (res?.data?.data && res.result === 0) {
    yield put({
      type: 'GETFULLRIGHTLISTSUCCESS',
      payload: res?.data?.data
    });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getSpaceInfoLoading',
      value: false
    })
  );
}


import { JsonParse } from '@/utils/fn';

// 不属于 PM/TM的角色返回1 否则返回0
export function isClient() {
  const userData: any = JsonParse(localStorage.getItem('user') || '');
  const role = userData?.role?.role !== 'PM' ? 1 : 0;
  return role;
}

export function isAdmin() {
  const userData: any = JsonParse(localStorage.getItem('user') || '');
  const role = userData?.role?.role === 'PM' ? 1 : 0;
  return role;
}


import React, { useCallback, useEffect, useState } from 'react';

import { t } from '@/i18n';
import FreeButton from '../Components/FreeButton';
import { useHistory } from 'react-router-dom';
import FreeField from '../Components/FreeField';
import { chackPassword, classNames } from '@/utils/fn';
import FreeLoading from '../Components/FreeLoading';
import * as Request from '@/axios/request';
import moment from 'moment';
import { EyeIcon, EyeOfIcon } from '@/assets/featherIcons';
import getPublicImg from '@/utils/getPublicImg';

const Logo = getPublicImg('AssetBozzIcon.png');

export default function VerificationPage() {
  const history = useHistory();
  const uuid = window.location.pathname.split('/verification/password/')[1];
  const [expired, setPired] = useState(false);
  const [newState, setNewState] = useState<any>({
    password: '',
    confirmPassword: ''
  });
  const [emptyError, setEmptyError] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);
  const [email, setEmail] = useState('');
  const [showPsw, setShowPsw] = useState(false);
  const [showCPsw, setShowCPsw] = useState(false);

  const getExpiring = useCallback(async (uuid: any) => {
    setLoadingGet(true);
    const res: any = await Request.getUserTempAuth(uuid);
    if (res?.result === 0) {
      setEmail(res?.data?.email);
    } else {
      setPired(true);
    }
    setLoadingGet(false);
  }, []);
  useEffect(() => {
    if (uuid) {
      getExpiring(uuid);
    }
  }, [getExpiring, uuid]);

  const handleSubmit = async () => {
    if (loading) return;
    const arr: any = [];
    if (!chackPassword(newState?.password)) {
      arr.push({
        field: 'password',
        value: t(
          'At least 8 alphanumerical characters with 1 or more upper case.'
        )
      });
    }
    if (!chackPassword(newState?.confirmPassword)) {
      arr.push({
        field: 'confirmPassword',
        value: t('Password error, please re-enter.')
      });
    }
    if (newState?.password !== newState?.confirmPassword) {
      arr.push({
        field: 'confirmPassword',
        value: t("Those passwords didn't match. Please try again.")
      });
    }
    setEmptyError(arr);
    if (arr?.length > 0) return;
    setLoading(true);
    const res: any = await Request.authSetPassword({
      uuid: uuid,
      password: newState?.password,
      confirmPassword: newState?.confirmPassword
    });
    if (res?.result === 0) {
      const res1: any = await Request.authSignIn({
        login: email,
        password: newState?.password
      });
      if (res1?.result === 0) {
        localStorage.setItem('user', JSON.stringify(res1?.data?.user));
        localStorage.setItem('token', JSON.stringify(res1?.data?.token));
        localStorage.setItem('path', '/v1/spaces');
        history.push('/v1/spaces');
      }
    } else {
      setPired(true);
    }
    setLoading(false);
  };
  return (
    <div
      className="flex justify-center items-center"
      style={{ height: window?.innerHeight }}
    >
      {loading && <FreeLoading />}

      {(loadingGet && <FreeLoading />) ||
        (!expired && (
          <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
            <div className="flex items-center">
              <img src={Logo} alt="" className="w-20" />
            </div>
            <div
              className="font-semibold text-4xl my-[20px] text-center lmD:text-2xl"
              style={{ textTransform: 'capitalize' }}
            >
              {t('Verify your email address')}
            </div>
            <div className="py-3 w-full">
              <FreeField
                value={newState.password}
                label={t('Password')}
                inputStyle={{ height: '50px' }}
                type={(showPsw && 'text') || 'password'}
                required
                onChange={(val: any) => {
                  setEmptyError(
                    emptyError?.filter(
                      (item: any) => item?.field !== 'password'
                    )
                  );
                  setNewState({
                    ...newState,
                    password: val?.target?.value
                  });
                }}
                errorText={
                  emptyError.find((item: any) => item?.field === 'password')
                    ?.value
                }
                disabled={loading}
                hintText={
                  !emptyError?.find((i: any) => i.field === 'password') &&
                  t(
                    'At least 8 alphanumerical characters with 1 or more upper case.'
                  )
                }
                rightIcon={
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer mx-2"
                    style={{
                      border: '0px',
                      borderRadius: '0 0.375rem 0.375rem 0'
                    }}
                    onClick={() => {
                      setShowPsw(!showPsw);
                    }}
                  >
                    <EyeIcon
                      className={classNames('w-5 h-5', showPsw && 'hidden')}
                    />
                    <EyeOfIcon
                      className={classNames('w-5 h-5', !showPsw && 'hidden')}
                    />
                  </div>
                }
              />
            </div>
            <div className="py-3 w-full">
              <FreeField
                value={newState.confirmPassword}
                label={t('Confirm Password')}
                inputStyle={{ height: '50px' }}
                type={(showCPsw && 'text') || 'password'}
                required
                onChange={(val: any) => {
                  setEmptyError(
                    emptyError?.filter(
                      (item: any) => item?.field !== 'confirmPassword'
                    )
                  );
                  setNewState({
                    ...newState,
                    confirmPassword: val?.target?.value
                  });
                }}
                errorText={
                  emptyError.find(
                    (item: any) => item?.field === 'confirmPassword'
                  )?.value
                }
                disabled={loading}
                rightIcon={
                  <div
                    className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer mx-2"
                    style={{
                      border: '0px',
                      borderRadius: '0 0.375rem 0.375rem 0'
                    }}
                    onClick={() => {
                      setShowCPsw(!showCPsw);
                    }}
                  >
                    <EyeIcon
                      className={classNames('w-5 h-5', showCPsw && 'hidden')}
                    />
                    <EyeOfIcon
                      className={classNames('w-5 h-5', !showCPsw && 'hidden')}
                    />
                  </div>
                }
              />
            </div>
            <div className="py-5 w-full">
              <FreeButton
                text={t('Complete verfication')}
                type="freeStyle1"
                style={{
                  fontSize: '16px',
                  margin: 0
                }}
                onClick={handleSubmit}
              />
            </div>
            <div className="py-2 text-center">
              &copy;&nbsp;2019-
              {moment().get('year')}&nbsp;
              {t('AssetBozz Limited. All rights reserved.')}
            </div>
          </div>
        )) || (
          <div className="flex justify-center items-center flex-col w-[600px] p-[50px] lmD:px-8">
            <div className="flex items-center">
              <img className="w-20" src={Logo} alt="" />
            </div>
            <div className="font-semibold text-2xl my-[20px] text-center lmD:text-xl">
              {t(
                'Sorry, the verification link has expired. Please sign up again.'
              )}
            </div>
            <div className="py-5 w-full">
              <FreeButton
                text={t('Sign up now')}
                type="freeStyle1"
                style={{
                  fontSize: '16px',
                  margin: 0
                }}
                onClick={() => {
                  history.push('/signup');
                }}
              />
            </div>
            <div className="py-2 text-center">
              &copy;&nbsp;2019-
              {moment().get('year')}&nbsp;
              {t('AssetBozz Limited. All rights reserved.')}
            </div>
          </div>
        )}
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import FreeButton from '@/views/Components/FreeButton';
import FreeLoading from '@/views/Components/FreeLoading';
import { useSelector } from 'react-redux';
import { storeState } from '@/store/reducers';
import { t } from '@/i18n';

const FreeAlertModal = (props: any) => {
  const {
    show,
    child,
    onSure = () => {},
    onClose = () => {},
    suretext = t('OK'),
    canceltext = t('Cancel'),
    sureBgColor = '#4F46E5',
    cancelBgColor = '#fff',
    iconChild,
    title = '',
    subContent = '',
    disable,
    hideCloseBtn = false,
    submitBtnType = 'bgErr'
  } = props;
  const { fullScreenStatus }: storeState['commonState'] = useSelector(
    (store: storeState) => store.commonState
  );
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[1112]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto bg-cardblack bg-opacity-20">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative  transform overflow-hidden
                 rounded-lg  px-4 pt-5 pb-4 text-left  transition-all sm:my-8 w-full sm:max-w-4xl sm:p-6"
              >
                {fullScreenStatus?.getSpaceInfoLoading && <FreeLoading />}
                <div className="flex rounded-tr-md rounded-tl-md w-full bg-whitenew  px-4 py-5 sm:p-6 ">
                  <div>{iconChild}</div>
                  <div className="px-3">
                    <div
                      className="text-cardblack text-2xl font-normal text-gray-700"
                      style={{ fontWeight: 500, textTransform: 'capitalize' }}
                    >
                      {title}
                    </div>
                    <div
                      className="text-cardgray text-lg font-normal text-gray-700"
                      style={{ marginTop: '10px' }}
                    >
                      {subContent}
                    </div>
                  </div>
                </div>
                {child}
                <div className="flex items-center justify-end px-4 sm:p-6 bg-whitenew rounded-br-md rounded-bl-md ">
                  {!hideCloseBtn && (
                    <FreeButton
                      type="white"
                      disable={disable}
                      text={canceltext}
                      onClick={() => onClose()}
                    />
                  )}
                  <FreeButton
                    type={submitBtnType}
                    disable={disable}
                    text={suretext}
                    onClick={() => onSure(child)}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default FreeAlertModal;

/*
 * @,@Descripttion: ,:
 * @,@Author: ,: 熊峰
 * @,@Date: ,: 2020-11-19 10:28:42
 * @LastEditors: 爱你一万年！！！
 * @LastEditTime: 2021-04-01 19:34:33
 * @,@功能: ,:
 */
export const NOT_LOGGED_IN = 'Not_logged_in';
export const ALREADY_LOGGED_IN = 'Already_logged_in';
export const LOGINSTATUS = 'LOGIN_STATUS';
export const USER = 'USER';
export const USERID = 'USER_ID';
export const LANGUAGE = 'LANGUAGE';
export const REMEMBERPASSWORD = 'REMEMBER_PASSWORD';
export const ACCESSRULES = 'ACCESS_RULES';
export const LOGINMETHOD = 'LOGIN_METHOD';
export const FCMTOKEN = 'FCM_TOKEN';
export const PROVIDERID = 'PROVIDER_ID';
export const ZONE = 'ZONE';
export const COUNTRY_REGION = 'COUNTRY_REGION';
export const TOKEN = 'TOKEN';
// export const TOKEN_EXPIRES_IN = 'TOKEN_EXPIRES_IN';
// export const ACCESS_TOKEN = 'ACCESS_TOKEN';
// export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const JOB_STATUS = 'JOB_STATUS';
export const ZONE_LIST = 'ZONE_LIST'; // 时区列表
export const COUNTRYS = 'COUNTRYS'; // 国家code
export const STATES = 'STATES'; // 国家-->地区code
export const GUIDESHOW = 'GUIDESHOW'; // 是否是显示引导页
export const HOMEBOZZ_CONTACTS = 'HOMEBOZZ_CONTACTS'; // APP 联系人
export const SEARCH_HISTORY = 'SEARCH_HISTORY'; // APP 联系人
export const HOMEBOZZ_MY_TEAMS_CONTACTS = 'HOMEBOZZ_MY_TEAMS_CONTACTS'; // APP MyTeams 联系人
export const HOMEBOZZ_OWNER_CONTACTS = 'HOMEBOZZ_OWNER_CONTACTS'; // APP OWNER 联系人
export const USER_ROLE = 'USER_ROLE'; // 登录角色

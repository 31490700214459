import React, { Fragment } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { changeLanguage, t } from '@/i18n';
import { allReducers } from '@/store/reducers';
import { useDispatch } from 'react-redux';

const LanguageMenu = ({ type = 'menu' }: { type?: 'menu' | 'button' }) => {
  const isMenu = type === 'menu';
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const languageList = [
    {
      value: 'en',
      label: (isMenu && t('English')) || 'EN'
    },
    // {
    //   value: 'id',
    //   label: t('Indonesian')
    // },

    // {
    //   value: 'zh_HK',
    //   label: (isMenu && t('Chinese(HK)')) || '繁'
    // },
    {
      value: 'zh_CN',
      label: (isMenu && t('Chinese(CN)')) || '简'
    }
    // {
    //   value: 'ja',
    //   label: (isMenu && t('Japanese')) || 'JA'
    // }
  ];
  return (
    <Fragment>
      {(type === 'menu' && (
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <LanguageIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
      )) ||
        languageList.map((itm) => {
          return (
            <IconButton
              // color={
              //   (localStorage.getItem('language') === itm.value && 'primary') ||
              //   'inherit'
              // }
              sx={{
                fontSize: '15px',
                fontWeight: 600,
                margin: '0px 5px',
                color:
                  (localStorage.getItem('language') === itm.value &&
                    'rgb(80, 110, 228)') ||
                  '#80808F'
              }}
              key={itm.value}
              onClick={() => {
                dispatch(allReducers._ChangeLanguageStatus(itm.value));
                changeLanguage(itm.value);
                localStorage.setItem('language', itm.value);
                location.reload();
              }}
            >
              <span
                style={{ width: '30px', height: '30px', lineHeight: '30px' }}
              >
                {itm.label}
              </span>
            </IconButton>
          );
        })}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {languageList.map((itm) => {
          return (
            <MenuItem
              key={itm.value}
              onClick={() => {
                dispatch(allReducers._ChangeLanguageStatus(itm.value));
                changeLanguage(itm.value);
                localStorage.setItem('language', itm.value);
                location.reload();
              }}
            >
              {itm.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
};

export default LanguageMenu;

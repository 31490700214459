import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { hashString } from 'react-hash-string';

import enJson from './en.json';
import idJson from './id.json';
import jaJson from './ja.json';
import zh_CNJson from './zh_CN.json';
import zh_HKJson from './zh_HK.json';

// 从句子计算哈希一个 key 值，该算法需要和 changeHash.js 处理方式保持一致
const hashKey = function (value: string) {
  let hash = 0;
  let i;
  let chr;
  if (value.length === 0) return hash;
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return (
    'k_' + ('0000' + hashString(value).toString().replace(/\s+/g, '')).slice(-7)
  );
};

// 当前使用前端处理的方案 将翻译相关的json文件放置在前端本地调用获取
const resources = {
  en: {
    translation: enJson
  },
  id: {
    translation: idJson
  },
  ja: {
    translation: jaJson
  },
  zh_CN: {
    translation: zh_CNJson
  },
  zh_HK: {
    translation: zh_HKJson
  }
};

// 切换语言
export const changeLanguage = (lng: string) => {
  localStorage.setItem('language', lng);
  return i18n.changeLanguage(lng);
};

// 语言包装函数
export const t = (str: string) => {
  const obj = JSON.parse(window.localStorage.getItem('i18n') || '{}');
  const key: any = String(hashKey(str));
  if (!obj[key]) {
    obj[key] = str;
    window.localStorage.setItem('i18n', JSON.stringify(obj));
  }
  const obJson: any = { ...enJson };
  return (obJson[key] && i18n.t(String(hashKey(str)))) || str;
  return i18n.t(String(hashKey(str)));
};

i18n.use(initReactI18next).init({
  resources, // 加载本地的资源
  lng: localStorage.getItem('language') || 'en', // 默认设置语言为‘en’
  // 插值语句
  interpolation: {
    escapeValue: false // 对 xss 中的安全作出反应
  }
});

export default i18n;

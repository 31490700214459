/* eslint-disable no-duplicate-case */
/*
 * @,@Descripttion: ,:
 * @,@Author: ,: 熊峰
 * @,@Date: ,: 2020-11-19 15:26:02
 * @LastEditors: mebin
 * @LastEditTime: 2021-12-28 17:26:58
 * @,@功能: ,:
 */
/*
 * @Descripttion:
 * @Author: 熊峰
 * @Date: 2020-11-17 14:57:52
 * @,@LastEditors: ,: 熊峰
 * @,@LastEditTime: ,: 2020-11-19 11:55:13
 * @功能:
 */
import { createAction, handleActions } from 'redux-actions';
import _ from 'lodash';
interface updateTypes {
  id: string;
  text: any;
  data?: any;
}
/**
 * Actions
 */
export const Actions_Job = {
  // job列表相关
  GETJOB_LIST: 'GETJOB_LIST',
  GETJOB_LIST_PARAMS: 'GETJOB_LIST_PARAMS',
  GETJOB_LIST_STATUS: 'GETJOB_LIST_STATUS',
  GETJOB_LISTSUCCESS: 'GETJOB_LISTSUCCESS',
  GETJOB_LISTFAIL: 'GETJOB_LISTFAIL',

  SETJOB_LISTDATA: 'SETJOB_LISTDATA',
  SET_PARENT_JOB_ID: 'SET_PARENT_JOB_ID', // follow up job
  // job但个信息相关
  GETJOB_INFO: 'GETJOB_INFO',
  GETJOB_INFOSUCCESS: 'GETJOB_INFOSUCCESS',
  // 新建job订单
  NEWJOB_INFORMATION: 'NEWJOB_INFORMATION',
  NEWJOB_INFORMATION_SUCCESS: 'NEWJOB_INFORMATION_SUCCESS',
  // 工单的价格
  GETEXPENSELIST: 'GETEXPENSELIST',
  GETEXPENSELIST_SUCCESS: 'GETEXPENSELIST_SUCCESS',

  // 获取供应商的列表接口
  GETPROVIDERLIST: 'GETPROVIDERLIST',
  GETPROVIDERLIST_SUCCESS: 'GETPROVIDERLIST_SUCCESS',

  SAVE_EXPENSE: 'SAVE_EXPENSE',
  SAVE_EXPENSESUCCESS: 'SAVE_EXPENSESUCCESS',

  GETFINANC_LIST: 'GETFINANC_LIST',
  GETFINANC_LISTSUCCESS: 'GETFINANC_LISTSUCCESS',

  UPDATEJOBSTATUS: 'UPDATEJOBSTATUS',

  // 修改jobinfo
  UPDATEJOBINFO: 'UPDATEJOBINFO',
  // UPDATEJOBINFO:'UPDATEJOBINFO',

  DELE_EXPENSE: 'DELE_EXPENSE',

  // job短信接口获取，发送
  GETPROVIDE_SMS: 'GETPROVIDE_SMS',
  GETPROVIDE_SMSSUCCESS: 'GETPROVIDE_SMSSUCCESS',
  SENDPROVIDE_SMS: 'SENDPROVIDE_SMS',
  SENDPROVIDE_SMS_SUCCESS: 'SENDPROVIDE_SMS_SUCCESS',

  // job邮件接口获取，发送
  GETPROVIDE_EMAIL: 'GETPROVIDE_EMAIL',
  GETPROVIDE_EMAILSUCCESS: 'GETPROVIDE_EMAILSUCCESS',
  SENDPROVIDE_EMAIL: 'SENDPROVIDE_EMAIL',
  SENDPROVIDE_EMAIL_SUCCESS: 'SENDPROVIDE_EMAIL_SUCCESS',

  // job whatsapp接口获取，发送
  GETPROVIDE_WHATSAPP: 'GETPROVIDE_WHATSAPP',
  GETPROVIDE_WHATSAPPSUCCESS: 'GETPROVIDE_WHATSAPPSUCCESS',
  SENDPROVIDE_WHATSAPP: 'SENDPROVIDE_WHATSAPP',
  SENDPROVIDE_WHATSAPP_SUCCESS: 'SENDPROVIDE_WHATSAPP_SUCCESS',

  SET_PROVIDER_PARAMS: 'SET_PROVIDER_PARAMS',
  SAVE_PROVIDER: 'SAVE_PROVIDER',
  UPDATE_PROVIDER: 'UPDATE_PROVIDER',
  SEARCH_JOB: 'SEARCH_JOB',

  // Assignment
  GET_JOB_ASSIGNMENTS: 'GET_JOB_ASSIGNMENTS',
  GET_JOB_ASSIGNMENTS_SUCCESS: 'GET_JOB_ASSIGNMENTS_SUCCESS',
  SAVE_JOB_ASSIGNMENTS: 'SAVE_JOB_ASSIGNMENTS',
  SAVE_JOB_ASSIGNMENTS_SUCCESS: 'SAVE_JOB_ASSIGNMENTS_SUCCESS',
  SEARCH_EMPLOYEES_FOR_JOB: 'SEARCH_EMPLOYEES_FOR_JOB',
  SEARCH_EMPLOYEES_FOR_JOB_SUCCESS: 'SEARCH_EMPLOYEES_FOR_JOB_SUCCESS',

  // Address
  GET_ADDRESSTEXT: 'GET_ADDRESSTEXT',
  GET_ADDRESSTEXT_SUCCESS: 'GET_ADDRESSTEXT_SUCCESS',
  ACCEPT_JOB: 'ACCEPT_JOB',
  ACCEPT_JOB_SUCCESS: 'ACCEPT_JOB_SUCCESS',
  CHECK_IN: 'CHECK_IN',
  UPDATE_NEW_JOB: 'UPDATE_NEW_JOB',

  CLEAN_JOB_LIST: 'CLEAN_JOB_LIST',
  CLEAN_JOB_LIST_DATA: 'CLEAN_JOB_LIST_DATA',
  CLEAN_JOB_INFO: 'CLEAN_JOB_INFO',

  SERVICE_PROVIDESAVE: 'SERVICE_PROVIDESAVE',

  // 获取jobchecklist
  GETCHECKLISTLIST: 'GETCHECKLISTLIST',
  GETCHECKLISTLIST_SUCCESS: 'GETCHECKLISTLIST_SUCCESS',
  ADDCHECKLISTTOJOB: 'ADDCHECKLISTTOJOB', // 新建checklist到job
  ADDCHECKLISTTOJOB_SUCCESS: 'ADDCHECKLISTTOJOB_SUCCESS', // 新建checklist到job成功
  GETJOBCHECKLISTLIST: 'GETJOBCHECKLISTLISTING',
  GETJOBCHECKLISTLIST_SUCCESS: 'GETJOBCHECKLISTLIST_SUCCESS',
  SAVE_JOB_CONTACT: 'SAVE_JOB_CONTACT', // 修改job contact person
  SAVE_JOB_CONTACT_SUCCESS: 'SAVE_JOB_CONTACT_SUCCESS',

  // 获取myteamuser
  GETMYTEAMUSERLIST: 'GETMYTEAMUSERLIST',
  GETMYTEAMUSERLIST_SUCCESS: 'GETMYTEAMUSERLIST_SUCCESS',

  GETJOB_TYPE: 'GETJOB_TYPE',
  GETJOB_TYPESUCCESS: 'GETJOB_TYPESUCCESS'
};

/**
 * Actions Creators
 */

export const _getJoblist = createAction(Actions_Job.GETJOB_LIST);
export const _getJoblistParams = createAction(Actions_Job.GETJOB_LIST_PARAMS);
export const _setjoblist = createAction(Actions_Job.SETJOB_LISTDATA);

export const getjobInfo = createAction(Actions_Job.GETJOB_INFO);
export const setjobinfo = createAction(Actions_Job.GETJOB_INFOSUCCESS);

export const newjob = createAction(Actions_Job.NEWJOB_INFORMATION);
export const _updateNewJob = createAction(Actions_Job.UPDATE_NEW_JOB);

export const getexpenlist = createAction(Actions_Job.GETEXPENSELIST);

export const getprovider = createAction(Actions_Job.GETPROVIDERLIST);

export const _getfinanclist = createAction(Actions_Job.GETFINANC_LIST);
export const _saveExpense = createAction(Actions_Job.SAVE_EXPENSE);

export const _updateJobinfo = createAction(Actions_Job.UPDATEJOBINFO);

export const _deleteexpense = createAction(Actions_Job.DELE_EXPENSE);

export const _SetProviderParams = createAction(Actions_Job.SET_PROVIDER_PARAMS);
export const _saveProvider = createAction(Actions_Job.SAVE_PROVIDER);
export const _updateProvider = createAction(Actions_Job.UPDATE_PROVIDER);

export const _getprovidesms = createAction(Actions_Job.GETPROVIDE_SMS);
export const _sendprovidesms = createAction(Actions_Job.SENDPROVIDE_SMS);

export const _getprovideemail = createAction(Actions_Job.GETPROVIDE_EMAIL);
export const _sendprovideemail = createAction(Actions_Job.SENDPROVIDE_EMAIL);

export const _getprovidewhatsapp = createAction(
  Actions_Job.GETPROVIDE_WHATSAPP
);
export const _sendprovidewhatsapp = createAction(
  Actions_Job.SENDPROVIDE_WHATSAPP
);

export const _updatejobstatus = createAction(Actions_Job.UPDATEJOBSTATUS);
export const _searchJob = createAction(Actions_Job.SEARCH_JOB);

export const _getJobAssignments = createAction(Actions_Job.GET_JOB_ASSIGNMENTS);
export const _saveJobAssignments = createAction(
  Actions_Job.SAVE_JOB_ASSIGNMENTS
);
export const _searchEmployeesForJob = createAction(
  Actions_Job.SEARCH_EMPLOYEES_FOR_JOB
);
export const _acceptJob = createAction(Actions_Job.ACCEPT_JOB);
export const _checkin = createAction(Actions_Job.CHECK_IN);
export const _getAddresstext = createAction(Actions_Job.GET_ADDRESSTEXT);
export const _cleanJobList = createAction(Actions_Job.CLEAN_JOB_LIST);
export const _cleanJobListData = createAction(Actions_Job.CLEAN_JOB_LIST_DATA);
export const _cleanJobInfo = createAction(Actions_Job.CLEAN_JOB_INFO);

export const _serviceProvidesave = createAction(
  Actions_Job.SERVICE_PROVIDESAVE
);

export const _getchecklist = createAction(Actions_Job.GETCHECKLISTLIST);
export const _addChecklistToJob = createAction(Actions_Job.ADDCHECKLISTTOJOB);
export const _getjobchecklist = createAction(Actions_Job.GETJOBCHECKLISTLIST);
export const _getmyteamuserlist = createAction(Actions_Job.GETMYTEAMUSERLIST);
export const _saveJobContact = createAction(Actions_Job.SAVE_JOB_CONTACT);

export const _getJobTypeList = createAction(Actions_Job.GETJOB_TYPE);

export const _setParentjobId = createAction(Actions_Job.SET_PARENT_JOB_ID);
export const jobReducers = {
  _getJoblist,
  _getJoblistParams,
  getjobInfo,
  newjob,
  getexpenlist,
  getprovider,
  setjobinfo,
  _setjoblist,
  _getfinanclist,
  _saveExpense,
  _deleteexpense,
  _updateJobinfo,
  _SetProviderParams,
  _saveProvider,
  _updateProvider,
  _sendprovidesms,
  _sendprovideemail,
  _sendprovidewhatsapp,
  _getprovidesms,
  _getprovideemail,
  _getprovidewhatsapp,
  _updatejobstatus,
  _searchJob,
  _getJobAssignments,
  _saveJobAssignments,
  _searchEmployeesForJob,
  _getAddresstext,
  _acceptJob,
  _checkin,
  _updateNewJob,
  _cleanJobList,
  _cleanJobListData,
  _cleanJobInfo,
  _serviceProvidesave,

  _getchecklist,
  _addChecklistToJob,
  _getjobchecklist,
  _getmyteamuserlist,
  _saveJobContact,

  _setParentjobId,

  _getJobTypeList
};
/**
 * Reducer
 */
const arrayString: Array<string> = [];
const jobInfoType: any = {};

const jobListTableDataType: any = [];
const defaultSessionState = {
  loading: false,
  jobTypeList: [],
  jobListData: {
    data: jobListTableDataType,
    page: 1,
    size: 10,
    total: 0
  },
  requestJobParams: {
    size: 10,
    page: 1,
    entity: '', // property asset none
    internal_jobs: null, // 1,0,null
    status: '', //
    order_by: '', // time_required, provider_id, entity , status
    direction: '', // asc ,desc
    search: '',
    history: '', // [ active , upcoming , completed , closed ]
    assigned_me: 0 // 1
  },
  parent_job_id: '',
  countJobStatus: {
    accepted: '0',
    active: '0',
    archived: '0',
    assigned: '0',
    cancelled: '0',
    closed: '0',
    new: '0',
    onHold: '0',
    started: '0',
    upcoming: '0'
  },
  jobInfo: jobInfoType,
  expenseList: { data: [], total: 0 },
  providerList: [],
  metaProvider: [],
  financialist: [],
  selelist: [],
  ProviderParams: {
    Params: {},
    type: '',
    onSelected: {},
    navigation: {}
  },
  providerurl: '',
  jobAssignments: {
    data: []
  },
  employees: [],
  maptext: {
    latitude: 0,
    longitude: 0
  },
  newJobData: {
    Country: '',
    country_text: '',
    CountryIndex: [],
    StateProvince: '',
    StateProvinceIndex: [],
    StateProvinceData: [],
    City: '',
    Address: '',
    Apartment: '',
    ZipPostal: '',
    required: arrayString,
    region: '',
    regionLocal: '',
    region_text: ''
  },
  addCheckList: {},
  checkList: [],
  jobCheckList: { data: [] },
  myteamuserList: [],
  myteamuserclientList: [],
  providerData: {
    short_url: ''
  }
};
export type jobState = Readonly<typeof defaultSessionState>;
export default handleActions(
  {
    [Actions_Job.GETJOB_LIST]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETJOB_LIST_PARAMS]: (state, action: any) => {
      const { id, text, data }: updateTypes = action.payload;
      switch (id) {
        case 'status':
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              status: text,
              page: 1
            }
          };
        case 'history':
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              history: text,
              status: '',
              search: '',
              page: 1
            }
          };
        case 'Sort':
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              order_by: data.order_by,
              direction: data.direction,
              page: 1
            }
          };
        case 'internal_jobs': {
          let param: any = null;
          if (text === 'Internal') {
            param = 1;
          } else if (text === 'External') {
            param = 0;
          }
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              internal_jobs: param,
              page: 1
            }
          };
        }
        case 'entity':
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              entity: text,
              page: 1
            }
          };
        case 'seviceProvider': {
          const param2: any = text[0] ? '1' : '';
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              assigned_me: param2,
              page: 1
            }
          };
        }
        case 'size':
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              page: data.page,
              size: data.size
            }
          };
        case 'search':
          return {
            ...state,
            requestJobParams: {
              ...state.requestJobParams,
              search: text,
              ...{
                size: 10,
                page: 1,
                entity: '', // property asset none
                internal_jobs: null, // 1,0,null
                status: '', //
                order_by: '', // time_required, provider_id, entity , status
                direction: '' // asc ,desc
              }
            }
          };
        default:
          return {
            ...state,
            requestJobParams: { ...state.requestJobParams }
          };
      }
    },
    [Actions_Job.GETJOB_LIST_STATUS]: (state, action: any) => {
      return {
        ...state,
        countJobStatus: {
          ...action.payload,
          onHold: action.payload['on-hold']
        }
      };
    },
    [Actions_Job.GETJOB_LISTSUCCESS]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        jobListData: action.payload
      };
    },
    [Actions_Job.SETJOB_LISTDATA]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        jobListData: action.payload
      };
    },
    [Actions_Job.GETJOB_LISTFAIL]: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.GETJOB_INFO]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETJOB_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        jobInfo: action.payload
      };
    },
    // [Actions_Job.NEWJOB_INFORMATION]: (state) => {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // },
    [Actions_Job.NEWJOB_INFORMATION_SUCCESS]: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.ACCEPT_JOB_SUCCESS]: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.GETEXPENSELIST]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETPROVIDERLIST]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETPROVIDERLIST_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        providerList: action.payload.data,
        metaProvider: action.payload.metaData
      };
    },
    // expense相关
    [Actions_Job.GETEXPENSELIST_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        expenseList: action.payload
      };
    },
    [Actions_Job.SAVE_EXPENSE]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.SAVE_EXPENSESUCCESS]: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.GETFINANC_LISTSUCCESS]: (state, action: any) => {
      return {
        ...state,
        loading: false,
        financialist: action.payload.financialist,
        selelist: action.payload.selelist
      };
    },
    [Actions_Job.DELE_EXPENSE]: (state) => {
      return {
        ...state,
        loading: true,
        expenseList: { data: [], total: 0 }
      };
    },
    [Actions_Job.UPDATEJOBINFO]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.SET_PROVIDER_PARAMS]: (state, action: any) => {
      return {
        ...state,
        ProviderParams: { ...state.ProviderParams, ...action.payload }
      };
    },
    [Actions_Job.GETPROVIDE_SMSSUCCESS]: (state, action: any) => {
      return {
        ...state,
        providerData: action.payload
      };
    },
    [Actions_Job.GETPROVIDE_EMAILSUCCESS]: (state, action: any) => {
      return {
        ...state,
        providerData: action.payload
      };
    },
    [Actions_Job.GETPROVIDE_WHATSAPPSUCCESS]: (state, action: any) => {
      return {
        ...state,
        providerData: action.payload
      };
    },
    [Actions_Job.GET_JOB_ASSIGNMENTS_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        jobAssignments: action.payload
      };
    },
    [Actions_Job.SAVE_JOB_ASSIGNMENTS_SUCCESS]: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.SEARCH_EMPLOYEES_FOR_JOB_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        employees: action.payload
      };
    },
    [Actions_Job.UPDATE_NEW_JOB]: (state, action: any) => {
      const { id, text, data }: updateTypes = action.payload;
      switch (id) {
        case 'Address':
          return {
            ...state,
            newJobData: {
              ...state.newJobData,
              Address: text,
              required: text
                ? state.newJobData.required.filter((value) => value !== id)
                : [...state.newJobData.required, id]
            }
          };
        case 'City':
          return {
            ...state,
            newJobData: {
              ...state.newJobData,
              City: text,
              required: text
                ? state.newJobData.required.filter((value) => value !== id)
                : [...state.newJobData.required, id]
            }
          };
        case 'Apartment,suite,etc':
          return {
            ...state,
            newJobData: { ...state.newJobData, Apartment: text }
          };
        case 'Zip/Postal':
          return {
            ...state,
            newJobData: { ...state.newJobData, ZipPostal: text }
          };
        case 'CountryIndex':
          return {
            ...state,
            newJobData: {
              ...state.newJobData,
              CountryIndex: text.index,
              Country: text.item.value || '',
              country_text: text.item.name || ''
            }
          };
        case 'StateProvinceIndex':
          return {
            ...state,
            newJobData: {
              ...state.newJobData,
              StateProvinceIndex: text.index,
              StateProvince: text.item.value || '',
              region: text.item.value || '',
              region_text: text.item.name || ''
            }
          };
        case 'StateProvinceData':
          return {
            ...state,
            newJobData: { ...state.newJobData, StateProvinceData: text }
          };
        case 'clean':
          return {
            ...state,
            newJobData: {
              Country: '',
              country_text: '',
              CountryIndex: [],
              StateProvince: '',
              StateProvinceIndex: [],
              StateProvinceData: [],
              City: '',
              Address: '',
              Apartment: '',
              ZipPostal: '',
              required: arrayString,
              region: '',
              regionLocal: '',
              region_text: ''
            }
          };
        case 'editjob':
          return {
            ...state,
            newJobData: {
              Country: _.get(data, 'country'),
              country_text: '',
              CountryIndex: [],
              StateProvince: '',
              StateProvinceIndex: [],
              StateProvinceData: [],
              City: _.get(data, 'city'),
              Address: _.get(data, 'address1'),
              Apartment: '',
              ZipPostal: '',
              required: arrayString,
              region: _.get(data, 'region'),
              regionLocal: '',
              region_text: ''
            }
          };

        case 'required':
          return {
            ...state,
            newJobData: {
              ...state.newJobData,
              required: [...state.newJobData.required, text]
            }
          };
        default:
          return {
            ...state,
            newJobData: action.payload
          };
      }
    },
    [Actions_Job.CLEAN_JOB_LIST]: (state) => {
      return {
        ...state,
        jobListData: {
          data: [],
          page: 1,
          size: 10,
          total: 0
        }
      };
    },
    [Actions_Job.CLEAN_JOB_INFO]: (state) => {
      return {
        ...state,
        jobInfo: {}
      };
    },
    [Actions_Job.CLEAN_JOB_LIST_DATA]: (state) => {
      return {
        ...state,
        jobListData: {
          ...state.jobListData,
          total: 0,
          data: []
        }
      };
    },

    // jobchecklist相关
    [Actions_Job.GETCHECKLISTLIST]: (state) => {
      // 获取checklist
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETCHECKLISTLIST_SUCCESS]: (state, action: any) => {
      // 获取checklist成功
      return {
        ...state,
        loading: false,
        checkList: action.payload
      };
    },
    [Actions_Job.ADDCHECKLISTTOJOB]: (state) => {
      // 添加checklist到job
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.ADDCHECKLISTTOJOB_SUCCESS]: (state) => {
      // 添加checklist到job成功
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.GETJOBCHECKLISTLIST]: (state) => {
      // 获取jobchecklist
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETJOBCHECKLISTLIST_SUCCESS]: (state, action: any) => {
      // 获取jobchecklist成功
      return {
        ...state,
        loading: false,
        jobCheckList: action.payload
      };
    },
    [Actions_Job.GETMYTEAMUSERLIST]: (state) => {
      // 获取myteamuser
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETMYTEAMUSERLIST_SUCCESS]: (state, action: any) => {
      // 获取myteamuser成功
      return {
        ...state,
        loading: false,
        myteamuserList: action.payload.myteamuserList,
        myteamuserclientList: action.payload.myteamuserclientList
      };
    },
    [Actions_Job.SET_PARENT_JOB_ID]: (state, action: any) => {
      return {
        ...state,
        parent_job_id: action.payload
      };
    },
    [Actions_Job.SAVE_JOB_CONTACT]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.SAVE_JOB_CONTACT_SUCCESS]: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    [Actions_Job.GETJOB_TYPE]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [Actions_Job.GETJOB_TYPESUCCESS]: (state, action: any) => {
      return {
        ...state,
        jobTypeList: action.payload,
        loading: false
      };
    }
  },
  defaultSessionState
);


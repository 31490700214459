import { createAction, handleActions } from 'redux-actions';
/**
 * Actions
 */
export const Actions_KnowledgeBase = {
  GET_SIGNATURE: 'GET_SIGNATURE', // 获取 s3 token
  GET_UPLOADS3DATA_SUCCESS: 'GET_UPLOADS3DATA_SUCCESS', // 获取 s3 token 成功后保存 上传所需的 uploadS3Data
  UPLOAD_ATTACHMENTFILETOS3: 'UPLOAD_ATTACHMENTFILETOS3', // 上传文件到 s3
  UPLOAD_ATTACHMENTFILETOS3_SUCCESS: 'UPLOAD_ATTACHMENTFILETOS3_SUCCESS', // 上传文件到 s3 成功

  New_ATTACHMENTFILE: 'New_ATTACHMENTFILE', // 新增附件
  UPDATE_ATTACHMENTFILE: 'UPDATE_ATTACHMENTFILE', // 更新附件
  REMOVED_ATTACHMENTFILE: 'REMOVED_ATTACHMENTFILE', // 删除附件
  COPY_ATTACHMENTFILE: 'COPY_ATTACHMENTFILE', // 批量复制附件
  NEW_SHAREATTACHMENTFILE: 'NEW_SHAREATTACHMENTFILE', // 新增分享附件
  GET_SHAREATTACHMENTFILE_LIST: 'GET_SHAREATTACHMENTFILE_LIST', // 获取分享附件列表
  GET_SHAREATTACHMENTFILE_LIST_SUCCESS: 'GET_SHAREATTACHMENTFILE_LIST_SUCCESS', // 获取分享附件列表成功
  REMOVED_SHAREATTACHMENTFILE: 'REMOVED_SHAREATTACHMENTFILE', // 删除分享附件

  GET_ATTACHMENTFILE_LIST: 'GET_ATTACHMENTFILE_LIST', // 获取附件列表
  GET_ATTACHMENTFILE_LIST_SUCCESS: 'GET_ATTACHMENTFILE_LIST_SUCCESS', // 获取附件列表成功
  GET_ATTACHMENTFILEFOLDER_LIST: 'GET_ATTACHMENTFILEFOLDER_LIST', // 获取附件文件夹列表
  GET_ATTACHMENTFILEFOLDER_LIST_SUCCESS:
    'GET_ATTACHMENTFILEFOLDER_LIST_SUCCESS', // 获取附件文件夹列表成功
  GET_ATTACHMENTFILESHARETOME_LIST_SUCCESS:
    'GET_ATTACHMENTFILESHARETOME_LIST_SUCCESS', // 获取附件文件shareToMe
  CLEAR_ALLFILES_LIST: 'CLEAR_ALLFILES_LIST',
  CLEAR_IS_VIEW: 'CLEAR_IS_VIEW',
  TENANT_ENTITY: 'TENANT_ENTITY',
  SET_STORAGE_ID: 'SET_STORAGE_ID'
};

/**
 * Actions Creators
 */
// 清空保持数据
export const _clearFilesList = createAction(
  Actions_KnowledgeBase.CLEAR_ALLFILES_LIST
);
export const _clearIsView = createAction(Actions_KnowledgeBase.CLEAR_IS_VIEW);
export const _tenant_entity = createAction(Actions_KnowledgeBase.TENANT_ENTITY);
export const _setStorageId = createAction(Actions_KnowledgeBase.SET_STORAGE_ID);
export const _getSignature = createAction(Actions_KnowledgeBase.GET_SIGNATURE);
export const _uploadAttachmentFileToS3 = createAction(
  Actions_KnowledgeBase.UPLOAD_ATTACHMENTFILETOS3
);
export const _newAttachmentFile = createAction(
  Actions_KnowledgeBase.New_ATTACHMENTFILE
);
export const _updateAttachmentFile = createAction(
  Actions_KnowledgeBase.UPDATE_ATTACHMENTFILE
);
export const _removeAttachmentFile = createAction(
  Actions_KnowledgeBase.REMOVED_ATTACHMENTFILE
);
export const _copyAttachmentFile = createAction(
  Actions_KnowledgeBase.COPY_ATTACHMENTFILE
);
export const _getAttachmentFileList = createAction(
  Actions_KnowledgeBase.GET_ATTACHMENTFILE_LIST
);
export const _getAttachmentFolderList = createAction(
  Actions_KnowledgeBase.GET_ATTACHMENTFILEFOLDER_LIST
);
export const _newShareAttachmentFile = createAction(
  Actions_KnowledgeBase.NEW_SHAREATTACHMENTFILE
);
export const _getShareAttachmentFileList = createAction(
  Actions_KnowledgeBase.GET_SHAREATTACHMENTFILE_LIST
);
export const _removeShareAttachmentFile = createAction(
  Actions_KnowledgeBase.REMOVED_SHAREATTACHMENTFILE
);
export const knowledgebaseReducers = {
  _clearFilesList,
  _clearIsView,
  _tenant_entity,
  _setStorageId,
  _getSignature,
  _uploadAttachmentFileToS3,
  _newAttachmentFile,
  _updateAttachmentFile,
  _removeAttachmentFile,
  _copyAttachmentFile,
  _getAttachmentFileList,
  _newShareAttachmentFile,
  _getShareAttachmentFileList,
  _removeShareAttachmentFile,
  _getAttachmentFolderList
};

/**
 * Reducer
 */
const defaultState = {
  attachmentfileList: [],
  publicList: [],
  clientList: [],
  storageId: 'url',
  folder: [],
  folderId: 0,
  requestAttachmentParams: {
    size: 100,
    page: 1,
    attachmetIdIn: [],
    parentId: ''
  },
  shareData: false,
  allFiles: [],
  isView: false,
  tenantEntity: '',
  uploadS3Data: {
    url: '',
    method: '',
    options: {},
    headers: {},
    key: ''
  } // 储存S3的变量
};

export type knowledgebaseState = Readonly<typeof defaultState>;
export default handleActions(
  {
    [Actions_KnowledgeBase.TENANT_ENTITY]: (state, action: any) => {
      return {
        ...state,
        tenantEntity: action.payload.tenantEntity
      };
    },
    [Actions_KnowledgeBase.CLEAR_IS_VIEW]: (state, action: any) => {
      return {
        ...state,
        isView: action.payload.isView
      };
    },
    [Actions_KnowledgeBase.CLEAR_ALLFILES_LIST]: (state) => {
      return {
        ...state,
        allFiles: []
      };
    },
    [Actions_KnowledgeBase.GET_UPLOADS3DATA_SUCCESS]: (state, action: any) => {
      return {
        ...state,
        uploadS3Data: action.payload
      };
    },
    [Actions_KnowledgeBase.UPLOAD_ATTACHMENTFILETOS3_SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        storageId: action.payload
      };
    },
    [Actions_KnowledgeBase.GET_ATTACHMENTFILESHARETOME_LIST_SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        loading: false,
        shareData: action.payload
      };
    },
    [Actions_KnowledgeBase.GET_ATTACHMENTFILEFOLDER_LIST_SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        loading: false,
        folder: action.payload.folder
      };
    },
    [Actions_KnowledgeBase.GET_ATTACHMENTFILE_LIST_SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        loading: false,
        attachmentfileList: action.payload.attachmentfileList,
        folderId: action.payload.parentId,
        allFiles: action.payload.allFiles
      };
    },
    [Actions_KnowledgeBase.GET_SHAREATTACHMENTFILE_LIST_SUCCESS]: (
      state,
      action: any
    ) => {
      return {
        ...state,
        loading: false,
        publicList: action.payload.publicList,
        clientList: action.payload.clientList
      };
    },
    [Actions_KnowledgeBase.SET_STORAGE_ID]: (state, action: any) => {
      return {
        ...state,
        storageId: action.payload.storageId
      };
    }
  },
  defaultState
);

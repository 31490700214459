/* eslint-disable max-len */
import React, { Fragment, useRef, useState } from 'react';
import { MaximizeIcon, XIcon } from '@/assets/featherIcons';
import { Dialog, Transition } from '@headlessui/react';
import * as Request from '@/axios/request';
import { useHistory } from 'react-router-dom';
import { allReducers } from '@/store/reducers';
import { useDispatch } from 'react-redux';
import jsQR from 'jsqr';
import './qrcode.css';
import toast from 'react-hot-toast';
import { openAlertDialog } from '@/utils/openDialog';
import { t } from '@/i18n';
import FreeField from './FreeField';
import FreeButton from './FreeButton';

const FreeQrCode = (props: any) => {
  const cameraVideoRef = useRef(null);
  const cameraCanvasRef = useRef(null);
  const dispatch = useDispatch();

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [hasDevice, setHasDevice] = useState(false);

  // 使用扫码枪输入
  function getCode() {
    const qrCodeEvent: any = document.getElementById('qrCode');
    qrCodeEvent?.setAttribute('readonly', 'readonly');
    setTimeout(() => {
      qrCodeEvent?.removeAttribute('readonly');
    }, 200);
    qrCodeEvent.addEventListener('keydown', (event: any) => {
      if (event.code === 'Enter') {
        // 此处为扫描枪输入
        const url = qrCodeEvent.value;
        goToUrl(url);
        qrCodeEvent.value = '';
        qrCodeEvent?.removeEventListener('keydown', () => {});
      }
    });
  }

  // 扫描成功后操作
  async function goToUrl(url: string) {
    console.log('goToUrlgoToUrlgoToUrl', url);

    if (!url?.includes('assetbozz')) {
      handleClose();
      toast.error(<span style={{ color: '#b91c1c' }}>Invalid QR code</span>, {
        duration: 3000,
        position: 'bottom-left'
      });
      return;
    }
    handleClose();
    dispatch(allReducers._voidBookingInfo());
    const code = url?.split('qrcode/')[1];
    if (code) {
      if (localStorage.getItem('user')) {
        const res: any = await Request.getBookingByCode({
          code
        });
        if (res?.result === 0) {
          if (res?.data?.entity === 'booking') {
            const book: any = await Request.getBookingRecordsJavaInfo(
              res?.data?.entityId
            );
            if (!book?.data || book?.data?.accountId !== res?.data?.accountId) {
              openAlertDialog({
                type: 'error',
                title: t(
                  'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
                ),
                size: '2xl',
                leftBtn: {
                  label: 'ok',
                  onClick: (close) => {
                    close();
                  }
                }
              });
              return;
            }
            history.push(`/v1/bookingInfo/${res?.data?.entityId}/${code}`);
            return;
          } else {
            openAlertDialog({
              type: 'error',
              title: t(
                'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
              ),
              size: '2xl',
              leftBtn: {
                label: 'ok',
                onClick: (close) => {
                  close();
                }
              }
            });
            return;
          }
        } else {
          // history.push('/v1/bookings');
          return;
        }
      } else {
        const link = `/login/qrcode/${code}`;
        const win: any = window;
        win.top.location.href = link;
        return;
      }
    }
  }

  function drawLine(begin: any, end: any, color: any, canvas: any) {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
  }

  const tick = (video: any, canvasElement: any) => {
    if (!video || !canvasElement) return;
    const canvas: any = canvasElement.getContext('2d');
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
      const imageData = canvas.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      const code: any = jsQR(
        imageData.data,
        imageData.width,
        imageData.height,
        {
          inversionAttempts: 'dontInvert'
        }
      );

      if (code) {
        drawLine(
          code.location.topLeftCorner,
          code.location.topRightCorner,
          '#FF3B58',
          canvas
        );
        drawLine(
          code.location.topRightCorner,
          code.location.bottomRightCorner,
          '#FF3B58',
          canvas
        );
        drawLine(
          code.location.bottomRightCorner,
          code.location.bottomLeftCorner,
          '#FF3B58',
          canvas
        );
        drawLine(
          code.location.bottomLeftCorner,
          code.location.topLeftCorner,
          '#FF3B58',
          canvas
        );
        goToUrl(code.data);
      }
    }
    requestAnimationFrame(() => tick(video, canvasElement));
  };

  function successFunc(mediaStream: any) {
    setHasDevice(true);
    const video: any = cameraVideoRef.current;
    const canvasElement: any = cameraCanvasRef.current;
    video.srcObject = mediaStream;
    video.setAttribute('playsinline', true);
    video.play();
    requestAnimationFrame(() => tick(video, canvasElement));
    // const video = document.getElementById('cameraVideo') as HTMLVideoElement;
    // 旧的浏览器可能没有srcObject
    // if ('srcObject' in video) {
    // }
    // video.onloadedmetadata = () => {
    //   video.play();
    //   requestAnimationFrame(tick);
    // };
  }

  function errorFunc(err: any) {
    console.log(`${err.name}------: ${err.message}`);
    // 没有开启摄像头
    getCode();
    // always check for errors at the end.
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // openScan();
    const opt = {
      audio: false,
      video: { facingMode: 'environment' }
      // video: true
    };
    navigator.mediaDevices.getUserMedia(opt).then(successFunc).catch(errorFunc);
  };

  // 关闭摄像头
  const closeMedia = () => {
    const video: any = cameraVideoRef.current;
    const stream: any = video?.srcObject;
    if (stream?.getTracks) {
      const tracks = stream.getTracks();
      tracks.forEach((track: any) => {
        track.stop();
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    closeMedia();
  };

  const [bookingId, setBookingId] = useState('');

  return (
    <Fragment>
      {(props?.child && <div onClick={handleClick}>{props?.child}</div>) || (
        <button
          type="button"
          className="rounded-full p-2.5 border"
          onClick={handleClick}
        >
          <MaximizeIcon />
        </button>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[99999]" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-300"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-300"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-full">
                    <div className="flex h-full flex-col bg-white shadow-xl">
                      <div className="p-4 px-6 bg-cyan-600">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-xl font-medium leading-6 text-whitenew">
                            QrCode
                          </Dialog.Title>
                          <input
                            id="qrCode"
                            autoComplete="off"
                            // type="password"
                            className="fixed -top-[120vh] -z-50"
                            autoFocus
                          />
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500
                                focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon
                                className="h-6 w-6 text-whitenew"
                                aria-hidden="true"
                                onClick={handleClose}
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                          }}
                        >
                          {hasDevice && props?.type === 'booking' && (
                            <div className="flex flex-col justify-center items-center mb-10 -mt-[50px]">
                              <div
                                style={{
                                  color: '#000',
                                  fontSize: '20px',
                                  lineHeight: '28px',
                                  // fontFamily: 'Inter',
                                  fontWeight: 600
                                }}
                              >
                                {t('Scanning QR Code')}
                              </div>
                              <div
                                className="text-center mt-5 font-medium"
                                style={{ color: '#374151' }}
                              >
                                {t(
                                  'Please scan the QR code of the booking in order to check-in'
                                )}
                              </div>
                            </div>
                          )}
                          {(hasDevice && (
                            <div
                              className="w-[300px] h-[300px] sm:w-[500px] sm:h-[500px]"
                              style={{
                                background:
                                  'linear-gradient(#0891b2, #0891b2) left top,linear-gradient(#0891b2, #0891b2) left top,linear-gradient(#0891b2, #0891b2) right top,linear-gradient(#0891b2, #0891b2) right top,linear-gradient(#0891b2, #0891b2) right bottom,linear-gradient(#0891b2, #0891b2) right bottom,linear-gradient(#0891b2, #0891b2) left bottom,linear-gradient(#0891b2, #0891b2) left bottom',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '2px 20px, 20px 2px'
                              }}
                            >
                              <div
                                style={{
                                  width: 'calc(100% - 4px)',
                                  height: 'calc(100% - 4px)'
                                }}
                              >
                                <video
                                  ref={cameraVideoRef}
                                  className="w-full h-full m-[2px] border border-cyan-600 object-cover"
                                />
                                <canvas
                                  id="canvas"
                                  hidden
                                  ref={cameraCanvasRef}
                                />
                              </div>
                            </div>
                          )) || (
                            <div className="text-lg text-cyan-600 text-center">
                              {t(
                                'Please make sure to turn on the scanning device'
                              )}
                            </div>
                          )}
                          {hasDevice && props?.type === 'booking' && (
                            <div className="flex flex-col justify-center items-center font-medium w-[300px]">
                              <div className="my-5">{t('Or')}</div>
                              <div className="mb-3">
                                {t('Enter the booking ID to check in')}
                              </div>
                              <FreeField
                                placeholder={t('Enter booking ID')}
                                onChange={(e: any) => {
                                  setBookingId(e?.target?.value);
                                }}
                                rightIcon={
                                  <FreeButton
                                    text={t('Confirm')}
                                    style={{ margin: 0 }}
                                    onClick={() => {
                                      console.log(bookingId);
                                    }}
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

export default FreeQrCode;

/*
 * @Date: 2020-06-16 14:42:29
 * @LastEditTime: 2022-02-23 23:25:08
 */

import { combineReducers } from 'redux';
import common, { commonState, action_common, commonReducers } from './common';
import login, { loginState, action_login, loginreducers } from './login';
import JobReducer, { jobReducers, Actions_Job, jobState } from './job';
import RolesReducer, { rolesReducers, action_roles, rolesState } from './roles';
import AssetReducer, {
  assetState,
  assetReducers,
  Actions_Asset
} from './asset';
import propertyReducer, {
  propertyState,
  propertyReducers,
  Actions_Property
} from './property';
import SettingsReducer, {
  settingsState,
  settingReducers,
  Actions_Settings
} from './settings';
import FinancialReducer, {
  financialState,
  financialReducers,
  Actions_Financial
} from './financial';
import TenantReducer, {
  tenantState,
  tenantReducers,
  Actions_Tenant
} from './tenant';
import KnowledgeBaseReducer, {
  knowledgebaseState,
  knowledgebaseReducers,
  Actions_KnowledgeBase
} from './knowledge';
import NotificationReducer, {
  notificationState,
  notificationReducers,
  Actions_Notification
} from './notification';
import SpaceReducer, {
  spaceState,
  spaceReducers,
  Actions_Space
} from './Freemium/Space';
import BookingReducer, {
  bookingState,
  bookingReducers,
  Actions_Booking
} from './Freemium/Booking';
export interface BaseAction {
  type: symbol;
  payload: any;
}
export const Actions = {
  ...action_common,
  ...action_login,
  ...Actions_Job,
  ...action_roles,
  ...Actions_Property,
  ...Actions_Asset,
  ...Actions_Settings,
  ...Actions_Financial,
  ...Actions_Tenant,
  ...Actions_KnowledgeBase,
  ...Actions_Notification,
  ...Actions_Space,
  ...Actions_Booking
};
export interface storeState {
  commonState: commonState;
  loginState: loginState;
  jobState: jobState;
  rolesState: rolesState;
  propertyState: propertyState;
  assetState: assetState;
  settingsState: settingsState;
  financialState: financialState;
  tenantState: tenantState;
  knowledgebaseState: knowledgebaseState;
  notificationState: notificationState;
  spaceState: spaceState;
  bookingState: bookingState;
}
export const allReducers = {
  ...commonReducers,
  ...loginreducers,
  ...jobReducers,
  ...rolesReducers,
  ...propertyReducers,
  ...assetReducers,
  ...settingReducers,
  ...financialReducers,
  ...tenantReducers,
  ...knowledgebaseReducers,
  ...notificationReducers,
  ...spaceReducers,
  ...bookingReducers
};

export default combineReducers<storeState>({
  commonState: common,
  loginState: login,
  jobState: JobReducer,
  rolesState: RolesReducer,
  propertyState: propertyReducer,
  assetState: AssetReducer,
  settingsState: SettingsReducer,
  financialState: FinancialReducer,
  tenantState: TenantReducer,
  knowledgebaseState: KnowledgeBaseReducer,
  notificationState: NotificationReducer,
  spaceState: SpaceReducer,
  bookingState: BookingReducer
});


import { lazy } from 'react';
// freemium 版本的路由
const FreemiumSpaces = lazy(() => import('@/views/Spaces'));
const FreemiumSpacesInfo = lazy(() => import('@/views/Spaces/Page/SpaceInfo'));
const FreemiumBookings = lazy(() => import('@/views/Bookings'));
const FreemiumBookingsInfo = lazy(
  () => import('@/views/Bookings/Page/BookingInfo')
);
const FreemiumClients = lazy(() => import('@/views/Clients'));
const FreemiumClientsInfo = lazy(
  () => import('@/views/Clients/Page/ClientInfo')
);
const FreemiumClientsUserInfo = lazy(
  () => import('@/views/Clients/Page/ClientUserInfo')
);
const FreemiumUserInfo = lazy(() => import('@/views/Clients/Page/UserInfo'));
const FreemiumSettings = lazy(() => import('@/views/Settings'));
const FreemiumShare = lazy(() => import('@/views/Spaces/components/ShareView'));
const FreemiumDashboard = lazy(() => import('@/views/Dashboard'));
const FreemiumMobileNewBooking = lazy(
  () => import('@/views/Bookings/Page/MobileNewBooking')
);

// layout = admin 页面嵌套在php里面 跳转时用window.top
// layout = v1 页面在浏览器最顶层
const HomeRoutes: any = [
  // php嵌套react 用admin
  {
    path: '/shareView',
    name: 'FreemiumShare',
    component: FreemiumShare,
    layout: '/admin'
  },

  // free 版本
  {
    path: '/dashboard',
    name: 'FreemiumDashboard',
    component: FreemiumDashboard,
    layout: '/v1'
  },
  {
    path: '/spaces',
    name: 'FreemiumSpaces',
    component: FreemiumSpaces,
    layout: '/v1'
  },
  {
    path: '/spaceInfo/:entityId',
    name: 'FreemiumSpaces',
    component: FreemiumSpacesInfo,
    layout: '/v1'
  },
  {
    path: '/bookings',
    name: 'FreemiumSpaces',
    component: FreemiumBookings,
    layout: '/v1'
  },
  {
    path: '/bookingInfo/:entityId/:qrcode?',
    name: 'FreemiumSpaces',
    component: FreemiumBookingsInfo,
    layout: '/v1'
  },
  {
    path: '/mobileNewBooking',
    name: 'FreemiumSpaces',
    component: FreemiumMobileNewBooking,
    layout: '/v1'
  },
  {
    path: '/clients',
    name: 'FreemiumSpaces',
    component: FreemiumClients,
    layout: '/v1'
  },
  {
    path: '/clientInfo/:entityId/:tabCardType?',
    name: 'FreemiumSpaces',
    component: FreemiumClientsInfo,
    layout: '/v1'
  },
  {
    path: '/clientUserInfo/:entityId',
    name: 'FreemiumSpaces',
    component: FreemiumClientsUserInfo,
    layout: '/v1'
  },
  {
    path: '/userInfo',
    name: 'FreemiumSpaces',
    component: FreemiumUserInfo,
    layout: '/v1'
  },
  {
    path: '/settings',
    name: 'FreemiumSpaces',
    component: FreemiumSettings,
    layout: '/v1'
  }
];

export default HomeRoutes;


import { takeLatest, call, put } from 'redux-saga/effects';
import { Actions, allReducers } from '@/store/reducers';
import * as request from '@/axios/request';
import _ from 'lodash';
import { RequestFullData } from '@/utils/fn';
export function* watchHomeAssetListSaga() {
  yield takeLatest(Actions.GET_ALL_ASSET_LIST, getAllAssetList);
}

/**
 * 获取物资列表
 * @param props
 */
// chat info asset list 2022-01-29
function* getAllAssetList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allAssetList',
      value: true
    })
  );

  try {
    const { result } = yield call(
      RequestFullData,
      request.getAssetList,
      1,
      props.payload,
      []
    );
    let arr: any = [];
    if (props.payload.coffee_member_id) {
      arr = result.map((value: any) => {
        let memberId = '';
        _.get(value, 'freeFormatFields.free_format_fields', []).forEach(
          (item: any) => {
            if (item?.key === 'member_id') {
              memberId = item?.value;
            }
          }
        );
        return {
          value: _.get(value, 'asset_id', ''),
          label: `${memberId} - ${_.get(value, 'product_name', '')}`,
          data: value
        };
      });
    } else {
      arr = result.map((value: any) => {
        return {
          value: _.get(value, 'asset_id', ''),
          label: `${_.get(value, 'product_name', '')}(${_.get(
            value,
            'brand',
            ''
          )})`,
          data: value
        };
      });
    }
    if (arr.length) {
      yield put(allReducers._getAllAssetListComplete(arr));
    }
  } catch (error) {
    console.log('error', error);
  }

  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allAssetList',
      value: false
    })
  );
}


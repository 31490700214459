export const Progress = () => {
  // 创建元素追加到body
  let div: any = document.createElement('div');
  const bar = document.createElement('div');
  div.appendChild(bar);
  document.body.appendChild(div);
  // div.className = 'animate-pulse';
  // 随便写点样式
  div.style.cssText = `
    position: fixed;
    top: 0;
    width: 100%;
    height: 3px;
    z-index: 99999;
  `;
  bar.style.cssText = `
    height: inherit;
    width: 0px;
    background-color: #0891b2;
    transition: all 300ms ease 0s
  `;
  // 写个计时器，每隔10毫秒增加进度条长度
  let speed = 30;
  const timer = setInterval(() => {
    // 只加到99，剩下的等请求结束后再加
    // 加入随机数 使进度条的进度平滑
    // 多层判断使进度条更平滑
    if (speed <= 30) {
      speed += Math.random();
      bar.style.width = speed + '%';
    }
    if (speed > 30 && speed <= 50) {
      speed += Math.random() / 2;
      bar.style.width = speed + '%';
    }
    if (speed > 50 && speed <= 70) {
      speed += Math.random() / 3;
      bar.style.width = speed + '%';
    }
    if (speed > 70 && speed <= 90) {
      speed += Math.random() / 4;
      bar.style.width = speed + '%';
    }
    if (speed > 90 && speed <= 95) {
      speed += Math.random() / 5;
      bar.style.width = speed + '%';
    }
    if (speed > 95 && speed <= 99) {
      speed += Math.random() / 6;
      bar.style.width = speed + '%';
    }
  }, 10);

  return {
    destroy() {
      // 清除计时器
      clearInterval(timer);
      // 请求结束后加到100
      bar.style.width = 100 + '%';
      if (div) {
        // 等200毫秒后清除，不然同时进行，效果不好
        setTimeout(() => {
          document.body.removeChild(div);
          // 元素清除之后必须赋值为空，不然会报错
          div = null;
        }, 400);
      }
    }
  };
};

/*
 * @Date: 2021-07-26 19:02:43
 * @LastEditTime: 2021-07-30 12:36:14
 * @Author: 爱你一万年！！！
 * @LastEditors: 爱你一万年！！！
 * @File info:
 */
/*
 * @Date: 2020-06-22 15:21:46
 * @LastEditTime: 2021-07-26 14:32:06
 */

import { BaseAction } from '..';
export const action_roles = {
  GET_PROVIDER: Symbol('GET_PROVIDER'),
  GET_PROVIDER_SUCCESS: Symbol('GET_PROVIDER_SUCCESS'),
  GET_ALL_PROVIDER: Symbol('GET_ALL_PROVIDER'),
  GET_ALL_PROVIDER_SUCCESS: Symbol('GET_ALL_PROVIDER_SUCCESS')
};

export const _getProvider: (payload: any) => BaseAction = (payload) => ({
  type: action_roles.GET_PROVIDER,
  payload
});
export const _getProviderSuccess: (payload: any) => BaseAction = (payload) => ({
  type: action_roles.GET_PROVIDER_SUCCESS,
  payload
});
export const _getAllProvider: (payload: any) => BaseAction = (payload) => ({
  type: action_roles.GET_ALL_PROVIDER,
  payload
});
export const _getAllProviderSuccess: (payload: any) => BaseAction = (
  payload
) => ({
  type: action_roles.GET_ALL_PROVIDER_SUCCESS,
  payload
});

export const rolesReducers = {
  _getProvider,
  _getProviderSuccess,
  _getAllProvider,
  _getAllProviderSuccess
};
interface Iprops {
  provider: {
    value: string;
    label: string;
    thumbnail: string;
  }[];
  allProvider: {
    value: string;
    label: string;
  }[];
}
const initialState: Iprops = {
  provider: [],
  allProvider: []
};

export type rolesState = Readonly<typeof initialState>;

export default (state: Iprops = initialState, action: any) => {
  switch (action.type) {
    case action_roles.GET_PROVIDER_SUCCESS:
      return {
        ...state,
        provider: action.payload
      };
    case action_roles.GET_ALL_PROVIDER_SUCCESS:
      return {
        ...state,
        allProvider: action.payload
      };

    default:
      return state;
  }
};

/*
 * @Date: 2022-02-23 23:15:51
 * @LastEditTime: 2022-03-10 12:21:28
 * @Author: mebin
 * @LastEditors: mebin
 * @File info:
 */
import { createAction, handleActions } from 'redux-actions';

export const Actions_Notification = {
  GET_NOTIFICATION_LIST: 'GET_NOTIFICATION_LIST',
  GET_NOTIFICATION_LIST_SUCCESS: 'GET_NOTIFICATION_LIST_SUCCESS',
  NEW_NOTIFICATION: 'NEW_NOTIFICATION',
  GET_NOTIFICATION_USER_LIST: 'GET_NOTIFICATION_USER_LIST',
  GET_NOTIFICATION_USER_LIST_SUCCESS: 'GET_NOTIFICATION_USER_LIST_SUCCESS'
};

export const _getNotificationList = createAction(
  Actions_Notification.GET_NOTIFICATION_LIST
);
export const _getNotificationListSuccess = createAction(
  Actions_Notification.GET_NOTIFICATION_LIST_SUCCESS
);
export const _newNotification = createAction(
  Actions_Notification.NEW_NOTIFICATION
);
export const _getNotificationUserList = createAction(
  Actions_Notification.GET_NOTIFICATION_USER_LIST
);
export const _getNotificationUserListSuccess = createAction(
  Actions_Notification.GET_NOTIFICATION_USER_LIST_SUCCESS
);

export const notificationReducers = {
  _getNotificationList,
  _getNotificationListSuccess,
  _newNotification,
  _getNotificationUserList,
  _getNotificationUserListSuccess
};

const defaultState = {
  NotificationList: [],
  NotificationTotal: 0,
  NotificationPageNum: 0,
  NotificationUserList: [],
  NotificationUserTotal: 0,
  requestNotificationUserParams: {
    page: 1,
    size: 20
  }
};

export type notificationState = Readonly<typeof defaultState>;

export default handleActions(
  {
    [Actions_Notification.GET_NOTIFICATION_LIST_SUCCESS]: (
      state,
      actions: any
    ) => {
      return {
        ...state,
        NotificationList: actions.payload.data,
        NotificationTotal: actions.payload.total,
        NotificationPageNum: actions.payload.pageNum
      };
    },
    [Actions_Notification.GET_NOTIFICATION_USER_LIST_SUCCESS]: (
      state,
      actions: any
    ) => {
      return {
        ...state,
        NotificationUserList: actions.payload.data,
        NotificationUserTotal: actions.payload.total
      };
    }
  },
  defaultState
);

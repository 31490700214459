import Api, { HOMEBOZZ_IMAGE_URL_S3 } from '@/axios/api';

export function renderImageurl_tn(photoid: any) {
  return `${Api.image.tnimage}${photoid}/tn/`;
}

export function renderImageurl_std(photoid: any) {
  return `${Api.image.tnimage}${photoid}/std/`;
}

export function renderImageurl_org(photoid: any) {
  return `${Api.image.tnimage}${photoid}/org/`;
}

export function renderImageurl_s3(photoid: any) {
  // console.log(
  //   'ssasasasasa',
  //   `${HOMEBOZZ_IMAGE_URL_S3}/${photoid}/image-content/actions/visitor`
  // );
  return `${HOMEBOZZ_IMAGE_URL_S3}/${photoid}/image-content/actions/visitor`;
}


/* eslint-disable no-duplicate-case */
import { createAction, handleActions } from 'redux-actions';

/**
 * Actions
 */
export const Actions_Space = {
  // space info列表相关
  GETSPACE_INFO: 'GETSPACE_INFO',
  GETSPACE_INFOSUCCESS: 'GETSPACE_INFOSUCCESS',

  GETBOOKING_INFO: 'GETBOOKING_INFO',
  GETBOOKING_INFOSUCCESS: 'GETBOOKING_INFOSUCCESS',

  UPDATESPACE_INFO: 'UPDATESPACE_INFO',
  UPDATESPACE_INFOSUCCESS: 'UPDATESPACE_INFOSUCCESS',

  UPDATEBOOKING_INFO: 'UPDATEBOOKING_INFO',
  UPDATEBOOKING_INFOSUCCESS: 'UPDATEBOOKING_INFOSUCCESS',

  SPACEMODAL: 'SPACEMODAL',
  BOOKINGMODAL: 'BOOKINGMODAL',
  SETMODAL: 'SETMODAL',
  DUPLICATESPACEMODAL: 'DUPLICATESPACEMODAL'
};

/**
 * Actions Creators
 */

export const _getSpaceInfo = createAction(Actions_Space.GETSPACE_INFO);
export const _getBookingInfo = createAction(Actions_Space.GETBOOKING_INFO);

export const _updateSpaceInfo = createAction(Actions_Space.UPDATESPACE_INFO);
export const _updateBookingInfo = createAction(
  Actions_Space.UPDATEBOOKING_INFO
);
// 弹窗控制
export const _openSapceModal = createAction(Actions_Space.SPACEMODAL);
export const _openDuplicateSapceModal = createAction(
  Actions_Space.DUPLICATESPACEMODAL
);
export const _openBookingModal = createAction(Actions_Space.BOOKINGMODAL);

export const _setModalAction = createAction(Actions_Space.SETMODAL);

export const spaceReducers = {
  _getSpaceInfo,
  _getBookingInfo,
  _updateSpaceInfo,
  _updateBookingInfo,
  _openBookingModal,
  _openSapceModal,
  _setModalAction,
  _openDuplicateSapceModal
};
/**
 * Reducer
 */

const defaultSessionState = {
  propertiesInfo: {
    phonecc: '',
    phone: '',
    currency: '',
    templateFields: {},
    status: '',
    bookingNum: '',
    revenueTotal: '',
    clientTotal: '',
    optikiSN: ''
  },
  bookingInfo: {
    priceMemberD: '',
    priceNonMemberD: '',
    dayPriceMemberD: '',
    dayPriceNonMemberD: '',
    timeUnit: '',
    fromTime: '',
    toTime: '',
    bookingUnit: '',
    actived: 0,
    dayOfWeekIn: ''
  },
  spaceModal: false,
  duplicateSpaceModal: false,
  bookingModal: false,
  publishModal: false
};
export type spaceState = Readonly<typeof defaultSessionState>;
export default handleActions(
  {
    [Actions_Space.GETSPACE_INFO]: (state) => {
      return {
        ...state
      };
    },
    [Actions_Space.GETSPACE_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        propertiesInfo: action.payload
      };
    },
    // 更新spaceinfo
    [Actions_Space.UPDATESPACE_INFO]: (state) => {
      return {
        ...state
      };
    },
    [Actions_Space.GETBOOKING_INFO]: (state) => {
      return {
        ...state
      };
    },
    [Actions_Space.GETBOOKING_INFOSUCCESS]: (state, action: any) => {
      return {
        ...state,
        bookingInfo: action.payload
      };
    },
    // 更新bookinginfo
    [Actions_Space.UPDATEBOOKING_INFO]: (state) => {
      return {
        ...state
      };
    },
    // 弹窗space
    [Actions_Space.SPACEMODAL]: (state, action: any) => {
      return {
        ...state,
        spaceModal: action.payload
      };
    },
    [Actions_Space.DUPLICATESPACEMODAL]: (state, action: any) => {
      return {
        ...state,
        duplicateSpaceModal: action.payload
      };
    },
    // 弹窗booking
    [Actions_Space.BOOKINGMODAL]: (state, action: any) => {
      return {
        ...state,
        bookingModal: action.payload
      };
    },
    // 根据字段修改弹框
    [Actions_Space.SETMODAL]: (state, action: any) => {
      return {
        ...state,
        publishModal: action.payload
      };
    }
  },
  defaultSessionState
);


import { takeLatest, call, put, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import { Actions, allReducers, storeState } from '@/store/reducers';
import * as request from '@/axios/request';
import _ from 'lodash';
import { RESPONSE_TYPES } from '@/axios/axios';
import { RequestFullData } from '@/utils/fn';
import { linkToTenant } from '@/utils/url';
import { t } from '@/i18n';

export function* watchHomeTenantSaga() {
  yield takeLatest(Actions.GET_ALL_TENANT_LIST, getAllTenantList);
  yield takeLatest(Actions.SAVE_TENANT, saveTenant);
  yield takeLatest(Actions.SAVE_TENANCY, saveTenancy);
  yield takeLatest(Actions.UPDATE_TENANT, updateTenant);
  yield takeLatest(Actions.DELETE_TENANT, deleteTenant);
  yield takeLatest(Actions.GET_TENANT_INFO, getTenantInfo);
  yield takeLatest(Actions.GET_TENANCIES_INFO, getTenanciesInfo);
  yield takeLatest(Actions.UPDATE_TENANT_STATUS, updateTenantStatus);
  yield takeLatest(Actions.UPDATE_TENANCY, updateTenancy);
  yield takeLatest(Actions.GET_USER_BIND_TENANT_DATA, getUserBindTenantData);
  yield takeLatest(Actions.GET_TENANCIES_COUNT, getTenancyCount);
}

/**
 * 创建租户
 * @param props
 */
function* saveTenant(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'newTenant',
      value: true
    })
  );

  const params = {
    name: _.get(props, 'payload.Tenant_name', ''),
    email: _.get(props, 'payload.Tenant_Email', ''),
    status: _.get(props, 'payload.owner_priority', ''),
    contactName: _.get(props, 'payload.contact_name', ''),
    contactPhoneCC: _.get(props, 'payload.contact_phoneCC', '').split('+')[1],
    contactPhone: _.get(props, 'payload.contact_phone', ''),
    address1: _.get(props, 'payload.description', ''),
    refId: _.get(props, 'payload.refId', ''),
    category: _.get(props, 'payload.category', ''),
    source: _.get(props, 'payload.source', '')
  };
  try {
    const res: RESPONSE_TYPES = yield call(request.saveTenant_Java, params);
    if (res.result === 0) {
      // yield put(allReducers._getTenantlist({ fill: 0 }));
      const obj = res?.data;
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newTenantView',
          value: false
        })
      );
      if (obj?.status === '2') {
        toast(t('Failed to sync xero!'), {
          icon: '❗',
          duration: 3000,
          id: 'messageTip',
          position: 'top-center',
          style: {
            maxWidth: '500px',
            wordWrap: 'break-word'
          }
        });
      }
      linkToTenant({
        id: obj?.id,
        entityId: obj?.id,
        entity: 'tenant'
      });
    } else if (res.result === -1) {
      const setErrorText = _.get(props, 'payload.setErrorText', '');
      setErrorText(`Add new tenants error!`);
    }
  } catch (err) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'newTenant',
      value: false
    })
  );
}

/**
 * 获取租户信息
 * @param props
 */
function* getTenantInfo(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getTenantInfo',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getTenantInfo_Java,
      props.payload
    );
    if (res.result === 0) {
      yield put({
        type: 'GET_TENANT_INFO_COMPLETE',
        payload: res.data
      });
    } else {
      toast(res.data, {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (err) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getTenantInfo',
      value: false
    })
  );
}

/**
 * 获取租约信息
 * @param props
 */
function* getTenanciesInfo(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getTenanciesInfo_Java,
      props.payload
    );
    if (res.result === 0) {
      yield put({
        type: 'GET_TENANCIES_INFO_COMPLETE',
        payload: res.data
      });
    }
  } catch (err) {}
}

/*
 * 新增  tenancy
 * @param params
 */
function* saveTenancy(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: false
    })
  );
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'newTenancy',
      value: true
    })
  );
  try {
    if (props?.payload?.storageId) {
      props.payload = {
        ...props.payload,
        files: [
          {
            storageId: props.payload.storageId,
            fileName: props.payload.fileName,
            fileSize: props.payload.fileSize,
            mineType: props.payload.mineType,
            attachmentType: props.payload.attachmentType
          }
        ]
      };
      delete props.payload.storageId;
      delete props.payload.fileName;
      delete props.payload.fileSize;
      delete props.payload.mineType;
      delete props.payload.attachmentType;
    }
    const res: RESPONSE_TYPES = yield call(
      request.saveTenancy_JAVA,
      props.payload
    );
    if (res.result === 0) {
      const setUpload = _.get(props, 'payload.setUpload', '');
      const renew = _.get(props, 'payload.renew', '');
      setUpload(false);
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newTenancy',
          value: false
        })
      );
      if (renew) {
        yield put(
          allReducers._FullScreenBackdropStatus({
            key: 'renewTenancyModal',
            value: false
          })
        );
      } else {
        yield put(
          allReducers._FullScreenBackdropStatus({
            key: 'newTenancyModal',
            value: false
          })
        );
      }
      props?.payload?.callBack(res);
      // linkToTenancy({
      //   id: res.data,
      //   entityId: res.data,
      //   entity: 'tenancy'
      // });
    } else {
      const setUpload = _.get(props, 'payload.setUpload', '');
      setUpload(false);
      if (res.data.message === 'file_name_repeat') {
        const setShowFileError = _.get(props, 'payload.setShowFileError', '');
        setShowFileError(true);
      }
      if (res.data.message === 'tenancy_number_repeated') {
        const setErrorText = _.get(props, 'payload.setTenancyNOErrorText', '');
        setErrorText(t('The tenancyNumber already exists, please re-enter!'));
      }
      if (res.data.message === 'description_length_too_long') {
        const setErrorText = _.get(props, 'payload.setTextError', '');
        setErrorText(t('Description is too long, please re-enter.'));
      }

      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newTenancy',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
      return;
    }
  } catch (err) {}
}

/** tenancies 获取数量 */
function* getTenancyCount(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getTenancyCount_Java,
      props.payload
    );
    if (res.result === 0) {
      const datas = _.get(res, 'data', {});
      yield put({ type: 'GET_TENANCIES_COUNT_SUCCESS', payload: datas });
    }
  } catch (e) {
    console.log(e);
  }
}

/**
 * 获取与tenant相关的user列表
 * @param params
 */
function* getUserBindTenantData(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getTenantUser_Java,
      props.payload
    );

    if (res.result === 0) {
      const datas = _.get(res, 'data', []);
      yield put({
        type: 'GET_USER_BIND_TENANT_DATA_COMPLETE',
        payload: datas
      });
    }
  } catch {}
}

/**
 * 通过多个请求获取全部的分页数据
 * @param props
 */
function* getAllTenantList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allTenantList',
      value: true
    })
  );
  try {
    const { result } = yield call(
      RequestFullData,
      request.getTenantListing_Java,
      1,
      props.payload,
      []
    );
    const arr: any = result.map((value: any) => {
      return {
        value: _.get(value, 'id', ''),
        label: _.get(value, 'name', ''),
        data: value
      };
    });
    if (arr.length) {
      yield put(allReducers._getAllTenantListComplete(arr));
    }
  } catch (error) {
    console.log('error', error);
  }

  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'allTenantList',
      value: false
    })
  );
}

/**
 * 修改租客
 * @param props
 */
function* updateTenant(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenant',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(request.updateTenant_Java, {
      params: props.payload,
      id: _.get(props, 'payload.id', '')
    });
    if (res.result === 0) {
      yield put(allReducers._getTenantInfo(_.get(props, 'payload.id', '')));
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'globalValidationBox',
          value: false
        })
      );
    } else {
      toast('Update tenants error!', {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (err) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenant',
      value: false
    })
  );
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenantView',
      value: false
    })
  );
}

/**
 * 删除租客
 * @param props
 */
function* deleteTenant(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.deleteTenant_Java,
      props.payload.id
    );
    if (res.result === 0) {
      const { requestTenantParams } = yield select(
        (state: storeState) => state.tenantState
      );
      yield put(
        allReducers._getTenantlistParams({ ...requestTenantParams, fill: 0 })
      );
      props.payload.history.push(`/v1/tenants`);
    } else {
      return;
    }
  } catch (err) {}
}

/**
 * 修改租客状态
 * @param props
 */
function* updateTenantStatus(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenant',
      value: true
    })
  );
  try {
    const params = {
      ...props.payload.params
    };
    const res: RESPONSE_TYPES = yield call(request.updateTenantStatus_Java, {
      params,
      id: _.get(props, 'payload.id', '')
    });
    if (res.result === 0) {
      yield put(allReducers._getTenantInfo(_.get(props, 'payload.id', '')));
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'globalValidationBox',
          value: false
        })
      );
    } else {
      toast('Update tenants error!', {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (err) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenant',
      value: false
    })
  );
}

/**
 * 修改租约
 * @param props
 */
function* updateTenancy(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenancy',
      value: true
    })
  );

  const params = {
    ...props.payload
  };
  try {
    const res: RESPONSE_TYPES = yield call(request.updateTenancy_JAVA, {
      params,
      id: _.get(props, 'payload.id', '')
    });
    if (res.result === 0) {
      yield put(
        allReducers._getTenanciesInfo({
          id: _.get(props, 'payload.id', '')
        })
      );
      yield put(
        allReducers._GetFinancialListParams({
          id: 'size',
          text: '',
          data: {
            page: 1,
            size: 20
          }
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'editTenancyModal',
          value: false
        })
      );
    } else if (res.result === -1) {
      if (res.data.message === 'tenancy_number_repeated') {
        const setErrorText = _.get(props, 'payload.setTenancyNOErrorText', '');
        setErrorText(t('The tenancyNumber already exists, please re-enter!'));
      }
      if (res.data.message === 'description_length_too_long') {
        const setErrorText = _.get(props, 'payload.setTextError', '');
        setErrorText(t('Description is too long, please re-enter.'));
      }
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'editTenancy',
          value: false
        })
      );
    } else {
      return;
    }
  } catch (err) {
    // toast(`${err}`, {
    //   id: 'only-one',
    //   icon: '❗',
    //   duration: 3000,
    //   position: 'top-center'
    // });
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'editTenancy',
      value: false
    })
  );
}


/* eslint-disable max-len */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Box from '@mui/material/Box';
import routes from '@/routes';
import ErrorBoundary from '../ErrorBoundary';

export default function Container() {
  const switchRoutes = (
    <React.Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            height: '20vh',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            className="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-r-transparent border-cyan-600 align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
            style={{
              position: 'absolute',
              zIndex: '100',
              left: '50%'
            }}
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </Box>
      }
    >
      <Switch>
        {routes.map((prop: any, key: any) => {
          if (prop.layout === '/v1') {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
          return null;
        })}
        {/* 默认路由、重定向 */}
        <Redirect from="/v1" to="/v1/dashboard" />
      </Switch>
    </React.Suspense>
  );

  return (
    <div className="rounded pb-20 mt-[52px] md:mt-[52px] md:pb-0">
      <ErrorBoundary>{switchRoutes}</ErrorBoundary>
    </div>
  );
}


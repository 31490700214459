import React from 'react';

import { classNames } from '@/utils/fn';

/**
 * left          左侧 图标/按钮/文本
 * right         右侧 图标/按钮/文本
 * label         标题
 * required      必填标志
 * placeholder   提示文本
 * onKeyDown     按键按下方法
 * onChange      值改变方法
 * hintText      提示文本
 *
 * @param props
 * @returns
 */
const FreeField = (props: any) => {
  return (
    <div>
      {(props?.label && (
        <label
          htmlFor="label"
          className="block text-sm font-medium text-gray-700 mb-1"
          style={{ textTransform: 'capitalize' }}
        >
          {props?.label}
          {(props?.required && <span style={{ color: '#b91c1c' }}> *</span>) ||
            ''}
        </label>
      )) ||
        ''}

      <div className="relative flex items-center">
        {props?.leftIcon || ''}
        {props?.leftNode && (
          <div className="absolute inset-y-0 left-0 flex items-center w-[20%]">
            {props?.leftNode}
          </div>
        )}
        {(props?.left && (
          <div
            className={classNames(
              'pointer-events-none absolute inset-y-0 flex items-center pl-3 text-lg',
              (props?.leftNode && 'left-[20%]') || 'left-0'
            )}
          >
            {props?.left}
          </div>
        )) ||
          ''}
        <input
          autoComplete={props?.complete || 'new-password'}
          // autoComplete="on"
          value={props?.value}
          id={props?.id}
          name={props?.name}
          type={props?.type || 'text'}
          className={classNames(
            'block rounded-md border-gray-300 px-3 focus:border-cyan-500 focus:ring-cyan-500 h-12 w-full',
            props?.right && 'pr-16',
            props?.rightIcon && 'pr-10',
            props?.disabled && 'hover:cursor-not-allowed'
          )}
          placeholder={props?.placeholder}
          onKeyDown={(e: any) => props?.onKeyDown?.(e)}
          onChange={(e: any) => props?.onChange?.(e)}
          style={{
            border: '1px solid hsl(0, 0%, 80%)',
            color: '#111827',
            fontWeight: 400,
            ...props?.inputStyle
          }}
          disabled={props?.disabled}
        />
        {(props?.right && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-lg">
            {props?.right}
          </div>
        )) ||
          ''}
        {(props?.rightIcon && props?.rightIcon) || ''}
      </div>
      <p className={(!props?.hintText && 'hidden') || 'mt-[.5rem]'}>
        {props?.hintText}
      </p>
      {(props?.errorText && (
        <div
          style={{
            color: '#b91c1c',
            marginTop: '.5rem'
          }}
        >
          {props?.errorText}
        </div>
      )) ||
        ''}
    </div>
  );
};
export default FreeField;

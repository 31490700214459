import { takeLatest, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { Actions, allReducers, storeState } from '@/store/reducers';
import * as request from '@/axios/request';
import toast from 'react-hot-toast';
import { RESPONSE_TYPES } from '@/axios/axios';
import { t } from '@/i18n';

export function* watchFinancialsSaga() {
  yield takeLatest(Actions.NEW_FINANCIAL, newFinancial);
  yield takeLatest(Actions.GET_FINANCIAL_LIST, getFinancialList);
  yield takeLatest(Actions.GET_FINANCIAL_ACCOUNT_LIST, getFinancialAccounts);
}

/**
 * New Financial
 * @param props
 */
function* newFinancial(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.newFinancial,
      props.payload.obj
    );

    if (res?.result === 0) {
      if (res?.data?.id) {
        if (_.get(props, 'payload.obj.entity', '') !== 'job') {
          props.payload.handleClose();
        } else {
          if (_.get(props, 'payload.obj.tenancyId')) {
            yield put(
              allReducers.getjobInfo({
                job_id: _.get(props, 'payload.obj.entityId', '')
              })
            );
          } else {
            yield put(
              allReducers._GetFinancialList({
                entity: _.get(props, 'payload.obj.entity', ''),
                entityId: _.get(props, 'payload.obj.entityId', '')
              })
            );
          }
        }
        yield put(
          allReducers._FullScreenBackdropStatus({
            key: 'newFinancials',
            value: false
          })
        );
      }
    } else if (res?.result === 100) {
      const setError = props?.payload?.setError;
      setError(t('Description is too long, please re-enter.'));
      const setLoading = props?.payload?.setLoading;
      setLoading(false);
    }
  } catch (error) {}
}
/**
 * Get Financial Accounts List
 * @param props
 */
function* getFinancialAccounts(props: any) {
  const { financialType, financialTypeSize }: storeState['financialState'] =
    yield select((store: storeState) => store.financialState);
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getFinancialType',
      value: true
    })
  );
  try {
    const page = props.payload.page ?? 1;
    const accountType = props.payload.accountType ?? 'exp';
    const { result, data } = yield call(request.getFinancialAccounts, {
      ...props.payload,
      size: financialTypeSize
    });

    if (result === 0) {
      const arr: any = data.data.map((value: any) => {
        return {
          value: _.get(value, 'id', ''),
          label: _.get(value, 'accountName', '')
        };
      });
      const total = data.total ?? 0;
      yield put({
        type: Actions.GET_FINANCIAL_ACCOUNT_LIST_OK,
        payload: {
          data: financialType.concat(arr),
          total: total
        }
      });
      if (total > page * financialTypeSize) {
        yield put(
          allReducers._GetFinancialAccounts({
            page: page + 1,
            size: financialTypeSize,
            accountType: accountType
          })
        );
      }
    } else {
      toast('Get financial accounts error!', {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getFinancialType',
      value: false
    })
  );
}
/**
 * Get Financial List
 * @param props
 */
function* getFinancialList(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getExpenseListing',
      value: true
    })
  );
  try {
    const { result, data } = yield call(request.getFinancial, props.payload);

    if (result === 0) {
      let num = 0;
      // eslint-disable-next-line array-callback-return
      data.data.map((item: any) => {
        num += Number(item.amount);
        item.accountName = _.get(item, 'financialAccount.accountName', '');
        item.amount = `${_.get(item, 'amount')}` || '';
        item.currency = `${_.get(item, 'currency')}` || '';
        item.association = {
          entity: item.entity,
          entity_id: item.entityId,
          entity_name: item.association || ''
        };
        item.type = `${_.get(item, 'financialAccount.accountName', '')}-${_.get(
          item,
          'financialAccount.accountType',
          ''
        )}`;
      });
      data.expenses = num;
      data.currency = _.get(data, 'data[0].currency', '');

      yield put({
        type: 'GET_FINANCIAL_LIST_OK',
        payload: {
          ...data,
          page: data.pageNum,
          size: data.pageSize,
          total: data.total
        }
      });
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getExpenseListing',
      value: false
    })
  );
}


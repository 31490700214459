import { takeLatest, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { Actions, allReducers, storeState } from '@/store/reducers';
import * as request from '@/axios/request';
import { RESPONSE_TYPES } from '@/axios/axios';
import { filterName } from '@/utils/fn';
import toast from 'react-hot-toast';
import { t } from '@/i18n';

export function* watchKnowledgeBaseSaga() {
  yield takeLatest(Actions.GET_SIGNATURE, getSignature);
  yield takeLatest(Actions.UPLOAD_ATTACHMENTFILETOS3, uploadAttachmentFileToS3);
  yield takeLatest(Actions.New_ATTACHMENTFILE, newAttachmentFile);
  yield takeLatest(Actions.UPDATE_ATTACHMENTFILE, updateAttachmentFile);
  yield takeLatest(Actions.REMOVED_ATTACHMENTFILE, removedAttachmentFile);
  yield takeLatest(Actions.COPY_ATTACHMENTFILE, copyAttachmentFile);
  yield takeLatest(Actions.GET_ATTACHMENTFILE_LIST, getAttachmentFileList);
  yield takeLatest(
    Actions.GET_ATTACHMENTFILEFOLDER_LIST,
    getAttachmentFolderList
  );
  yield takeLatest(Actions.NEW_SHAREATTACHMENTFILE, newShareAttachmentFile);
  yield takeLatest(
    Actions.GET_SHAREATTACHMENTFILE_LIST,
    getShareAttachmentFileList
  );
  yield takeLatest(
    Actions.REMOVED_SHAREATTACHMENTFILE,
    removeShareAttachmentFile
  );
}

/**
 * 获取s3 token
 * @param props
 */
function* getSignature(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'overMaxLength',
      value: false
    })
  );
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  try {
    const signData = {
      contentSha256: props.payload.contentSha256,
      originFileName:
        (props.payload.originFileName && props.payload.originFileName) ||
        props.payload.originFileName
    };
    const res: RESPONSE_TYPES = yield call(request.getSignature_Java, signData);
    if (res.result === 0) {
      const data = res.data;
      if (_.get(props, 'payload.maxLength', '') > data.maxContentLength) {
        yield put(
          allReducers._FullScreenBackdropStatus({
            key: 'overMaxLength',
            value: true
          })
        );
      } else {
        const headers = {
          Authorization: data.headers.Authorization,
          'x-amz-content-sha256': data.headers['x-amz-content-sha256'],
          'x-amz-date': data.headers['x-amz-date'],
          'x-amz-storage-class': data.headers['x-amz-storage-class']
        };
        const uploadS3Data = {
          url: data.url,
          method: data.method,
          options: props.payload.data,
          headers: headers,
          key: data.key
        };
        yield put({
          type: 'GET_UPLOADS3DATA_SUCCESS',
          payload: uploadS3Data
        });
      }
    } else {
      toast(res.data, {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
    }
  } catch (error) {}
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: false
    })
  );
}
/**
 * 上传文件到s3服务器
 * @param props
 */
function* uploadAttachmentFileToS3() {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  const { uploadS3Data }: storeState['knowledgebaseState'] = yield select(
    (store: storeState) => store.knowledgebaseState
  );
  try {
    const res: RESPONSE_TYPES = yield call(request.uploadToS3, uploadS3Data);
    if (res.result === 0) {
      yield put({
        type: 'UPLOAD_ATTACHMENTFILETOS3_SUCCESS',
        payload: uploadS3Data.key
      });
    } else {
      toast(res.data, {
        id: 'only-one',
        icon: '❗',
        duration: 10000,
        position: 'top-center'
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    }
  } catch (error: any) {}
}

/**
 * 新建 附件
 * @param props
 */
function* newAttachmentFile(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.saveAttachmentFile_Java,
      props.payload
    );
    const params = {
      entity: _.get(props, 'payload.entity', ''),
      entityId: _.get(props, 'payload.entityId', ''),
      parentId: _.get(props, 'payload.parentId', ''),
      size: 100
    };
    if (res.result === 0) {
      const onRefresh = _.get(props, 'payload.onRefresh', '');
      onRefresh({
        ...params,
        fileName: _.get(props, 'payload.fileName', ''),
        id: params?.parentId,
        refresh: true
      });
      const onOpen = _.get(props, 'payload.onOpen', '');
      onOpen(false);
      const setCheckedE = _.get(props, 'payload.setCheckedE', '');
      setCheckedE('F');
      const setRepeatText = _.get(props, 'payload.setRepeatText', '');
      setRepeatText('');
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'noFile',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newFolder',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'newFileOrLink',
          value: false
        })
      );

      const setFilename = _.get(props, 'payload.setFilename', '');
      setFilename(null);
      const selectShowA = _.get(props, 'payload.selectShowA', '');
      selectShowA(true);
      const selectShowB = _.get(props, 'payload.selectShowB', '');
      selectShowB(false);
      const selectShowC = _.get(props, 'payload.selectShowC', '');
      selectShowC(false);

      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    } else if (res.result === -1) {
      const setRepeatText = _.get(props, 'payload.setRepeatText', '');
      const setTextError = _.get(props, 'payload.setTextError', '');

      if (res.data.message === 'file_name_repeat') {
        if (_.get(props, 'payload.attachmentType', '') === 'url') {
          setRepeatText(`The link already exists, please recreate it!`);
        } else {
          setRepeatText(
            `The ${_.get(
              props,
              'payload.fileName',
              ''
            )} already exists, please recreate it!`
          );
        }
      }
      if (res.data.message === 'description_length_too_long') {
        setTextError(t('Description is too long, please re-enter.'));
      }
      yield put({
        type: 'UPLOAD_FIELE_ERR'
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    }
  } catch (error: any) {}
}

function* getAttachmentFolderList(props: any) {
  try {
    // 获取文件夹
    const res_folder: RESPONSE_TYPES = yield call(
      request.getAttachmentFileListing_Java,
      {
        entity: _.get(props, 'payload.entity', ''),
        entityId: _.get(props, 'payload.entityId', ''),
        parentId: _.get(props, 'payload.parentId', ''),
        attachmentType: 'folder'
      }
    );
    if (res_folder.result === 0) {
      const datas = _.get(res_folder, 'data.data', []);
      const folder: any = [];
      let num = 1;

      if (_.get(props, 'payload.parentId', '')) {
        const folderinfo: RESPONSE_TYPES = yield call(
          request.getAttachmentFileInfo_Java,
          _.get(props, 'payload.parentId', '')
        );
        if (folderinfo.result === 0) {
          folder.push({
            id: _.get(folderinfo, 'data.id', ''),
            name: _.get(folderinfo, 'data.file.fileName', ''),
            parentId: _.get(folderinfo, 'data.parentId', ''),
            num: 1,
            value: _.get(folderinfo, 'data.id', ''),
            label: _.get(folderinfo, 'data.file.fileName', '')
          });
        }
      }
      datas.forEach((value: any) => {
        num++;
        folder.push({
          id: _.get(value, 'id', ''),
          name: _.get(value, 'file.fileName', ''),
          parentId: _.get(value, 'parentId', ''),
          num: num,
          value: _.get(value, 'id', ''),
          label: _.get(value, 'file.fileName', '')
        });
      });

      yield put({
        type: 'GET_ATTACHMENTFILEFOLDER_LIST_SUCCESS',
        payload: {
          folder: folder.sort((a: any, b: any) => {
            return a.parentId > b.parentId ? 1 : -1;
          })
        }
      });
    }
  } catch (error) {}
}
/**
 * 获取附件列表
 * @param props
 */
function* getAttachmentFileList(props: any) {
  const { allFiles, isView }: storeState['knowledgebaseState'] = yield select(
    (store: storeState) => store.knowledgebaseState
  );

  if (!isView) {
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'getAttachment',
        value: true
      })
    );
  }

  try {
    // 当shareToMe=1时查询列表数据
    if (!_.get(props, 'payload.shareToMe', '')) {
      const res_shareToMe: RESPONSE_TYPES = yield call(
        request.getAttachmentFileListing_Java,
        {
          entity: _.get(props, 'payload.entity', ''),
          entityId: _.get(props, 'payload.entityId', ''),
          shareToMe: 1
        }
      );
      if (res_shareToMe.result === 0) {
        const datas = _.get(res_shareToMe, 'data.data', []);
        if (datas.length > 0) {
          yield put({
            type: 'GET_ATTACHMENTFILESHARETOME_LIST_SUCCESS',
            payload: true
          });
        } else {
          yield put({
            type: 'GET_ATTACHMENTFILESHARETOME_LIST_SUCCESS',
            payload: false
          });
        }
      }
    }

    yield put(
      allReducers._getAttachmentFolderList({ ...props.payload, parentId: '' })
    );

    const res: RESPONSE_TYPES = yield call(
      request.getAttachmentFileListing_Java,
      props.payload
    );

    if (res.result === 0) {
      const datas = _.get(res, 'data.data', []);
      const arr: any = datas.map((value: any) => {
        return {
          id: _.get(value, 'id', ''),
          entity: _.get(value, 'entity', ''),
          entityId: _.get(value, 'entityId', ''),
          parentId: _.get(value, 'parentId', ''),
          type: _.get(value, 'attachmentType', ''),
          description: _.get(value, 'description', ''),
          filename:
            _.get(value, 'attachmentType', '') === 'url'
              ? _.get(value, 'text', '')
              : _.get(value, 'file.fileName', ''),
          shareType: _.get(value, 'share.shareType', ''),
          shareCreateBy: _.get(value, 'share.createBy', ''),
          updateUser: filterName(
            _.get(value, 'share.updateUser.firstname', ''),
            _.get(value, 'share.updateUser.middlename', ''),
            _.get(value, 'share.updateUser.lastname', '')
          ),
          shareUser: _.get(value, 'share.user', []).map((v: any) => {
            return {
              value: _.get(v, 'userId', ''),
              label: filterName(
                _.get(v, 'firstname', ''),
                _.get(v, 'middlename', ''),
                _.get(v, 'lastname', '')
              )
            };
          }),
          attachmentId: _.get(value, 'file.id', '')
        };
      });
      // 所有获取到的数据整合
      const allArr = (allFiles || []).concat(arr);
      // 将所有数据去重
      const newArr: Array<any> = [];

      allArr.forEach((itm: any) => {
        if (newArr.find((i: any) => i.id === itm.id)) return;
        newArr.push(itm);
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'noFile',
          value: newArr?.length < 1
        })
      );

      yield put({
        type: 'GET_ATTACHMENTFILE_LIST_SUCCESS',
        payload: {
          attachmentfileList: arr,
          folderId: _.get(props, 'payload.parentId', ''),
          allFiles: newArr
        }
      });
    }
  } catch (error) {
    yield put(
      allReducers._FullScreenBackdropStatus({
        key: 'getAttachment',
        value: false
      })
    );
  }
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'getAttachment',
      value: false
    })
  );
}

/**
 * 更新 附件描述
 * @param props
 */
function* updateAttachmentFile(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  try {
    const entity = _.get(props, 'payload.entity', '');
    const entityId = _.get(props, 'payload.entityId', '');
    const params = {
      description: _.get(props, 'payload.description', ''),
      fileName: _.get(props, 'payload.filename', ''),
      text: _.get(props, 'payload.text', '')
    };
    const res: RESPONSE_TYPES = yield call(
      request.updateAttachmentFileInfo_Java,
      {
        params,
        id: _.get(props, 'payload.id', '')
      }
    );
    if (res.result === 0) {
      const onRefresh = _.get(props, 'payload.onRefresh', '');
      onRefresh({
        ...params,
        entity,
        entityId,
        fileId: _.get(props, 'payload.id', ''),
        id: _.get(props, 'payload.parentId', ''),
        refresh: true
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'editFolder',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'editFileOrLink',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    } else if (res?.result === -1) {
      const setTextError = _.get(props, 'payload.setTextError', '');
      if (res.data.message === 'description_length_too_long') {
        setTextError(t('Description is too long, please re-enter.'));
      }
      yield put({
        type: 'UPLOAD_FIELE_ERR'
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    }
  } catch (error) {}
}

/**
 * 批量删除附件
 * @param props
 */
function* removedAttachmentFile(props: any) {
  // const {
  //   attachmentfileList
  // }: storeState["knowledgebaseState"] = yield select(
  //   (store: storeState) => store.knowledgebaseState
  // );

  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  const id = _.get(props, 'payload.id', '');
  try {
    const res: RESPONSE_TYPES = yield call(request.deleteAttachmentFile_Java, {
      attachmentIdIn: id
    });
    if (res.result === 0) {
      // yield put(
      //   allReducers._getAttachmentFileList({
      //     entity: _.get(props, "payload.entity", ""),
      //     entityId: _.get(props, "payload.entityId", ""),
      //     parentId: _.get(props, "payload.parentId", ""),
      //   })
      // );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'removedFiles',
          value: false
        })
      );
      const onRefresh = _.get(props, 'payload.onRefresh', '');
      onRefresh(props.payload, 'delete');
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    }
  } catch (error) {}
}

/**
 * 批量复制附件
 * @param props
 */
function* copyAttachmentFile(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.copyAttachmentFile_Java,
      props.payload
    );
    if (res.result === 0) {
      const onRefresh = _.get(props, 'payload.onRefresh', '');
      onRefresh({
        ...props.payload,
        id: props.payload.parentId,
        refresh: true
      });
      yield put(
        allReducers._getAttachmentFileList({
          entity: _.get(props, 'payload.entity', ''),
          entityId: _.get(props, 'payload.entityId', ''),
          parentId: _.get(props, 'payload.tabParentId', '')
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'movetoFolder',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    } else if (res.result === -1) {
      const setRepeatText = _.get(props, 'payload.setRepeatText', '');
      if (res.data.message === 'file_name_repeat') {
        setRepeatText(
          `The file already exists in this folder, please rename it or move to other folder!`
        );
      }
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
    }
  } catch (error) {}
}

/**
 * 新增 分享文件
 * @param props
 */
function* newShareAttachmentFile(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.saveShareAttachmentFile_Java,
      props.payload
    );
    if (res.result === 0) {
      // yield put(
      //   allReducers._getShareAttachmentFileList({ page: 1, size: 100 })
      // );
      yield put(
        allReducers._getAttachmentFileList({
          entity: _.get(props, 'payload.entity', ''),
          entityId: _.get(props, 'payload.entityId', ''),
          parentId: _.get(props, 'payload.parentId', '')
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'shareFiles',
          value: false
        })
      );
      const onOpen = _.get(props, 'payload.onOpen', '');
      onOpen(false);
      const setShareIdIn = _.get(props, 'payload.setShareIdIn', '');
      setShareIdIn([]);
    }
  } catch (error) {}
}

/**
 * 查询 分享文件列表
 * @param props
 */
function* getShareAttachmentFileList(props: any) {
  try {
    const res: RESPONSE_TYPES = yield call(
      request.getShareAttachmentListing_Java,
      props.payload
    );
    if (res.result === 0) {
      const datas = _.get(res, 'data.data', '');
      const arr: any = datas.map((value: any) => {
        return {
          id: _.get(value, 'id', ''),
          shareId: _.get(value, 'shareId', ''),
          description: _.get(value, 'description', ''),
          type: _.get(value, 'attachmentType', ''),
          filename:
            _.get(value, 'attachmentType', '') === 'url'
              ? _.get(value, 'text', '')
              : _.get(value, 'file.fileName', ''),
          shareType: _.get(value, 'shareType', ''),
          clientname: filterName(
            _.get(value, 'user.firstname', ''),
            _.get(value, 'user.middlename', ''),
            _.get(value, 'user.lastname', '')
          )
        };
      });
      const arr1 = arr.filter((item: any) => item.shareType === 'public');
      const arr2 = arr.filter((item: any) => item.shareType === 'client');
      yield put({
        type: 'GET_SHAREATTACHMENTFILE_LIST_SUCCESS',
        payload: {
          publicList: arr1,
          clientList: arr2
        }
      });
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'getShareAttachment',
          value: false
        })
      );
    }
  } catch (error) {}
}

function* removeShareAttachmentFile(props: any) {
  yield put(
    allReducers._FullScreenBackdropStatus({
      key: 'filetip',
      value: true
    })
  );
  try {
    const res: RESPONSE_TYPES = yield call(
      request.deleteShareAttachmentFile_Java,
      {
        shareIdIn: _.get(props, 'payload.shareId', '')
      }
    );
    if (res.result === 0) {
      yield put(
        allReducers._getShareAttachmentFileList({ page: 1, size: 100 })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'removedFiles',
          value: false
        })
      );
      yield put(
        allReducers._FullScreenBackdropStatus({
          key: 'filetip',
          value: false
        })
      );
      const onOpen = _.get(props, 'payload.onOpen', '');
      onOpen(false);
    }
  } catch (error) {}
}


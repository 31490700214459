import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Stores from '@/store/index';
import NewAdmin from '@/layouts/NewAdmin';
import './i18n';
import SignupPage from './views/User/SignupPage';
import LoginPage from '@/views/User/LoginPage';
import ForgotPasswordPage from '@/views/User/ForgotPasswordPage';
import VerificationPage from '@/views/User/VerificationPage';
import ResetPasswordPage from '@/views/User/ResetPasswordPage';
import QrcodePage from './views/User/QrcodePage';

LicenseInfo.setLicenseKey(
  '0bc28b6e05baf191d9c23a581ed893faTz03OTQxNixFPTE3MzI1MDEwNjQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);
if (process.env.NODE_ENV === 'production') {
  console.log = function () {}; // 禁用所有控制台输出
}
export const homeBozzTheme = createTheme({
  palette: {
    financials: {
      expenses: '#FF7D31',
      revenue: '#4344A6',
      error: '#EE2D41',
      successfully: '#0BB7AF'
    }
  }
});
const store = Stores();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={homeBozzTheme}>
        <Provider store={store}>
          <BrowserRouter basename={window.__POWERED_BY_QIANKUN__ ? '/v1' : '/'}>
            <Switch>
              <Route path="/v1" component={NewAdmin} />
              <Route path="/login" component={LoginPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/qrcode/:qrcode" component={QrcodePage} />
              <Route
                path="/verification/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                path="/verification/password"
                component={VerificationPage}
              />
              <Route
                path="/verification/reset-password"
                component={ResetPasswordPage}
              />
              {localStorage.getItem('token') && localStorage.getItem('user') ? (
                <Redirect from="/" to="/v1" />
              ) : (
                <Redirect from="/" to="/login" />
              )}
            </Switch>
          </BrowserRouter>
          <Toaster reverseOrder={true} />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;


import React, { useEffect } from 'react';
import * as Request from '@/axios/request';
import { useHistory, useParams } from 'react-router-dom';
import { openAlertDialog } from '@/utils/openDialog';
import { t } from '@/i18n';

export default function QrcodePage() {
  const history = useHistory();
  const params = useParams();
  const { qrcode }: any = params;
  async function goToUrl(code: string) {
    const res: any = await Request.getBookingByCode({
      code
    });
    if (res?.result === 0) {
      if (res?.data?.entity === 'booking') {
        const book: any = await Request.getBookingRecordsJavaInfo(
          res?.data?.entityId
        );
        if (!book?.data || book?.data?.accountId !== res?.data?.accountId) {
          openAlertDialog({
            type: 'error',
            title: t(
              'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
            ),
            size: '2xl',
            // contant: t(
            //   'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
            // ),
            leftBtn: {
              label: 'ok',
              onClick: (close) => {
                close();
                history.push('/v1/bookings');
              }
            }
          });
          return;
        }
        history.push(`/v1/bookingInfo/${res?.data?.entityId}/${code}`);
        return;
      } else {
        openAlertDialog({
          type: 'error',
          title: t(
            'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
          ),
          size: '2xl',
          // contant: t(
          //   'This QR code is invalid, please make sure it is a valid booking QR code, thanks.'
          // ),
          leftBtn: {
            label: 'ok',
            onClick: (close) => {
              close();
              history.push('/v1/bookings');
            }
          }
        });
        return;
      }
    } else {
      history.push('/v1/bookings');
      return;
    }
  }

  useEffect(() => {
    if (!qrcode) return;

    localStorage.setItem('qrcode', qrcode);
    if (localStorage.getItem('user')) {
      goToUrl(qrcode);
    } else {
      const link = `/login/qrcode/${qrcode}`;
      const win: any = window;
      win.top.location.href = link;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrcode]);

  return <div />;
}
